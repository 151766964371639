<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import { UiFormCheckbox, UiFormNumberInput } from 'redge-media-web-ui';
import { NumberDivider } from 'redge-media-web-utils';
import Urls from '@/application/urls';
import { ItemType, RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';

const isTimeshift = ({ type }) => type === ItemType.TIMESHIFT;

const getQuantityUnit = (model) => (isTimeshift(model) ? 'PRODUCT_SPECIAL_QUANTITY_MINUTES' : 'PRODUCT_SPECIAL_QUANTITY_DAYS');

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        modelUrl: Urls.PRODUCTS_SPECIAL_ID(this.id),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.EXTERNAL_UID,
            Fields.TITLE,
            Fields.TYPE_DISABLED,
            {
              field: 'quantity',
              isHidden: [ItemType.DOWNLOAD, ItemType.NO_ADVERTISING].includes(model.type),
              component: UiFormNumberInput,
              componentOptions: {
                label: this.quantityLabel(model),
                divider: this.quantityDevider(model),
                tooltip: Fields.commonTooltip(this.quantityUnitTooltip(model)),
              },
            },
            {
              field: 'global',
              component: UiFormCheckbox,
              componentOptions: {
                label: 'PRODUCT_SPECIAL_GLOBAL',
              },
            },
            Fields.CREATE_SINCE(),
            Fields.CREATE_TILL(),
            Fields.PLATFORMS,
          ],
          [Fields.HEADER_LEAD, Fields.LEAD, Fields.HEADER_DESCRIPTION, Fields.textareaField('description')],
        ],
      };
    },
  },
  methods: {
    quantityUnitTooltip(model) {
      switch (model.type) {
        case ItemType.CATCH_UP:
          return this.$translate('HELP_PRODUCT_SPECIAL_CATCH_UP_QUANTITY');
        case ItemType.MAX_BITRATE:
          return this.$translate('HELP_COMMON_BITRATE');
        case ItemType.TIMESHIFT:
          return this.$translate('HELP_PRODUCT_SPECIAL_TIMESHIFT_QUANTITY');
        case ItemType.VIDEO_SESSION_LIMIT:
          return this.$translate('HELP_VIDEO_SESSION_LIMIT_QUANTITY');
        default:
          return this.$translate('HELP_PRODUCT_SPECIAL_N_PVR_QUANTITY');
      }
    },
    quantityDevider(model) {
      switch (model.type) {
        case ItemType.N_PVR:
        case ItemType.CATCH_UP:
          return NumberDivider.DAY_TO_SECONDS;
        case ItemType.TIMESHIFT:
          return NumberDivider.MINUTE_TO_SECONDS;
        default:
          return 1;
      }
    },
    quantityLabel(model) {
      switch (model.type) {
        case ItemType.MAX_BITRATE:
          return `${this.$translate('COMMON_BITRATE')}`;
        case ItemType.VIDEO_SESSION_LIMIT:
          return `${this.$translate('COMMON_QUANTITY')}`;
        default:
          return `${this.$translate('COMMON_QUANTITY')} ${this.$translate(getQuantityUnit(model))}`;
      }
    },
  },
};
</script>
