<script>
import Vue from 'vue';
import Urls from '@/application/urls';
import http, { CancelToken, isHttpRequestCanceled } from '@/lib/http';
import { AppEvent } from '@/enums';
import { getActiveFilters, getAvailableFilterName, getDefaultFilters } from '@/lib/settings-filters';
import { ExportMixin, ListViewRouterMixin, RoleMixin } from '@/mixin';
import VListWrapper from '@/components/list-view/list-wrapper.vue';
import errorHandler from '@/helper/error-handler';

export const DEFAULT_PAGINATION_QUERY = {
  maxResults: 50,
  firstResult: 0,
};

export default Vue.extend({
  components: {
    VListWrapper,
  },
  mixins: [ExportMixin, RoleMixin, ListViewRouterMixin],
  data() {
    return {
      filters: [],
      buttons: [],
      paramsConfig: {},
      records: [],
      collectionMeta: {},
      availableFiltersSettings: [],
      source: undefined,
      isViewInitialized: false,
      model: undefined,
    };
  },
  computed: {
    activeFilters() {
      return getActiveFilters(this.routeQuery, this.filters);
    },
  },
  watch: {
    routeQuery: {
      async handler(newValue, oldValue) {
        if (!this.isViewInitialized) {
          await this.initializeView();
          this.isViewInitialized = true;
        }

        if (!Object.entries(this.routeQuery).length) {
          this.addInitQueryParams();
        } else if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.load().catch(() => {});
        }
      },
      deep: true,
      immediate: true,
    },
    recordsUrl: 'load',
  },
  beforeDestroy() {
    this.source?.cancel();
  },
  methods: {
    async initializeView() {
      this.availableFiltersSettings = await http
        .get(Urls.APPLICATION_GUI_FILTER_NAME(getAvailableFilterName(this.$route)))
        .then(({ data }) => data)
        .catch(() => []);

      const wrapper = this.$children.find((child) => child.$options.name === 'list-wrapper');

      if (wrapper) {
        wrapper.$set(wrapper, 'filters', this.filters);
        wrapper.$set(wrapper, 'buttons', this.buttons);
        wrapper.$set(wrapper, 'collectionMeta', this.collectionMeta);
        wrapper.$set(wrapper, 'availableFiltersSettings', this.availableFiltersSettings);
      }
    },
    addInitQueryParams() {
      const shouldApplyDefaultFilters = Object.keys(this.activeFilters).length === 0
        && this.routeQuery.filterId === undefined
        && this.availableFiltersSettings?.length;

      const queryList = {
        ...DEFAULT_PAGINATION_QUERY,
        ...this.paramsConfig,
        ...this.routeQuery,
        ...(shouldApplyDefaultFilters ? getDefaultFilters(this.availableFiltersSettings) : {}),
      };
      this.updateRouteQuery(queryList);
    },
    async load() {
      this.source?.cancel();
      this.source = CancelToken.source();

      this.$el.classList.add('loading');

      try {
        const { data } = await http.get(this.recordsUrl, {
          params: { ...this.routeQueryWithoutFilterId, ...this.extraParams },
          cancelToken: this.source.token,
        });
        this.records = data.items || data;
        Object.entries(data.meta || {}).forEach(([key, value]) => this.$set(this.collectionMeta, key, value));
        this.model = data.custom;

        this.$emit(AppEvent.MODEL, data.custom);
      } catch (error) {
        if (!isHttpRequestCanceled(error)) {
          errorHandler(error?.response?.data?.errors?.[0] || {}, this);
        }
      }

      this.$el.classList.remove('loading');
    },
  },
});
</script>
