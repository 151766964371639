<template>
  <div class="ui-form-input">
    <label
      v-tooltip="tooltip"
      :for="attrs.id"
      class="ui-form-input__label"
      v-text="`${$translate(label)}:`"
    ></label>
    <div class="ui-form-input__input-wrapper">
      <ui-price-input v-bind="attrs" class="ui-input ui-form-input__input" v-on="$listeners" />
    </div>
  </div>
</template>
<script>
import UiPriceInput from '@/components/ui-price-input/index.vue';

export default {
  components: {
    UiPriceInput,
  },
  inheritAttrs: false,
  props: {
    label: String,
    tooltip: [String, Object],
  },
  computed: {
    attrs() {
      const { id, ...attrs } = this.$attrs;

      return {
        ...attrs,
        id: id || `input-${this._uid}`,
      };
    },
  },
};
</script>
