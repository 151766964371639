const addLeadingZero = (num) => (num < 10 ? '0' : '') + num;

const formatHourAndMinutes = (duration) => {
  const hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration - hours * 3600) / 60);

  if (hours && minutes < 10) {
    minutes = `0${minutes}`;
  }

  if (minutes) {
    return !hours ? `${minutes}m` : `${hours}h ${minutes}m`;
  }

  return undefined;
};

export default (seconds) => {
  const secondsValue = isNaN(seconds) ? 0 : seconds;
  const hh = addLeadingZero(Math.floor(secondsValue / 3600));
  const mm = addLeadingZero(Math.floor(secondsValue / 60) % 60);
  const ss = addLeadingZero(Math.floor(secondsValue) % 60);

  return `${hh}:${mm}:${ss}`;
};

export { formatHourAndMinutes };
