import { UiModalPopup } from 'redge-media-web-ui';
import http from '@/lib/http';

export default {
  data() {
    return {
      isSynchronizing: false,
    };
  },
  methods: {
    async synchronizeModel({ incremental, id }) {
      if (this.isSynchronizing) {
        return;
      }

      try {
        this.isSynchronizing = true;
        this.$modal.create(UiModalPopup, {
          text: this.$translate('COMMON_SYNCHRONIZATION_STARTED'),
        });
        await http.put(id ? this.synchronizeUrl(id) : this.synchronizeUrl, { incremental });
        await this.init();
        this.isSynchronizing = false;
      } catch (error) {
        this.isSynchronizing = false;
        throw error;
      }
    },
  },
};
