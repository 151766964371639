<template>
  <person-list :person-type-url="personTypeUrl" :person-url="personUrl" v-on="$listeners" />
</template>
<script>
import Urls from '@/application/urls';
import PersonList from '@/components/person-list/index.vue';

export default {
  components: {
    PersonList,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      personTypeUrl: Urls.PRODUCTS_LIVES_PROGRAMMES_PERSON_TYPES,
      personUrl: Urls.PRODUCTS_LIVES_PROGRAMMES_ID_PERSONS(this.id),
    };
  },
};
</script>
