<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import { UiTableDetailLink } from '@/components/ui-table';
import UiFormDictionarySelect from '@/components/ui-form/ui-form-dictionary-select.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Filters from '@/components/filter/filters';
import * as Fields from '@/components/ui-form/fields';
import * as ContentFields from '@/modules/content/view/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.DOCUMENTS,
      removeModelUrlFunction: ({ id }) => Urls.DOCUMENTS_ID(id),
      saveModelUrlFunction: () => Urls.DOCUMENTS,
      saveModelModalTitleFunction: () => 'ADD_DOCUMENT',
      saveModelFormElementsFunction: () => [
        [
          ContentFields.ALIAS,
          Fields.TYPE(Urls.DOCUMENTS_TYPES),
          Fields.checkboxField('multilingual', 'COMMON_MULTILINGUAL'),
          {
            field: 'mimeType',
            isMandatory: true,
            component: UiFormDictionarySelect,
            componentOptions: {
              label: 'DOCUMENT_MIME_TYPE',
              dictUrl: Urls.DOCUMENTS_MIME_TYPES,
            },
          },
          Fields.TITLE,
        ],
      ],
      paramsConfig: {
        sort: QueryParam.ALIAS,
        order: QueryParam.ASCENDING,
      },
      filters: [
        Filters.PLATFORMS,
        Filters.autocomplete('types', { label: 'COMMON_TYPE', dictUrl: Urls.DOCUMENTS_TYPES }),
        Filters.MULTILINGUAL,
        Filters.autocomplete('mime_types', { label: 'DOCUMENT_MIME_TYPE', dictUrl: Urls.DOCUMENTS_MIME_TYPES }),
        Filters.input('content_length_less', { label: 'DOCUMENT_LENGTH_LESS_THAN', type: 'number', single: true }),
        Filters.input('content_length_greater', {
          label: 'DOCUMENT_LENGTH_GREATER_THAN',
          type: 'number',
          single: true,
        }),
      ],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'alias',
            minWidth: 240,
            isSortable: true,
            headingLabel: 'DOCUMENT_ALIAS',
            dataComponent: UiTableDetailLink,
            dataComponentPropsMapper: ({ id, alias }) => ({ id, text: alias, name: 'document.detail' }),
          },
          {
            field: 'title',
            minWidth: 240,
            isSortable: true,
            headingLabel: 'COMMON_TITLE',
            dataMapper: ({ title }) => title,
          },
          Columns.PLATFORMS,
          Columns.TYPE,
          {
            field: 'mimeType',
            width: 150,
            headingLabel: 'DOCUMENT_MIME_TYPE',
          },
          {
            field: 'contentLength',
            width: 90,
            isSortable: true,
            headingLabel: 'DOCUMENT_LENGTH',
            dataMapper: ({ contentLength }) => contentLength,
          },
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
