<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import {
  Constants, ItemType, QueryParam, RoleName,
} from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import { priceFormat } from '@/helper';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';
import * as PaymentFields from '@/modules/payment/view/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    const url = Urls.PROMOTIONS_ID_CRITERIA(this.id);

    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_PROMOTION_PRODUCTS_CRITERIA' : 'ADD_PROMOTION_PRODUCTS_CRITERIA'),
      saveModelFormElementsFunction: (model) => [
        [
          Fields.ITEM_TYPE(model, Constants.ALL_PRODUCT_TYPES),
          PaymentFields.MIN_PRICE(),
          PaymentFields.MAX_PRICE(),
          PaymentFields.MIN_RATING(),
          PaymentFields.MAX_RATING(),
          Fields.PROVIDER,
        ],
      ],
      saveModelDefaultValues: {
        itemType: ItemType.VOD,
      },
      paramsConfig: {
        sort: QueryParam.ID,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'itemType',
            minWidth: 100,
            headingLabel: 'COMMON_PRODUCT_TYPE',
          },
          {
            field: 'minPrice',
            minWidth: 100,
            dataMapper: ({ minPrice }) => priceFormat(minPrice),
            headingLabel: 'MIN_PRICE',
          },
          {
            field: 'maxPrice',
            minWidth: 100,
            dataMapper: ({ maxPrice }) => priceFormat(maxPrice),
            headingLabel: 'MAX_PRICE',
          },
          {
            field: 'minRating',
            minWidth: 200,
            headingLabel: 'MIN_RATING',
          },
          {
            field: 'maxRating',
            minWidth: 200,
            headingLabel: 'MAX_RATING',
          },
          {
            field: 'provider',
            minWidth: 240,
            headingLabel: 'PRODUCT_PROVIDER',
            dataMapper: ({ provider }) => provider?.name,
          },
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
