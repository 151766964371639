<template>
  <ui-detail v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import { UiFormNumberInput } from 'redge-media-web-ui';
import { NumberDivider } from 'redge-media-web-utils';
import Urls from '@/application/urls';
import { ItemType, RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        modelUrl: Urls.PRODUCTS_VODS_ID_PAGE(this.id, this.page),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.checkboxField('downloadAvailable', 'COMMON_AVAILABILITY'),
            {
              field: 'availabilityDuration',
              isMandatory: model.downloadAvailable && model.type !== ItemType.SERIAL,
              component: UiFormNumberInput,
              componentOptions: {
                label: 'DOWNLOAD_AVAILABILITY_DURATION',
                divider: NumberDivider.DAY_TO_SECONDS,
                tooltip: Fields.commonTooltip('HELP_DOWNLOAD_AVAILABILITY_DURATION'),
              },
            },
            {
              field: 'startedAvailabilityDuration',
              component: UiFormNumberInput,
              componentOptions: {
                label: 'DOWNLOAD_STARTED_AVAILABILITY_DURATION',
                divider: NumberDivider.HOUR_TO_SECONDS,
                tooltip: Fields.commonTooltip('HELP_DOWNLOAD_STARTED_AVAILABILITY_DURATION'),
              },
            },
          ],
        ],
      };
    },
  },
};
</script>
