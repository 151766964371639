<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
        >
          <router-link :to="{ name: 'serial.detail', params: { id: model.serial.id, page: Page.DETAIL } }">
            <ui-product-title :product="model.serial" />
          </router-link>
          /
          <router-link :to="{ name: 'serial.season.detail', params: { id: model.season.id, page: Page.DETAIL } }">
            <ui-product-title :product="model.season" />
          </router-link>
          /
          <ui-product-title :product="model" />
        </h1>
        <div class="details-container__header-icons">
          <div class="details-container__header-elements details-container__header-elements--left">
            <ui-external-application-icons :model="model" :is-model-active="isModelActive" />
          </div>
          <div class="details-container__header-elements">
            <span
              v-if="hasRole(RoleName.ADMIN) && model && model.externalUid"
              v-tooltip="$translate('HELP_SYNCHRONIZATION')"
              class="details-container__header-element icon-timeshift"
              @click="synchronizeModel({ incremental: false })"
            ></span>
            <span
              v-if="isAllowed && model && model.externalUid"
              v-tooltip="$translate('HELP_SYNCHRONIZATION_INCREMENTAL')"
              class="details-container__header-element icon-efficiency"
              @click="synchronizeModel({ incremental: true })"
            ></span>
            <ui-switch
              v-if="model"
              v-tooltip="activateModelButtonText"
              :disabled="!isAllowed"
              :value="isModelActive"
              class="details-container__header-element"
              @input="activateModel()"
            />
          </div>
        </div>
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { Page, RoleName } from '@/enums';
import { ActivateModelMixin, RoleMixin, SynchronizeModelMixin } from '@/mixin';
import { isSessionDbAvailable } from '@/helper';
import UiTabs from '@/components/ui-tabs/index.vue';
import UiProductTitle from '@/components/ui-product-title/index.vue';
import Bundles from '@/modules/product/view/vods/detail/bundles.vue';
import Persons from '@/modules/product/view/vods/detail/persons.vue';
import Downloads from '@/modules/product/view/vods/detail/downloads.vue';
import DisplaySchedules from '@/modules/product/view/vods/detail/display-schedules.vue';
import PaymentSchedules from '@/modules/product/view/vods/detail/payment-schedules.vue';
import Subscriptions from '@/modules/product/view/vods/detail/subscriptions.vue';
import Payments from '@/modules/product/view/vods/detail/payments.vue';
import Audits from '@/modules/product/view/vods/detail/audits.vue';
import VideoSessions from '@/modules/product/view/vods/detail/video-sessions.vue';
import UiExternalApplicationIcons from '@/components/ui-external-application-icons/index.vue';
import Detail from './detail.vue';
import Files from './files.vue';
import AdBreaks from './ad-breaks.vue';

export default {
  components: {
    UiSwitch,
    UiTabs,
    UiProductTitle,
    UiExternalApplicationIcons,
    Detail,
    Persons,
    Bundles,
    Files,
    Downloads,
    DisplaySchedules,
    PaymentSchedules,
    Payments,
    Subscriptions,
    AdBreaks,
    Audits,
    VideoSessions,
  },
  mixins: [ActivateModelMixin, RoleMixin, SynchronizeModelMixin],
  data() {
    return {
      RoleName,
      writeRole: RoleName.CMS_WRITE,
      synchronizeUrl: Urls.PRODUCTS_VODS_ID_SYNCHRONIZE(this.$route.params.id),
      model: undefined,
      Page,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
    tabs() {
      return [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'COMMON_PERSONS', page: Page.PERSONS },
        { label: 'COMMON_BUNDLES', page: Page.BUNDLES },
        { label: 'COMMON_FILES', page: Page.FILES },
        { label: 'COMMON_DOWNLOADS', page: Page.DOWNLOADS },
        { label: 'TAB_DISPLAY-SCHEDULES', page: Page.DISPLAY_SCHEDULES },
        { label: 'TAB_PAYMENT-SCHEDULES', page: Page.PAYMENT_SCHEDULES },
        { label: 'COMMON_PAYMENTS', page: Page.PAYMENTS },
        { label: 'COMMON_SUBSCRIPTIONS', page: Page.SUBSCRIPTIONS },
        { label: 'COMMON_AD_BREAKS', page: Page.AD_BREAKS },
        { label: 'TAB_VIDEO-SESSIONS', page: Page.VIDEO_SESSIONS, isHidden: isSessionDbAvailable() },
        { label: 'COMMON_EVENTS', page: Page.AUDITS },
      ];
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
    init() {
      this.$refs.childComponent.$refs.uiDetail?.fetchModel();
    },
  },
};
</script>
