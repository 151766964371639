<template>
  <div v-if="range">
    <span v-text="range.start"></span>
    <br>
    <span v-text="range.stop"></span>
  </div>
</template>
<script>
import { Constants } from '@/enums';
import catchupRange from '@/helper/catchup-range';

export default {
  name: 'ui-table-start-stop',
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    range() {
      const range = catchupRange(this.record);

      if (range) {
        return {
          start: this.$dateFormat(range.start + Constants.CDN_EPOCH),
          stop: this.$dateFormat(range.stop + Constants.CDN_EPOCH),
        };
      }

      return undefined;
    },
  },
};
</script>
