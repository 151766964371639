<template>
  <ui-detail v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        modelUrl: `${Urls.VIEWS}/${this.id}`,
        formElementsFunction: () => [
          [
            Fields.HEADER_METADATA,
            Fields.KEY,
            Fields.NAME,
            Fields.checkboxField('displayOnSitemap', 'VIEW_DISPLAY_ON_SITEMAP'),
          ],
        ],
      };
    },
  },
};
</script>
