<template>
  <span v-tooltip="option.name" class="multiselect__tag">
    <span v-text="name"></span>
    <i
      aria-hidden="true"
      tabindex="1"
      class="multiselect__tag-icon"
      @keypress.enter.prevent="remove(option)"
      @mousedown.prevent="remove(option)"
    ></i>
  </span>
</template>
<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    remove: {
      type: Function,
      required: true,
    },
  },
  computed: {
    name() {
      return this.option.name || this.option.key;
    },
  },
};
</script>
