<template>
  <div class="ui-form-multiselect">
    <label
      v-if="label"
      v-tooltip="tooltip"
      class="ui-form-multiselect__label"
      v-text="`${$translate(label)}:`"
    ></label>
    <ui-multiselect v-bind="uiMultiselectProps" v-on="uiMultiselectListeners" />
  </div>
</template>
<script>
import { UiMultiselect } from 'redge-media-web-ui';
import { DictionaryFetchMixin } from '@/mixin';
import { filterObjectEntries, filterOutObjectEntries } from '@/helper';

export default {
  components: {
    UiMultiselect,
  },
  mixins: [DictionaryFetchMixin],
  inheritAttrs: false,
  props: {
    ...filterOutObjectEntries(UiMultiselect.props, ['records', 'fetching']),
    tooltip: [String, Object],
    label: String,
  },
  computed: {
    uiMultiselectProps() {
      return {
        ...this.$attrs,
        ...filterObjectEntries(this.$props, Object.keys(UiMultiselect.props)),
        searchable: true,
        fetching: this.fetching,
        records: this.records,
      };
    },
    uiMultiselectListeners() {
      return { ...this.$listeners, fetch: this.fetch };
    },
  },
};
</script>
