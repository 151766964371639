<template>
  <div class="ui-panel">
    <div class="ui-panel__header">
      <h2 class="ui-panel__header-text" v-text="$translate(title)"></h2>
    </div>
    <div class="flex flex-wrap">
      <template v-for="setting in settings">
        <ui-product-file-image
          v-for="image in getImagesForSetting(setting)"
          :key="`${setting.label}-${image.id}`"
          :label="setting.label"
          :type="type"
          :image="image"
          :is-allowed="isAllowed"
          :style="getSizeStylesForSetting(setting, type, image)"
          class="ui-product-file-image--transparency-grid"
          @edit="saveImage(setting, image)"
          @delete="deleteImage(image)"
          @upload="uploadImage(setting, $event, image)"
        />
        <ui-product-file-image
          v-if="getAddingAvailableForSetting(setting, type)"
          :key="`${setting.label}-add`"
          :label="setting.label"
          :type="type"
          :is-allowed="isAllowed"
          :style="getSizeStylesForSetting(setting, type)"
          @edit="saveImage(setting)"
          @upload="uploadImage(setting, $event)"
        />
      </template>
    </div>
  </div>
</template>
<script>
import {
  UiFormTextInput, UiModalConfirm, UiModalPopup, UiScalerModal,
} from 'redge-media-web-ui';
import info from '@/application/info';
import Urls from '@/application/urls';
import http from '@/lib/http';
import { AppEvent, ImageLabel, ImageType } from '@/enums';
import { SaveModelMixin } from '@/mixin';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';
import * as Fields from '@/components/ui-form/fields';
import UiProductFileImage from './ui-product-file-image.vue';

export default {
  components: {
    UiProductFileImage,
  },
  mixins: [SaveModelMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    isAllowed: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    images: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Array,
      required: true,
    },
    imagesUrlFunction: {
      type: Function,
      default: Urls.ITEMS_ID_IMAGES,
    },
    imagesUploadUrlFunction: {
      type: Function,
      default: Urls.ITEMS_ID_IMAGES_UPLOAD,
    },
  },
  methods: {
    getImagesForSetting({ multiple, label }) {
      if (multiple) {
        return this.images[label];
      }

      return [this.images[label]?.[0]].filter(Boolean);
    },
    getSizeStylesForSetting({ label }, type, image) {
      const imageLabel = this.getImageLabel(label, type, image);
      const fixedHeight = 170;
      const [aspectWidth, aspectHeight] = imageLabel.split('x').map((str) => parseInt(str, 10));

      return {
        height: `${fixedHeight}px`,
        width: `${fixedHeight * (aspectWidth / aspectHeight)}px`,
      };
    },
    getImageLabel(label, type, image) {
      if (label && type !== ImageType.TITLE_TREATMENT) {
        return label;
      }

      if (!image || !image.url) {
        return ImageLabel.ASPECT_16X9;
      }

      const urlParams = image.url.split('?')[1];
      if (!urlParams?.length) {
        return ImageLabel.ASPECT_16X9;
      }

      const params = new URLSearchParams(urlParams);
      const dsth = params.get('dsth');
      const dstw = params.get('dstw');

      if (dstw && dsth) {
        return `${dstw}x${dsth}`;
      }

      return ImageLabel.ASPECT_16X9;
    },
    getAddingAvailableForSetting(setting) {
      return setting.multiple || !this.getImagesForSetting(setting)?.length;
    },
    deleteImage(image) {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_REMOVE'),
        confirmationText: this.$translate('COMMON_REMOVE_CONFIRMATION'),
      });

      modal.$on(AppEvent.SUCCESS, async () => {
        await http.delete(`${this.imagesUrlFunction(this.product.id)}/${image.id}`);
        this.$emit(AppEvent.CHANGE);
        modal.close();
      });
    },
    async saveImage({ label, scaler }, image) {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: (model) => [
          [
            {
              field: 'url',
              isMandatory: true,
              component: UiFormTextInput,
              componentOptions: {
                label: 'COMMON_URL',
                iconClass: model.url ? 'icon-image' : '',
                on: {
                  'icon-click': () => {
                    const scalerModal = this.$modal.create(UiScalerModal, {
                      value: model.url,
                      defaultParams: scaler?.main || {},
                      disableDomainCorrection: !info.scalerDomainCorrection,
                    });

                    scalerModal.$on(AppEvent.INPUT, (url) => {
                      model.url = url;
                      scalerModal.$emit(AppEvent.CLOSE);
                    });

                    scalerModal.$on(AppEvent.ERROR, (error) => {
                      this.$modal?.create(UiModalPopup, {
                        text: this.$translate(error),
                        modalModifier: 'ui-modal-popup--error',
                        hideAfter: 5000,
                        overlay: true,
                      });
                    });
                  },
                },
                'data-qa': 'vod-cover-url',
              },
            },
            Fields.textInputField('templateUrl', 'ITEM_IMAGE_TEMPLATE_URL', false, true),
          ],
        ],
        modalTitleFunction: () => 'EDIT_IMAGE',
        defaultValues: {
          ...image,
          label,
          type: this.type,
        },
      });

      const saveModelPromiseFunction = (model) => {
        const url = `${this.imagesUrlFunction(this.product.id)}/${image?.id || ''}`;
        return http[image ? 'put' : 'post'](url, model);
      };

      await this.openSaveModelModal(modal, saveModelPromiseFunction);
      this.$emit(AppEvent.CHANGE);
    },
    uploadImage({ label, scaler }, { target: { files } }, image) {
      const [file] = files;
      const main = scaler?.main;

      if (this.type === ImageType.TITLE_TREATMENT && file.type !== 'image/png') {
        throw { code: 'COMMON_FILE_EXTENSION_NOT_SUPPORTED' };
      }

      const scalerParams = scaler && {
        dstw: main?.dstw,
        dsth: main?.dsth,
        stype: scaler.type,
      };

      const params = {
        ...scalerParams,
        label,
        type: this.type,
      };

      const url = `${this.imagesUploadUrlFunction(this.product.id)}/${image?.id || ''}`;

      this.$loader.show('SAVING_DATA');
      return http[image ? 'put' : 'post'](url, file, {
        params,
        headers: { 'Content-Type': 'application/octet-stream' },
      })
        .then(() => this.$emit(AppEvent.CHANGE))
        .catch(() => {})
        .then(() => this.$loader.hide());
    },
  },
};
</script>
