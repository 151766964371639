import { AuditResourceType, Page } from '@/enums';
import productLink from './product-link';

export default (resource) => {
  let id = resource.resourceId;
  let name = '';
  let page = '';

  switch (resource.resourceType) {
    case AuditResourceType.BANNER:
      name = 'banners.detail';
      page = 'detail';
      break;
    case AuditResourceType.CONTACT:
      name = 'contact.thread.detail';
      page = 'detail';
      break;
    case AuditResourceType.DOCUMENT:
      name = 'document.detail';
      page = 'detail';
      break;
    case AuditResourceType.OPERATOR:
      name = 'operator.detail';
      page = Page.AUDITS;
      id = id || resource.operator.id;
      break;
    case AuditResourceType.PROMOTION:
      name = 'promotions.detail';
      page = 'detail';
      break;
    case AuditResourceType.SUBSCRIBER:
      name = 'subscriber.detail';
      page = Page.AUDITS;
      break;
    case AuditResourceType.TIMER:
      name = 'timer.detail';
      page = Page.AUDITS;
      id = resource.resourceName;
      break;
    case AuditResourceType.SETTING:
      name = 'setting.page';
      page = Page.AUDITS;
      id = resource.resourceName;
      break;
    case AuditResourceType.SECTION:
      name = 'section.detail';
      page = Page.AUDITS;
      break;
    case AuditResourceType.BUNDLE:
    case AuditResourceType.ITEM:
    case AuditResourceType.PRODUCT:
    case AuditResourceType.VOD:
      return productLink({ type: resource.resourceItemType, id }, 'audits');
    default:
      return {};
  }

  return {
    name,
    params: {
      id,
      page,
    },
  };
};
