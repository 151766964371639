import * as views from '@/modules';
import productRoutes from './product';
import subscriberRoutes from './subscriber';
import contentRoutes from './content';
import paymentRoutes from './payment';
import reportRoutes from './report';
import administrationRoutes from './administration';
import tenantRoutes from './tenants';

export default [
  {
    path: '/login',
    name: 'login',
    component: views.Login,
    meta: {
      titleLabels: ['Login'],
    },
  },
  {
    path: '/',
    redirect: { name: 'home' },
  },
  {
    path: '/gui',
    redirect: { name: 'home' },
  },
  {
    path: '/gui/dashboards',
    name: 'home',
    component: () => import('@/modules/dashboard/index.vue'),
    meta: {
      titleLabels: ['Dashboard'],
    },
  },
  ...productRoutes,
  ...subscriberRoutes,
  ...contentRoutes,
  ...paymentRoutes,
  ...reportRoutes,
  ...administrationRoutes,
  ...tenantRoutes,
];
