<template>
  <v-list-wrapper :table-definition="tableDefinition">
    <template #name="{ record: { id, name } }">
      <ui-table-detail-link :id="id" name="agreement.detail">
        <ui-item-title :item="{ title: name }" no-title-label="COMMON_NO_NAME" />
      </ui-table-detail-link>
    </template>
  </v-list-wrapper>
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';
import * as Filters from '@/components/filter/filters';
import UiItemTitle from '@/components/ui-item-title/index.vue';
import { UiTableDetailLink } from '@/components/ui-table';

export default ListView.extend({
  components: {
    UiItemTitle,
    UiTableDetailLink,
  },
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.AGREEMENTS,
      removeModelUrlFunction: ({ id }) => Urls.AGREEMENTS_ID(id),
      saveModelUrlFunction: () => Urls.AGREEMENTS,
      saveModelModalTitleFunction: () => 'ADD_AGREEMENT',
      saveModelFormElementsFunction: () => [[Fields.NAME, Fields.TYPE(Urls.AGREEMENTS_TYPES), Fields.KEY, Fields.RANK]],
      filters: [Filters.autocomplete('types', { label: 'COMMON_TYPE', dictUrl: Urls.AGREEMENTS_TYPES })],
      paramsConfig: {
        sort: QueryParam.RANK,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.NAME,
          Columns.AGREEMENT_OBLIGATORY,
          { ...Columns.TYPE, isSortable: true },
          {
            field: 'key',
            minWidth: 200,
            headingLabel: 'COMMON_KEY',
          },
          Columns.RANK,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
