import Urls from '@/application/urls';
import {
  autocomplete, input, priceInput, product, singleSwitch,
} from '@/components/filter/filters';

export const PRODUCTS = product('products', { label: 'COMMON_PRODUCT' });

export const PAYMENT_CHANNEL = autocomplete('channels', {
  label: 'PAYMENT_CHANNEL',
  dictUrl: Urls.PAYMENTS_CHANNELS,
});

export const PAYMENT_CURRENCY = autocomplete('currencies', {
  label: 'PAYMENT_CURRENCY',
  dictUrl: Urls.CURRENCIES_DICTIONARIES,
});

export const PAYMENT_STATUS = autocomplete('statuses', {
  label: 'COMMON_STATUS',
  dictUrl: Urls.PAYMENTS_STATUSES,
});

export const PAYMENT_SYSTEM = autocomplete('systems', {
  label: 'PAYMENT_SYSTEM',
  dictUrl: Urls.PAYMENTS_SYSTEMS,
});

export const PRICE_GREATER = priceInput('priceGreater', { label: 'PAYMENT_PRICE_GREATER' });

export const PRICE_LESS = priceInput('priceLess', { label: 'PAYMENT_PRICE_LESS' });

export const PROMOTION_ID = input('promotionId', { label: 'PROMOTION_ID' });

export const SUBSCRIPTION_ID = input('subscriptionId', { label: 'SUBSCRIPTION_ID' });

export const SUBSCRIPTION_STATUS = autocomplete('statuses', {
  label: 'COMMON_STATUS',
  dictUrl: Urls.SUBSCRIPTIONS_STATUSES,
});
export const CURRENT_ACTIVE = singleSwitch('currentValid', { label: 'CURRENT_ACTIVE' });
