import { NumberDivider } from 'redge-media-web-utils';
import http from '@/lib/http';
import Urls from '@/application/urls';

const INFO_FETCH_INTERVAL = 15 * NumberDivider.MINUTE_TO_SECONDS; // [s];

class Info {
  constructor() {
    this.sso = {};
    this.scalerDomainCorrection = undefined;
    this.applications = undefined;
    this._intervalId = undefined;
  }

  _fetch(url) {
    return http.get(url).then(({ data }) => {
      if (data.applications) {
        data.applications = Object.entries(data.applications).map(([appId, application]) => ({
          appId,
          ...application,
        }));
      }

      Object.assign(this, data);
    });
  }

  fetchAuthInfo() {
    clearInterval(this._intervalId);
    return this._fetch(Urls.AUTH_INFO);
  }

  fetchInfo() {
    clearInterval(this._intervalId);
    // This interval is responsible for prolonging the operator's session
    this._intervalId = setInterval(() => this.fetchInfo(), INFO_FETCH_INTERVAL * 1000);
    return this._fetch(Urls.INFO);
  }

  getApplicationInfoById(id) {
    return this.applications?.find(({ appId }) => appId.toLowerCase() === id);
  }
}

const info = new Info();
export default info;
