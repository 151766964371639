import { unwrapValueArray, wrapValueArray } from '@/lib/settings-filters';

const SINGLE_VALUE_ROUTE_PARAMS = [
  'sort',
  'order',
  'maxResults',
  'firstResult',
  'keyword',
  'since',
  'till',
  'active',
  'automaticAssignment',
  'tester',
  'createdAtSince',
  'createdAtTill',
  'lastLoggedAtSince',
  'lastLoggedAtTill',
  'all_sections',
  'currentValid',
];

const isEmpty = (value) => ['', undefined, null].includes(value) || (Array.isArray(value) && value.length === 0);

const removeEmptyValues = (object) => Object.entries(object).reduce(
  (acc, [key, value]) => ({
    ...acc,
    ...(isEmpty(value) ? {} : { [key]: value }),
  }),
  {},
);

const routeQueryListToObject = (acc, [key, value]) => ({
  ...acc,
  [key]: SINGLE_VALUE_ROUTE_PARAMS.includes(key) ? unwrapValueArray(value) : wrapValueArray(value),
});

export default {
  computed: {
    routeQuery() {
      return Object.entries(this.$route.query).reduce(routeQueryListToObject, {});
    },
    routeQueryWithoutFilterId() {
      return Object.entries(this.$route.query)
        .filter(([key]) => key !== 'filterId')
        .reduce(routeQueryListToObject, {});
    },
  },
  methods: {
    updateRouteQuery(queryParams = {}) {
      const query = removeEmptyValues({ ...this.routeQuery, ...queryParams });

      if (JSON.stringify(this.routeQuery) !== JSON.stringify(query)) {
        this.$router.replace({ path: this.$route.path, params: this.$route.params, query });
      }
    },
  },
};
