<template>
  <div class="sidebar-content">
    <div class="sidebar-content__filters">
      <ui-search class="sidebar-content__search" @change="request.params.keyword = $event" />
      <select v-model="request.url" class="ui-select sidebar-content__select">
        <option
          v-for="filter in filters"
          :key="filter.url"
          :value="filter.url"
          v-text="$translate(filter.label)"
        ></option>
      </select>
    </div>
    <div class="ui-filter-item ui-filter-item--switch">
      <label v-text="`${$translate('COMMON_ACTIVE_MALE')}:`"></label>
      <ui-multi-switch v-model="request.params.active" />
    </div>

    <ul v-if="records.length > 0" class="sidebar-content__list">
      <li v-for="record in records" :key="record.id" class="sidebar-content__list-item">
        <div
          :style="background(record.images)"
          class="sidebar-content__list-item-image"
          @mousedown="onMouseDown(record, $event)"
        ></div>
        <div class="sidebar-content__list-item-description">
          <router-link :to="productLink(record)" class="sidebar-content__list-item-name">
            <ui-item-title :item="record" />
          </router-link>
        </div>
      </li>
    </ul>
    <p
      v-else-if="isFetching"
      class="sidebar-content__no-data-info"
      v-text="`${$translate('WIDGET_SIDEBAR_PRODUCTS_LOADING')}...`"
    ></p>
    <p v-else class="sidebar-content__no-data-info" v-text="$translate('COMMON_NO_RESULTS')"></p>
  </div>
</template>
<script>
import { UiMultiSwitch } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import UiSearch from '@/components/search/search.vue';
import UiItemTitle from '@/components/ui-item-title/index.vue';
import { QueryParam } from '@/enums';
import { background, productLink } from '@/helper';
import DroppableMixin from '@/mixin/droppable';

const filters = [
  { label: 'MENU_CONTENT_BANNER', url: Urls.BANNERS },
  { label: 'MENU_CONTENT_SECTION', url: Urls.SECTIONS_DICTIONARIES },
];

export default {
  components: {
    UiSearch,
    UiItemTitle,
    UiMultiSwitch,
  },
  mixins: [DroppableMixin],
  props: {
    records: {
      type: Array,
      required: true,
    },
    fetchRecords: {
      type: Function,
      required: true,
    },
    isFetching: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filters,
      request: {
        url: filters[0].url,
        params: {
          sort: QueryParam.CREATED_AT,
          order: QueryParam.DESCENDING,
          keyword: undefined,
          active: undefined,
        },
      },
    };
  },
  watch: {
    request: {
      handler: 'fetchRecords',
      immediate: true,
      deep: true,
    },
  },
  methods: {
    background,
    productLink,
  },
};
</script>
