<template>
  <div class="ui-active-filters">
    <div
      v-for="(activeFilter, field) in activeFiltersWithAdditionalInformation"
      :key="field"
      class="ui-active-filters__filter"
    >
      <p
        :class="{ 'ui-active-filters__filter-label--hidden': filterIsSwitch(activeFilter) }"
        class="ui-active-filters__filter-label"
        v-text="$translate(activeFilter.componentOptions.label)"
      ></p>
      <div class="ui-active-filters__filter-values">
        <div
          v-for="value in activeFilter.values"
          :key="value"
          :class="{ 'ui-active-filters__value--mandatory': activeFilter.isMandatory }"
          class="ui-active-filters__value"
          @click.stop="openFilters(activeFilter)"
        >
          <span
            :class="getValueTextFlagClass(activeFilter, value)"
            class="ui-active-filters__value-text"
            v-text="getActiveFilterValueText(activeFilter, value)"
          ></span>
          <span
            v-if="!activeFilter.isMandatory"
            class="ui-active-filters__value-remove"
            @click.stop="removeActiveFilterValue(activeFilter, value)"
          >
            ×
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isRelativeDate, parseRelativeDate } from 'redge-media-web-ui';
import { DateFormat } from 'redge-media-web-utils';
import EventBus from '@/application/event-bus';
import { AppEvent, Constants } from '@/enums';
import { priceFormat } from '@/helper';
import DatePicker from '@/components/filter/widget/date-picker.vue';
import MultiSwitch from '@/components/filter/widget/multi-switch.vue';
import PriceInput from '@/components/filter/widget/price-input.vue';

const filterIsDatePicker = ({ component }) => component === DatePicker;
const filterIsPriceInput = ({ component }) => component === PriceInput;
const filterIsSwitch = ({ component }) => component === MultiSwitch;

const flagValuesLast = (a, b) => {
  const aValue = filterIsSwitch(a);
  const bValue = filterIsSwitch(b);

  if (aValue === bValue) {
    return 0;
  }
  if (bValue) {
    return -1;
  }
  return 1;
};

export default {
  props: {
    activeFilters: {
      type: Object,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  computed: {
    activeFiltersWithAdditionalInformation() {
      const activeFiltersList = Object.entries(this.activeFilters).map(([field, values]) => [
        field,
        { ...this.getFilterByField(field), values },
      ]);
      const activeFiltersListSorted = activeFiltersList.sort(([, additionalInformationA], [, additionalInformationB]) => flagValuesLast(additionalInformationA, additionalInformationB));
      return activeFiltersListSorted.reduce(
        (acc, [field, additionalInformation]) => ({ ...acc, [field]: additionalInformation }),
        {},
      );
    },
  },
  methods: {
    filterIsSwitch,
    openFilters({ field }) {
      EventBus.$emit(Constants.OPEN_FILTER_MENU, field);
    },
    getFilterByField(field) {
      return this.filters.find((f) => f.field === field);
    },
    getActiveFilterValueText(activeFilter, value) {
      if (filterIsDatePicker(activeFilter)) {
        if (isRelativeDate(value)) {
          const { label } = parseRelativeDate(value);
          return `${label.value} ${this.$translate(label.unit)} ${this.$translate(label.period)}`;
        }

        const format = activeFilter.componentOptions.timepicker
          ? DateFormat.INPUT_DATETIME_FORMAT
          : DateFormat.DATE_FORMAT;

        return this.$dateFormat(value, format);
      }
      if (filterIsPriceInput(activeFilter)) {
        return priceFormat(value);
      }
      if (filterIsSwitch(activeFilter)) {
        return this.$translate(activeFilter.componentOptions.label);
      }
      return value;
    },
    removeActiveFilterValue({ field, values }, value) {
      this.$emit(
        AppEvent.CHANGE,
        field,
        values.filter((v) => v !== value),
      );
    },
    getValueTextFlagClass(activeFilter, value) {
      if (filterIsSwitch(activeFilter)) {
        return [
          'ui-active-filters__value-text-flag',
          `ui-active-filters__value-text-flag--${JSON.parse(value) ? 'on' : 'off'}`,
        ];
      }

      return [];
    },
  },
};
</script>
