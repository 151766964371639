<template>
  <ui-detail v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import { UiFormCheckbox } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as ContentFields from '@/modules/content/view/fields';

const textEditorComponent = () => import('@/components/ui-text-editor/ui-text-editor.vue');

const modelValidator = (model) => {
  if (model.mimeType !== 'application/json') {
    return;
  }

  try {
    JSON.parse(model.content);
  } catch (error) {
    throw { code: 'DOCUMENT_JSON_INVALID' };
  }
};

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        isSaveButtonSticky: true,
        modelUrl: Urls.DOCUMENTS_ID(this.id),
        onSubmitModelValidation: modelValidator,
        formElementsFunction: ({ mimeType }) => [
          [
            Fields.HEADER_METADATA,
            ContentFields.ALIAS,
            Fields.TYPE_DISABLED,
            {
              field: 'multilingual',
              component: UiFormCheckbox,
              componentOptions: {
                label: 'COMMON_MULTILINGUAL',
                tooltip: Fields.commonTooltip('HELP_COMMON_MULTILINGUAL'),
              },
            },
            Fields.textInputField('mimeType', 'DOCUMENT_MIME_TYPE', false, true),
            Fields.TITLE,
            Fields.PLATFORMS,
            {
              field: 'content',
              component: textEditorComponent,
              componentOptions: {
                mimeType,
              },
            },
          ],
        ],
      };
    },
  },
};
</script>
