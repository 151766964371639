<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_ID_CARDS(this.id),
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.CARD_MASK,
          Columns.CARD_EXPIRES,
          Columns.CARD_HASH,
          Columns.CARD_STATUS,
          Columns.CARD_TYPE,
          Columns.CARD_REASON,
        ],
        records: this.records,
      };
    },
  },
});
</script>
