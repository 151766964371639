import {
  UiFormCheckbox, UiFormNumberInput, UiFormTextInput, UiFormTimeInput,
} from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { checkboxField, commonTooltip, textHeader } from '@/components/ui-form/fields';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import UiFormDictionarySelect from '@/components/ui-form/ui-form-dictionary-select.vue';
import UiFormMultipleChoice from '@/components/ui-form/ui-form-multiple-choice.vue';
import {
  UiMultiselectOptionProduct,
  UiMultiselectOptionWithNameHover,
  UiMultiselectTagWithNameHover,
} from '@/components/ui-multiselect';
import {
  ItemType, MediumType, Platform, VideoType,
} from '@/enums';

export const ADVISORS = {
  field: 'advisors',
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'COMMON_ADVISORS',
    dictType: 'ADVISOR',
    multiple: true,
    slots: {
      singleLabel: UiMultiselectTagWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
    'data-qa': 'vod-advisors',
  },
};

export const AUTOPLAY = {
  field: 'autoplay',
  component: UiFormCheckbox,
  componentOptions: {
    label: 'VOD_AUTOPLAY',
    'data-qa': 'vod-autoplay',
  },
};

export const CATEGORIES = (itemsCategoriesType) => ({
  field: 'categories',
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'ITEM_CATEGORIES',
    dictUrl: Urls.ITEMS_CATEGORIES_TYPE(itemsCategoriesType),
    field: 'key',
    multiple: true,
    slots: {
      tag: UiMultiselectTagWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
    'data-qa': 'vod-categories',
  },
});

export const CREATE_CHAT_ENABLED = (label) => checkboxField('chatEnabled', label);

export const CHROMECAST_PLATFORMS = {
  field: 'chromecastServicePlatforms',
  component: UiFormMultipleChoice,
  componentOptions: {
    label: 'PRODUCT_CHROMECAST_PLATFORM',
    dictType: 'PLATFORM',
    dataFilter: ({ name }) => ![Platform.ANDROID_TV, Platform.SMART_TV, Platform.APPLE_TV].includes(name),
    'data-qa': 'vod-chromecast-platforms',
  },
};

export const COUNTRIES = {
  field: 'countries',
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'ITEM_COUNTRIES',
    dictType: 'COUNTRY',
    field: 'key',
    multiple: true,
    slots: {
      tag: UiMultiselectTagWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
    'data-qa': 'vod-countries',
  },
};

export const CREDITS_TIME = {
  field: 'creditsTime',
  component: UiFormTimeInput,
  componentOptions: {
    label: 'PRODUCT_CREDITS_TIME',
    'data-qa': 'vod-credits-time',
  },
};

export const DURATION = {
  field: 'duration',
  component: UiFormTimeInput,
  isMandatory: true,
  componentOptions: {
    label: 'PRODUCT_DURATION',
    'data-qa': 'vod-duration-seconds',
  },
};

export const EPISODE_NUMBER = {
  field: 'episodeNumber',
  component: UiFormNumberInput,
  componentOptions: {
    label: 'EPISODE_NUMBER',
    tooltip: commonTooltip('HELP_PROGRAMME_EPISODE_NUMBER'),
  },
};

export const FILE_FORMAT = (formats) => ({
  field: 'format',
  isMandatory: true,
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'FILE_FORMAT',
    dict: formats.map((name) => ({ name })),
    'data-qa': 'vod-file-format',
  },
});

export const GEOIP_COUNTRY_BLACKLIST = (model) => ({
  field: 'geoipCountriesBlacklisted',
  component: UiFormAutocompleteInline,
  isHidden: !model.geoipManualLock,
  componentOptions: {
    label: 'GEOIP_COUNTRY_ISO_BLACKLIST',
    dictType: 'GEOIP_COUNTRY',
    field: 'key',
    multiple: true,
    tooltip: commonTooltip('HELP_GEOIP_COUNTRY_BLACKLIST'),
    slots: {
      tag: UiMultiselectTagWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
  },
});

export const GEOIP_COUNTRY_WHITELIST = (model) => ({
  field: 'geoipCountriesWhitelisted',
  component: UiFormAutocompleteInline,
  isHidden: !model.geoipManualLock,
  componentOptions: {
    label: 'GEOIP_COUNTRY_ISO_WHITELIST',
    dictType: 'GEOIP_COUNTRY',
    field: 'key',
    multiple: true,
    tooltip: commonTooltip('HELP_GEOIP_COUNTRY_WHITELIST'),
    slots: {
      tag: UiMultiselectTagWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
  },
});

export const HEADER_GEOLOCK = textHeader('COMMON_GEOLOCK');

export const GEOIP_MANUAL_LOCK = {
  field: 'geoipManualLock',
  component: UiFormCheckbox,
  componentOptions: {
    label: 'GEOIP_MANUAL_LOCK',
  },
};

export const GEOLOCK_FIELDS = (model) => [
  HEADER_GEOLOCK,
  GEOIP_MANUAL_LOCK,
  GEOIP_COUNTRY_WHITELIST(model),
  GEOIP_COUNTRY_BLACKLIST(model),
];

export const GENRES = {
  field: 'genres',
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'COMMON_GENRES',
    dictType: 'GENRE',
    field: 'key',
    multiple: true,
    slots: {
      tag: UiMultiselectTagWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
    'data-qa': 'vod-genres',
  },
};

export const HD = {
  field: 'hd',
  component: UiFormCheckbox,
  componentOptions: {
    label: 'VOD_HD',
    'data-qa': 'vod-hd',
  },
};

export const LOGIN_REQUIRED = {
  field: 'loginRequired',
  component: UiFormCheckbox,
  componentOptions: {
    label: 'PRODUCT_LOGIN_REQUIRED',
    tooltip: commonTooltip('HELP_PRODUCT_LOGIN_REQUIRED'),
    'data-qa': 'vod-login-required',
  },
};

const videoTypesWithoutAudio = [VideoType.TRAILER, VideoType.PPV];

export const MEDIUM_TYPE = (model, videoType) => {
  model.videoType = model.type === MediumType.VIDEO ? videoType : undefined;

  return {
    field: 'type',
    isHidden: videoTypesWithoutAudio.includes(videoType),
    isMandatory: true,
    component: UiFormDictionarySelect,
    componentOptions: {
      label: 'COMMON_TYPE',
      dictUrl: Urls.ITEMS_DICTIONARIES_MEDIA_TYPES,
      disabled: !!model.id,
      'data-qa': 'vod-medium-type',
    },
  };
};

export const NUMBER = {
  field: 'number',
  isMandatory: true,
  component: UiFormTextInput,
  componentOptions: {
    label: 'COMMON_NUMBER',
    'data-qa': 'vod-number-input',
  },
};

export const INTRO_END_TIME = {
  field: 'introEndTime',
  component: UiFormTimeInput,
  componentOptions: {
    label: 'COMMON_INTRO_END_TIME',
  },
};

export const INTRO_START_TIME = {
  field: 'introStartTime',
  component: UiFormTimeInput,
  componentOptions: {
    label: 'COMMON_INTRO_START_TIME',
  },
};

export const ORIGINAL_TITLE = {
  field: 'originalTitle',
  component: UiFormTextInput,
  componentOptions: {
    label: 'VOD_ORIGINAL_TITLE',
    'data-qa': 'vod-original-title',
  },
};

export const PRODUCTION = {
  field: 'production',
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'PRODUCT_PRODUCTION',
    dictType: 'PRODUCTION',
    field: 'key',
    slots: {
      singleLabel: UiMultiselectTagWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
    'data-qa': 'vod-production',
    allowEmpty: true,
  },
};

export const RATING = {
  field: 'rating',
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'COMMON_RATING',
    allowEmpty: true,
    dictUrl: Urls.RATINGS,
    field: 'value',
    'data-qa': 'vod-rating',
  },
};

export const RATING_EMBEDDED = (model) => ({
  field: 'ratingEmbedded',
  component: UiFormCheckbox,
  componentOptions: {
    label: 'VOD_RATING_EMBEDDED',
    tooltip: commonTooltip('HELP_VOD_RATING_EMBEDDED'),
    'data-qa': 'vod-rating-embeded',
  },
  isHidden: !model.rating && model.rating !== 0,
});

export const RECAP_END_TIME = {
  field: 'recapEndTime',
  component: UiFormTimeInput,
  componentOptions: {
    label: 'COMMON_RECAP_END_TIME',
  },
};

export const RECAP_START_TIME = {
  field: 'recapStartTime',
  component: UiFormTimeInput,
  componentOptions: {
    label: 'COMMON_RECAP_START_TIME',
  },
};

export const SEASON = {
  field: 'season',
  component: UiFormAutocompleteInline,
  componentOptions: {
    allowEmpty: true,
    label: 'COMMON_SEASON',
    dictUrl: Urls.ITEMS_TYPE_DICTIONARIES(ItemType.SEASON),
    slots: {
      option: UiMultiselectOptionProduct,
    },
    isOptionDisabled: (record) => !record.name,
    maxResults: 50,
  },
};

export const SELECTED_SEASON = (model) => ({
  field: '_selectedSeason',
  fieldForErrorCode: 'destinationSeasonId',
  isMandatory: true,
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'SERIES_SEASON_SELECTED',
    field: 'number',
    disabled: model._selectedSeries === undefined,
    dictUrl: Urls.PRODUCTS_VODS_SERIALS_ID_SEASONS_DICTIONARIES(model._selectedSeries?.id),
    maxResults: 50,
    on: {
      input: () => {
        model.destinationSeasonId = model._selectedSeason?.id;
      },
    },
  },
});

export const SELECTED_SERIES = (model) => ({
  field: '_selectedSeries',
  fieldForErrorCode: 'destinationSerialId',
  isMandatory: true,
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'SERIES_SELECTED',
    field: 'title',
    dictUrl: Urls.PRODUCTS_VODS_SERIALS,
    maxResults: 50,
    on: {
      input: () => {
        model.destinationSerialId = model._selectedSeries?.id;
        model._selectedSeason = undefined;
        model.destinationSeasonId = undefined;
      },
    },
  },
});

export const SERIES_SHOW_EPISODE_NUMBER = {
  field: 'showEpisodeNumber',
  component: UiFormCheckbox,
  componentOptions: {
    label: 'SERIES_SHOW_EPISODE_NUMBER',
    tooltip: commonTooltip('HELP_SERIES_SHOW_EPISODE_NUMBER'),
    'data-qa': 'vod-show-episode-number',
  },
};

export const SORT_TYPE = {
  field: 'sortType',
  isMandatory: true,
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'SERIES_SORT_TYPE',
    dict: [{ name: 'ASC' }, { name: 'DESC' }],
    'data-qa': 'vod-sort-type',
  },
};

export const SHOW_LOGO_IN_PLAYER = {
  field: 'showLogoInPlayer',
  component: UiFormCheckbox,
  componentOptions: {
    label: 'VOD_SHOW_LOGO_IN_PLAYER',
    'data-qa': 'vod-show-provider-logo-in-player',
  },
};

export const TAGS = {
  field: 'tags',
  component: UiFormAutocompleteInline,
  componentOptions: {
    field: 'key',
    multiple: true,
    slots: {
      tag: UiMultiselectTagWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
    label: 'ITEM_TAGS',
    dictType: 'TAG',
    'data-qa': 'vod-tags',
  },
};

export const TITLE_NOT_MANDATORY = {
  field: 'title',
  isMandatory: false,
  component: UiFormTextInput,
  componentOptions: {
    label: 'COMMON_TITLE',
    'data-qa': 'vod-title-input',
  },
};

export const UHD = {
  field: 'uhd',
  component: UiFormCheckbox,
  componentOptions: {
    label: 'ITEM_UHD',
    'data-qa': 'vod-uhd',
  },
};

export const SHOW_RECOMMENDATIONS = checkboxField('showRecommendations', 'ITEM_SHOW_RECOMMENDATIONS');

export const YEAR = {
  field: 'year',
  component: UiFormNumberInput,
  componentOptions: {
    label: 'PRODUCT_YEAR',
    'data-qa': 'vod-year',
  },
};
