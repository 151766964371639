import * as views from '@/modules';
import { Page } from '@/enums';

export default [
  {
    path: '/gui/content/sections',
    name: 'section',
    redirect: { name: 'section.label', params: { key: 'main' } },
  },
  {
    path: '/gui/content/sections/:key',
    name: 'section.label',
    component: views.ContentSections,
    meta: {
      titleLabels: ['MENU_CONTENT_SECTION'],
    },
  },
  {
    path: '/gui/content/sections/:id/:page',
    name: 'section.detail',
    component: views.ContentSectionsDetail,
    meta: {
      titleLabels: ['MENU_CONTENT_SECTION'],
    },
  },
  {
    path: '/gui/content/views',
    name: 'view',
    component: views.ContentViews,
    meta: {
      titleLabels: ['MENU_CONTENT_VIEWS'],
    },
  },
  {
    path: '/gui/content/views/:id/:page',
    name: 'views.detail',
    component: views.ContentViewsDetail,
    meta: {
      titleLabels: ['COMMON_VIEW'],
    },
  },
  {
    path: '/gui/content/categories',
    name: 'category',
    component: views.ContentCategories,
    meta: {
      titleLabels: ['COMMON_CATEGORIES'],
    },
  },
  {
    path: '/gui/content/categories/:id/:page',
    name: 'categories.detail',
    component: views.ContentCategoriesDetail,
    meta: {
      titleLabels: ['COMMON_CATEGORIES'],
    },
  },
  {
    path: '/gui/content/dictionaries',
    name: 'dictionaries',
    redirect: { name: 'dictionaries.page', params: { page: Page.COUNTRY } },
  },
  {
    path: '/gui/content/dictionaries/:page',
    name: 'dictionaries.page',
    component: views.ContentDictionaries,
    meta: {
      titleLabels: ['COMMON_DICTIONARIES'],
    },
  },
  {
    path: '/gui/content/logos',
    name: 'logo',
    component: views.ContentLogos,
    meta: {
      titleLabels: ['MENU_CONTENT_LOGO'],
    },
  },
  {
    path: '/gui/content/logos/:id/:page',
    name: 'logos.detail',
    component: views.ContentLogosDetail,
    meta: {
      titleLabels: ['MENU_CONTENT_LOGO'],
    },
  },
  {
    path: '/gui/content/documents',
    name: 'document',
    component: views.ContentDocuments,
    meta: {
      titleLabels: ['MENU_CONTENT_DOCUMENT'],
    },
  },
  {
    path: '/gui/content/documents/:id/:page',
    name: 'document.detail',
    component: views.ContentDocumentsDetail,
    meta: {
      titleLabels: ['MENU_CONTENT_DOCUMENT'],
    },
  },
  {
    path: '/gui/content/translations/fo/:page?',
    name: 'translation.fo',
    component: views.ContentTranslations,
    meta: {
      titleLabels: ['COMMON_TRANSLATIONS'],
    },
  },
  {
    path: '/gui/content/agreements',
    name: 'agreement',
    component: views.ContentAgreements,
    meta: {
      titleLabels: ['COMMON_AGREEMENTS'],
    },
  },
  {
    path: '/gui/content/agreements/:id/:page',
    name: 'agreement.detail',
    component: views.ContentAgreementsDetail,
    meta: {
      titleLabels: ['COMMON_AGREEMENTS'],
    },
  },
  {
    path: '/gui/content/banners',
    name: 'banner',
    component: views.ContentBanners,
    meta: {
      titleLabels: ['MENU_CONTENT_BANNER'],
    },
  },
  {
    path: '/gui/content/banners/:id/:page',
    name: 'banners.detail',
    component: views.ContentBannersDetail,
    meta: {
      titleLabels: ['MENU_CONTENT_BANNER'],
    },
  },
  {
    path: '/gui/content/profiles/avatars',
    name: 'avatar',
    component: views.ContentAvatars,
    meta: {
      titleLabels: ['MENU_CONTENT_AVATAR'],
    },
  },
  {
    path: '/gui/content/profiles/avatars/:id/:page',
    name: 'avatars.detail',
    component: views.ContentAvatarsDetail,
    meta: {
      titleLabels: ['MENU_CONTENT_AVATAR'],
    },
  },
  {
    path: '/gui/content/audits',
    name: 'contentaudit',
    component: views.ContentAudits,
    meta: {
      titleLabels: ['COMMON_EVENTS'],
    },
  },
];
