export default {
  ASCENDING: 'asc',
  ALIAS: 'alias',
  CREATED_AT: 'createdAt',
  DESCENDING: 'desc',
  EMAIL: 'email',
  ID: 'id',
  KEY: 'key',
  LAST_LOGGED_AT: 'lastLoggedAt',
  NAME: 'name',
  NUMBER: 'number',
  MODIFIED_AT: 'modifiedAt',
  PERSON_NAME: 'person.name',
  PROFILE_NAME: 'profile-name',
  RANK: 'rank',
  SINCE: 'since',
  SLUG: 'slug',
  TITLE: 'title',
};
