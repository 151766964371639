<template>
  <span v-tooltip="name" class="ui-multiselect-option-with-name-hover" v-text="name"></span>
</template>
<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      return this.option.name || this.option.key;
    },
  },
};
</script>
