<template>
  <div ref="product-ghost" class="product-ghost section-item responsive-item-wrapper">
    <p v-if="isItemAlgorithm" class="title" v-text="item.name"></p>
    <ui-product-title v-else :product="item" class="title" />
    <div class="responsive-item">
      <div class="image-wrapper">
        <div v-if="image" class="image" :style="image"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { ContentType, ImageLabel } from '@/enums';
import UiProductTitle from '@/components/ui-product-title/index.vue';

export default Vue.extend({
  components: {
    UiProductTitle,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isItemAlgorithm() {
      return this.item.type === ContentType.ALGORITHM;
    },
    image() {
      if (this.isItemAlgorithm) {
        return 'background-image: url(/static/img/algorithms.png);';
      }

      const image = this.item.imageUrl || this.item.images?.[ImageLabel.ASPECT_16X9]?.[0]?.url;

      return image ? `background-image: url('${image}')` : undefined;
    },
  },
  created() {
    this.$mount();
  },
});
</script>
