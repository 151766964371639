import { UiFormNumberInput, UiFormTextInput } from 'redge-media-web-ui';
import { NumberDivider } from 'redge-media-web-utils';
import UiFormPriceInput from '@/components/ui-form/ui-form-price-input.vue';
import { priceInputField, textInputField, textInputWitLink } from '@/components/ui-form/fields';
import { productLink } from '@/helper';
import { DiscountType } from '@/enums';

export const CHANNEL = textInputField('channel', 'PAYMENT_CHANNEL');

export const CURRENCY = textInputField('currency', 'PAYMENT_CURRENCY');

export const DURATION = {
  field: 'duration',
  component: UiFormNumberInput,
  componentOptions: {
    label: 'PAYMENT_DURATION',
    divider: NumberDivider.HOUR_TO_SECONDS,
  },
};

export const MAX_PRICE = (isHidden = false) => ({
  isHidden,
  field: 'maxPrice',
  component: UiFormPriceInput,
  componentOptions: {
    label: 'MAX_PRICE',
  },
});

export const MAX_RATING = (isHidden = false) => ({
  isHidden,
  field: 'maxRating',
  component: UiFormNumberInput,
  componentOptions: {
    label: 'MAX_RATING',
  },
});

export const MIN_PRICE = (isHidden = false) => ({
  isHidden,
  field: 'minPrice',
  component: UiFormPriceInput,
  componentOptions: {
    label: 'MIN_PRICE',
  },
});

export const MIN_RATING = (isHidden = false) => ({
  isHidden,
  field: 'minRating',
  component: UiFormNumberInput,
  componentOptions: {
    label: 'MIN_RATING',
  },
});

export const PRICE = priceInputField('price', 'PAYMENT_PRICE');

export const PRODUCT = (vm, model, isAllowed) => {
  const componentOptions = {
    label: 'COMMON_PRODUCT',
    disabled: true,
  };

  if (!isAllowed) {
    return {
      field: 'product.title',
      component: UiFormTextInput,
      componentOptions,
    };
  }

  return model.product?.deletedAt
    ? {
      field: 'product.title',
      component: UiFormTextInput,
      componentOptions: {
        ...componentOptions,
        inputClass: 'line-through',
      },
    }
    : textInputWitLink('product.title', 'COMMON_PRODUCT', () => vm.$router.push(productLink(model.product)));
};

export const PROMOTION_FREE_ACCESS_DURATION = (model) => ({
  field: 'freeAccessDuration',
  isHidden: model.type !== DiscountType.FREE_ACCESS,
  isMandatory: true,
  component: UiFormNumberInput,
  componentOptions: {
    label: 'PROMOTION_FREE_ACCESS_DURATION',
    min: 1,
    divider: NumberDivider.HOUR_TO_SECONDS,
  },
});

export const STATUS = textInputField('status', 'COMMON_STATUS');
