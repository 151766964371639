<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { ModelDetailMixin, RemoveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as SubscriberColumns from '@/modules/subscriber/view/columns';
import DeviceDetailTemplate from '@/modules/subscriber/view/device/device-detail.vue';

export default ListView.extend({
  mixins: [ModelDetailMixin, RemoveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_ID_DEVICES(this.id),
      removeModelUrlFunction: ({ id }) => Urls.DEVICES_ID(id),
      modelDetailTemplate: DeviceDetailTemplate,
      modelDetailUrlFunction: ({ id }) => Urls.DEVICES_ID_VERSIONS(id),
      paramsConfig: {
        sort: QueryParam.LAST_LOGGED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.DEVICE_DETAIL((record) => ({ device: record })),
          Columns.DEVICE_INFO((record) => ({ device: record })),
          Columns.DEVICE_MAKER,
          Columns.DEVICE_OS,
          SubscriberColumns.DEVICE_APP_VERSION,
          Columns.CREATED_AT,
          SubscriberColumns.DEVICE_LAST_LOGIN_DATE,
          SubscriberColumns.DEVICE_LAST_LOGIN_IP,
          SubscriberColumns.ACTIONS_DEVICE(
            this.isAllowed,
            this.showModelDetail,
            this.removeModel,
            this.isLoadingDetail,
          ),
        ],
        records: this.records,
      };
    },
  },
});
</script>
