<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import { UiFormNumberInput } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { PromotionCodeType, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';
import * as Columns from '@/components/ui-table/columns';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  mixins: [SaveModelMixin, RemoveModelMixin],
  data() {
    const url = Urls.PROMOTIONS_ID_CODES(this.id, PromotionCodeType.MULTIPLE);

    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_PROMOTION_CODE_MULTIPLE_USE' : 'ADD_PROMOTION_CODE_MULTIPLE_USE'),
      saveModelFormElementsFunction: () => [
        [
          Fields.textInputField('value', 'PROMOTION_CODE_NAME', true),
          {
            field: 'count',
            component: UiFormNumberInput,
            componentOptions: {
              label: 'PROMOTION_CODE_COUNT',
              tooltip: Fields.commonTooltip('HELP_PROMOTION_CODE_COUNT'),
            },
          },
        ],
      ],
      exportUrl: Urls.PROMOTIONS_ID_CODES_EXPORT(this.id, PromotionCodeType.MULTIPLE),
      paramsConfig: {
        sort: QueryParam.ID,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'value',
            minWidth: 200,
            headingLabel: 'PROMOTION_CODE_VALUE',
          },
          {
            field: 'count',
            minWidth: 200,
            headingLabel: 'PROMOTION_CODE_COUNT',
          },
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
