<template>
  <div class="ui-filter-item ui-filter-item--switch">
    <label>{{ $translate(label) }}:</label>
    <ui-switch :value="value" @input="onInput(value)" />
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import { AppEvent } from '@/enums';

export default {
  components: {
    UiSwitch,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
    },
  },
  computed: {
    value() {
      return this.values?.[0] && JSON.parse(this.values[0]);
    },
  },
  methods: {
    onInput(value) {
      this.$emit(AppEvent.CHANGE, value ? undefined : true);
    },
  },
};
</script>
