<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { Page } from '@/enums';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      recordsUrl: Urls.ITEMS_ID_VIDEOS_SESSIONS(this.id),
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.SUBSCRIBER_DETAIL(({ profile: { subscriber } }) => ({ subscriber, page: Page.PROFILES }), false),
          { ...Columns.SINCE, isSortable: false },
          { ...Columns.TILL, isSortable: false },
          Columns.VIDEO_SESSION_ID,
          Columns.DEVICE_DETAIL(({ device }) => ({ device })),
          Columns.DEVICE_INFO(({ device }) => ({ device })),
          Columns.RECORD_DEVICE_MAKER,
          Columns.RECORD_DEVICE_OS,
          Columns.RECORD_DEVICE_APP_VERSION,
          Columns.SESSION_DELETE_REASON,
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
