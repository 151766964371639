<template>
  <ui-modal :header-label="title" @close="close">
    <ui-form v-bind="formDefinition" />
  </ui-modal>
</template>
<script>
import { UiForm, UiFormTextarea } from 'redge-media-web-ui';
import { AppEvent } from '@/enums';

const beautifyJSON = (jsonString = '') => JSON.stringify(JSON.parse(jsonString), null, 4);

export default {
  components: {
    UiForm,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formDefinition() {
      return {
        value: {
          event: beautifyJSON(this.item.event),
        },
        elements: [
          [
            {
              field: 'event',
              component: UiFormTextarea,
              componentOptions: {
                label: 'COMMON_DESCRIPTION',
                disabled: true,
                class: 'fixed-textarea-wrapper',
              },
            },
          ],
        ],
      };
    },
  },
  methods: {
    close() {
      this.$emit(AppEvent.CLOSE);
    },
  },
};
</script>
