<script>
import dayjs from 'dayjs';
import { DateFormat } from 'redge-media-web-utils';
import SubscriptionsList from '@/components/subscriptions-list/index.vue';
import { QueryParam } from '@/enums';

export default SubscriptionsList.extend({
  data() {
    return {
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
        since: dayjs().subtract(28, 'day').startOf('day').format(DateFormat.DATETIME_FORMAT),
        till: dayjs().add(1, 'day').endOf('day').format(DateFormat.DATETIME_FORMAT),
      },
    };
  },
});
</script>
