<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
        >
          <ui-product-title :product="model" />
        </h1>
        <div class="details-container__header-icons">
          <div class="details-container__header-elements details-container__header-elements--left">
            <ui-external-application-icons :model="model" :is-model-active="isModelActive" />
          </div>
          <div class="details-container__header-elements">
            <ui-switch
              v-if="model"
              v-tooltip="activateModelButtonText"
              :disabled="!isAllowed"
              :value="isModelActive"
              class="details-container__header-element"
              @input="activateModel()"
            />
          </div>
        </div>
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import { Page, RoleName } from '@/enums';
import { ActivateModelMixin, RoleMixin } from '@/mixin';
import { isSessionDbAvailable } from '@/helper';
import Bundles from '@/modules/product/view/vods/detail/bundles.vue';
import DisplaySchedules from '@/modules/product/view/vods/detail/display-schedules.vue';
import PaymentSchedules from '@/modules/product/view/vods/detail/payment-schedules.vue';
import Payments from '@/modules/product/view/vods/detail/payments.vue';
import Audits from '@/modules/product/view/vods/detail/audits.vue';
import VideoSessions from '@/modules/product/view/vods/detail/video-sessions.vue';
import Subscriptions from '@/modules/product/view/vods/detail/subscriptions.vue';
import Sections from '@/modules/product/view/vods/detail/sections.vue';
import UiTabs from '@/components/ui-tabs/index.vue';
import UiProductTitle from '@/components/ui-product-title/index.vue';
import UiExternalApplicationIcons from '@/components/ui-external-application-icons/index.vue';
import AdBreaks from './ad-breaks.vue';
import Detail from './detail.vue';
import Programmes from './programmes.vue';
import Files from './files.vue';
import Recordings from './recordings.vue';

export default {
  components: {
    UiSwitch,
    UiTabs,
    UiProductTitle,
    AdBreaks,
    UiExternalApplicationIcons,
    Detail,
    Bundles,
    Programmes,
    Files,
    Sections,
    DisplaySchedules,
    PaymentSchedules,
    Payments,
    Subscriptions,
    Recordings,
    Audits,
    VideoSessions,
  },
  mixins: [ActivateModelMixin, RoleMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
    tabs() {
      return [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'TAB_PROGRAMMES', page: Page.PROGRAMMES },
        { label: 'COMMON_BUNDLES', page: Page.BUNDLES },
        { label: 'COMMON_FILES', page: Page.FILES },
        { label: 'COMMON_SECTIONS', page: Page.SECTIONS },
        { label: 'TAB_DISPLAY-SCHEDULES', page: Page.DISPLAY_SCHEDULES },
        { label: 'TAB_PAYMENT-SCHEDULES', page: Page.PAYMENT_SCHEDULES },
        { label: 'COMMON_PAYMENTS', page: Page.PAYMENTS },
        { label: 'COMMON_SUBSCRIPTIONS', page: Page.SUBSCRIPTIONS },
        { label: 'COMMON_RECORDINGS', page: Page.RECORDINGS, isHidden: !this.hasRole(RoleName.CMS_WRITE) },
        {
          label: 'TAB_VIDEO-SESSIONS',
          page: Page.VIDEO_SESSIONS,
          isHidden: isSessionDbAvailable() || !this.hasRole(RoleName.CMS_WRITE),
        },
        { label: 'COMMON_AD_BREAKS', page: Page.AD_BREAKS },
        { label: 'COMMON_EVENTS', page: Page.AUDITS },
      ];
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
  },
};
</script>
