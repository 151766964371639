<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
          v-text="operatorTitle"
        ></h1>
        <ui-switch
          v-if="model && isAbleToActivateAccount"
          v-tooltip="activateModelButtonText"
          :value="isModelActive"
          class="details-container__header-element"
          @input="activateModel('OPERATOR')"
        />
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import operator from '@/application/operator';
import { ActivateModelMixin } from '@/mixin';
import UiTabs from '@/components/ui-tabs/index.vue';
import { Page } from '@/enums';
import Detail from './detail.vue';
import Audits from './audits.vue';

export default {
  components: {
    UiSwitch,
    UiTabs,
    Detail,
    Audits,
  },
  mixins: [ActivateModelMixin],
  data() {
    return {
      tabs: [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'COMMON_EVENTS', page: Page.AUDITS },
      ],
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
    operatorTitle() {
      const getOperatorTitle = ({ firstName, lastName, email }) => {
        const name = `${firstName || ''} ${lastName || ''}`.trim();
        return `${email} ${name ? `(${name})` : ''}`.trim();
      };

      return getOperatorTitle(this.model);
    },
    isAbleToActivateAccount() {
      return parseInt(this.id, 10) !== operator.id;
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
  },
};
</script>
