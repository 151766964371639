<template>
  <ui-modal :header-label="modalTitleFunction(model)" class="ui-modal--scrollable" @close="close">
    <ui-form v-model="model" v-bind="formDefinition" />
  </ui-modal>
</template>
<script>
import { UiForm } from 'redge-media-web-ui';
import { AppEvent } from '@/enums';

export default {
  components: {
    UiForm,
  },
  props: {
    formElementsFunction: {
      type: Function,
      required: true,
    },
    modalTitleFunction: {
      type: Function,
      required: true,
    },
    defaultValues: {
      type: Object,
      default: () => ({}),
    },
    submitButtonLabel: {
      type: String,
      default: 'COMMON_SAVE',
    },
  },
  data() {
    return {
      model: JSON.parse(JSON.stringify(this.defaultValues)),
      error: undefined,
    };
  },
  computed: {
    formDefinition() {
      return {
        elements: this.formElementsFunction(this.model),
        buttons: [
          {
            onClick: this.close,
            label: 'COMMON_CANCEL',
            type: 'button',
            class: 'button',
            tabindex: '0',
          },
          {
            onClick: this.save,
            label: this.submitButtonLabel,
            type: 'submit',
            class: 'button button-info',
            tabindex: '0',
          },
        ],
        errors: this.error?.response?.data?.errors,
      };
    },
  },
  methods: {
    close() {
      this.$emit(AppEvent.CLOSE);
    },
    save() {
      this.$emit(AppEvent.SUCCESS, this.model);
    },
  },
};
</script>
