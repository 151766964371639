import { UiModalConfirm } from 'redge-media-web-ui';
import http from '@/lib/http';
import { AppEvent } from '@/enums';

export default {
  methods: {
    removeModel(model, httpMethod = 'delete') {
      return new Promise((resolve) => {
        const modal = this.$modal.create(UiModalConfirm, {
          title: this.$translate('COMMON_REMOVE'),
          confirmationText: this.removeConfirmationText || this.$translate('COMMON_REMOVE_CONFIRMATION'),
        });

        modal.$on(AppEvent.SUCCESS, async () => {
          try {
            await http[httpMethod](this.removeModelUrlFunction(model));
            modal.close();
            await this.load?.();
            resolve();
          } catch (error) {
            this.error = error;
            throw error;
          }
        });
      });
    },
  },
};
