<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import * as Filters from '@/components/filter/filters';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    const url = Urls.ITEMS_ID_DISPLAY_SCHEDULES(this.id);

    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_DISPLAY_SCHEDULE' : 'ADD_DISPLAY_SCHEDULE'),
      saveModelFormElementsFunction: (model) => [
        [
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL(),
          Fields.TYPE(Urls.ITEMS_DICTIONARIES_DISPLAY_SCHEDULES_TYPES),
          Fields.PLATFORMS_MANDATORY(model),
        ],
      ],
      filters: [
        Filters.CREATE_SINCE(),
        Filters.CREATE_TILL(),
        Filters.autocomplete('displayScheduleTypes', {
          label: 'COMMON_TYPE',
          dictUrl: Urls.ITEMS_DICTIONARIES_DISPLAY_SCHEDULES_TYPES,
        }),
        Filters.PLATFORMS,
      ],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.SINCE,
          Columns.TILL,
          { ...Columns.TYPE, isSortable: true },
          Columns.PLATFORMS,
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
