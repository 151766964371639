import * as views from '@/modules';

export default [
  {
    path: '/gui/report/statistics',
    name: 'statistics',
    component: views.ReportStatistics,
    meta: {
      titleLabels: ['MENU_REPORT_STATISTICS'],
    },
  },
];
