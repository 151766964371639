<template>
  <div class="ui-table-poster" :style="posterStyle" :class="{ 'ui-table-poster--contain': contain }">
    <i
      v-if="record.video && isPlayable"
      class="ui-table-poster__icon ui-table-poster__icon--video"
      @click="play(playId, MediumType.VIDEO, videoType)"
    ></i>
    <i
      v-if="record.audio && isPlayable"
      class="ui-table-poster__icon ui-table-poster__icon--audio"
      @click="play(playId, MediumType.AUDIO, videoType)"
    ></i>
  </div>
</template>
<script>
import PlayMixin from '@/mixin/play.vue';
import { ImageLabel, ItemType, MediumType } from '@/enums';

export default {
  name: 'ui-table-poster',
  mixins: [PlayMixin],
  props: {
    record: {
      type: Object,
      required: true,
    },
    contain: {
      type: Boolean,
      default: false,
    },
    videoType: {
      type: String,
    },
  },
  data() {
    return { MediumType };
  },
  computed: {
    posterStyle() {
      const { url, images } = this.record;
      const posterUrl = (images && images[ImageLabel.ASPECT_16X9] && images[ImageLabel.ASPECT_16X9][0].url) || url;
      return posterUrl ? `background-image: url('${posterUrl}')` : '';
    },
    playId() {
      return this.record.type === ItemType.PROGRAMME ? this.record.programRecordingId : this.record.id;
    },
    isPlayable() {
      return this.record.playable || this.record.type !== ItemType.PROGRAMME;
    },
  },
};
</script>
