<template>
  <ui-form-text-input v-bind="attrs" v-on="listeners">
    <input
      ref="uiInputFileInput"
      type="file"
      class="hidden-file-input"
      @change="$emit('file-upload', $event)"
    >
  </ui-form-text-input>
</template>
<script>
import { UiFormTextInput } from 'redge-media-web-ui';

export default {
  components: {
    UiFormTextInput,
  },
  props: UiFormTextInput.props,
  computed: {
    attrs() {
      return {
        ...this.$attrs,
        ...this.$props,
        iconClass: this.$attrs.isIconVisible ? 'icon-upload' : undefined,
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        'icon-click': () => this.$refs.uiInputFileInput.dispatchEvent(new MouseEvent('click')),
      };
    },
  },
};
</script>
