<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import { UiFormNumberInput } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { PromotionCodeType, QueryParam, RoleName } from '@/enums';
import { SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Filters from '@/components/filter/filters';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  mixins: [SaveModelMixin],
  data() {
    const url = Urls.PROMOTIONS_ID_CODES(this.id, PromotionCodeType.SINGLE);

    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: url,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: () => 'PROMOTION_GENERATE_CODES',
      saveModelFormElementsFunction: () => [
        [
          Fields.textInputField('prefix', 'PROMOTION_CODE_PREFIX'),
          {
            field: 'count',
            isMandatory: true,
            component: UiFormNumberInput,
            componentOptions: {
              label: 'PROMOTION_CODE_COUNT',
              min: 1,
            },
          },
        ],
      ],
      exportUrl: Urls.PROMOTIONS_ID_CODES_EXPORT(this.id, PromotionCodeType.SINGLE),
      filters: [
        Filters.input('prefixes', { label: 'PROMOTION_CODE_PREFIX' }),
        Filters.SUBSCRIBER,
        Filters.multiSwitch('used', { label: 'PROMOTION_CODE_USED' }),
      ],
      paramsConfig: {
        sort: QueryParam.ID,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'value',
            minWidth: 200,
            headingLabel: 'PROMOTION_CODE_VALUE',
          },
          Columns.createDateColumn('usedAt', 'PROMOTION_CODE_USE_DATE', 200, false),
          Columns.SUBSCRIBER_DETAIL(({ subscriber }) => ({ subscriber }), false),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
