<template>
  <ui-multiple-choice-base v-bind="uiMultipleChoiceBaseOptions" v-on="$listeners" />
</template>
<script>
import { UiMultipleChoiceBase } from 'redge-media-web-ui';
import { DictionaryFetchMixin } from '@/mixin';
import { filterObjectEntries, filterOutObjectEntries } from '@/helper';

export default {
  components: {
    UiMultipleChoiceBase,
  },
  mixins: [DictionaryFetchMixin],
  props: {
    ...filterOutObjectEntries(UiMultipleChoiceBase.props, ['records', 'fetching']),
    maxResults: {
      type: Number,
      default: null,
    },
  },
  computed: {
    uiMultipleChoiceBaseOptions() {
      return {
        ...filterObjectEntries(this.$props, Object.keys(UiMultipleChoiceBase.props)),
        fetching: this.fetching,
        records: this.records,
      };
    },
  },
  created() {
    this.fetch();
  },
};
</script>
