<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <ui-title :payment="model" class="details-container__header-text" />
        <span
          v-if="isAllowed && model && model.active && model.status === PaymentStatus.CONFIRMED"
          v-tooltip="$translate('HELP_BLOCK')"
          class="details-container__header-element icon-block"
          @click="blockPayment"
        ></span>
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiModalConfirm } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import {
  AppEvent, Page, PaymentStatus, RoleName,
} from '@/enums';
import http from '@/lib/http';
import { RoleMixin } from '@/mixin';
import UiTabs from '@/components/ui-tabs/index.vue';
import Detail from './detail.vue';
import UiTitle from './title.vue';

export default {
  components: {
    UiTabs,
    Detail,
    UiTitle,
  },
  mixins: [RoleMixin],
  data() {
    return {
      PaymentStatus,
      writeRole: RoleName.CRM_WRITE,
      tabs: [{ label: 'TAB_DETAIL', page: Page.DETAIL }],
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
    blockPayment() {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_REMOVE'),
        confirmationText: this.$translate('PAYMENT_BLOCK_CONFIRMATION'),
      });

      modal.$on(AppEvent.SUCCESS, async () => {
        try {
          await http.post(Urls.PAYMENTS_ID_BLOCK(this.model.id));
          modal.close();
          this.$refs.childComponent.$refs.uiDetail?.fetchModel();
        } catch (error) {
          this.error = error;
          throw error;
        }
      });
    },
  },
};
</script>
