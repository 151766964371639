<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import { ModelDetailMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as AdministrationColumns from '@/modules/administration/view/columns';
import { QueryParam } from '@/enums';

export default ListView.extend({
  mixins: [ModelDetailMixin],
  props: {
    id: [String, Number],
    page: String,
  },
  data() {
    return {
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          AdministrationColumns.AUDIT_OPERATOR,
          AdministrationColumns.AUDIT_EVENT_TYPE,
          Columns.CREATED_AT,
          AdministrationColumns.ACTIONS_DETAIL(this.showModelDetail),
        ],
        records: this.records,
      };
    },
  },
});
</script>
