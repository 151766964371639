import { ItemType } from '@/enums';

export default (product, page = 'detail') => {
  let name = '';

  switch (product.type) {
    case ItemType.PROGRAMME:
      name = 'lives.programme.detail';
      break;
    case ItemType.BANNER:
      name = 'banners.detail';
      break;
    case ItemType.SERIAL:
      name = 'serial.detail';
      break;
    case ItemType.SEASON:
      name = 'serial.season.detail';
      break;
    case ItemType.EPISODE:
      name = 'serial.episode.detail';
      break;
    case ItemType.LIVE:
      name = 'lives.detail';
      break;
    case ItemType.PPV:
      name = 'ppvs.detail';
      break;
    case ItemType.VOD:
      name = 'vods.detail';
      break;
    case ItemType.CATCH_UP:
    case ItemType.DOWNLOAD:
    case ItemType.MAX_BITRATE:
    case ItemType.N_PVR:
    case ItemType.NO_ADVERTISING:
    case ItemType.TIMESHIFT:
    case ItemType.VIDEO_SESSION_LIMIT:
      name = 'specials.detail';
      break;
    case ItemType.BUNDLE:
      name = 'bundles.detail';
      break;
    case ItemType.SECTION:
      name = 'section.detail';
      break;
    default:
      return {};
  }

  return {
    name,
    params: {
      id: product.id,
      page,
    },
  };
};
