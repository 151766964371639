<template>
  <div class="ui-search">
    <input
      v-model="value"
      v-focus
      :placeholder="placeholder"
      class="ui-input ui-search__input"
      data-qa="search-input"
      type="text"
    >
    <span class="ui-search__icon icon-search"></span>
  </div>
</template>
<script>
import { focus } from 'redge-media-web-ui';
import { AppEvent } from '@/enums';

export default {
  directives: {
    focus,
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
      timeoutRef: undefined,
      created: false,
    };
  },
  watch: {
    keyword: {
      async handler() {
        this.value = this.keyword;
        await this.$nextTick();
        this.created = true;
      },
      immediate: true,
    },
    value() {
      clearTimeout(this.timeoutRef);
      if (this.created) {
        this.timeoutRef = setTimeout(() => {
          this.$emit(AppEvent.CHANGE, this.value);
        }, 400);
      }
    },
  },
};
</script>
