<template>
  <div v-if="product" class="details-form">
    <ui-product-file-images v-bind="artworkImagesProps" @change="load" />

    <ui-product-file-images v-bind="titleTreatmentImagesProps" @change="load" />
  </div>
</template>
<script>
import Urls from '@/application/urls';
import http from '@/lib/http';
import { AppEvent, RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { artworkImagesProps, titleTreatmentImagesProps } from '@/components/vod-files/images';
import UiProductFileImages from '@/components/product-file-images/index.vue';

export default {
  components: {
    UiProductFileImages,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      product: undefined,
    };
  },
  computed: {
    artworkImagesProps() {
      return artworkImagesProps(this.isAllowed, this.product, this.product.images);
    },
    titleTreatmentImagesProps() {
      return titleTreatmentImagesProps(this.isAllowed, this.product, this.product.titleTreatmentImages);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.product = await http.get(Urls.PRODUCTS_BUNDLES_ID_PAGE(this.id, this.page)).then(({ data }) => data);
      this.$emit(AppEvent.MODEL, this.product);
    },
  },
};
</script>
