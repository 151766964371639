<template>
  <div class="sidebar-content">
    <div class="sidebar-content__filters">
      <ui-search class="sidebar-content__search" @change="request.params.keyword = $event" />
      <select v-model="request.url" class="ui-select sidebar-content__select">
        <option
          v-for="filter in filters"
          :key="filter.url"
          :value="filter.url"
          v-text="$translate(filter.label)"
        ></option>
      </select>
    </div>
    <div class="ui-filter-item ui-filter-item--switch">
      <label v-text="`${$translate('COMMON_ACTIVE_MALE')}:`"></label>
      <ui-multi-switch v-model="request.params.active" />
    </div>
    <ul v-if="records.length > 0" class="sidebar-content__list">
      <li
        v-for="record in records"
        :key="record.id"
        :class="{ 'opacity-4': !record.displayActive }"
        class="sidebar-content__list-item"
      >
        <div
          :style="background(record.images)"
          class="sidebar-content__list-item-image"
          @mousedown="onMouseDown(record, $event)"
        ></div>
        <div class="sidebar-content__list-item-description">
          <router-link :to="productLink(record)" class="sidebar-content__list-item-name">
            <ui-product-title :product="record" />
          </router-link>
          <div class="sidebar-content__list-item-categories">
            <span
              v-if="record.mainCategory"
              v-tooltip="record.mainCategory.name"
              class="sidebar-content__list-item-category sidebar-content__list-item-category--main"
              v-text="record.mainCategory.key"
            ></span>
            <span
              v-for="category in record.categories"
              :key="category.key"
              v-tooltip="category.name"
              class="sidebar-content__list-item-category sidebar-content__list-item-category--category"
              v-text="category.key"
            ></span>
          </div>
        </div>
      </li>
    </ul>
    <p
      v-else-if="isFetching"
      class="sidebar-content__no-data-info"
      v-text="`${$translate('WIDGET_SIDEBAR_PRODUCTS_LOADING')}...`"
    ></p>
    <p v-else class="sidebar-content__no-data-info" v-text="$translate('COMMON_NO_RESULTS')"></p>
  </div>
</template>
<script>
import { UiMultiSwitch } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import UiSearch from '@/components/search/search.vue';
import UiProductTitle from '@/components/ui-product-title/index.vue';
import { QueryParam } from '@/enums';
import { background, productLink } from '@/helper';
import DroppableMixin from '@/mixin/droppable';

const filters = [
  { label: 'MENU_PRODUCT_VODS', url: Urls.PRODUCTS_VODS },
  { label: 'MENU_PRODUCT_LIVES', url: Urls.PRODUCTS_LIVES },
  { label: 'MENU_PRODUCT_SERIES', url: Urls.PRODUCTS_VODS_SERIALS },
  { label: 'MENU_PRODUCT_PPVS', url: Urls.PRODUCTS_PPVS },
  { label: 'COMMON_BUNDLES', url: Urls.PRODUCTS_BUNDLES },
];

export default {
  components: {
    UiSearch,
    UiProductTitle,
    UiMultiSwitch,
  },
  mixins: [DroppableMixin],
  props: {
    records: {
      type: Array,
      required: true,
    },
    fetchRecords: {
      type: Function,
      required: true,
    },
    isFetching: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filters,
      request: {
        url: filters[0].url,
        params: {
          sort: QueryParam.CREATED_AT,
          order: QueryParam.DESCENDING,
          keyword: undefined,
          active: undefined,
        },
      },
    };
  },
  watch: {
    request: {
      handler: 'fetchRecords',
      immediate: true,
      deep: true,
    },
  },
  methods: {
    background,
    productLink,
  },
};
</script>
