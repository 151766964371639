<template>
  <ui-detail v-bind="detailDefinition" class="ui-detail--readonly-detail" v-on="$listeners" />
</template>
<script>
import Urls from '@/application/urls';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as SubscriberFields from '@/modules/subscriber/view/subscriber/fields';

export default {
  components: {
    UiDetail,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    detailDefinition() {
      return {
        modelUrl: Urls.DEVICES_ID(this.id),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.textInputField('uid', 'DEVICE_UUID'),
            Fields.textInputField('name', 'COMMON_NAME'),
            Fields.SUBSCRIBER(this, model, 'devices'),
            Fields.textInputField('platform', 'PLATFORM_NAME'),
            Fields.textInputField('agent', 'DEVICE_AGENT'),
            Fields.textInputField('agentVersion', 'DEVICE_AGENT_VERSION'),
            Fields.textInputField('os', 'DEVICE_OS'),
            Fields.textInputField('osVersion', 'DEVICE_OS_VERSION'),
            Fields.textInputField('maker', 'DEVICE_MAKER'),
            Fields.textInputField('appVersion', 'DEVICE_APP_VERSION'),
            Fields.textInputField('lastLoggedIp', 'DEVICE_LAST_LOGIN_IP'),
            Fields.CREATED_AT,
            SubscriberFields.LAST_LOGIN_DATE,
          ],
        ],
      };
    },
  },
};
</script>
