<template>
  <div class="sections-sidebar">
    <ul class="sections-sidebar__tabs">
      <li v-for="(tab, index) in tabs" :key="index" class="sections-sidebar__tab">
        <button
          class="sections-sidebar__tab-button"
          :class="{ 'sections-sidebar__tab-button--active': tab === activeTab }"
          @click="changeActiveTab(tab)"
          v-text="$translate(tab.title)"
        ></button>
      </li>
    </ul>
    <component
      :is="activeTab.component"
      :records="activeTabRecords"
      :fetch-records="fetchRecords"
      :is-fetching="isFetching"
    />
  </div>
</template>
<script>
import http, { CancelToken, isHttpRequestCanceled } from '@/lib/http';
import ProductList from './product-list.vue';
import ProgrammeList from './programme-list.vue';
import EpisodeList from './episode-list.vue';
import AlgorithmList from './algorithm-list.vue';
import ContentList from './content-list.vue';

const tabs = [
  { title: 'COMMON_PRODUCTS', component: ProductList },
  { title: 'TAB_PROGRAMMES', component: ProgrammeList },
  { title: 'COMMON_EPISODES', component: EpisodeList },
  { title: 'COMMON_ALGORITHMS', component: AlgorithmList },
  { title: 'COMMON_CONTENT_PLURAL', component: ContentList },
];

export default {
  data() {
    return {
      tabs,
      activeTab: tabs[0],
      activeTabRecords: [],
      cancelToken: undefined,
      isFetching: false,
    };
  },
  methods: {
    changeActiveTab(tab) {
      if (this.activeTab !== tab) {
        this.activeTab = tab;
        this.activeTabRecords = [];
      }
    },
    async fetchRecords({ params, url }) {
      this.cancelToken?.cancel();
      this.cancelToken = CancelToken.source();

      this.isFetching = true;
      try {
        this.activeTabRecords = await http
          .get(url, { params, cancelToken: this.cancelToken.token })
          .then(({ data }) => data.items || data);
        this.isFetching = false;
      } catch (error) {
        if (!isHttpRequestCanceled(error)) {
          this.isFetching = false;
          throw error;
        }
      }
    },
  },
};
</script>
