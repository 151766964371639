<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { ItemType, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Filters from '@/components/filter/filters';
import * as Fields from '@/components/ui-form/fields';
import * as ProductFilters from '@/modules/product/view/filters';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_BUNDLES,
      removeModelUrlFunction: ({ id }) => Urls.PRODUCTS_BUNDLES_ID(id),
      saveModelUrlFunction: () => Urls.PRODUCTS_BUNDLES,
      saveModelModalTitleFunction: () => 'ADD_BUNDLE',
      saveModelFormElementsFunction: () => [[Fields.TITLE, Fields.CREATE_SINCE(), Fields.CREATE_TILL(), Fields.RANK]],
      saveModelDefaultValues: {
        type: ItemType.BUNDLE,
        type_: ItemType.BUNDLE,
      },
      exportUrl: Urls.PRODUCTS_BUNDLES_EXPORT,
      filters: [Filters.ACTIVE, ProductFilters.HAS_DESCRIPTION, Filters.CREATE_SINCE(), Filters.EXTERNAL_UID, ProductFilters.CURRENCIES],
      paramsConfig: {
        sort: QueryParam.RANK,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.PRODUCT_DETAIL((record) => ({ product: record }), 'COMMON_TITLE'),
          {
            field: 'numberOfElements',
            width: 160,
            headingLabel: 'BUNDLE_NUMBER_OF_ELEMENTS',
          },
          Columns.PLATFORMS,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.RANK,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
