<template>
  <div class="ui-section-header" :class="{ 'ui-section-header--inactive': !section.displayActive }">
    <div v-if="isAllowed && isDraggable" class="ui-section-header__move icon-sidebar-medium"></div>

    <div class="ui-section-header__content">
      <h4 class="ui-section-header__content-title">
        <ui-item-title :item="section" />
      </h4>
      <p v-tooltip="sinceTillInfoTooltipText" class="icon-calendar ui-section-header__content-text"></p>
      <p v-if="section.layoutDict" class="ui-section-header__content-text" v-text="section.layoutDict.name"></p>
      <p v-if="section.tilesAspect" class="ui-section-header__content-text" v-text="section.tilesAspect"></p>
      <p v-if="section.tilesSize" class="ui-section-header__content-text" v-text="section.tilesSize"></p>
      <p v-if="section.contentType" class="ui-section-header__content-text" v-text="section.contentType"></p>
      <ui-table-platforms :platforms="section.platforms" />
    </div>

    <div class="ui-section-header__actions">
      <span
        v-tooltip="$translate('HELP_EDIT')"
        class="ui-section-header__actions-element icon-settings"
        @click="$emit('edit')"
      ></span>
      <span
        v-if="isAllowed"
        v-tooltip="$translate('HELP_REMOVE')"
        class="ui-section-header__actions-element icon-trash"
        @click="$emit('remove')"
      ></span>
      <div class="ui-section-header__actions-toggle">
        <span
          :class="isExpanded ? 'icon-up' : 'icon-down'"
          class="ui-section-header__actions-toggle-icon"
          @click="$emit('toggle')"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import UiItemTitle from '@/components/ui-item-title/index.vue';
import { UiTablePlatforms } from '@/components/ui-table/index';

export default {
  components: {
    UiItemTitle,
    UiTablePlatforms,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    isAllowed: {
      type: Boolean,
      required: true,
    },
    isDraggable: {
      type: Boolean,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    sinceTillInfoTooltipText() {
      const { since, till } = this.section;
      const sinceText = `${this.$translate('COMMON_SINCE')}: ${this.$dateFormat(since)}`;

      if (till) {
        return `${sinceText} \n ${this.$translate('COMMON_TILL')}: ${this.$dateFormat(till)}`;
      }

      return sinceText;
    },
  },
};
</script>
