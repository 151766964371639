<template>
  <ui-detail
    ref="uiDetail"
    v-bind="detailDefinition"
    v-on="$listeners"
    @model="onModel"
  />
</template>
<script>
import { UiModalConfirm, UiModalPopup } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { AppEvent, RoleName } from '@/enums';
import operator from '@/application/operator';
import http from '@/lib/http';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as AdministrationFields from '@/modules/administration/view/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.ADMIN,
    };
  },
  computed: {
    detailDefinition() {
      const isOperatorEditingHimself = operator.id === parseInt(this.id, 10);

      return {
        isAllowed: this.isAllowed,
        modelUrl: Urls.OPERATORS_ID(this.id),
        fromApiModelMapper: (model) => ({
          password: '',
          _passwordPlaceholder: '********',
          _passwordInputType: 'password',
          ...model,
        }),
        toApiModelMapper: ({ _initialToken, token, ...model }) => ({
          ...model,
          token: _initialToken === token ? undefined : token,
        }),
        formElementsFunction: (model) => [
          [
            Fields.textHeaderWithIcon(
              'COMMON_METADATA',
              !model.emptyPassword ? 'icon-trash' : '',
              'HELP_REMOVE_PASSWORD',
              this.removePassword,
            ),
            AdministrationFields.EMAIL(isOperatorEditingHimself),
            Fields.EXTERNAL_UID,
            AdministrationFields.FIRST_NAME,
            AdministrationFields.LAST_NAME,
            {
              ...Fields.textInputField('_passwordPlaceholder', 'OPERATOR_PASSWORD', false, true),
              isHidden: model.emptyPassword,
            },
            { ...AdministrationFields.PASSWORD(model), isHidden: !model.emptyPassword },
            Fields.textInputField('token', 'OPERATOR_TOKEN'),
            Fields.ROLES(Urls.OPERATORS_ROLES, true, isOperatorEditingHimself),
          ],
        ],
      };
    },
  },
  methods: {
    onModel(model) {
      if (model.password !== '') {
        const updatedModel = this.$refs.uiDetail.updateModel({ emptyPassword: false });
        this.$refs.uiDetail.setSerializedModel(updatedModel);
      }

      const updatedModel = this.$refs.uiDetail.updateModel({ _initialToken: model.token });
      this.$refs.uiDetail.setSerializedModel(updatedModel);
    },
    removePassword() {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_REMOVE'),
        confirmationText: this.$translate('OPERATOR_REMOVE_PASSWORD'),
      });

      modal.$on(AppEvent.SUCCESS, async () => {
        await http.delete(Urls.OPERATORS_ID_PASSWORD(this.$route.params.id));
        const updatedModel = this.$refs.uiDetail.updateModel({ emptyPassword: true, password: '' });
        this.$refs.uiDetail.setSerializedModel(updatedModel);
        modal.close();
        this.$modal.create(UiModalPopup, {
          text: this.$translate('OPERATOR_PASSWORD_REMOVE_CONFIRMATION'),
        });
      });
    },
  },
};
</script>
