import Urls from '@/application/urls';
import menuSections from '@/application/menu-data';
import http from '@/lib/http';
import { Constants } from '@/enums';
import { getTenantUid } from '@/helper';

const DEFAULT_VALUES = {
  id: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  roles: [],
  tenants: [],
  availableSections: [],
  selectedTenant: undefined,
  isLogged: false,
  password: undefined,
  emptyPassword: true,
};

class Operator {
  constructor() {
    Object.assign(this, DEFAULT_VALUES);
  }

  fetch() {
    return http.get(Urls.OPERATORS_DETAIL).then(({ data }) => {
      const { selectedTenant, tenants } = data;
      const cachedTenant = getTenantUid() || selectedTenant.uid;

      if (!tenants.length) {
        localStorage.removeItem(Constants.SELECTED_TENANT_UID);
      }

      const isRequiredTenantChange = tenants.length && selectedTenant && cachedTenant === selectedTenant.uid;

      if (isRequiredTenantChange) {
        localStorage.setItem(Constants.SELECTED_TENANT_UID, cachedTenant);
      }

      Object.assign(this, { ...data, isLogged: true });

      const roles = this.roles.map(({ name }) => name);
      this.availableSections = menuSections.filter(({ readRole }) => roles.includes(readRole));

      this.setTenantAllProductsPaid();
    });
  }

  async setTenantAllProductsPaid() {
    const url = Urls.APPLICATION_SETTINGS_KEY('PRODUCT_ALL_PAID');
    this.selectedTenant.allProductsPaid = await http.get(url).then(({ data }) => data);
  }

  async logout() {
    await http.post(Urls.AUTH_LOGOUT, {}).catch(() => {});
    Object.assign(this, DEFAULT_VALUES);
  }
}

export default new Operator();
