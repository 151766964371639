<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    const url = Urls.NICKNAMES_PROHIBITED;
    return {
      writeRole: RoleName.ADMIN,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => Urls.NICKNAMES_PROHIBITED_ID(id),
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : `${url}`),
      saveModelModalTitleFunction: ({ id }) => (id ? 'ADD_NICKNAME_PROHIBITED' : 'EDIT_NICKNAME_PROHIBITED'),
      saveModelFormElementsFunction: () => [
        [Fields.TYPE(Urls.NICKNAMES_PROHIBITED_TYPES), Fields.textInputField('value', 'COMMON_VALUE', true)],
      ],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'type',
            width: 200,
            headingLabel: 'COMMON_TYPE',
          },
          {
            field: 'value',
            minWidth: 200,
            headingLabel: 'COMMON_VALUE',
          },
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
