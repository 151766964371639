export default {
  AD_SERVER: 'AD_SERVER',
  AGREEMENT: 'AGREEMENT',
  AGREEMENT_VERSION: 'AGREEMENT_VERSION',
  BANNER: 'BANNER',
  BUNDLE: 'BUNDLE',
  CATEGORY: 'CATEGORY',
  CONTACT: 'CONTACT',
  DOCUMENT: 'DOCUMENT',
  GEOIP: 'GEOIP',
  ITEM: 'ITEM',
  OPERATOR: 'OPERATOR',
  PRODUCT: 'PRODUCT',
  PROMOTION: 'PROMOTION',
  SECTION: 'SECTION',
  SETTING: 'SETTING',
  SOFTWARE_DECODING: 'SOFTWARE_DECODING',
  SUBSCRIBER: 'SUBSCRIBER',
  TIMER: 'TIMER',
  VOD: 'VOD',
};
