<template>
  <div class="ui-table-actions">
    <div v-for="button in buttons" :key="button.icon" class="ui-table-actions__icon-wrapper">
      <span
        v-tooltip="$translate(button.tooltip)"
        :class="button.icon"
        class="ui-table-actions__icon"
        @click="button.onClick"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ui-table-actions',
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
