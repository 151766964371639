<template>
  <div v-if="model" class="details-form">
    <div class="ui-panel h-100 m-0">
      <div class="ui-panel__header">
        <h2 class="ui-panel__header-text" v-text="$translate('COMMON_ATTACHMENTS')"></h2>
        <div v-if="isAllowed">
          <v-button-icon
            icon="icon-add"
            tooltip="HELP_ADD"
            class="ui-panel__header-button"
            @click="createModel"
          />
        </div>
      </div>
      <ui-table v-bind="tableDefinition" class="ui-table--without-panel" />
    </div>
  </div>
</template>
<script>
import Urls from '@/application/urls';
import { AppEvent, RoleName } from '@/enums';
import http from '@/lib/http';
import { RemoveModelMixin, RoleMixin, SaveModelMixin } from '@/mixin';
import VButtonIcon from '@/components/button/button-icon.vue';
import { UiTable } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default {
  components: {
    VButtonIcon,
    UiTable,
  },
  mixins: [RemoveModelMixin, RoleMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    const url = Urls.AGREEMENTS_ID_ATTACHMENTS(this.id);

    return {
      writeRole: RoleName.CMS_WRITE,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_ATTACHMENT' : 'ADD_ATTACHMENT'),
      saveModelFormElementsFunction: (model) => [[Fields.NAME, Fields.URL_WITH_FILE_UPLOAD(model)]],
      model: undefined,
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.NAME,
          Columns.FILE_URL,
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.model.attachments,
      };
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.model = await http.get(Urls.AGREEMENTS_ID_PAGE(this.id, this.page)).then(({ data }) => data);
      this.$emit(AppEvent.MODEL, this.model);
    },
  },
};
</script>
