<template>
  <div class="ui-filter-item ui-filter-item--switch">
    <label @click="next">{{ $translate(label) }}:</label>
    <ui-multi-switch :value="value" @input="change" />
  </div>
</template>
<script>
import { UiMultiSwitch } from 'redge-media-web-ui';
import { AppEvent } from '@/enums';

const VALUES = [false, undefined, true];

export default {
  components: {
    UiMultiSwitch,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    value() {
      return this.values.length > 0 ? JSON.parse(this.values[0]) : undefined;
    },
  },
  methods: {
    change(value) {
      this.$emit(AppEvent.CHANGE, value !== undefined ? [value] : []);
    },
    next() {
      const index = VALUES.indexOf(this.value);
      this.change(index >= VALUES.length - 1 ? VALUES[0] : VALUES[index + 1]);
    },
  },
};
</script>
