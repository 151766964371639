<template>
  <div class="ui-filter-item">
    <ui-date-picker-input
      ref="datepicker"
      :is-relative-date-enabled="true"
      :timepicker="timepicker"
      :value="value"
      :label="$translate(label)"
      :language="language"
      @input="onInput"
    />
  </div>
</template>
<script>
import { UiDatePickerInput } from 'redge-media-web-ui';
import { AppEvent } from '@/enums';
import i18n from '@/application/i18n';

export default {
  components: {
    UiDatePickerInput,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      default: () => [],
    },
    timepicker: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value() {
      return this.values[0];
    },
    language() {
      return new Intl.Locale(i18n.language).language;
    },
  },
  methods: {
    onInput(value) {
      if (this.value !== value) {
        this.$emit(AppEvent.CHANGE, value ? [value] : []);
      }
    },
    focus() {
      this.$refs.datepicker.show();
    },
    hideDatePicker() {
      this.$refs.datepicker.hide();
    },
  },
};
</script>
