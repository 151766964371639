<template>
  <span v-tooltip="record.status">
    <img class="icon" :src="`/static/img/icon/recording/recording_${record.status}.png`">
  </span>
</template>
<script>
export default {
  name: 'ui-table-recording-status',
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
};
</script>
