var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"translations-list"},[_c('v-list-wrapper',{attrs:{"table-definition":_vm.tableDefinition},scopedSlots:_vm._u([{key:"key",fn:function(ref){
var record = ref.record;
var index = ref.index;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(record.key),expression:"record.key"}],staticClass:"ui-input",class:{
          'input-modified-color': _vm.isKeyDifferent(record, index),
          'input-invalid-color': _vm.isKeyEmpty(record),
        },attrs:{"disabled":!_vm.isAllowed,"type":"text"},domProps:{"value":(record.key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(record, "key", $event.target.value)}}})]}},_vm._l((_vm.languages),function(language){return {key:language,fn:function(ref){
        var record = ref.record;
        var index = ref.index;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(record.values[language]),expression:"record.values[language]"}],key:language,staticClass:"ui-input",class:{ 'input-modified-color': _vm.isValueDifferent(record, index, language) },attrs:{"disabled":!_vm.isAllowed,"type":"text"},domProps:{"value":(record.values[language])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(record.values, language, $event.target.value)}}})]}}})],null,true)}),_c('div',{staticClass:"translations-list__button-wrapper"},[_c('button',{staticClass:"button button-info",attrs:{"disabled":_vm.changedRecords.length === 0,"tabindex":"0","type":"submit"},domProps:{"textContent":_vm._s(_vm.$translate('COMMON_SAVE'))},on:{"click":function($event){$event.preventDefault();return _vm.save($event)}}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }