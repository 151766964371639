<template>
  <li class="ui-filter-item-selected-value">
    <span v-text="value"></span>
    <span class="ui-filter-item-selected-value__remove icon-close" @click.stop="remove"></span>
  </li>
</template>
<script>
import { AppEvent } from '@/enums';

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    remove() {
      this.$emit(AppEvent.REMOVE, this.value);
    },
  },
};
</script>
