import Vue from 'vue';
import { UiFormNumberInput, UiFormTextInput } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { SectionLayoutType, SectionType } from '@/enums';
import { getTenantUid } from '@/helper';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import UiFormDictionarySelect from '@/components/ui-form/ui-form-dictionary-select.vue';
import * as Fields from '@/components/ui-form/fields';
import { numberInputField, textInputField } from '@/components/ui-form/fields';

export const ALIAS = textInputField('alias', 'DOCUMENT_ALIAS', true);

export const CATEGORY_TYPE = (model) => ({
  field: 'categoryType',
  isHidden: model.contentType !== SectionType.CATALOG,
  isMandatory: true,
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'SECTION_CATEGORY_TYPE',
    dictUrl: Urls.ITEMS_CATEGORIES_TYPE('types'),
    on: {
      input: () => {
        model.mainCategory = undefined;
      },
    },
  },
});

export const CONTENT_TYPE = (model) => ({
  // Using temporary _contentType is required because in section detail you need to confirm contentType value change and by clicking Cancel you can revert it.
  // Without using temporary _contentType all changes would be applied directly to contentType field, which could force layout dict change.
  // We want this dict to change only when user confirmed contentType value change.
  field: '_contentType',
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'COMMON_TYPE',
    dictUrl: Urls.SECTIONS_CONTENT_TYPES,
    on: {
      input: () => {
        if (model._contentType === SectionType.CATALOG) {
          model.layoutDict = undefined;
        }

        Vue.set(model, 'contentType', model._contentType);
      },
    },
  },
});

export const LAYOUT_DICT = (model) => ({
  field: 'layoutDict',
  isMandatory: true,
  component: UiFormDictionarySelect,
  componentOptions: {
    valueAsObject: true,
    label: 'SECTION_LAYOUT',
    field: 'name',
    ...(model.contentType === SectionType.CATALOG
      ? { dictType: 'SECTION_LAYOUT_CATALOG' }
      : { dictType: 'SECTION_LAYOUT' }),
  },
});

export const PRESENTATION_TIME = (model) => ({
  field: 'presentationTime',
  component: UiFormNumberInput,
  isHidden: model.layoutDict?.name !== SectionLayoutType.BANNER,
  componentOptions: {
    label: 'COMMON_PRESENTATION_TIME',
    tooltip: Fields.commonTooltip('HELP_PRESENTATION_TIME'),
  },
});

export const REFRESH_INTERVAL = numberInputField('refreshInterval', 'SECTION_REFRESH_INTERVAL');

export const SLUG = textInputField('slug', 'COMMON_SLUG', true);

export const TILES_ASPECT = {
  field: 'tilesAspect',
  isMandatory: true,
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'SECTION_TILES_ASPECT',
    dictUrl: Urls.SECTIONS_TILES_ASPECTS,
  },
};

export const TILES_ACTIVITY = (onFetched) => ({
  field: 'tilesActivity',
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'SECTION_TILES_ACTIVITY',
    dictUrl: Urls.TENANTS_SECTIONS_TILE_ACTIVITIES(getTenantUid()),
    on: {
      fetched: onFetched,
    },
  },
});

export const TILES_SIZE = {
  field: 'tilesSize',
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'SECTION_TILES_SIZE',
    dictUrl: Urls.SECTIONS_TILES_SIZES,
  },
};

export const APP_URL = {
  field: 'appUrl',
  component: UiFormTextInput,
  componentOptions: {
    label: 'COMMON_APP_URL',
    tooltip: Fields.commonTooltip('HELP_APP_URL'),
  },
};

export const WEB_URL = {
  field: 'webUrl',
  component: UiFormTextInput,
  componentOptions: {
    label: 'COMMON_WEB_URL',
    tooltip: Fields.commonTooltip('HELP_WEB_URL'),
  },
};

export const VIEWS = {
  field: 'views',
  isMandatory: true,
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'COMMON_VIEW',
    dictType: 'VIEW',
    field: 'key',
    multiple: true,
  },
};
