import UrlUtil from '@/helper/url';

export default (medium) => {
  const query = medium && UrlUtil.extractQuery(medium.url);

  if (query && query.startTime && query.stopTime) {
    return {
      start: parseInt(query.startTime, 10),
      stop: parseInt(query.stopTime, 10),
    };
  }

  return undefined;
};
