<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition">
    <template #active="{ record }">
      <span v-if="record.displayActive" class="icon-success"></span>
      <span
        v-else-if="record.active"
        v-tooltip="`${$translate('AGREEMENT_VERSION_SINCE')} ${$dateFormat(record.since, 'YYYY-MM-DD HH:mm')}`"
      >
        <i class="no-active-icon">!</i>
        <span class="icon-success"></span>
      </span>
      <span
        v-else
        v-tooltip="$translate('HELP_AGREEMENT_VERSION_ADD_TITLE')"
        class="icon-block cursor-pointer"
        @click="activate(record.id)"
      ></span>
    </template>
    <template #description="{ record }">
      <span v-if="record.description" v-text="record.description"></span>
      <span v-else class="font-style-italic opacity-5" v-text="$translate('NO_DESCRIPTION')"></span>
    </template>
    <template #content="{ record }">
      <span v-if="record.content" v-text="record.content"></span>
      <span v-else class="font-style-italic opacity-5" v-text="$translate('NO_CONTENT')"></span>
    </template>
  </v-list-wrapper>
</template>
<script>
import { UiModalConfirm } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import http from '@/lib/http';
import { AppEvent, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as ContentColumns from '@/modules/content/view/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';
import UiModalActivationErrors from '@/components/modal/ui-modal-activation-errors.vue';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    const url = Urls.AGREEMENTS_ID_VERSIONS(this.id);

    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_VERSION' : 'ADD_VERSION'),
      saveModelFormElementsFunction: () => [
        [
          Fields.textareaField('description', 'COMMON_DESCRIPTION'),
          Fields.datePickerField('since', 'AGREEMENT_VERSION_SINCE', true),
          {
            field: 'content',
            component: () => import('@/components/ui-text-editor/ui-text-editor.vue'),
          },
        ],
      ],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'active',
            width: 100,
            isHidden: !this.isAllowed,
            headingLabel: 'COMMON_ACTIVE_FEMALE',
          },
          Columns.DESCRIPTION,
          {
            field: 'content',
            minWidth: 350,
            headingLabel: 'AGREEMENT_VERSION_CONTENT',
          },
          Columns.SINCE,
          ContentColumns.ACTIONS_AGREEMENT_VERSIONS(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
  methods: {
    activate(id) {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('AGREEMENT_VERSION_ADD_TITLE'),
        confirmationText: this.$translate('AGREEMENT_VERSION_ACTIVATE_CONFIRMATION'),
      });

      modal.$on(AppEvent.SUCCESS, async () => {
        try {
          await http.put(Urls.AGREEMENTS_ID_VERSIONS_ID_ACTIVATE(this.id, id));
          modal.close();
          this.load();
        } catch (error) {
          const errorResponseData = error?.response?.data;

          if (Array.isArray(errorResponseData)) {
            this.$modal.create(UiModalActivationErrors, { errors: errorResponseData });
          } else {
            throw error;
          }
        }
      });
    },
  },
});
</script>
