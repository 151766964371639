<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import AuditDetailList from '@/modules/administration/view/audit/detail/index.vue';

export default AuditDetailList.extend({
  data() {
    return {
      writeRole: RoleName.ADMIN,
      recordsUrl: Urls.APPLICATIONS_BO_TRANSLATIONS_AUDITS,
      exportUrl: Urls.APPLICATIONS_BO_TRANSLATIONS_AUDITS_EXPORT,
    };
  },
});
</script>
