<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import Urls from '@/application/urls';
import { ItemType, RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as ProductFields from '@/modules/product/view/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        isSaveButtonSticky: true,
        modelUrl: Urls.PRODUCTS_VODS_ID_PAGE(this.id, this.page),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.TITLE,
            ProductFields.ORIGINAL_TITLE,
            Fields.EXTERNAL_UID,
            Fields.LOGO,
            ProductFields.DURATION,
            ProductFields.INTRO_START_TIME,
            ProductFields.INTRO_END_TIME,
            ProductFields.CREDITS_TIME,
            ProductFields.YEAR,
            ProductFields.HD,
            ProductFields.UHD,
            ProductFields.SHOW_RECOMMENDATIONS,
            ProductFields.LOGIN_REQUIRED,
            Fields.CREATE_SINCE(),
            Fields.CREATE_TILL(),
            ProductFields.ADVISORS,
            ProductFields.RATING,
            ProductFields.RATING_EMBEDDED(model),
            ProductFields.AUTOPLAY,
            ProductFields.SHOW_LOGO_IN_PLAYER,
            Fields.PROVIDER,
            ProductFields.PRODUCTION,
            Fields.MAIN_CATEGORY(ItemType.VOD),
            ProductFields.CATEGORIES(ItemType.VOD),
            ProductFields.GENRES,
            ProductFields.TAGS,
            ProductFields.COUNTRIES,
            Fields.CUSTOM_BADGE,
            Fields.PLATFORMS,
            ProductFields.CHROMECAST_PLATFORMS,
            Fields.CREATED_AT,
            Fields.MODIFIED_AT,
          ],
          [
            Fields.CREATE_HEADER_CHAT(this.id, 'COMMON_COMMENTS'),
            ProductFields.CREATE_CHAT_ENABLED('COMMON_ENABLED_PLURAL'),
            ...ProductFields.GEOLOCK_FIELDS(model),
            Fields.HEADER_LEAD,
            Fields.LEAD,
            Fields.HEADER_DESCRIPTION,
            Fields.DESCRIPTION,
          ],
        ],
      };
    },
  },
};
</script>
