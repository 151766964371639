<template>
  <div
    :id="`element-${element.id}`"
    :data-rank="element.rank"
    :data-id="element.id"
    :class="{ 'sortable-item': isAllowed, 'section-item--is-inactive': inactive }"
    class="section-item responsive-item-wrapper"
  >
    <div v-if="isAllowed" class="section-item-drag icon-sidebar-medium"></div>

    <p class="title">
      <template v-if="algorithmic">
        {{ element.item.name }}
      </template>
      <router-link v-else :to="productLink(element.item)" class="item-link">
        <template v-if="element.item.number">
          S{{ element.item.season.number }}E{{ element.item.number }}
        </template>
        <ui-product-title :product="element.item" />
        <template v-if="element.item.number">
          -
          <ui-product-title :product="element.item.season.serial" />
        </template>
      </router-link>
    </p>

    <div class="section-item__content">
      <div v-for="{ label, value } in contentElements" :key="label" class="section-item__content-element">
        <span class="section-item__content-element-label" v-text="`${$translate(label)}: `"></span>
        <span v-text="value"></span>
      </div>
    </div>

    <div v-if="isAllowed" class="section-item__actions">
      <span
        v-if="isWarningVisible"
        v-tooltip="$translate('HELP_ITEM_MISSING_PLATFORM')"
        class="section-item__actions-warning icon-warning"
      ></span>
      <span class="section-item__actions-button icon-edit" @click="$emit('edit')"></span>
      <span class="section-item__actions-button icon-trash" @click="$emit('remove')"></span>
    </div>
  </div>
</template>
<script>
import { dateFormat } from 'redge-media-web-ui';
import dayjs from 'dayjs';
import { ContentType, ItemType } from '@/enums';
import { productLink } from '@/helper';
import UiProductTitle from '@/components/ui-product-title/index.vue';

export default {
  components: {
    UiProductTitle,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    platforms: {
      type: Array,
      required: true,
    },
    isAllowed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    algorithmic() {
      return this.element.contentType === ContentType.ALGORITHM;
    },
    contentElements() {
      const elements = this.algorithmic
        ? [{ label: 'SECTION_ALGORITHM_LIMIT', value: this.element.maxResults }]
        : [
          { label: 'COMMON_SINCE', value: dateFormat(this.element.since) },
          { label: 'COMMON_TILL', value: dateFormat(this.element.till) },
          { label: 'COMMON_TYPE', value: this.element.item.type },
        ];

      return elements.filter(({ value }) => value);
    },
    inactive() {
      if (this.algorithmic) {
        return false;
      }

      const now = dayjs();
      return !this.element.item.displayActive || dayjs(this.element.since) > now || now > dayjs(this.element.till);
    },
    isWarningVisible() {
      const elementDisplaySchedules = this.element.item.displaySchedules || [];
      const elementPlatforms = elementDisplaySchedules.reduce(
        (acc, { platforms }) => [...acc, ...platforms.map(({ name }) => name)],
        [],
      );
      const elementHasMissingPlatforms = this.platforms.some((platform) => !elementPlatforms.includes(platform));

      return (
        elementHasMissingPlatforms
        && this.element.contentType === ContentType.ELEMENT
        && this.element.item.type !== ItemType.PROGRAMME
      );
    },
  },
  methods: {
    productLink,
  },
};
</script>
