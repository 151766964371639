<template>
  <div class="ui-product-file-image" :class="{ 'ui-product-file-image--with-error': hasImageLoadingError }">
    <div class="ui-product-file-image__actions">
      <span class="ui-product-file-image__actions-text" v-text="label"></span>
      <template v-if="isAllowed">
        <i v-if="image" class="ui-product-file-image__actions-button icon-upload" @click="showUpload"></i>
        <i class="ui-product-file-image__actions-button icon-edit" @click="$emit(AppEvent.EDIT)"></i>
        <i v-if="image" class="ui-product-file-image__actions-button icon-trash" @click="$emit(AppEvent.DELETE)"></i>
      </template>
    </div>
    <div
      v-if="image && isImageUrlValid === true"
      class="ui-product-file-image__preview"
      :style="{ 'background-image': `url('${imageUrl}')` }"
    ></div>
    <div v-else-if="hasImageLoadingError" class="ui-product-file-image__error-wrapper">
      <span
        v-tooltip="$translate('COMMON_IMAGE_LOADING_ERROR')"
        class="ui-product-file-image__error icon-warning"
      ></span>
    </div>
    <div v-else-if="isAllowed" class="ui-product-file-image__add" @click="showUpload">
      <span class="ui-product-file-image__add-icon icon-add"></span>
    </div>
    <input
      ref="input"
      class="hidden-file-input"
      type="file"
      @change="$emit(AppEvent.UPLOAD, $event)"
    >
  </div>
</template>
<script>
import { AppEvent, ImageType } from '@/enums';

export default {
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
    image: Object,
    isAllowed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      AppEvent,
      ImageType,
      isImageUrlValid: undefined,
    };
  },
  computed: {
    imageUrl() {
      if (this.image) {
        const { url } = this.image;
        return `${url}${url.includes('?') ? '&' : '?'}${new Date().getTime()}`;
      }
    },
    hasImageLoadingError() {
      return this.image && this.isImageUrlValid === false;
    },
  },
  watch: {
    imageUrl: {
      handler(imageUrl) {
        const img = new Image();
        img.onload = () => {
          this.isImageUrlValid = true;
        };
        img.onerror = () => {
          this.isImageUrlValid = false;
        };
        img.src = imageUrl;
      },
      immediate: true,
    },
  },
  methods: {
    showUpload() {
      this.$refs.input.dispatchEvent(new MouseEvent('click'));
    },
  },
};
</script>
