<template>
  <ui-product-title :class="{ 'opacity-4': option.active === false }" :product="{ title: option[field] }" />
</template>
<script>
import UiProductTitle from '@/components/ui-product-title/index.vue';

export default {
  components: {
    UiProductTitle,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
  },
};
</script>
