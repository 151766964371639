<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import TranslationList from '@/components/translation-list/index.vue';

export default TranslationList.extend({
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.APPLICATIONS_FO_TRANSLATIONS,
      exportUrl: Urls.APPLICATIONS_FO_TRANSLATIONS_EXPORT,
      importUrl: Urls.APPLICATIONS_FO_TRANSLATIONS_IMPORT,
    };
  },
});
</script>
