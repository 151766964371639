<template>
  <div v-tooltip="option.name" v-text="$parent.currentOptionLabel"></div>
</template>
<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
};
</script>
