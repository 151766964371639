<template>
  <div v-if="model" class="details-form">
    <div class="ui-panel h-100 m-0">
      <div class="ui-panel__header">
        <div></div>
        <div>
          <v-button-icon
            icon="icon-add"
            tooltip="HELP_ADD"
            class="ui-panel__header-button"
            @click="createModel"
          />
        </div>
      </div>
      <ui-table v-bind="tableDefinition" class="ui-table--without-panel" />
    </div>
  </div>
</template>
<script>
import Urls from '@/application/urls';
import http from '@/lib/http';
import { AppEvent } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import VButtonIcon from '@/components/button/button-icon.vue';
import { UiTable } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default {
  components: {
    VButtonIcon,
    UiTable,
  },
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    const url = Urls.APPLICATION_TIMERS_ID_SUSPENSION_WINDOWS(this.id);

    return {
      model: undefined,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_SUSPENSION_WINDOW' : 'ADD_SUSPENSION_WINDOW'),
      saveModelFormElementsFunction: () => [
        [
          Fields.textInputField('description', 'COMMON_DESCRIPTION'),
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL_MANDATORY(),
          Fields.TYPE(Urls.APPLICATION_TIMERS_SUSPENSION_WINDOWS_TYPES),
          Fields.checkboxField('active', 'TIMER_SUSPENSION_WINDOW_ACTIVE'),
        ],
      ],
      records: [],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.DESCRIPTION,
          { ...Columns.SINCE, isSortable: false },
          { ...Columns.TILL, isSortable: false },
          Columns.TYPE,
          {
            field: 'active',
            width: 150,
            headingLabel: 'TIMER_SUSPENSION_WINDOW_ACTIVE',
            dataMapper: ({ active }) => this.$translate(active ? 'COMMON_YES' : 'COMMON_NO'),
          },
          Columns.ACTIONS_EDIT_REMOVE(true, this.editModel, this.removeModel),
        ],
        records: this.model.suspensionWindows,
      };
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.model = await http.get(Urls.APPLICATION_TIMERS_ID(this.id)).then(({ data }) => data);
      this.$emit(AppEvent.MODEL, this.model);
    },
  },
};
</script>
