import Vue from 'vue';

const defaultStoreData = {
  filterMenuActive: false,
  modifiedNotSavedFilters: [],
};

const store = Vue.observable({ ...defaultStoreData });

export const storeGetters = {
  filterMenuActive: () => store.filterMenuActive,
  modifiedNotSavedFilters: () => store.modifiedNotSavedFilters,
};

export const storeMutations = {
  setFilterMenuActive(value) {
    store.filterMenuActive = value;
  },
  setModifiedNotSavedFilters(value) {
    store.modifiedNotSavedFilters = value;
  },
};

export const resetStore = () => {
  Object.keys(defaultStoreData).forEach((key) => {
    Vue.set(store, key, defaultStoreData[key]);
  });
};
