<template>
  <ui-modal class="ui-player" close-on-overlay-click @close="close">
    <div :id="PLAYER_HOLDER" class="ui-player__holder">
      <div v-if="error" class="ui-player-error" data-qa="player-error">
        <h3 class="ui-player-error__title" v-text="$translate('PLAYER_ERROR')"></h3>
        <p v-if="error.code" data-qa="player-error-code">
          <span v-text="$translate('PLAYER_ERROR_CODE')"></span>
          {{ error.code }}
        </p>
        <p v-if="error.type" data-qa="player-error-type">
          <span v-text="$translate('PLAYER_ERROR_TYPE')"></span>
          {{ error.type }}
        </p>
        <p v-if="error.message" data-qa="player-error-message">
          <span v-text="$translate('PLAYER_ERROR_MESSAGE')"></span>
          {{ error.message }}
        </p>
      </div>
    </div>
  </ui-modal>
</template>
<script>
import {
  CorePlayer, DashRenderer, NativeRenderer, PlayerEvent,
} from 'redgalaxy-player-web';
import GuiModule from 'redgalaxy-player-web/dist/module/gui';
import DrmModule from 'redgalaxy-player-web/dist/module/drm';
import StripeModule from 'redgalaxy-player-web/dist/module/stripe';
import SubtitleModule from 'redgalaxy-player-web/dist/module/subtitle';
import SubtitleGuiModule from 'redgalaxy-player-web/dist/module/subtitle-gui';
import { AppEvent } from '@/enums';

CorePlayer.renderers = [DashRenderer, NativeRenderer];
CorePlayer.modules = [GuiModule, DrmModule, StripeModule, SubtitleModule, SubtitleGuiModule];

const PLAYER_HOLDER = 'player-holder';

const playerConfig = {
  keyboardEvents: true,
  httpConfig: {
    drm: {
      withCredentials: true,
    },
    playlist: {
      withCredentials: true,
    },
  },
  skin: null,
};

export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PLAYER_HOLDER,
      player: undefined,
      error: undefined,
    };
  },
  async mounted() {
    await this.$nextTick();

    this.player = new CorePlayer(PLAYER_HOLDER);
    this.player.setup({ ...playerConfig, ...this.config });

    this.player.on(PlayerEvent.ERROR, (error) => {
      if (error?.code !== 401) {
        this.error = error;

        if (this.player.renderer) {
          this.player.stop();
        }
      }
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }

    this.error = undefined;
  },
  methods: {
    close() {
      this.$emit(AppEvent.CLOSE);
    },
  },
};
</script>
