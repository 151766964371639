<template>
  <v-list-wrapper :table-definition="tableDefinition">
    <template #availability="{ record }">
      <div class="ui-table-availability">
        <span v-text="`${$translate('PLATFORM_NAME')}:`.toUpperCase()"></span>
        <ui-table-platforms :platforms="record.platforms" />
      </div>
      <div class="ui-table-availability">
        <span v-text="`${$translate('COMMON_CATCHUP')}:`.toUpperCase()"></span>
        <ui-table-platforms :platforms="record.catchupServicePlatforms" />
      </div>
      <div class="ui-table-availability">
        <span v-text="`${$translate('COMMON_NPVR')}:`.toUpperCase()"></span>
        <ui-table-platforms :platforms="record.npvrServicePlatforms" />
      </div>
    </template>
  </v-list-wrapper>
</template>
<script>
import dayjs from 'dayjs';
import { DateFormat } from 'redge-media-web-utils';
import Urls from '@/application/urls';
import http from '@/lib/http';
import {
  ItemType, QueryParam, RoleName, VideoType,
} from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import * as Filters from '@/components/filter/filters';
import ListView from '@/components/list-view/list-view.vue';
import { UiTablePlatforms } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';
import * as ProductColumns from '@/modules/product/view/columns';
import * as ProductFields from '@/modules/product/view/fields';
import * as ProductFilters from '@/modules/product/view/filters';

export default ListView.extend({
  components: {
    UiTablePlatforms,
  },
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_LIVES_ID_PROGRAMMES(this.id),
      removeModelUrlFunction: ({ id }) => Urls.PRODUCTS_LIVES_PROGRAMMES_ID(id),
      saveModelUrlFunction: () => Urls.PRODUCTS_LIVES_PROGRAMMES,
      saveModelModalTitleFunction: () => 'ADD_PROGRAMME',
      saveModelFormElementsFunction: () => [
        [
          Fields.TITLE,
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL_MANDATORY(),
          ProductFields.SEASON,
          ProductFields.EPISODE_NUMBER,
        ],
      ],
      saveModelDefaultValues: {
        active: false,
        since: dayjs().add(1, 'hour').startOf('hour').valueOf(),
        till: dayjs().add(2, 'hour').startOf('hour').valueOf(),
        live: {
          id: this.$route.params.id,
          type_: ItemType.LIVE,
        },
        type_: ItemType.PROGRAMME,
      },
      filters: [
        Filters.CREATE_SINCE(),
        ProductFilters.CATEGORIES(ItemType.LIVE),
        ProductFilters.GENRE,
        ProductFilters.HAS_DESCRIPTION,
        Filters.multiSwitch('recommended', { label: 'PROGRAMME_RECOMMENDED' }),
        Filters.multiSwitch('trailers', { label: 'ITEM_TRAILER' })
      ],
      paramsConfig: {
        sort: QueryParam.SINCE,
        order: QueryParam.ASCENDING,
        since: dayjs().subtract(1, 'day').format(DateFormat.DATETIME_FORMAT),
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.POSTER((record) => ({ record, videoType: VideoType.CATCHUP })),
          ProductColumns.TITLE,
          {
            field: 'availability',
            width: 220,
            headingLabel: 'COMMON_AVAILABILITY',
          },
          Columns.SINCE,
          Columns.TILL,
          ProductColumns.PROGRAMME_ACTIONS(this.isAllowed, this.createVod, this.removeModel, this.transformToVod),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
  methods: {
    createVod(programme) {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: (model) => [
          [
            Fields.TITLE,
            Fields.MAIN_CATEGORY(ItemType.VOD, model.itemType === ItemType.EPISODE),
            Fields.ITEM_TYPE(model, [ItemType.VOD, ItemType.EPISODE]),
            {
              ...Fields.checkboxField('_addToBundle', 'ADD_PRODUCT_TO_BUNDLE'),
              isHidden: model.itemType === ItemType.EPISODE,
            },
            { ...Fields.CREATE_SINCE(), isHidden: !model._addToBundle || model.itemType === ItemType.EPISODE },
            { ...Fields.CREATE_TILL(), isHidden: !model._addToBundle || model.itemType === ItemType.EPISODE },
            { ...Fields.RANK, isHidden: !model._addToBundle || model.itemType === ItemType.EPISODE },
            {
              ...Fields.SELECTED_BUNDLE(model),
              isHidden: !model._addToBundle || model.itemType === ItemType.EPISODE,
            },
            { ...ProductFields.SEASON, isHidden: model.itemType === ItemType.VOD },
            { ...ProductFields.EPISODE_NUMBER, isHidden: model.itemType === ItemType.VOD },
          ],
        ],
        modalTitleFunction: () => 'CREATE_VOD',
        defaultValues: {
          since: dayjs().startOf('day').format(DateFormat.DATETIME_FORMAT),
          till: null,
          rank: 1,
          itemType: ItemType.VOD,
          _addToBundle: false,
          ...programme,
        },
      });

      const saveModelPromiseFunction = (model) => {
        const url = model.itemType === ItemType.EPISODE
          ? Urls.PRODUCTS_LIVES_PROGRAMMES_ID_EPISODE(model.id)
          : Urls.PRODUCTS_LIVES_PROGRAMMES_ID_VOD(model.id);

        return http.post(url, model);
      };

      this.openSaveModelModal(modal, saveModelPromiseFunction);
    },
    transformToVod(programme) {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: () => [[Fields.TITLE, Fields.MAIN_CATEGORY(ItemType.VOD)]],
        modalTitleFunction: () => 'TRANSFORM_TO_VOD',
        defaultValues: {
          since: dayjs().startOf('day').format(DateFormat.DATETIME_FORMAT),
          till: null,
          rank: 1,
          ...programme,
        },
      });
      const saveModelPromiseFunction = (model) => {
        const url = Urls.PRODUCTS_LIVES_PROGRAMMES_ID_VOD_TRANSFORM(model.id);
        return http.post(url, model);
      };
      this.openSaveModelModal(modal, saveModelPromiseFunction);
    },
  },
});
</script>
