<template>
  <span
    v-if="productIsEpisode && !product.title"
    class="font-style-italic"
    v-text="`${$translate('SERIES_EPISODE')} ${product.number}`"
  ></span>
  <span
    v-else-if="productIsSeasons && !product.title"
    class="font-style-italic"
    v-text="`${$translate('SERIES_SEASON')} ${product.number}`"
  ></span>
  <ui-item-title v-else :item="product" :no-title-label="noTitleLabel" />
</template>
<script>
import { ItemType } from '@/enums';
import UiItemTitle from '@/components/ui-item-title/index.vue';

export default {
  name: 'ui-product-title',
  components: {
    UiItemTitle,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    noTitleLabel: {
      type: String,
      default: 'COMMON_NO_TITLE',
    },
  },
  computed: {
    productIsEpisode() {
      return this.product.type === ItemType.EPISODE;
    },
    productIsSeasons() {
      return this.product.type === ItemType.SEASON;
    },
  },
};
</script>
