import { dateFormat } from 'redge-media-web-ui';
import { translate } from '@/helper';
import {
  UiTableActions,
  UiTableDetailLink,
  UiTableDetailLinkDevice,
  UiTableDetailLinkProduct,
  UiTableDetailLinkSubscriber,
  UiTableDeviceInfo,
  UiTablePlatforms,
  UiTablePoster,
  UiTableRecordingName,
  UiTableRecordingStatus,
} from '@/components/ui-table/index';
import { QueryParam } from '@/enums';

export const createDateColumn = (field, headingLabel, width = 160, isSortable = true) => ({
  field,
  width,
  isSortable,
  defaultSortOrder: QueryParam.DESCENDING,
  headingLabel,
  dataMapper: (record) => dateFormat(record[field]),
});

export const BUTTON_BLOCK = (onClick) => ({
  tooltip: 'HELP_BLOCK',
  icon: 'icon-block',
  onClick,
});

export const BUTTON_EDIT = (onClick) => ({
  tooltip: 'HELP_EDIT',
  icon: 'icon-edit',
  onClick,
});

export const BUTTON_MORE = (onClick, isLoadingDetail = false) => ({
  tooltip: 'HELP_MORE',
  icon: `icon-zoom-in ${isLoadingDetail ? 'cursor-default pointer-events-none' : ''}`,
  onClick,
});

export const BUTTON_MOVE = (onClick, tooltip = 'HELP_MOVE') => ({
  tooltip,
  icon: 'icon-fullscreen',
  onClick,
});

export const BUTTON_REMOVE = (onClick) => ({
  tooltip: 'HELP_REMOVE',
  icon: 'icon-trash',
  onClick,
});

export const BUTTON_FORGET = (onClick) => ({
  tooltip: 'HELP_FORGET',
  icon: 'icon-trash',
  onClick,
});

export const ACTIONS = (isAllowed, dataComponentPropsMapper) => ({
  field: 'actions',
  width: 90,
  isHidden: !isAllowed,
  headingLabel: 'COMMON_ACTIONS',
  dataComponent: UiTableActions,
  dataComponentPropsMapper,
});

export const ACTIONS_EDIT_REMOVE = (isAllowed, editItemMethod, removeItemMethod) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: [BUTTON_EDIT(() => editItemMethod(record)), BUTTON_REMOVE(() => removeItemMethod(record))],
  });
  return ACTIONS(isAllowed, dataComponentPropsMapper);
};

export const ACTIONS_REMOVE = (isAllowed, removeItemMethod) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: [BUTTON_REMOVE(() => removeItemMethod(record))],
  });
  return ACTIONS(isAllowed, dataComponentPropsMapper);
};

export const AGREEMENT_OBLIGATORY = {
  field: 'obligatory',
  minWidth: 180,
  headingLabel: 'AGREEMENT_OBLIGATORY',
  dataMapper: ({ obligatory }) => translate(obligatory ? 'COMMON_YES' : 'COMMON_NO'),
};

export const AGREEMENT_VERSION_SINCE = {
  ...createDateColumn('since', 'AGREEMENT_VERSION_SINCE'),
  isSortable: false,
};

export const CARD_EXPIRES = {
  field: 'expiration',
  width: 40,
  headingLabel: 'CARD_EXPIRES',
};

export const CARD_HASH = {
  field: 'hash',
  width: 80,
  headingLabel: 'CARD_HASH',
};

export const CARD_MASK = {
  field: 'mask',
  width: 40,
  headingLabel: 'CARD_MASK',
};

export const CARD_REASON = {
  field: 'statusMessage',
  width: 50,
  headingLabel: 'CARD_REASON',
};

export const CARD_STATUS = {
  field: 'status',
  width: 40,
  headingLabel: 'CARD_STATUS',
};

export const CARD_TYPE = {
  field: 'type',
  width: 40,
  headingLabel: 'CARD_TYPE',
};

export const CREATED_AT = createDateColumn('createdAt', 'COMMON_CREATED');

export const CURRENCY = {
  field: 'currency',
  width: 80,
  headingLabel: 'COMMON_CURRENCY',
};

export const DESCRIPTION = {
  field: 'description',
  minWidth: 250,
  headingLabel: 'COMMON_DESCRIPTION',
};

export const DEVICE_AGENT_VERSION = {
  field: 'agentVersion',
  width: 160,
  headingLabel: 'DEVICE_AGENT_VERSION',
};

export const DEVICE_DETAIL = (dataComponentPropsMapper) => ({
  field: 'deviceUid',
  minWidth: 160,
  headingLabel: 'DEVICE_UUID_FULL',
  dataComponent: UiTableDetailLinkDevice,
  dataComponentPropsMapper,
});

export const DEVICE_INFO = (dataComponentPropsMapper) => ({
  field: 'deviceInfo',
  width: 100,
  headingLabel: 'COMMON_DEVICE',
  dataComponent: UiTableDeviceInfo,
  dataComponentPropsMapper,
});

export const DEVICE_MAKER = {
  field: 'maker',
  width: 120,
  isSortable: true,
  headingLabel: 'DEVICE_MAKER',
};

export const DEVICE_OS = {
  field: 'os',
  width: 160,
  isSortable: true,
  headingLabel: 'DEVICE_OS',
  dataMapper: ({ os, osVersion = '' }) => `${os} ${osVersion}`,
};

export const EXTERNAL_UID = {
  field: 'externalUid',
  width: 140,
  isSortable: true,
  headingLabel: 'COMMON_EXTERNAL_UID',
};

export const FILE_FORMAT = {
  field: 'format',
  width: 110,
  headingLabel: 'FILE_FORMAT',
};

export const FILE_URL = {
  field: 'url',
  minWidth: 300,
  headingLabel: 'COMMON_URL',
  dataClass: 'break-all',
};

export const ITEM_NAME = (dataComponentPropsMapper, isSortable = true) => ({
  field: 'name',
  minWidth: 240,
  isSortable,
  headingLabel: 'COMMON_NAME',
  dataComponent: UiTableDetailLink,
  dataComponentPropsMapper,
});

export const KEY = {
  field: 'key',
  minWidth: 200,
  headingLabel: 'COMMON_KEY',
};

export const LANGUAGE = {
  field: 'language',
  width: 90,
  headingLabel: 'COMMON_LANGUAGE',
};

export const MODIFIED_AT = createDateColumn('modifiedAt', 'COMMON_MODIFIED');

export const NAME = {
  field: 'name',
  minWidth: 200,
  headingLabel: 'COMMON_NAME',
};

export const LAST_LOGIN_DATE = createDateColumn('lastLoggedAt', 'COMMON_LAST_LOGIN_DATE', 180);

export const PLATFORMS = {
  field: 'platforms',
  width: 140,
  headingLabel: 'PLATFORM_NAME',
  dataComponent: UiTablePlatforms,
  dataComponentPropsMapper: ({ platforms }) => ({ platforms: platforms || [] }),
};

export const POSTER = (dataComponentPropsMapper) => ({
  field: 'poster',
  width: 120,
  dataComponent: UiTablePoster,
  dataComponentPropsMapper,
});

export const PRODUCT_DETAIL = (dataComponentPropsMapper, headingLabel = 'COMMON_PRODUCT') => ({
  field: 'item',
  minWidth: 200,
  headingLabel,
  dataComponent: UiTableDetailLinkProduct,
  dataComponentPropsMapper,
});

export const RANK = {
  field: 'rank',
  width: 90,
  isSortable: true,
  headingLabel: 'COMMON_RANK',
};

export const RECORD_DEVICE_APP_VERSION = {
  field: 'device.appVersion',
  width: 100,
  headingLabel: 'DEVICE_APP_VERSION',
};

export const RECORD_DEVICE_MAKER = {
  field: 'device.maker',
  width: 120,
  headingLabel: 'DEVICE_MAKER',
};

export const RECORD_DEVICE_OS = {
  field: 'os',
  width: 120,
  headingLabel: 'DEVICE_OS',
  dataMapper: ({ device: { os, osVersion } }) => `${os} ${osVersion}`,
};

export const RECORDING_NAME = {
  field: 'name',
  minWidth: 240,
  headingLabel: 'COMMON_NAME',
  dataComponent: UiTableRecordingName,
};

export const RECORDING_STATUS = {
  field: 'status',
  width: 40,
  dataComponent: UiTableRecordingStatus,
};

export const ROLES = {
  field: 'roles',
  minWidth: 350,
  headingLabel: 'COMMON_ROLE',
  dataMapper: ({ roles }) => roles?.map(({ name }) => name).join(', '),
};

export const SESSION_DELETE_REASON = {
  field: 'deleteReason',
  width: 140,
  headingLabel: 'SESSION_DELETE_REASON',
};

export const SINCE = createDateColumn('since', 'COMMON_SINCE');

export const SUBSCRIBER_DETAIL = (dataComponentPropsMapper, isSortable = true) => ({
  field: 'subscriber.email',
  minWidth: 160,
  isSortable,
  headingLabel: 'COMMON_SUBSCRIBER',
  dataComponent: UiTableDetailLinkSubscriber,
  dataComponentPropsMapper,
});

export const TILL = createDateColumn('till', 'COMMON_TILL');

export const TYPE = {
  field: 'type',
  width: 140,
  headingLabel: 'COMMON_TYPE',
};

export const VIDEO_SESSION_ID = {
  field: 'videoSessionId',
  minWidth: 180,
  headingLabel: 'VIDEO_SESSION_ID',
  dataClass: 'text-truncated',
};
