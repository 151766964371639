<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as SubscriberColumns from '@/modules/subscriber/view/columns';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.DEVICES_ID_VERSIONS(this.id),
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          SubscriberColumns.DEVICE_OS_VERSION,
          Columns.DEVICE_AGENT_VERSION,
          { ...Columns.CREATED_AT, isSortable: false },
          SubscriberColumns.DEVICE_VERSION_LAST_ATTACHED,
        ],
        records: this.records,
      };
    },
  },
});
</script>
