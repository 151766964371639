<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
          v-text="model.key"
        ></h1>
        <ui-switch
          v-if="model"
          v-tooltip="activateModelButtonText"
          :value="isModelActive"
          class="details-container__header-element"
          @input="activateModel('VIEW')"
        />
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import UiTabs from '@/components/ui-tabs/index.vue';
import { Page } from '@/enums';
import { ActivateModelMixin } from '@/mixin';
import Detail from './detail.vue';
import Sections from './sections.vue';

export default {
  components: {
    UiTabs,
    UiSwitch,
    Detail,
    Sections,
  },
  mixins: [ActivateModelMixin],
  data() {
    return {
      tabs: [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'MENU_CONTENT_SECTION', page: Page.SECTIONS },
      ],
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
  },
};
</script>
