<template>
  <div v-if="model" class="ui-external-application-icons">
    <div v-if="model && model.externalApplications" class="ui-external-application-icons">
      <a
        v-for="(application, name) in model.externalApplications"
        :key="application"
        v-tooltip="$translate(`OPEN_MATERIAL_IN_${name.toUpperCase()}`)"
        :href="application"
        target="_blank"
      ><img
        :src="`/static/img/icon/application/${name.toUpperCase()}.png`"
        :alt="`${name}`"
        class="ui-external-application-icons__icon"
      ></a>
    </div>
    <div
      v-if="model.webUrl"
      v-tooltip="isModelActive ? $translate('OPEN_MATERIAL_IN_FO') : $translate('ACTIVE_MATERIAL_FO')"
    >
      <a
        :href="model.webUrl"
        :class="{ 'details-container__header-elements--inactive': !isModelActive }"
        target="_blank"
      ><img
        :src="`/static/img/icon/application/FRONT_OFFICE.png`"
        :alt="$translate('FRONT_OFFICE')"
        class="ui-external-application-icons__icon"
      ></a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    model: [Object, undefined],
    isModelActive: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.ui-external-application-icons {
  display: flex;

  &__icon {
    display: block;
    width: 25px;
    margin: 3px;
  }
}
</style>
