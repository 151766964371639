import { setup } from 'axios-cache-adapter';
import EventBus from '@/application/event-bus';
import { Constants } from '@/enums';
import getTenantUid from '@/helper/get-tenant-uid';

const http = setup({
  baseURL: `${process.env.VUE_APP_BO_URL}/api/local`,
  withCredentials: true,
  cache: {
    // Turn off default cache mechanism for all requests. This should be overridden per request for special cases
    maxAge: 0,
  },
  params: {},
});

http.interceptors.request.use((config) => {
  config.params.tenantUid = getTenantUid();

  return config;
});

http.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    const response = error?.response;

    if (response?.status === 403 && response?.data?.code !== 'OPERATOR_INVALID_EMAIL_OR_PASSWORD') {
      EventBus.$emit(Constants.AUTHENTICATION_REQUIRED);
    }

    return Promise.reject(error);
  },
);

export { CancelToken, isCancel as isHttpRequestCanceled } from 'axios';
export default http;
