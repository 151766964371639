<template>
  <div class="details-container header-container">
    <ui-tabs :current-route="$route" :tabs="tabs" />
    <component :is="page" class="content" :page="page" />
  </div>
</template>
<script>
import UiTabs from '@/components/ui-tabs/index.vue';
import { Page } from '@/enums';
import Timers from './timers.vue';
import States from './states.vue';

export default {
  components: {
    UiTabs,
    Timers,
    States,
  },
  data() {
    return {
      tabs: [
        { label: 'MENU_ADMINISTRATION_TIMER', page: Page.TIMERS },
        { label: 'TAB_STATES', page: Page.STATES },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page;
    },
  },
};
</script>
