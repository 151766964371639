<template>
  <ul class="ui-tabs">
    <li
      v-for="(tab, index) in visibleTabs"
      :key="index"
      :class="{ 'ui-tabs__item--active': tab.page === currentRoute.params.page }"
      class="ui-tabs__item"
    >
      <a
        v-if="tab.url"
        :href="tab.url"
        class="ui-tabs__item-link"
        target="_blank"
        v-text="$translate(tab.label)"
      ></a>
      <router-link
        v-else
        :to="{ name: currentRoute.name, params: { page: tab.page }, query: tab.query }"
        class="ui-tabs__item-link"
        v-text="$translate(tab.label)"
      />
    </li>
  </ul>
</template>
<script>
import { RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';

const CRM_READ_ROLE_PAGES = ['payments', 'subscriptions', 'video-sessions', 'recordings'];

export default {
  mixins: [RoleMixin],
  props: {
    currentRoute: {
      type: Object,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    visibleTabs() {
      const filteredByOptions = this.tabs.filter(({ isHidden, url }) => !isHidden && url !== null);

      if (!this.hasRole(RoleName.CRM_READ)) {
        return filteredByOptions.filter(({ page }) => !CRM_READ_ROLE_PAGES.includes(page));
      }

      return filteredByOptions;
    },
  },
};
</script>
