<template>
  <div class="ui-table-device-info">
    <span v-tooltip="device.platform" :class="`icon-${device.platform.toLowerCase()}`"></span>
    <span v-if="device.platform === Platform.BROWSER" :key="deviceAgentInfo" v-tooltip="deviceAgentInfo">
      <img :src="`/static/img/icon/browser/${deviceBrowser}.png`" class="ui-table-device-info__browser-icon">
      <span v-text="device.agentVersion.split('.')[0]"></span>
    </span>
  </div>
</template>
<script>
import { Platform } from '@/enums';

const BROWSERS = {
  firefox: 'firefox',
  chrome: 'chrome',
  opera: 'opera',
  'internet explorer': 'internet_explorer',
  safari: 'safari',
};

export default {
  name: 'ui-table-device-info',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { Platform };
  },
  computed: {
    deviceAgentInfo() {
      return `${this.device.agent} ${this.device.agentVersion}`;
    },
    deviceBrowser() {
      if (this.device.agent) {
        return BROWSERS[this.device.agent.toLowerCase().trim()] || 'other';
      }

      return undefined;
    },
  },
};
</script>
