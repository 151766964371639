<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import { NumberDivider } from 'redge-media-web-utils';
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { SynchronizeModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as AdministrationColumns from '@/modules/administration/view/columns';

export default ListView.extend({
  mixins: [SynchronizeModelMixin],
  data() {
    return {
      writeRole: RoleName.ADMIN,
      recordsUrl: Urls.APPLICATION_TIMERS,
      synchronizeUrl: Urls.APPLICATION_TIMERS_ID_SYNCHRONIZE,
      paramsConfig: {
        sort: QueryParam.NAME,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.ITEM_NAME(({ id, name }) => ({ id, text: name, name: 'timer.detail' })),
          Columns.createDateColumn('dateValue', 'COMMON_VALUE'),
          {
            field: 'hostName',
            width: 200,
            isSortable: true,
            headingLabel: 'TIMER_HOSTNAME',
          },
          {
            field: 'integration',
            width: 120,
            isSortable: true,
            headingLabel: 'TIMER_INTEGRATION',
            dataMapper: ({ integration }) => this.$translate(integration ? 'COMMON_YES' : 'COMMON_NO'),
          },
          Columns.createDateColumn('startedAt', 'TIMER_STARTED_AT'),
          {
            field: 'timeoutDuration',
            width: 200,
            isSortable: true,
            headingLabel: 'TIMER_TIMEOUT',
            dataMapper: ({ timeoutDuration }) => (timeoutDuration ? timeoutDuration / NumberDivider.MINUTE_TO_SECONDS : null),
          },
          {
            field: 'active',
            width: 150,
            headingLabel: 'COMMON_ACTIVE_MALE',
            dataMapper: ({ active }) => this.$translate(active ? 'COMMON_YES' : 'COMMON_NO'),
          },
          AdministrationColumns.TIMER_ACTIONS(this.synchronizeModel),
        ],
        records: this.records,
      };
    },
  },
  methods: {
    init() {
      this.load();
    },
  },
});
</script>
