<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
        >
          <ui-item-title :item="{ title: model.name }" no-title-label="COMMON_NO_NAME" />
        </h1>
        <ui-switch
          v-if="model"
          v-tooltip="activateModelButtonText"
          :value="isModelActive"
          class="details-container__header-element"
          @input="activateModel('PROMOTION')"
        />
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import { ActivateModelMixin } from '@/mixin';
import UiTabs from '@/components/ui-tabs/index.vue';
import UiItemTitle from '@/components/ui-item-title/index.vue';
import { DiscountType, Page } from '@/enums';
import Detail from './detail.vue';
import Products from './products.vue';
import CodesSingleUse from './codes-single-use.vue';
import CodesMultipleUse from './codes-multiple-use.vue';
import Discounts from './discounts.vue';
import Audits from './audits.vue';
import Criteria from './criteria.vue';

export default {
  components: {
    UiItemTitle,
    UiSwitch,
    UiTabs,
    Audits,
    Detail,
    Products,
    CodesSingleUse,
    CodesMultipleUse,
    Discounts,
    Criteria,
  },
  mixins: [ActivateModelMixin],
  data() {
    return {
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
    tabs() {
      return [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'COMMON_PRODUCTS', page: Page.PRODUCTS },
        { label: 'TAB_CODES_SINGLE_USE', page: Page.CODES_SINGLE_USE },
        { label: 'TAB_CODES_MULTIPLE_USE', page: Page.CODES_MULTIPLE_USE },
        { label: 'TAB_DISCOUNTS', page: Page.DISCOUNTS, isHidden: this.model?.type !== DiscountType.DISCOUNT },
        { label: 'CRITERIA', page: Page.CRITERIA, isHidden: this.model?.staticType },
        { label: 'COMMON_EVENTS', page: Page.AUDITS },
      ];
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
  },
};
</script>
