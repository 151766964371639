<template>
  <div class="ui-available-filters" data-qa="active-filters-select">
    <select v-model="selectedSetting" class="ui-select ui-available-filters__select" @change="change">
      <option :value="emptySetting" data-qa="reset-filters-option" v-text="emptySetting.name"></option>
      <option v-for="setting in availableFiltersSettings" :key="setting.id" :value="setting">
        {{ $translate(setting.name) }}
        <template v-if="isFilterSettingModified && setting === selectedSetting">
          [{{ $translate('COMMON_MODIFIED') }}]
        </template>
      </option>
    </select>
    <div class="ui-available-filters__discard-wrapper">
      <span
        v-if="isFilterSettingModified && selectedSetting.id >= 0"
        class="ui-available-filters__discard"
        @click="change"
      ></span>
    </div>
  </div>
</template>
<script>
import { AppEvent } from '@/enums';
import { checkIfFilterSettingMatchesFilters } from '@/lib/settings-filters';

const EMPTY_SETTING = {
  name: '-------------',
  filters: {},
  id: -1,
};

export default {
  props: {
    activeFilters: {
      type: Object,
      required: true,
    },
    availableFiltersSettings: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => EMPTY_SETTING,
    },
  },
  data() {
    return {
      selectedSetting: undefined,
      emptySetting: { ...EMPTY_SETTING },
    };
  },
  computed: {
    isFilterSettingModified() {
      return this.selectedSetting && !checkIfFilterSettingMatchesFilters(this.selectedSetting, this.activeFilters);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.selectedSetting = this.value;
      },
    },
  },
  methods: {
    change() {
      if (this.selectedSetting.filters) {
        this.$emit(AppEvent.CHANGE, this.selectedSetting);
      }
    },
    setEmpty() {
      this.$emit(AppEvent.CHANGE, this.emptySetting);
    },
  },
};
</script>
