const Urls = {
  ADS_SERVERS: 'ads/servers',
  ADS_SERVERS_ACTIVE: 'ads/servers/active',
  ADS_SERVERS_AUDITS: 'ads/servers/audits',
  ADS_SERVERS_AUDITS_EXPORT: 'ads/servers/audits/export',
  ADS_SERVERS_ID: (id) => `ads/servers/${id}`,
  ADS_SERVERS_TYPES: 'ads/servers/types',
  ADS_TAGS: 'ads/tags',
  ADS_TAGS_ID: (id) => `ads/tags/${id}`,

  AGREEMENTS: 'agreements',
  AGREEMENTS_ID: (id) => `agreements/${id}`,
  AGREEMENTS_ID_ATTACHMENTS: (id) => `agreements/${id}/attachments`,
  AGREEMENTS_ID_AUDITS: (id) => `agreements/${id}/audits`,
  AGREEMENTS_ID_PAGE: (id, page) => `agreements/${id}/${page}`,
  AGREEMENTS_ID_VERSIONS: (id) => `agreements/${id}/versions`,
  AGREEMENTS_ID_VERSIONS_ID_ACTIVATE: (agreementId, versionId) => `agreements/${agreementId}/versions/${versionId}/activate`,
  AGREEMENTS_PAYMENT_DICTIONARIES: '/agreements/PAYMENT/dictionaries',
  AGREEMENTS_TYPES: 'agreements/types',

  APPLICATIONS_CONTENTS_LANGUAGES: 'contents/languages',
  APPLICATION_GUI_FILTER_NAME: (name) => `application/gui/filter/${name}`,
  APPLICATION_SETTINGS_AUDITS: 'application/settings/audits',
  APPLICATION_SETTINGS_AUDITS_EXPORT: 'application/settings/audits/export',
  APPLICATION_SETTINGS_KEY: (key) => `application/settings/${key}`,
  APPLICATION_TIMERS: 'application/timers',
  APPLICATION_TIMERS_ID: (id) => `application/timers/${id}`,
  APPLICATION_TIMERS_ID_AUDITS: (id) => `application/timers/${id}/audits`,
  APPLICATION_TIMERS_ID_AUDITS_EXPORT: (id) => `application/timers/${id}/audits/export`,
  APPLICATION_TIMERS_ID_SUSPENSION_WINDOWS: (id) => `application/timers/${id}/suspension/windows`,
  APPLICATION_TIMERS_ID_SYNCHRONIZE: (id) => `application/timers/${id}/synchronize`,
  APPLICATION_TIMERS_SUSPENSION_WINDOWS_TYPES: 'application/timers/suspension/windows/types',
  APPLICATION_TYPE: (type) => `application/${type}`,

  APPLICATIONS_BO_TRANSLATIONS: 'applications/bo/translations',
  APPLICATIONS_BO_TRANSLATIONS_AUDITS: 'applications/bo/translations/audits',
  APPLICATIONS_BO_TRANSLATIONS_AUDITS_EXPORT: 'applications/bo/translations/audits/export',
  APPLICATIONS_BO_TRANSLATIONS_EXPORT: 'applications/bo/translations/export',
  APPLICATIONS_BO_TRANSLATIONS_IMPORT: 'applications/bo/translations/import',
  APPLICATIONS_FO_TRANSLATIONS: 'applications/fo/translations',
  APPLICATIONS_FO_TRANSLATIONS_AUDITS: 'applications/fo/translations/audits',
  APPLICATIONS_FO_TRANSLATIONS_AUDITS_EXPORT: 'applications/fo/translations/audits/export',
  APPLICATIONS_FO_TRANSLATIONS_EXPORT: 'applications/fo/translations/export',
  APPLICATIONS_FO_TRANSLATIONS_IMPORT: 'applications/fo/translations/import',
  APPLICATIONS_TRANSLATIONS: 'translations',
  APPLICATIONS_TRANSLATIONS_LANGUAGES: 'translations/languages',

  AUDITS: 'audits',
  AUDITS_EXPORT: 'audits/export',
  AUDITS_EVENT_TYPES: 'audits/events/types',
  AUDITS_GROUP_NAME: (name) => `audits/group/${name}`,
  AUDITS_GROUP_NAME_EXPORT: (name) => `audits/group/${name}/export`,
  AUDITS_TYPE_TYPE_ID_EXPORT: (type, id) => `audits/type/${type}/${id}/export`,

  AUTH_INFO: 'auth/info',
  AUTH_LOGIN: 'auth/login',
  AUTH_LOGIN_SSO: 'auth/login/sso',
  AUTH_LOGOUT: 'auth/logout',

  BANNERS: 'banners',
  BANNERS_ID: (id) => `banners/${id}`,
  BANNERS_ID_LINK: (id) => `banners/${id}/link`,

  CACHE_INVALIDATION: 'cache/:cacheType',
  CHAT: 'chat',
  CONTACTS: 'contacts',
  CONTACTS_CATEGORIES: 'contacts/categories',
  CONTACTS_CATEGORIES_ID: (id) => `contacts/categories/${id}`,
  CONTACTS_EXPORT: 'contacts/export',
  CONTACTS_ID: (id) => `contacts/${id}`,
  CONTACTS_ID_REPLY: (id) => `contacts/${id}/reply`,
  CONTACTS_STATUSES: 'contacts/statuses',

  CURRENCIES_DICTIONARIES: '/currencies/dictionaries',

  DASHBOARDS: 'dashboards',
  DASHBOARDS_NAME: (name) => `dashboards/${name}`,

  DEVICES: 'devices',
  DEVICES_EXPORT: 'devices/export',
  DEVICES_ID: (id) => `devices/${id}`,
  DEVICES_ID_DOWNLOADS_ITEMS: (id) => `devices/${id}/downloads/items`,
  DEVICES_ID_VERSIONS: (id) => `devices/${id}/versions`,
  DEVICES_SOFTWARE_DECODING: 'devices/softwaredecoding',
  DEVICES_SOFTWARE_DECODING_DEVICES: 'devices/softwaredecoding/devices',
  DEVICES_SOFTWARE_DECODING_DEVICES_ID: (id) => `devices/softwaredecoding/devices/${id}`,

  DICTIONARIES_TYPE: (type) => `dictionaries/${type}`,

  DOCUMENTS: 'documents',
  DOCUMENTS_ID: (id) => `documents/${id}`,
  DOCUMENTS_ID_AUDITS: (id) => `documents/${id}/audits`,
  DOCUMENTS_MIME_TYPES: 'documents/mime/types',
  DOCUMENTS_TYPES: 'documents/types',

  DOWNLOADS_ITEMS: 'downloads/items',
  DOWNLOADS_ITEMS_ID: (id) => `downloads/items/${id}`,

  FILES_UPLOAD: 'files/upload',

  GEOIP_IPDB_IP: (ip) => `/geoip/ipdb/${ip}`,
  GEOIP_TYPE: (type) => `geoip/${type}`,
  GEOIP_TYPE_ID: (type, id) => `geoip/${type}/${id}`,

  INFO: 'info',

  ITEMS_BREAKS_PLACEMENTS: 'items/breaks/placements',
  ITEMS_CATEGORIES: 'items/categories',
  ITEMS_CATEGORIES_ID: (id) => `items/categories/${id}`,
  ITEMS_CATEGORIES_ID_AUDITS: (id) => `items/categories/${id}/audits`,
  ITEMS_CATEGORIES_ID_IMAGES: (id) => `items/categories/${id}/images`,
  ITEMS_CATEGORIES_ID_IMAGES_UPLOAD: (id) => `items/categories/${id}/images/upload`,
  ITEMS_CATEGORIES_TYPE: (type) => `items/categories/${type}`,
  ITEMS_DICTIONARIES_DISPLAY_SCHEDULES_TYPES: 'items/dictionaries/display/schedules/types',
  ITEMS_DICTIONARIES_MEDIA_FORMATS: 'items/dictionaries/media/formats',
  ITEMS_DICTIONARIES_MEDIA_TYPES: 'items/dictionaries/media/types',
  ITEMS_DICTIONARIES_SERVICE_TYPES: 'items/dictionaries/service/types',
  ITEMS_DICTIONARIES_TYPE: (type) => `items/dictionaries/${type}`,
  ITEMS_DICTIONARIES_VIDEOS_TYPES: 'items/dictionaries/videos/types',
  ITEMS_DRM_FAIRPLAY_CERTIFICATE: 'items/drm/fairplay/certificate',
  ITEMS_ID_AD_BREAKS: (id) => `items/${id}/ads/breaks`,
  ITEMS_ID_AD_BREAKS_EXTERNAL: (id) => `items/${id}/ads/breaks/external`,
  ITEMS_ID_AUDITS: (id) => `items/${id}/audits`,
  ITEMS_ID_AUDITS_EXPORT: (id) => `items/${id}/audits/export`,
  ITEMS_ID_DISPLAY_SCHEDULES: (id) => `items/${id}/display/schedules`,
  ITEMS_ID_DRM_KEY: (id) => `items/${id}/drm/key`,
  ITEMS_ID_DRM_TYPE: (itemId, drmType) => `/items/${itemId}/drm/${drmType}`,
  ITEMS_ID_IMAGES: (id) => `items/${id}/images`,
  ITEMS_ID_IMAGES_UPLOAD: (id) => `items/${id}/images/upload`,
  ITEMS_ID_MEDIA: (id) => `items/${id}/media`,
  ITEMS_ID_PLAYER_CONFIGURATION: (id) => `items/${id}/player/configuration`,
  ITEMS_ID_SECTIONS: (id) => `items/${id}/sections`,
  ITEMS_ID_SECTIONS_AVAILABLE: (id) => `items/${id}/sections/available`,
  ITEMS_ID_TYPE_PLAYLISTS: (id, type) => `/items/${id}/${type}/playlists`,
  ITEMS_ID_VIDEOS_SESSIONS: (id) => `items/${id}/videos/sessions`,
  ITEMS_LOGOS: 'items/logos',
  ITEMS_LOGOS_ID: (id) => `items/logos/${id}`,
  ITEMS_LOGOS_ID_IMAGES: (id) => `items/logos/${id}/images`,
  ITEMS_LOGOS_ID_IMAGES_UPLOAD: (id) => `items/logos/${id}/images/upload`,
  ITEMS_TYPE_DICTIONARIES: (type) => `items/${type}/dictionaries`,
  ITEMS_PARENT_ID_TYPE_DICTIONARIES: (parentItemId, type) => `items/${parentItemId}/${type}/dictionaries`,

  NICKNAMES_PROHIBITED: 'nicknames/prohibited',
  NICKNAMES_PROHIBITED_ID: (id) => `nicknames/prohibited/${id}`,
  NICKNAMES_PROHIBITED_TYPES: 'nicknames/prohibited/types',

  OPERATORS: 'operators',
  OPERATORS_DETAIL: 'operators/detail',
  OPERATORS_DICTIONARIES: 'operators/dictionaries',
  OPERATORS_EXPORT: 'operators/export',
  OPERATORS_ID: (id) => `operators/${id}`,
  OPERATORS_ID_AUDITS: (id) => `operators/${id}/audits`,
  OPERATORS_ID_AUDITS_EXPORT: (id) => `operators/${id}/audits/export`,
  OPERATORS_ID_PASSWORD: (id) => `operators/${id}/password`,
  OPERATORS_PASSWORD: 'operators/password',
  OPERATORS_ROLES: 'operators/roles',

  PAYMENTS: 'payments',
  PAYMENTS_CHANNELS: 'payments/channels',
  PAYMENTS_EXPORT: 'payments/export',
  PAYMENTS_ID: (id) => `payments/${id}`,
  PAYMENTS_ID_BLOCK: (id) => `payments/${id}/block`,
  PAYMENTS_SCHEDULES_TYPES: 'payments/schedules/types',
  PAYMENTS_STATUSES: 'payments/statuses',
  PAYMENTS_SYSTEMS: 'payments/systems',

  PRODUCTS_BUNDLES: 'products/bundles',
  PRODUCTS_BUNDLES_ELEMENTS_ID: (id) => `products/bundles/elements/${id}`,
  PRODUCTS_BUNDLES_EXPORT: 'products/bundles/export',
  PRODUCTS_BUNDLES_ID: (id) => `products/bundles/${id}`,
  PRODUCTS_BUNDLES_ID_ELEMENTS: (id) => `products/bundles/${id}/elements`,
  PRODUCTS_BUNDLES_ID_ELEMENTS_ID: (bundleId, elementId) => `products/bundles/${bundleId}/elements/${elementId}`,
  PRODUCTS_BUNDLES_ID_PAGE: (id, page) => `products/bundles/${id}/${page}`,
  PRODUCTS_EPISODES: 'products/episodes',
  PRODUCTS_EPISODES_EXPORT: 'products/episodes/export',
  PRODUCTS_ID_BUNDLES: (id) => `products/${id}/bundles`,
  PRODUCTS_ID_PAYMENTS: (id) => `products/${id}/payments`,
  PRODUCTS_ID_PAYMENTS_SCHEDULES: (id) => `products/${id}/payments/schedules`,
  PRODUCTS_ID_PAYMENTS_SCHEDULES_ID_DEACTIVATE: (productId, scheduleId) => `products/${productId}/payments/schedules/${scheduleId}/deactivate`,
  PRODUCTS_ID_STRIPES: (id) => `products/${id}/stripes`,
  PRODUCTS_ID_SUBSCRIPTIONS: (id) => `products/${id}/subscriptions`,
  PRODUCTS_LIVES: 'products/lives',
  PRODUCTS_LIVES_EXPORT: 'products/lives/export',
  PRODUCTS_LIVES_ID: (id) => `products/lives/${id}`,
  PRODUCTS_LIVES_ID_PAGE: (id, page) => `products/lives/${id}/${page}`,
  PRODUCTS_LIVES_ID_PROGRAMMES: (id) => `products/lives/${id}/programmes`,
  PRODUCTS_LIVES_ID_RECORDINGS: (id) => `products/lives/${id}/recordings`,
  PRODUCTS_LIVES_PROGRAMMES: 'products/lives/programmes',
  PRODUCTS_LIVES_PROGRAMMES_ID: (id) => `products/lives/programmes/${id}`,
  PRODUCTS_LIVES_PROGRAMMES_ID_EPISODE: (id) => `products/lives/programmes/${id}/episode`,
  PRODUCTS_LIVES_PROGRAMMES_ID_MEDIA_VODS_ID: (programmeId, vodId) => `products/lives/programmes/${programmeId}/media/vods/${vodId}`,
  PRODUCTS_LIVES_PROGRAMMES_ID_PAGE: (id, page) => `products/lives/programmes/${id}/${page}`,
  PRODUCTS_LIVES_PROGRAMMES_ID_PERSONS: (id) => `products/lives/programmes/${id}/persons`,
  PRODUCTS_LIVES_PROGRAMMES_ID_RECORDINGS_ID_MEDIA: (programmeId, recordingId) => `products/lives/programmes/${programmeId}/recordings/${recordingId}/media`,
  PRODUCTS_LIVES_PROGRAMMES_ID_RECORDINGS_ID_MEDIA_PERIOD: (programmeId, recordingId) => `products/lives/programmes/${programmeId}/recordings/${recordingId}/media/period`,
  PRODUCTS_LIVES_PROGRAMMES_ID_VOD: (id) => `products/lives/programmes/${id}/vod`,
  PRODUCTS_LIVES_PROGRAMMES_ID_VOD_TRANSFORM: (id) => `products/lives/programmes/${id}/vod/transform`,
  PRODUCTS_LIVES_PROGRAMMES_PERSON_TYPES: 'products/lives/programmes/person/types',
  PRODUCTS_LIVES_RECORDINGS: 'products/lives/recordings',
  PRODUCTS_LIVES_RECORDINGS_EXPORT: 'products/lives/recordings/export',
  PRODUCTS_LIVES_RECORDINGS_ID: (id) => `products/lives/recordings/${id}`,
  PRODUCTS_LIVES_RECORDINGS_STATUSES: 'products/lives/recordings/statuses',
  PRODUCTS_PPVS: 'products/ppvs',
  PRODUCTS_PPVS_EXPORT: 'products/ppvs/export',
  PRODUCTS_PPVS_ID_MEDIA_VODS_ID: (productId, vodId) => `products/ppvs/${productId}/media/vods/${vodId}`,
  PRODUCTS_PPVS_ID_MEDIA_PERIOD: (id) => `products/ppvs/${id}/media/period`,
  PRODUCTS_PPVS_ID_PAGE: (id, page) => `products/ppvs/${id}/${page}`,
  PRODUCTS_SEASONS: 'products/seasons',
  PRODUCTS_SEASONS_EXPORT: 'products/seasons/export',
  PRODUCTS_SPECIAL: 'products/special',
  PRODUCTS_SPECIAL_EXPORT: 'products/special/export',
  PRODUCTS_SPECIAL_ID: (id) => `products/special/${id}`,
  PRODUCTS_SPECIAL_TYPES: 'products/special/types',
  PRODUCTS_VODS: 'products/vods',
  PRODUCTS_VODS_AUDIO_TRACKS_TYPES: 'products/vods/audio-tracks/types',
  PRODUCTS_VODS_EPISODES_ID: (id) => `products/vods/episodes/${id}`,
  PRODUCTS_VODS_EPISODES_ID_MOVE: (id) => `products/vods/episodes/${id}/move`,
  PRODUCTS_VODS_EPISODES_ID_PAGE: (id, page) => `products/vods/episodes/${id}/${page}`,
  PRODUCTS_VODS_EXPORT: 'products/vods/export',
  PRODUCTS_VODS_ID: (id) => `products/vods/${id}`,
  PRODUCTS_VODS_ID_AUDIO_TRACKS: (id) => `products/vods/${id}/audio-tracks`,
  PRODUCTS_VODS_ID_ATTACHMENTS: (id) => `products/vods/${id}/attachments`,
  PRODUCTS_VODS_ID_MEDIA_PERIOD: (id) => `products/vods/${id}/media/period`,
  PRODUCTS_VODS_ID_MEDIA_TYPE_VODS_ID: (productId, mediumType, vodId) => `products/vods/${productId}/media/${mediumType}/vods/${vodId}`,
  PRODUCTS_VODS_ID_MEDIA_VODS_ID_TRAILERS: (productId, vodId) => `products/vods/${productId}/media/vods/${vodId}/trailers`,
  PRODUCTS_VODS_ID_MOVE_ID: (vodId, seasonId) => `products/vods/${vodId}/move/${seasonId}`,
  PRODUCTS_VODS_ID_PAGE: (id, page) => `products/vods/${id}/${page}`,
  PRODUCTS_VODS_ID_PERSONS: (id) => `products/vods/${id}/persons`,
  PRODUCTS_VODS_ID_SUBTITLES: (id) => `products/vods/${id}/subtitles`,
  PRODUCTS_VODS_SERIALS_ID_SUBTITLES: (id) => `products/vods/serials/${id}/subtitles`,
  PRODUCTS_VODS_ID_SYNCHRONIZE: (id) => `products/vods/${id}/synchronize`,
  PRODUCTS_VODS_PERSON_TYPES: 'products/vods/person/types',
  PRODUCTS_VODS_SEASONS_ID_PAGE: (id, page) => `products/vods/seasons/${id}/${page}`,
  PRODUCTS_VODS_SERIALS: 'products/vods/serials',
  PRODUCTS_VODS_SERIALS_EXPORT: 'products/vods/serials/export',
  PRODUCTS_VODS_SERIALS_ID: (id) => `products/vods/serials/${id}`,
  PRODUCTS_VODS_SERIALS_ID_PAGE: (id, page) => `products/vods/serials/${id}/${page}`,
  PRODUCTS_VODS_SERIALS_ID_SEASONS: (id) => `products/vods/serials/${id}/seasons`,
  PRODUCTS_VODS_SERIALS_ID_SEASONS_ID_MOVE: (serialId, seasonId) => `products/vods/serials/${serialId}/seasons/${seasonId}/move`,
  PRODUCTS_VODS_SERIALS_ID_SEASONS_DICTIONARIES: (id) => `products/vods/serials/${id}/seasons/dictionaries`,

  PROFILES_AVATARS: 'profiles/avatars',
  PROFILES_AVATARS_ID: (id) => `profiles/avatars/${id}`,

  PROMOTIONS: 'promotions',
  PROMOTIONS_DISCOUNT_TYPES: 'promotions/discount/types',
  PROMOTIONS_ID: (id) => `promotions/${id}`,
  PROMOTIONS_ID_AUDITS: (id) => `promotions/${id}/audits`,
  PROMOTIONS_ID_AUDITS_EXPORT: (id) => `promotions/${id}/audits/export`,
  PROMOTIONS_ID_CODES: (id, codeType) => `promotions/${id}/codes/${codeType}`,
  PROMOTIONS_ID_CODES_EXPORT: (id, codeType) => `promotions/${id}/codes/${codeType}/export`,
  PROMOTIONS_ID_CRITERIA: (id) => `promotions/${id}/criteria`,
  PROMOTIONS_ID_DISCOUNTS: (id) => `promotions/${id}/discounts`,
  PROMOTIONS_ID_PRODUCTS: (id) => `promotions/${id}/products`,
  PROMOTIONS_TYPES: 'promotions/types',

  RATINGS: 'ratings',

  SECTIONS: 'sections',
  SECTIONS_ALGORITHMS: 'sections/algorithms',
  SECTIONS_CONTENT_TYPES: 'sections/content/types',
  SECTIONS_DICTIONARIES: 'sections/dictionaries',
  SECTIONS_ID: (id) => `sections/${id}`,
  SECTIONS_ID_ALGORITHMS: (id) => `sections/${id}/algorithms`,
  SECTIONS_ID_ALGORITHMS_ID: (sectionId, algorithmId) => `sections/${sectionId}/algorithms/${algorithmId}`,
  SECTIONS_ID_ELEMENTS: (id) => `sections/${id}/elements`,
  SECTIONS_ID_ELEMENTS_ID: (sectionId, elementId) => `sections/${sectionId}/elements/${elementId}`,
  SECTIONS_ID_PAGE: (id, page) => `sections/${id}/${page}`,
  SECTIONS_ID_SHIFT: (id) => `sections/${id}/shift`,
  SECTIONS_ID_SHIFT_ALGORITHMS_ID: (sectionId, algorithmId) => `sections/${sectionId}/shift/algorithms/${algorithmId}`,
  SECTIONS_ID_SHIFT_ELEMENTS_ID: (sectionId, elementId) => `sections/${sectionId}/shift/elements/${elementId}`,
  SECTIONS_ID_WEB_URL_DEFAULT: (id) => `sections/${id}/web-url/default`,
  SECTIONS_TILES_ASPECTS: 'sections/tiles/aspects',
  SECTIONS_TILES_SIZES: 'sections/tiles/sizes',

  SUBSCRIBERS: 'subscribers',
  SUBSCRIBERS_GROUPS: 'subscribers/groups',
  SUBSCRIBERS_GROUPS_ALGORITHMS: 'subscribers/groups/algorithms',
  SUBSCRIBERS_GROUPS_ID: (id) => `subscribers/groups/${id}`,
  SUBSCRIBERS_GROUPS_TYPES: 'subscribers/groups/types',
  SUBSCRIBERS_DICTIONARIES: 'subscribers/dictionaries',
  SUBSCRIBERS_EXPORT: 'subscribers/export',
  SUBSCRIBERS_HTTP_SESSIONS_ID_BLOCK: (id) => `subscribers/http/sessions/${id}/block`,
  SUBSCRIBERS_ID: (id) => `subscribers/${id}`,
  SUBSCRIBERS_ID_AUTHS_APP: (id, app) => `subscribers/${id}/auths/${app}`,
  SUBSCRIBERS_ID_AGREEMENTS: (id) => `subscribers/${id}/agreements`,
  SUBSCRIBERS_ID_AUDITS: (id) => `subscribers/${id}/audits`,
  SUBSCRIBERS_ID_BOOKMARKS_TYPE: (id, type) => `subscribers/${id}/bookmarks/${type}`,
  SUBSCRIBERS_ID_CARDS: (id) => `subscribers/${id}/cards`,
  SUBSCRIBERS_ID_DEVICES: (id) => `subscribers/${id}/devices`,
  SUBSCRIBERS_ID_DOWNLOADS_ITEMS: (id) => `subscribers/${id}/downloads/items`,
  SUBSCRIBERS_ID_ERASE: (id) => `subscribers/${id}/erase`,
  SUBSCRIBERS_ID_HTTP_SESSIONS: (id) => `subscribers/${id}/http/sessions`,
  SUBSCRIBERS_ID_HTTP_SESSIONS_EXPORT: (id) => `subscribers/${id}/http/sessions/export`,
  SUBSCRIBERS_ID_PAYMENTS: (id) => `subscribers/${id}/payments`,
  SUBSCRIBERS_ID_PAYMENTS_FREE: (id) => `subscribers/${id}/payments/free`,
  SUBSCRIBERS_ID_PROFILES: (id) => `subscribers/${id}/profiles`,
  SUBSCRIBERS_ID_RECORDINGS: (id) => `subscribers/${id}/recordings`,
  SUBSCRIBERS_ID_SUBSCRIPTIONS: (id) => `subscribers/${id}/subscriptions`,
  SUBSCRIBERS_ID_WALLET: (id) => `subscribers/${id}/wallet`,
  SUBSCRIBERS_ID_WALLET_FREE_AMOUNT: (id) => `subscribers/${id}/wallet/free/amount`,
  SUBSCRIBERS_ID_VERIFICATION_EMAIL_RESEND: (id) => `subscribers/${id}/verification/email/resend`,
  SUBSCRIBERS_ID_VIDEO_SESSIONS: (id) => `subscribers/${id}/videos/sessions`,
  SUBSCRIBERS_ID_VIDEOS_SESSIONS_EXPORT: (id) => `subscribers/${id}/videos/sessions/export`,
  SUBSCRIBERS_VIDEOS_SESSIONS_ID_BLOCK: (id) => `subscribers/videos/sessions/${id}/block`,

  SUBSCRIPTIONS: 'subscriptions',
  SUBSCRIPTIONS_EXPORT: 'subscriptions/export',
  SUBSCRIPTIONS_ID: (id) => `subscriptions/${id}`,
  SUBSCRIPTIONS_ID_BLOCK: (id) => `subscriptions/${id}/block`,
  SUBSCRIPTIONS_ID_PAYMENTS: (id) => `subscriptions/${id}/payments`,
  SUBSCRIPTIONS_STATUSES: 'subscriptions/statuses',

  STATISTICS: 'statistics',
  STATISTICS_ID: (id) => `statistics/${id}`,

  TENANTS: 'tenants',
  TENANTS_CLONES: 'tenants/clones',
  TENANTS_CONFIG: 'tenants/config',
  TENANTS_ID: (id) => `tenants/${id}`,
  TENANTS_SECTIONS_TILE_ACTIVITIES: (id) => `tenants/${id}/sections/tile-activities`,
  TENANTS_UID_RATINGS: (uid) => `tenants/${uid}/ratings`,

  TYPE_ID_ACTIVATION: (type, id) => `${type}/${id}/activation`,

  VIEWS: 'views',
  VIEWS_ID_SECTIONS: (id) => `views/${id}/sections`,

  WALLET_PAYMENTS: 'wallet/payments',
  WALLET_STATUSES: 'wallet/statuses',
};

export default Urls;
