<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import http from '@/lib/http';
import {
  ItemType, QueryParam, RoleName, VideoType,
} from '@/enums';
import { RemoveModelMixin, RoleMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as ProductColumns from '@/modules/product/view/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';
import * as ProductFields from '@/modules/product/view/fields';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';

export default ListView.extend({
  mixins: [RemoveModelMixin, RoleMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    const url = Urls.PRODUCTS_VODS_SEASONS_ID_PAGE(this.id, this.page);

    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => Urls.PRODUCTS_VODS_EPISODES_ID(id),
      saveModelUrlFunction: () => url,
      saveModelModalTitleFunction: () => 'ADD_EPISODE',
      saveModelFormElementsFunction: () => [
        [
          ProductFields.TITLE_NOT_MANDATORY,
          ProductFields.NUMBER,
          ProductFields.DURATION,
          ProductFields.YEAR,
          ProductFields.RATING,
          ProductFields.HD,
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL(),
        ],
      ],
      saveModelDefaultValues: {
        type: ItemType.EPISODE,
        type_: ItemType.EPISODE,
      },
      paramsConfig: {
        sort: QueryParam.NUMBER,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.POSTER((record) => ({ record, videoType: VideoType.MOVIE })),
          ProductColumns.TITLE,
          ProductColumns.NUMBER,
          Columns.PLATFORMS,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          ProductColumns.SEASON_ACTIONS(this.isAllowed, this.moveEpisode, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
  methods: {
    moveEpisode(episodeId) {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: (model) => [[ProductFields.SELECTED_SERIES(model), ProductFields.SELECTED_SEASON(model)]],
        modalTitleFunction: () => 'SERIES_EPISODE_MOVE_TITLE',
      });
      const saveModelPromiseFunction = (model) => {
        const url = Urls.PRODUCTS_VODS_EPISODES_ID_MOVE(episodeId);
        return http.put(url, model);
      };

      this.openSaveModelModal(modal, saveModelPromiseFunction);
    },
  },
});
</script>
