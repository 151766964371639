<script>
import dayjs from 'dayjs';
import { DateFormat } from 'redge-media-web-utils';
import Urls from '@/application/urls';
import * as Filters from '@/components/filter/filters';
import SubscriptionsList from '@/components/subscriptions-list/index.vue';
import * as PaymentFilters from '@/modules/payment/view/filters';
import { QueryParam } from '@/enums';

export default SubscriptionsList.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: null,
      recordsUrl: Urls.PRODUCTS_ID_SUBSCRIPTIONS(this.id),
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
        since: dayjs().subtract(28, 'day').startOf('day').format(DateFormat.DATETIME_FORMAT),
        till: dayjs().add(1, 'day').endOf('day').format(DateFormat.DATETIME_FORMAT),
      },
      filters: [
        Filters.CREATE_SINCE(),
        Filters.CREATE_TILL(),
        PaymentFilters.PRODUCTS,
        PaymentFilters.SUBSCRIPTION_STATUS,
        PaymentFilters.PAYMENT_CHANNEL,
        PaymentFilters.PRICE_GREATER,
        PaymentFilters.PRICE_LESS,
      ],
    };
  },
});
</script>
