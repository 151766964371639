<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as SubscriberColumns from '@/modules/subscriber/view/columns';
import { QueryParam } from '@/enums';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      recordsUrl: Urls.SUBSCRIBERS_ID_BOOKMARKS_TYPE(this.id, 'FAVOURITE'),
      paramsConfig: {
        sort: QueryParam.PROFILE_NAME,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          SubscriberColumns.BOOKMARKS_PROFILE_NAME,
          Columns.PRODUCT_DETAIL(({ item }) => ({ product: item })),
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
        ],
        records: this.records,
      };
    },
  },
});
</script>
