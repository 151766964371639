import EventBus from '@/application/event-bus';
import SectionElement from '@/modules/content/view/section/ghost-element.vue';
import UiDraggableProduct from '@/helper/draggable-product.vue';
import { RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';

export default {
  mixins: [RoleMixin],
  props: {
    targetClass: {
      type: String,
      default: '.droppable',
    },
    droppableClass: {
      type: String,
      default: '.section-item-list-wrapper',
    },
  },
  data() {
    return {
      draggableItem: undefined,
      item: undefined,
      draggableWidth: 0,
      draggableHeight: 0,
      currentTarget: undefined,
      targets: undefined,
      ghost: undefined,
      ghostClass: SectionElement,
      uiDraggable: UiDraggableProduct,
      writeRole: RoleName.CMS_WRITE,
    };
  },
  methods: {
    onMouseDown(item, event) {
      if (!this.uiDraggable || !this.isAllowed) {
        return;
      }

      this.item = item;
      this.targets = document.querySelectorAll(this.targetClass);
      Array.from(this.targets).forEach((target) => {
        target.addEventListener('mouseenter', this.itemOnMouseEnter);
        target.addEventListener('mouseleave', this.itemOnMouseLeave);
      });

      event.preventDefault();
      document.addEventListener('mousemove', this.itemOnMouseMove);
      document.addEventListener('mouseup', this.ghostOnMouseUp);

      this.draggableItem = new this.uiDraggable({
        propsData: { item: this.item },
      });

      this.$nextTick(() => {
        [this.draggableWidth, this.draggableHeight] = this.draggableItem.getDims();
        const left = event.pageX - this.draggableWidth / 2;
        const top = event.pageY - this.draggableHeight / 2;

        this.draggableItem.updatePosition(left, top);
      });
    },
    itemOnMouseEnter(event) {
      this.currentTarget = event.currentTarget;
      EventBus.$emit('DROPPABLE_MOUSE_ENTER', this.item, this.ghost);
      const sortableItems = this.currentTarget.querySelectorAll('.sortable-item');
      Array.from(sortableItems).forEach((item) => {
        item.addEventListener('mouseenter', this.onSortableItemMouseEnter);
      });

      this.ghost = new this.ghostClass({
        propsData: { item: this.item },
      });

      const droppableEl = this.currentTarget.querySelector(this.droppableClass);
      droppableEl.appendChild(this.ghost.$el);
    },
    itemOnMouseLeave() {
      const sortableItems = this.currentTarget.querySelectorAll('.sortable-item');
      Array.from(sortableItems).forEach((item) => {
        item.removeEventListener('mouseenter', this.onSortableItemMouseEnter);
      });
      this.currentTarget = undefined;

      if (this.ghost) {
        this.ghost.$destroy();
        this.ghost.$el.parentNode.removeChild(this.ghost.$el);
      }
    },
    onSortableItemMouseEnter(event) {
      const item = event.target;
      const elms = Array.from(item.parentNode.children);
      const itemIndex = elms.indexOf(item);
      const ghostIndex = elms.indexOf(this.ghost.$el);

      if (itemIndex < ghostIndex) {
        item.parentNode.insertBefore(this.ghost.$el, item);
      } else {
        item.parentNode.insertBefore(this.ghost.$el, item.nextSibling);
      }
    },
    ghostOnMouseUp() {
      this.draggableItem.destroy();
      document.removeEventListener('mousemove', this.itemOnMouseMove);
      document.removeEventListener('mouseup', this.ghostOnMouseUp);

      Array.from(this.targets).forEach((target) => {
        target.removeEventListener('mouseenter', this.itemOnMouseEnter);
        target.removeEventListener('mouseleave', this.itemOnMouseLeave);
      });

      if (this.currentTarget) {
        const sortableItems = this.currentTarget.querySelectorAll('.sortable-item');
        Array.from(sortableItems).forEach((item) => {
          item.removeEventListener('mouseenter', this.onSortableItemMouseEnter);
        });
      }

      if (this.ghost && this.currentTarget) {
        const elms = Array.from(this.ghost.$el.parentNode.children);
        const rank = elms.indexOf(this.ghost.$el);
        this.ghost.$destroy();
        this.ghost.$el.parentNode.removeChild(this.ghost.$el);
        EventBus.$emit('ADD_ELEMENT_TO_SECTION', this.item, rank);
      }

      this.currentTarget = undefined;
    },
    itemOnMouseMove(event) {
      const left = event.pageX - this.draggableWidth / 2;
      const top = event.pageY - this.draggableHeight / 2;

      this.draggableItem.updatePosition(left, top);
    },
  },
};
