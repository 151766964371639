<template>
  <div class="flex-column">
    <ui-tabs :current-route="$route" :tabs="tabs" />
    <v-list-wrapper :search="false" :table-definition="tableDefinition" />
  </div>
</template>
<script>
import { UiFormTextInput } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { AppEvent, Page, RoleName } from '@/enums';
import http from '@/lib/http';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import UiTabs from '@/components/ui-tabs/index.vue';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';

const defaultIpData = {
  country: {},
  groups: undefined,
};

export default ListView.extend({
  components: {
    UiTabs,
  },
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.ADMIN,
      removeModelUrlFunction: ({ id }) => Urls.GEOIP_TYPE_ID(this.id.toUpperCase(), id),
      saveModelUrlFunction: () => Urls.GEOIP_TYPE(this.id.toUpperCase()),
      saveModelModalTitleFunction: () => 'ADD_GEOLOCK',
      saveModelFormElementsFunction: () => [
        [
          Fields.textInputField('cidrIp', 'GEOIP_CIDR_IP', true),
          Fields.numberInputField('cidrMask', 'GEOIP_CIDR_MASK', true),
          Fields.textInputField('description', 'COMMON_DESCRIPTION', true),
          { ...Fields.textInputField('countryIsoCode', 'GEOIP_COUNTRY_ISO'), isHidden: this.id !== 'white' },
        ],
      ],
      saveModelDefaultValues: {
        countryIsoCode: '',
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.page;
    },
    recordsUrl() {
      return Urls.GEOIP_TYPE(this.$route.params.page.toUpperCase());
    },
    tabs() {
      return [
        { label: 'TAB_WHITE', page: Page.WHITE, query: this.$route.query },
        { label: 'TAB_BLACK', page: Page.BLACK, query: this.$route.query },
      ];
    },
    tableDefinition() {
      return {
        columns: [
          {
            field: 'range',
            minWidth: 280,
            headingLabel: 'GEOIP_IP_RANGE',
            dataMapper: ({ minIp, maxIp }) => `${minIp} - ${maxIp}`,
          },
          { ...Columns.DESCRIPTION, field: 'metadata.description' },
          {
            field: 'metadata.country.isoCode',
            minWidth: 160,
            headingLabel: 'GEOIP_COUNTRY_ISO',
          },
          Columns.ACTIONS_REMOVE(true, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
  async created() {
    this.buttons.unshift({
      icon: 'icon-zoom-in',
      tooltip: 'HELP_GEOIP_CHECK',
      onClick: this.openCheckIpModal,
    });

    if (this.id === 'white') {
      const url = Urls.APPLICATION_SETTINGS_KEY('GEOIP_COUNTRY_ISO_CODE');
      this.saveModelDefaultValues.countryIsoCode = await http.get(url).then(({ data }) => data);
    }
  },
  methods: {
    openCheckIpModal() {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: (model) => [
          [
            {
              field: 'ip',
              component: UiFormTextInput,
              componentOptions: {
                label: 'GEOIP_IP',
                class: {
                  'success-input-wrapper': model.ipData?.valid === true,
                  'error-input-wrapper': model.ipData?.valid === false,
                },
              },
            },
            Fields.checkboxField('portability', 'GEOIP_PORTABILITY'),
            {
              ...Fields.textInputField('ipData.country.isoCode', 'GEOIP_COUNTRY_ISO', false, true),
              isHidden: !model.ipData.country.isoCode,
            },
            {
              ...Fields.textInputField('ipData.country.name', 'GEOIP_COUNTRY', false, true),
              isHidden: !model.ipData.country.name,
            },
            { ...Fields.textInputField('ipData.groups', 'GEOIP_GROUP', false, true), isHidden: !model.ipData.groups },
          ],
        ],
        modalTitleFunction: () => 'GEOIP_CHECK',
        defaultValues: {
          ipData: defaultIpData,
        },
        submitButtonLabel: 'COMMON_CHECK',
      });

      modal.$on(AppEvent.SUCCESS, async (model) => {
        model.ipData = defaultIpData;
        const options = { params: { portability: model.portability } };

        try {
          const { data } = await http.get(Urls.GEOIP_IPDB_IP(model.ip || null), options);
          model.ipData = { ...data, groups: data?.groups?.join(', ') };
        } catch (error) {
          model.ipData = { ...model.ipData, valid: false };
          throw error;
        }
      });
    },
  },
});
</script>
