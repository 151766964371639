<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { ItemType, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Filters from '@/components/filter/filters';
import * as Fields from '@/components/ui-form/fields';
import * as ProductColumns from '@/modules/product/view/columns';
import * as ProductFilters from '@/modules/product/view/filters';

export default ListView.extend({
  mixins: [SaveModelMixin, RemoveModelMixin],
  data() {
    const url = Urls.PRODUCTS_PPVS;
    return {
      writeRole: RoleName.ADMIN,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'COMMON_EDIT_PPV' : 'COMMON_ADD_PPV'),
      saveModelFormElementsFunction: (model) => [
        [Fields.SELECTED_LIVE_PARENT(model, false), Fields.SELECTED_PRODUCT_WITH_PARENT(model)],
      ],
      saveModelDefaultValues: {
        itemType: ItemType.PROGRAMME,
        programmeId: undefined,
        type: ItemType.PPV,
        type_: ItemType.PPV,
      },
      exportUrl: Urls.PRODUCTS_PPVS_EXPORT,
      filters: [Filters.PLATFORMS, Filters.ACTIVE, ProductFilters.CURRENCIES, ProductFilters.STRIPE],
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.POSTER(),
          ProductColumns.TITLE,
          Columns.PLATFORMS,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
