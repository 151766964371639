<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { Page, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as SubscriberColumns from '@/modules/subscriber/view/columns';

export default ListView.extend({
  mixins: [RemoveModelMixin],
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.DOWNLOADS_ITEMS,
      removeModelUrlFunction: ({ id }) => Urls.DOWNLOADS_ITEMS_ID(id),
      paramsConfig: {
        sort: QueryParam.MODIFIED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.SUBSCRIBER_DETAIL(({ device: { subscriber } }) => ({ subscriber, page: Page.DOWNLOADS }), false),
          Columns.PRODUCT_DETAIL(({ item }) => ({ product: item, page: Page.DOWNLOADS })),
          { ...Columns.DEVICE_DETAIL(({ device }) => ({ device })), headingLabel: 'COMMON_DEVICE' },
          SubscriberColumns.DEVICE_DOWNLOAD_STARTED,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
