<template>
  <span class="multiselect__tag">
    <ui-product-title :class="{ 'opacity-4': option.active === false }" :product="{ title: option[field] }" />
    <i
      v-if="option[field]"
      aria-hidden="true"
      tabindex="1"
      class="multiselect__tag-icon"
      @keypress.enter.prevent="remove(option)"
      @mousedown.prevent="remove(option)"
    ></i>
  </span>
</template>
<script>
import UiProductTitle from '@/components/ui-product-title/index.vue';

export default {
  components: {
    UiProductTitle,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    remove: {
      type: Function,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
  },
};
</script>
