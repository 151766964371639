import {
  ACTIONS,
  BUTTON_BLOCK,
  BUTTON_FORGET,
  BUTTON_MORE,
  BUTTON_REMOVE,
  createDateColumn,
} from '@/components/ui-table/columns';

export const ACTIONS_DEVICE = (isAllowed, openDetailMethod, removeItemMethod, isLoadingDetail = false) => {
  const dataComponentPropsMapper = (model) => ({
    buttons: [
      BUTTON_MORE(() => openDetailMethod(model), isLoadingDetail),
      BUTTON_REMOVE(() => removeItemMethod(model)),
    ],
  });
  return ACTIONS(isAllowed, dataComponentPropsMapper);
};

export const ACTIONS_SESSIONS = (isAllowed, removeItemMethod) => {
  const dataComponentPropsMapper = (model) => ({
    buttons: model.displayActive ? [BUTTON_BLOCK(() => removeItemMethod(model, 'post'))] : [],
  });
  return ACTIONS(isAllowed, dataComponentPropsMapper);
};

export const ACTIONS_SUBSCRIBER = (isAllowed, removeItemMethod, resendVerificationEmailMethod) => {
  const dataComponentPropsMapper = ({
    id, active, email, verified,
  }) => ({
    buttons: [
      ...(active ? [BUTTON_FORGET(() => removeItemMethod(id))] : []),
      ...(email && !verified
        ? [
          {
            tooltip: 'HELP_SUBSCRIBER_EMAIL_RESEND',
            icon: 'icon-send',
            onClick: () => resendVerificationEmailMethod(id),
          },
        ]
        : []),
    ],
  });
  return ACTIONS(isAllowed, dataComponentPropsMapper);
};

export const BOOKMARKS_PROFILE_NAME = {
  field: 'profile-name',
  minWidth: 240,
  isSortable: true,
  headingLabel: 'SUBSCRIBER_BOOKMARKS_PROFILE_NAME',
  dataMapper: ({ profile }) => profile.name,
};

export const DEVICE_APP_VERSION = {
  field: 'appVersion',
  width: 150,
  isSortable: true,
  headingLabel: 'DEVICE_APP_VERSION',
};

export const DEVICE_DOWNLOAD_STARTED = createDateColumn('startedAt', 'DEVICE_DOWNLOAD_STARTED');

export const DEVICE_LAST_LOGIN_DATE = createDateColumn('lastLoggedAt', 'COMMON_LAST_LOGIN_DATE', 180);

export const DEVICE_LAST_LOGIN_IP = {
  field: 'lastLoggedIp',
  width: 160,
  headingLabel: 'DEVICE_LAST_LOGIN_IP',
};

export const DEVICE_OS_VERSION = {
  field: 'osVersion',
  width: 150,
  headingLabel: 'DEVICE_OS_VERSION',
};

export const DEVICE_VERSION_LAST_ATTACHED = {
  ...createDateColumn('lastAttachedAt', 'DEVICE_VERSION_LAST_ATTACHED'),
  isSortable: false,
};
