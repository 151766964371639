<template>
  <div v-if="!isLogged" class="login">
    <header class="login__header">
      <button
        v-for="language in i18n.languages"
        :key="language"
        :class="{ 'login__header-language--active': i18n.language === language }"
        class="login__header-language"
        @click="i18n.setLanguage(language)"
        v-text="language.toUpperCase()"
      ></button>
    </header>

    <div class="login__content">
      <div
        :class="{ 'login__container--without-email-and-password': !isLoggingWithEmailAndPasswordActive }"
        class="login__container"
      >
        <div class="login__logo-wrapper">
          <img src="/static/img/logo.svg" alt="Redge Media Portal" class="login__logo">
        </div>
        <p class="login__description" v-text="$translate('LOGIN_DESCRIPTION')"></p>
      </div>
      <form
        class="login__form"
        :class="{
          'login__form--with-sso': isLoggingWithSsoActive,
          'login__form--with-email-and-password': isLoggingWithEmailAndPasswordActive,
        }"
      >
        <h1 class="login__form-header" v-text="$translate('SUBSCRIBER_LOGIN_ACTION')"></h1>
        <div v-if="isLoggingWithSsoActive" class="login__form-sso">
          <button
            type="button"
            class="login__form-submit login__form-submit--sso"
            @click.prevent="openAuthorizeBySSO"
            v-text="$translate('COMMON_LOGIN_BY_SSO')"
          ></button>
        </div>
        <div v-if="isLoggingWithEmailAndPasswordActive" class="login__form-inputs">
          <div class="login__form-input-wrapper login__form-input-wrapper--email">
            <label for="email" class="login__form-label" v-text="$translate('COMMON_EMAIL')"></label>
            <input
              id="email"
              v-model="operator.email"
              v-focus
              :class="{ 'login__form-input--invalid': fieldHasError('email') }"
              class="ui-input login__form-input"
              type="text"
              name="email"
              data-qa="login-email"
            >
          </div>
          <div class="login__form-input-wrapper login__form-input-wrapper--password">
            <label for="password" class="login__form-label" v-text="$translate('OPERATOR_PASSWORD')"></label>
            <input
              id="password"
              v-model="operator.password"
              :class="{ 'login__form-input--invalid': fieldHasError('password') }"
              class="ui-input login__form-input"
              type="password"
              name="password"
              data-qa="login-password"
            >
          </div>
        </div>
        <div v-if="errors.length > 0" class="login__form-errors" data-qa="login-errors">
          <p
            v-for="error in errors"
            :key="error.code"
            class="login__form-error"
            v-text="$translate(error.code)"
          ></p>
        </div>
        <button
          v-if="isLoggingWithEmailAndPasswordActive"
          type="submit"
          class="login__form-submit"
          data-qa="login-submit-button"
          @click.prevent="login(operator)"
          v-text="$translate('SUBSCRIBER_LOGIN_ACTION')"
        ></button>
      </form>
    </div>

    <footer class="login__footer">
      <span class="login__footer-text">
        <template v-if="buildInfo">
          GUI: <em>{{ buildInfo.guiDate }} ({{ buildInfo.guiVersion }})</em> | API:
          <em>{{ buildInfo.apiDate }} ({{ buildInfo.apiVersion }})</em> |
        </template>
        &copy;
      </span>
      <a class="login__footer-link" href="https://www.redge.com/" v-text="$translate('COMMON_COMPANY_NAME')"></a>
    </footer>
  </div>
</template>
<script>
import qs from 'qs';
import http from '@/lib/http';
import { focus } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import info from '@/application/info';
import operator from '@/application/operator';
import i18n from '@/application/i18n';

const SSO_AUTHORIZE_RESPONSE_TYPE = 'code';
const getCurrentUrlWithoutParams = () => window.location.href.split('?')[0];

export default {
  directives: {
    focus,
  },
  data() {
    return {
      i18n,
      info,
      operator: {
        email: undefined,
        password: undefined,
      },
      errors: [],
      buildInfo: undefined,
    };
  },
  computed: {
    isLogged() {
      return operator.isLogged;
    },
    isLoggingWithSsoActive() {
      return this.info.sso.active;
    },
    isLoggingWithEmailAndPasswordActive() {
      return this.info.emailAndPassword.active;
    },
  },
  async created() {
    this.buildInfo = await http
      .get('/static/info/build.bo.json', { baseURL: process.env.VUE_APP_BO_URL })
      .then(({ data }) => data)
      .catch(() => {});
  },
  async mounted() {
    if (this.isLogged) {
      this.$router.push({ name: 'home' });
    } else if (this.isLoggingWithSsoActive && Object.keys(this.$route.query).includes(SSO_AUTHORIZE_RESPONSE_TYPE)) {
      const data = {
        authorizationCode: this.$route.query[SSO_AUTHORIZE_RESPONSE_TYPE],
        redirectUri: getCurrentUrlWithoutParams(),
      };
      this.login(data, Urls.AUTH_LOGIN_SSO);
    }
  },
  methods: {
    fieldHasError(field) {
      return this.errors.length > 0 && this.errors.some((e) => !e.field || e.field === field);
    },
    openAuthorizeBySSO() {
      const params = {
        client_id: this.info.sso.clientId,
        redirect_uri: getCurrentUrlWithoutParams(),
        response_type: SSO_AUTHORIZE_RESPONSE_TYPE,
        scope: 'USER_GET',
      };

      window.location.href = `${this.info.sso.url}/authorize?${qs.stringify(params)}`;
    },
    login(data, url = Urls.AUTH_LOGIN) {
      this.errors = [];

      http
        .post(url, data)
        .then(async () => {
          await operator.fetch();
          await this.info.fetchInfo();
          this.$router.push({ path: this.$route.params.next || '/gui' });
        })
        .catch((error) => {
          const { errors, code } = (error.response && error.response.data) || {};
          if (errors) {
            this.errors = errors;
          } else if (code) {
            this.errors = [{ code }];
          }
        });
    },
  },
};
</script>
