import * as views from '@/modules';
import { Page } from '@/enums';

export default [
  {
    path: '/gui/subscriber/subscribers',
    name: 'subscriber',
    component: views.SubscriberSubscribers,
    meta: {
      titleLabels: ['MENU_USER_SUBSCRIBER'],
    },
  },
  {
    path: '/gui/subscriber/groups',
    name: 'groups',
    component: views.SubscriberGroups,
    meta: {
      titleLabels: ['MENU_USER_GROUPS'],
    },
  },
  {
    path: '/gui/subscriber/subscribers/:id/:page',
    name: 'subscriber.detail',
    component: views.SubscriberSubscribersDetail,
    meta: {
      titleLabels: ['MENU_USER_SUBSCRIBER'],
    },
  },
  {
    path: '/gui/subscriber/devices',
    name: 'device',
    component: views.SubscriberDevices,
    meta: {
      titleLabels: ['COMMON_DEVICES'],
    },
  },
  {
    path: '/gui/subscriber/devices/:id/:page',
    name: 'device.detail',
    component: views.SubscriberDevicesDetail,
    meta: {
      titleLabels: ['COMMON_DEVICES'],
    },
  },
  {
    path: '/gui/subscriber/contacts',
    name: 'contact',
    redirect: { name: 'contact.page', params: { page: Page.THREADS } },
  },
  {
    path: '/gui/subscriber/contacts/:page',
    name: 'contact.page',
    component: views.SubscriberContacts,
    meta: {
      titleLabels: ['MENU_USER_CONTACT'],
    },
  },
  {
    path: '/gui/subscriber/contacts/threads/:id',
    name: 'contact.thread.detail',
    component: views.SubscriberContactsDetail,
    meta: {
      titleLabels: ['MENU_USER_CONTACT'],
    },
  },
  {
    path: '/gui/subscriber/downloads',
    name: 'download',
    component: views.SubscriberDownloads,
    meta: {
      titleLabels: ['COMMON_DOWNLOADS'],
    },
  },
  {
    path: '/gui/subscriber/recordings',
    name: 'recording',
    component: views.SubscriberRecordings,
    meta: {
      titleLabels: ['COMMON_RECORDINGS'],
    },
  },
  {
    path: '/gui/subscriber/audits',
    name: 'subscriberaudit',
    component: views.SubscriberAudits,
    meta: {
      titleLabels: ['COMMON_EVENTS'],
    },
  },
];
