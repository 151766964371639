<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import { UiFormNumberInput } from 'redge-media-web-ui';
import { NumberDivider } from 'redge-media-web-utils';
import Urls from '@/application/urls';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';

export default {
  components: {
    UiDetail,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: true,
        modelUrl: Urls.APPLICATION_TIMERS_ID(this.id),
        formElementsFunction: () => [
          [
            Fields.HEADER_METADATA,
            Fields.textInputField('name', 'COMMON_NAME', false, true),
            Fields.datePickerField('value', 'COMMON_VALUE', false, true),
            Fields.textInputField('hostname', 'TIMER_HOSTNAME', false, true),
            Fields.checkboxField('active', 'COMMON_ACTIVE_MALE'),
            Fields.checkboxField('integration', 'TIMER_INTEGRATION', false, true),
            Fields.datePickerField('startedAt', 'TIMER_STARTED_AT'),
            {
              field: 'timeoutDuration',
              component: UiFormNumberInput,
              componentOptions: {
                label: 'TIMER_TIMEOUT',
                divider: NumberDivider.MINUTE_TO_SECONDS,
              },
            },
            Fields.checkboxField('lastStartedManually', 'TIMER_LAST_STARTED_MANUALLY', false, true),
          ],
          [Fields.HEADER_DESCRIPTION, Fields.textareaField('description')],
        ],
      };
    },
  },
};
</script>
