import ItemType from './item-type';

export default {
  ALL_PRODUCT_TYPES: [
    ItemType.BUNDLE,
    ItemType.VOD,
    ItemType.SERIAL,
    ItemType.SEASON,
    ItemType.EPISODE,
    ItemType.LIVE,
    ItemType.SPECIAL,
    ItemType.PPV,
  ],
  AD_BLOCK_KEY: 'ad-block-modal-closed',
  AUTHENTICATION_REQUIRED: 'AUTHENTICATION_REQUIRED',
  CDN_EPOCH: 978307200000,
  CONTENT_LANGUAGE_KEY: 'redgalaxy-portal-bo-content-lang',
  LANGUAGE_KEY: 'redgalaxy-portal-bo-lang',
  OPEN_FILTER_MENU: 'OPEN_FILTER_MENU',
  RATINGS: [0, 7, 12, 16, 18],
  SELECTED_TENANT_UID: 'redgalaxy-portal-bo-selected-tenant-uid',
};
