import UiLoader from './ui-loader.vue';

const LoaderPlugin = {
  install(Vue) {
    this.loader = null;

    Vue.component('ui-loader', UiLoader);

    Vue.prototype.$loader = {
      show(text) {
        this.hide();

        const ComponentClass = Vue.extend(UiLoader);
        this.loader = new ComponentClass({
          parent: this.$root,
          propsData: {
            text,
            overlay: true,
          },
        });

        this.loader.$mount();
        document.body.appendChild(this.loader.$el);
      },
      hide() {
        if (this.loader) {
          this.loader.$el.parentNode?.removeChild(this.loader.$el);
          this.loader.$destroy();
          this.loader = null;
        }
      },
    };
  },
};

export default LoaderPlugin;
