<script>
import { DictionaryFetchMixin } from '@/mixin';

export default {
  mixins: [DictionaryFetchMixin],
  render() {
    return this.$scopedSlots.default({
      fetching: this.fetching,
      fetch: this.fetch,
      records: this.records,
    });
  },
};
</script>
