<template>
  <div ref="ghost" class="product-draggable">
    <div class="image" :style="image"></div>
  </div>
</template>
<script>
import Vue from 'vue';
import { background } from '@/helper';

export default Vue.extend({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      return background(this.item.images);
    },
  },
  created() {
    this.$mount();
    document.body.appendChild(this.$el);
  },
  methods: {
    updatePosition(x, y) {
      this.$refs.ghost.style.top = `${y}px`;
      this.$refs.ghost.style.left = `${x}px`;
    },
    getDims() {
      return [this.$refs.ghost.offsetWidth, this.$refs.ghost.offsetHeight];
    },
    destroy() {
      this.$el.parentNode.removeChild(this.$el);
      this.$destroy();
    },
  },
});
</script>
