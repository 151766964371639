<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [SaveModelMixin, RemoveModelMixin],
  data() {
    return {
      writeRole: RoleName.ADMIN,
      recordsUrl: Urls.ADS_TAGS,
      removeModelUrlFunction: ({ id }) => Urls.ADS_TAGS_ID(id),
      saveModelUrlFunction: ({ id }) => (id ? Urls.ADS_TAGS_ID(id) : Urls.ADS_TAGS),
      saveModelModalTitleFunction: ({ id }) => (id ? 'COMMON_EDIT_ADS_TAG' : 'COMMON_ADD_ADS_TAG'),
      saveModelFormElementsFunction: () => [
        [
          Fields.textInputField('name', 'COMMON_NAME', true),
          Fields.textInputField('vastParameterName', 'VAST_PARAMETER_NAME'),
          Fields.textInputField('vastParameterValue', 'VAST_PARAMETER_VALUE'),
        ],
      ],
      paramsConfig: {
        sort: QueryParam.NAME,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.NAME,
          {
            field: 'vastParameterName',
            minWidth: 350,
            headingLabel: 'VAST_PARAMETER_NAME',
          },
          {
            field: 'vastParameterValue',
            minWidth: 250,
            headingLabel: 'VAST_PARAMETER_VALUE',
          },
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
