<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_ID_AGREEMENTS(this.id),
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.ITEM_NAME(({ id, name }) => ({ id, text: name, name: 'agreement.detail' }), false),
          {
            field: 'value',
            minWidth: 180,
            headingLabel: 'AGREEMENT_VERSION_ACCEPTED',
            dataMapper: ({ value }) => this.$translate(value ? 'COMMON_YES' : 'COMMON_NO'),
          },
          Columns.AGREEMENT_OBLIGATORY,
          Columns.TYPE,
          Columns.AGREEMENT_VERSION_SINCE,
        ],
        records: this.records,
      };
    },
  },
});
</script>
