<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as ProductFields from '@/modules/product/view/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      synchronizeUrl: Urls.PRODUCTS_VODS_ID_SYNCHRONIZE(this.id),
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        modelUrl: Urls.PRODUCTS_VODS_SEASONS_ID_PAGE(this.id, this.page),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.EXTERNAL_UID,
            ProductFields.NUMBER,
            ProductFields.TITLE_NOT_MANDATORY,
            Fields.CREATE_SINCE(),
            Fields.CREATE_TILL(),
            Fields.PLATFORMS,
            Fields.CREATED_AT,
            Fields.MODIFIED_AT,
          ],
          [...ProductFields.GEOLOCK_FIELDS(model)],
        ],
      };
    },
  },
};
</script>
