<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as ContentFields from '@/modules/content/view/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        isSaveButtonSticky: true,
        modelUrl: Urls.BANNERS_ID(this.id),
        formElementsFunction: () => [
          [
            Fields.HEADER_METADATA,
            Fields.textInputField('externalUid', 'COMMON_EXTERNAL_UID'),
            Fields.TITLE,
            Fields.LOGO,
            Fields.CREATE_SINCE(),
            Fields.CREATE_TILL(),
            Fields.CUSTOM_BADGE,
            Fields.PLATFORMS,
            Fields.CREATED_AT,
            Fields.MODIFIED_AT,
            Fields.textHeader('BANNER_BUTTON_SETTINGS'),
            ContentFields.WEB_URL,
            ContentFields.APP_URL,
            Fields.textInputField('buttonLabel', 'BANNER_BUTTON_LABEL'),
            Fields.checkboxField('externalView', 'BANNER_EXTERNAL_VIEW'),
          ],
          [Fields.HEADER_LEAD, Fields.LEAD, Fields.HEADER_DESCRIPTION, Fields.textareaField('description')],
        ],
      };
    },
  },
};
</script>
