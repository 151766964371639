import { serializeQuery } from 'axios-cache-adapter';
import Urls from '@/application/urls';
import http, { CancelToken, isHttpRequestCanceled } from '@/lib/http';
import errorHandler from '@/helper/error-handler';

const CACHE_OPTIONS = {
  maxAge: 15 * 60 * 1000,
  exclude: { query: false },
};

const pendingRequests = {};

// This wrapper takes care of two aspects
// First: it caches all fetched resources for maxAge time
// Second: if there are multiple requests for single resource at one time, it will only make one request. All other requests will get promise of the first request.
const fetchDictionary = (url, params, cancelToken) => {
  const requestKey = url + serializeQuery({ params });

  if (!pendingRequests[requestKey]) {
    pendingRequests[requestKey] = http
      .get(url, { params, cancelToken, cache: CACHE_OPTIONS })
      .then(({ data }) => {
        delete pendingRequests[requestKey];
        return data.items || data;
      })
      .catch((error) => {
        delete pendingRequests[requestKey];
        throw error;
      });
  }

  return pendingRequests[requestKey];
};

export default {
  props: {
    dict: {
      type: Array,
    },
    dictUrl: {
      type: String,
    },
    dictType: {
      type: String,
    },
    maxResults: {
      type: Number,
      default: 5,
    },
    dataFilter: {
      type: Function,
    },
  },
  data() {
    return {
      source: undefined,
      fetching: false,
      records: [],
    };
  },
  computed: {
    dictionaryFetchProps() {
      return [this.dict, this.dictUrl, this.dictType, this.maxResults];
    },
  },
  watch: {
    dictionaryFetchProps: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.fetch();
        }
      },
      deep: true,
    },
  },
  methods: {
    async fetch(keyword, getRecordLabel, maxResults = this.maxResults) {
      let records;
      this.fetching = true;

      if (this.dict) {
        const data = keyword
          ? this.dict.filter((record) => getRecordLabel(record).toLowerCase().includes(keyword.toLowerCase()))
          : this.dict;
        records = maxResults ? data.slice(0, maxResults) : data;
      } else {
        this.source?.cancel();
        this.source = CancelToken.source();
        const url = this.dictUrl || Urls.ITEMS_DICTIONARIES_TYPE(this.dictType);

        try {
          records = await fetchDictionary(url, { keyword, maxResults }, this.source.token);
        } catch (error) {
          if (isHttpRequestCanceled(error)) {
            return;
          }

          errorHandler(error?.response?.data?.errors?.[0] || {}, this);
        }
      }

      this.records = this.dataFilter ? records.filter(this.dataFilter) : records;

      this.$emit('fetched', this.records);

      this.fetching = false;
    },
  },
};
