<template>
  <div class="ui-form-multiselect">
    <label v-tooltip="tooltip" class="ui-form-multiselect__label" v-text="`${$translate(label)}: `"></label>
    <ui-multiselect v-bind="uiMultiselectProps" v-on="uiMultiselectListeners" />
  </div>
</template>
<script>
import { UiMultiselect } from 'redge-media-web-ui';
import { AppEvent } from '@/enums';
import { DictionaryFetchMixin } from '@/mixin';
import { filterObjectEntries, filterOutObjectEntries } from '@/helper';

export default {
  components: {
    UiMultiselect,
  },
  mixins: [DictionaryFetchMixin],
  inheritAttrs: false,
  props: {
    ...filterOutObjectEntries(UiMultiselect.props, ['records', 'fetching']),
    value: [Object, String, Number],
    valueAsObject: Boolean,
    label: {
      type: String,
      required: true,
    },
    tooltip: [String, Object],
    maxResults: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    uiMultiselectProps() {
      const value = this.valueAsObject
        ? this.value
        : this.records.find((record) => JSON.stringify(record[this.field]) === JSON.stringify(this.value));

      return {
        ...this.$attrs,
        ...filterObjectEntries(this.$props, Object.keys(UiMultiselect.props)),
        fetching: this.fetching,
        records: this.records,
        searchable: true,
        internalSearch: true,
        value,
      };
    },
    uiMultiselectListeners() {
      return {
        ...this.$listeners,
        input: (value) => this.$emit(AppEvent.INPUT, this.valueAsObject ? value : value?.[this.field], this.value),
      };
    },
  },
  created() {
    this.fetch();
  },
};
</script>
