<template>
  <div v-if="model" class="details-form">
    <ui-product-file-medium
      :product="model"
      :videos="model.trailers"
      :video-type="VideoType.TRAILER"
      :title="$translate('VOD_FILES_TRAILERS')"
      :allowed="isAllowed"
      @change="load"
    />

    <ui-product-file-images v-bind="artworkImagesProps" @change="load" />

    <ui-product-file-images v-bind="titleTreatmentImagesProps" @change="load" />
  </div>
</template>
<script>
import Urls from '@/application/urls';
import http from '@/lib/http';
import {
  AppEvent, ImageLabel, ImageType, RoleName, VideoType,
} from '@/enums';
import { RoleMixin } from '@/mixin';
import UiProductFileImages from '@/components/product-file-images/index.vue';
import UiProductFileMedium from '@/components/product-file-medium/index.vue';
import { titleTreatmentImagesProps } from '@/components/vod-files/images';

export default {
  components: {
    UiProductFileImages,
    UiProductFileMedium,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      model: undefined,
      VideoType,
    };
  },
  computed: {
    artworkImagesProps() {
      return {
        title: 'COMMON_FILES',
        isAllowed: this.isAllowed,
        product: this.model,
        type: ImageType.ARTWORK,
        images: this.model.images,
        settings: [
          {
            label: ImageLabel.ASPECT_16X9,
            scaler: { main: { dstw: 1920, dsth: 1080 } },
          },
          {
            label: ImageLabel.ASPECT_1X1,
            scaler: { main: { dstw: 960, dsth: 960 } },
          },
          {
            label: ImageLabel.ASPECT_3X4,
            scaler: { main: { dstw: 746, dsth: 560 } },
          },
        ],
      };
    },
    titleTreatmentImagesProps() {
      return titleTreatmentImagesProps(this.isAllowed, this.model, this.model.titleTreatmentImages);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.model = await http.get(Urls.BANNERS_ID(this.id)).then(({ data }) => data);
      this.$emit(AppEvent.MODEL, this.model);
    },
  },
};
</script>
