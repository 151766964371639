<template>
  <ui-modal class="ui-modal-activation-errors" header-label="ACTIVATION_ERRORS_TITLE" @close="close">
    <p class="ui-modal-activation-errors__title" v-text="$translate('ACTIVATION_ERRORS_LIST_LABEL')"></p>

    <ul class="ui-modal-activation-errors__list">
      <li v-for="{ code } in errors" :key="code" v-text="$translate(code)"></li>
    </ul>

    <div class="ui-modal__actions">
      <button
        tabindex="0"
        type="button"
        class="button"
        @click.prevent="close"
        v-text="$translate('COMMON_CLOSE')"
      ></button>
    </div>
  </ui-modal>
</template>
<script>
import { AppEvent } from '@/enums';

export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.$modal.close();
  },
  methods: {
    close() {
      this.$emit(AppEvent.CLOSE);
    },
  },
};
</script>
