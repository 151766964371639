<template>
  <vod-files :product-url="productUrl" v-on="$listeners" />
</template>
<script>
import Urls from '@/application/urls';
import VodFiles from '@/components/vod-files/index.vue';

export default {
  components: {
    VodFiles,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      productUrl: Urls.PRODUCTS_VODS_ID_PAGE(this.id, this.page),
    };
  },
};
</script>
