<template>
  <v-list-wrapper :table-definition="tableDefinition">
    <template #product="{ record }">
      <div class="ui-table-promotion-product">
        <span
          v-if="record.displayAvailable"
          v-tooltip="$translate('HELP_PROMOTION_DISCOUNT_FREE_PRODUCT')"
          class="icon-warning ui-table-promotion-product__icon"
        >
        </span>
        <ui-table-detail-link-product :product="record" :class="{ 'line-through': record.displayAvailable }" />
      </div>
    </template>
  </v-list-wrapper>
</template>
<script>
import { UiFormCheckbox } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import {
  Constants, ItemType, QueryParam, RoleName,
} from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { UiTableDetailLinkProduct } from '@/components/ui-table';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';
import * as PaymentFields from '@/modules/payment/view/fields';

export default ListView.extend({
  components: {
    UiTableDetailLinkProduct,
  },
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    const url = Urls.PROMOTIONS_ID_PRODUCTS(this.id);

    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: () => url,
      saveModelModalTitleFunction: () => 'ADD_PRODUCT_TO_PROMOTION',
      saveModelFormElementsFunction: (model) => [
        [
          {
            field: '_isSingle',
            component: UiFormCheckbox,
            componentOptions: {
              label: 'PROMOTION_MULTIPLE_PRODUCTS',
              on: {
                input: (value) => {
                  model._isSingle = value;
                  model._selectedProduct = undefined;
                  model.productId = undefined;
                  model.maxPrice = undefined;
                  model.minPrice = undefined;
                  model.minRating = undefined;
                  model.maxRating = undefined;
                },
              },
            },
          },
          Fields.ITEM_TYPE(model, Constants.ALL_PRODUCT_TYPES),
          Fields.SELECTED_SERIES_PARENT(model, ![ItemType.SEASON, ItemType.EPISODE].includes(model.itemType)),
          Fields.SELECTED_SEASON_PARENT(model, model.itemType !== ItemType.EPISODE),
          Fields.SELECTED_PRODUCT_WITH_PARENT(model, true),
          PaymentFields.MIN_PRICE(!model._isSingle),
          PaymentFields.MAX_PRICE(!model._isSingle),
          PaymentFields.MIN_RATING(!model._isSingle),
          PaymentFields.MAX_RATING(!model._isSingle),
          { ...Fields.PROVIDER, isHidden: !model._isSingle },
        ],
      ],
      saveModelDefaultValues: {
        itemType: ItemType.VOD,
      },
      paramsConfig: {
        sort: QueryParam.ID,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'product',
            minWidth: 200,
            headingLabel: 'COMMON_PRODUCT',
          },
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
    isSaveModelButtonVisible() {
      return this.model?.staticType && this.isAllowed;
    },
  },
});
</script>
