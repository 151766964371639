<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import * as Filters from '@/components/filter/filters';
import SubscriptionsList from '@/components/subscriptions-list/index.vue';
import * as PaymentFilters from '@/modules/payment/view/filters';

export default SubscriptionsList.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_ID_SUBSCRIPTIONS(this.id),
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
      filters: [
        Filters.CREATE_SINCE(),
        Filters.CREATE_TILL(),
        PaymentFilters.CURRENT_ACTIVE,
        PaymentFilters.PRODUCTS,
        PaymentFilters.SUBSCRIPTION_STATUS,
        PaymentFilters.PAYMENT_CHANNEL,
        PaymentFilters.PRICE_GREATER,
        PaymentFilters.PRICE_LESS,
      ],
      isSubscriberView: true,
    };
  },
});
</script>
