<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import * as Filters from '@/components/filter/filters';
import ListView from '@/components/list-view/list-view.vue';
import * as Fields from '@/components/ui-form/fields';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import {
  ItemType, QueryParam, RoleName, VideoType,
} from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import * as ProductColumns from '@/modules/product/view/columns';
import * as ProductFilters from '@/modules/product/view/filters';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_LIVES,
      removeModelUrlFunction: ({ id }) => Urls.PRODUCTS_LIVES_ID(id),
      saveModelUrlFunction: () => Urls.PRODUCTS_LIVES,
      saveModelModalTitleFunction: () => 'ADD_CHANNEL',
      saveModelFormElementsFunction: () => [
        [Fields.TITLE, Fields.MAIN_CATEGORY(ItemType.LIVE), Fields.RANK, Fields.CREATE_SINCE(), Fields.CREATE_TILL()],
      ],
      saveModelDefaultValues: {
        type: ItemType.LIVE,
        type_: ItemType.LIVE,
      },
      exportUrl: Urls.PRODUCTS_LIVES_EXPORT,
      filters: [
        Filters.PRODUCT_ID,
        Filters.PLATFORMS,
        Filters.ACTIVE,
        ProductFilters.HAS_DESCRIPTION,
        ProductFilters.AD_BREAKS,
        ProductFilters.MAIN_CATEGORIES(ItemType.LIVE),
        ProductFilters.CATEGORIES(ItemType.LIVE),
        ProductFilters.CURRENCIES,
        ProductFilters.FREE,
        ProductFilters.UHD,
        ProductFilters.AUDIO,
        ProductFilters.MOVIE,
        ProductFilters.LOGIN_REQUIRED,
        Filters.EXTERNAL_UID,
        ProductFilters.ENCRYPTED,
        ProductFilters.CHROMECAST,
        ProductFilters.TIMESHIFT,
        ProductFilters.CATCHUP,
        ProductFilters.NPRV,
        ProductFilters.STARTOVER,
        ProductFilters.STRIPE,
      ],
      paramsConfig: {
        sort: QueryParam.RANK,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.POSTER((record) => ({ record, videoType: VideoType.LIVE })),
          ProductColumns.TITLE,
          Columns.RANK,
          Columns.PLATFORMS,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
