import * as views from '@/modules';

export default [
  {
    path: '/gui/payment/payments',
    name: 'payments',
    component: views.PaymentPayments,
    meta: {
      titleLabels: ['COMMON_PAYMENTS'],
    },
  },
  {
    path: '/gui/payment/payments/:id/:page',
    name: 'payments.detail',
    component: views.PaymentPaymentsDetail,
    meta: {
      titleLabels: ['COMMON_PAYMENTS'],
    },
  },
  {
    path: '/gui/payment/subscriptions',
    name: 'subscriptions',
    component: views.PaymentSubscriptions,
    meta: {
      titleLabels: ['COMMON_SUBSCRIPTIONS'],
    },
  },
  {
    path: '/gui/payment/subscriptions/:id/:page',
    name: 'subscriptions.detail',
    component: views.PaymentSubscriptionsDetail,
    meta: {
      titleLabels: ['COMMON_SUBSCRIPTIONS'],
    },
  },
  {
    path: '/gui/payment/wallets',
    name: 'wallet',
    component: views.PaymentWallets,
    meta: {
      titleLabels: ['COMMON_WALLET'],
    },
  },
  {
    path: '/gui/payment/promotions',
    name: 'promotions',
    component: views.PaymentPromotions,
    meta: {
      titleLabels: ['MENU_SALE_PROMOTIONS'],
    },
  },
  {
    path: '/gui/payment/promotions/:id/:page',
    name: 'promotions.detail',
    component: views.PaymentPromotionsDetail,
    meta: {
      titleLabels: ['MENU_SALE_PROMOTIONS'],
    },
  },
  {
    path: '/gui/payment/audits',
    name: 'salesaudit',
    component: views.PaymentAudits,
    meta: {
      titleLabels: ['COMMON_EVENTS'],
    },
  },
];
