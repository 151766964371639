<script>
import http from '@/lib/http';
import Urls from '@/application/urls';
import Player from '@/components/player/player.vue';
import { getTenantUid } from '@/helper';

export default {
  methods: {
    play(id, type, videoType) {
      const tenantUid = getTenantUid();
      const playlistUrl = Urls.ITEMS_ID_TYPE_PLAYLISTS(id, type);

      this.openPlayer(id, videoType, {
        playlist: `${http.defaults.baseURL}${playlistUrl}?videoType=${videoType}&tenantUid=${tenantUid}`,
      });
    },
    async playSource(id, url, format, videoType) {
      const cert = await http
        .get(Urls.ITEMS_DRM_FAIRPLAY_CERTIFICATE)
        .then(({ data }) => data)
        .catch(() => {});

      const getUrlForDrmType = (drmType) => {
        const tenantUid = getTenantUid();
        const drmUrl = `${Urls.ITEMS_ID_DRM_TYPE(id, drmType)}?type=${videoType}&tenantUid=${tenantUid}`;
        return `${http.defaults.baseURL}${drmUrl}`;
      };

      this.openPlayer(id, videoType, {
        file: {
          [format]: url,
        },
        drm: {
          playready: getUrlForDrmType('playready'),
          widevine: getUrlForDrmType('widevine'),
          fairplay: {
            src: getUrlForDrmType('fairplay'),
            cert,
          },
        },
      });
    },
    async openPlayer(id, type, customConfiguration) {
      const {
        data: { stripe, timeshift },
      } = await http.get(Urls.ITEMS_ID_PLAYER_CONFIGURATION(id), { params: { type } });

      const config = {
        width: '100%',
        height: '100%',
        volumeType: 'horizontal',
        autoplayBlockedRule: 'mute',
        ...(stripe ? { stripe } : {}),
        ...(timeshift ? { timeShift: timeshift } : {}),
        ...customConfiguration,
      };

      this.$modal.create(Player, { config });
    },
  },
};
</script>
