<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import { PersonType, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import UiFormDictionarySelect from '@/components/ui-form/ui-form-dictionary-select.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';
import * as Filters from '@/components/filter/filters';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    personTypeUrl: {
      type: String,
      required: true,
    },
    personUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: this.personUrl,
      removeModelUrlFunction: ({ id }) => `${this.personUrl}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${this.personUrl}/${id}` : this.personUrl),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_PERSON' : 'ADD_PERSON'),
      saveModelFormElementsFunction: (model) => {
        if (model.name && !model._nameObject) {
          model._nameObject = { name: model.name };
        }

        return [
          [
            {
              field: 'type',
              isMandatory: true,
              component: UiFormDictionarySelect,
              componentOptions: {
                label: 'COMMON_TYPE',
                dictUrl: this.personTypeUrl,
                on: {
                  input: () => {
                    if (model.type !== PersonType.ACTOR) {
                      model.role = '';
                    }
                  },
                },
              },
            },
            {
              field: '_nameObject',
              fieldForErrorCode: 'name',
              isMandatory: true,
              component: UiFormAutocompleteInline,
              componentOptions: {
                label: 'COMMON_NAME',
                dictType: 'PERSON',
                allowEmpty: true,
                allowNewValue: true,
                on: {
                  input: () => {
                    model.name = model._nameObject?.name;
                  },
                },
              },
            },
            { ...Fields.textInputField('role', 'COMMON_ROLE'), isHidden: model.type !== PersonType.ACTOR },
          ],
        ];
      },
      filters: [Filters.autocomplete('types', { label: 'COMMON_TYPE', dictUrl: this.personTypeUrl })],
      paramsConfig: {
        sort: QueryParam.PERSON_NAME,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'person.name',
            minWidth: 240,
            isSortable: true,
            headingLabel: 'COMMON_NAME',
            dataMapper: ({ name }) => name,
          },
          {
            field: 'type',
            width: 200,
            isSortable: true,
            headingLabel: 'COMMON_TYPE',
          },
          {
            field: 'role',
            minWidth: 240,
            headingLabel: 'COMMON_ROLE',
          },
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
