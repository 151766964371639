<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import AuditDetailList from '@/modules/administration/view/audit/detail/index.vue';

export default AuditDetailList.extend({
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.ITEMS_ID_AUDITS(this.id),
      exportUrl: Urls.ITEMS_ID_AUDITS_EXPORT(this.id),
    };
  },
});
</script>
