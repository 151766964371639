import Vue from 'vue';
import VueRouter from 'vue-router';
import { UiModalPopup } from 'redge-media-web-ui';
import { AppEvent, Constants } from '@/enums';
import EventBus from '@/application/event-bus';
import operator from '@/application/operator';
import info from '@/application/info';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
});

const APP_TITLE = 'Redge Media Portal';

router.beforeEach((to, from, next) => {
  if (router.app.$modal) {
    router.app.$modal.close();
  }

  if (!to.name) {
    return next({ name: 'home' });
  }

  router.app.$nextTick(() => {
    document.title = [APP_TITLE, ...(to.meta.titleLabels || [])].map(router.app.$translate).join(' / ');
  });

  return next();
});

const AUTHENTICATION_REQUIRED_MODAL_CLASS = 'ui-modal-popup--authentication-required';

EventBus.$on(Constants.AUTHENTICATION_REQUIRED, () => {
  const isModalAlreadyVisible = document.getElementsByClassName(AUTHENTICATION_REQUIRED_MODAL_CLASS).length;

  if (isModalAlreadyVisible) {
    return;
  }

  const redirect = () => {
    operator.isLogged = false;
    info.fetchAuthInfo();
    router.push({
      name: 'login',
      query: { ...router.currentRoute.query },
      params: {
        next: router.currentRoute?.fullPath,
      },
    });
  };

  if (operator.isLogged) {
    router.app.$modal
      .create(UiModalPopup, {
        hideAfter: 10000,
        modalModifier: `ui-modal-popup--error ${AUTHENTICATION_REQUIRED_MODAL_CLASS}`,
        overlay: true,
        text: router.app.$translate('COMMON_OPERATOR_LOGOUT_INFO'),
      })
      .$on(AppEvent.CLOSE, () => redirect());
  } else if (router.currentRoute.name !== 'login') {
    redirect();
  }
});

export default router;
