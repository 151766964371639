<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition">
    <template #internal-id="{ record }">
      <ui-table-detail-link
        :id="record.id"
        :text="`${$translate('COMMON_PAYMENT')}: ${record.id}`"
        name="payments.detail"
      />
      <ui-table-detail-link
        v-if="record.subscription"
        :id="record.subscription.id"
        :text="`${$translate('PAYMENT_SUBSCRIPTION')}: ${record.subscription.id}`"
        name="subscriptions.detail"
      />
      <ui-table-detail-link
        v-if="record.promotion"
        :id="record.promotion.id"
        :text="`${$translate('COMMON_PROMOTION')}: ${record.promotion.id}`"
        :tooltip="record.promotion.name"
        name="promotions.detail"
      />
    </template>
    <template #external-id="{ record }">
      <div v-if="record.system" v-text="`${$translate('PAYMENT_SYSTEM')}: ${record.system}`"></div>
      <div v-if="record.externalUid" v-text="`${$translate('COMMON_UID')}: ${record.externalUid}`"></div>
    </template>
    <template #subscriber="{ record }">
      <ui-table-detail-link-subscriber :subscriber="record.subscriber" page="payments" />
      <div v-text="ipCountryMapper(record)"></div>
    </template>
  </v-list-wrapper>
</template>
<script>
import dayjs from 'dayjs';
import { DateFormat } from 'redge-media-web-utils';
import { QueryParam, RoleName } from '@/enums';
import Urls from '@/application/urls';
import ListView from '@/components/list-view/list-view.vue';
import * as PaymentFilters from '@/modules/payment/view/filters';
import * as Filters from '@/components/filter/filters';
import { UiTableDetailLink, UiTableDetailLinkSubscriber } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import * as PaymentColumns from '@/modules/payment/view/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';

export default ListView.extend({
  components: {
    UiTableDetailLink,
    UiTableDetailLinkSubscriber,
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.PAYMENTS,
      exportUrl: Urls.PAYMENTS_EXPORT,
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
        since: dayjs().subtract(28, 'day').startOf('day').format(DateFormat.DATETIME_FORMAT),
        till: dayjs().add(1, 'day').endOf('day').format(DateFormat.DATETIME_FORMAT),
      },
      filters: [
        Filters.SINCE_MANDATORY,
        Filters.TILL_MANDATORY,
        PaymentFilters.CURRENT_ACTIVE,
        Filters.SUBSCRIBER,
        PaymentFilters.PRODUCTS,
        PaymentFilters.PAYMENT_STATUS,
        PaymentFilters.PAYMENT_CHANNEL,
        PaymentFilters.PAYMENT_SYSTEM,
        Filters.EXTERNAL_UID,
        PaymentFilters.PRICE_GREATER,
        PaymentFilters.PRICE_LESS,
        PaymentFilters.PAYMENT_CURRENCY,
        PaymentFilters.PROMOTION_ID,
        PaymentFilters.SUBSCRIPTION_ID,
      ],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'internal-id',
            width: 140,
            headingLabel: 'COMMON_INTERNAL_ID',
          },
          {
            field: 'external-id',
            width: 140,
            headingLabel: 'COMMON_EXTERNAL_ID',
          },
          {
            field: 'subscriber',
            minWidth: 160,
            isHidden: this.isSubscriberView,
            headingLabel: 'COMMON_SUBSCRIBER',
          },
          PaymentColumns.PAYMENT_PRODUCT,
          PaymentColumns.PAYMENT_PRICE,
          PaymentColumns.PAYMENT_CURRENCY,
          PaymentColumns.PAYMENT_TYPE,
          { ...PaymentColumns.PAYMENT_IP_COUNTRY, isHidden: !this.isSubscriberView },
          Columns.SINCE,
          Columns.TILL,
          Columns.CREATED_AT,
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
  methods: {
    ipCountryMapper: PaymentColumns.PAYMENT_IP_COUNTRY.dataMapper,
  },
});
</script>
