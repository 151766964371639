<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import { UiFormCheckbox } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as PaymentFields from '@/modules/payment/view/fields';
import { commonTooltip } from '@/components/ui-form/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        modelUrl: Urls.PROMOTIONS_ID(this.id),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.NAME,
            Fields.TYPE_DISABLED,
            {
              field: 'staticType',
              component: UiFormCheckbox,
              componentOptions: {
                label: 'STATIC_TYPE',
                tooltip: commonTooltip('HELP_STATIC_TYPE'),
                disabled: true,
              },
            },
            Fields.textInputField('description', 'COMMON_DESCRIPTION'),
            PaymentFields.PROMOTION_FREE_ACCESS_DURATION(model),
            Fields.CREATE_SINCE(),
            Fields.CREATE_TILL(),
            Fields.CREATED_AT,
            Fields.MODIFIED_AT,
          ],
        ],
      };
    },
  },
};
</script>
