<template>
  <div class="ui-filter-item" :class="{ 'ui-filter-item--modified': modifiedNotSaved }">
    <label v-tooltip.icon="labelTooltipText" v-text="`${$translate(label)}:`"></label>
    <div class="flex">
      <input
        ref="input"
        v-model="keyword"
        :type="type"
        class="ui-input"
        @keydown.enter="select(keyword)"
      >
      <button class="button ml-1" @click="select(keyword)" v-text="$translate('COMMON_ADD')"></button>
    </div>
    <ul class="ui-filter-item__selected-values">
      <ui-filter-item-selected-value
        v-for="value in values"
        :key="value"
        :value="value"
        @remove="remove"
      />
    </ul>
  </div>
</template>
<script>
import { AppEvent } from '@/enums';
import UiFilterItemSelectedValue from './ui-filter-item-selected-value.vue';

export default {
  components: {
    UiFilterItemSelectedValue,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      default: () => [],
    },
    modifiedNotSaved: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    single: {
      type: Boolean,
      default: false,
    },
    exampleValues: {
      type: String,
    },
  },
  data() {
    return {
      keyword: '',
    };
  },
  computed: {
    labelTooltipText() {
      return this.exampleValues ? `${this.$translate('HELP_EXAMPLE_SHORTCUT')} ${this.exampleValues}` : '';
    },
  },
  watch: {
    keyword() {
      this.$emit(AppEvent.CHANGE_MODIFIED_NOT_SAVED, !!this.keyword);
    },
  },
  methods: {
    select(value) {
      if (!value) {
        return;
      }

      if (this.single) {
        this.$emit(AppEvent.CHANGE, [value]);
      } else if (!this.values.includes(value)) {
        this.$emit(AppEvent.CHANGE, [...this.values, value]);
      }

      this.keyword = '';
    },
    remove(value) {
      this.$emit(
        AppEvent.CHANGE,
        this.values.filter((v) => v !== value),
      );
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
