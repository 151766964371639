<template>
  <form class="translations-list">
    <v-list-wrapper :table-definition="tableDefinition">
      <template #key="{ record, index }">
        <input
          v-model="record.key"
          :disabled="!isAllowed"
          :class="{
            'input-modified-color': isKeyDifferent(record, index),
            'input-invalid-color': isKeyEmpty(record),
          }"
          class="ui-input"
          type="text"
        >
      </template>
      <template v-for="language in languages" #[language]="{ record, index }">
        <input
          :key="language"
          v-model="record.values[language]"
          :disabled="!isAllowed"
          :class="{ 'input-modified-color': isValueDifferent(record, index, language) }"
          class="ui-input"
          type="text"
        >
      </template>
    </v-list-wrapper>
    <div class="translations-list__button-wrapper">
      <button
        :disabled="changedRecords.length === 0"
        tabindex="0"
        type="submit"
        class="button button-info"
        @click.prevent="save"
        v-text="$translate('COMMON_SAVE')"
      ></button>
    </div>
  </form>
</template>
<script>
import { UiFormCheckbox, UiModalConfirm, UiModalPopup } from 'redge-media-web-ui';
import i18n from '@/application/i18n';
import { AppEvent, QueryParam, RoleName } from '@/enums';
import http from '@/lib/http';
import { RoleMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';
import UiFormFileInput from '@/components/ui-form/ui-form-file-input.vue';

const copyRecords = (records) => [...records].map(({ id, key, values }) => ({ id, key, values: { ...values } }));

export default ListView.extend({
  mixins: [RoleMixin, SaveModelMixin],
  data() {
    return {
      importUrl: undefined,
      writeRole: RoleName.CMS_WRITE,
      paramsConfig: {
        sort: QueryParam.KEY,
        order: QueryParam.ASCENDING,
      },
      languages: i18n.languages.map((language) => language.toUpperCase()),
      recordsCopy: [],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'key',
            minWidth: 250,
            isSortable: true,
            headingLabel: 'COMMON_KEY',
          },
          ...this.languages.map((language) => ({
            field: language,
            minWidth: 250,
            headingLabel: language,
          })),
          Columns.ACTIONS(this.isAllowed, (record, index) => ({
            buttons: [Columns.BUTTON_REMOVE(() => this.removeModel(index))],
          })),
        ],
        records: this.recordsCopy,
      };
    },
    changedRecords() {
      return this.recordsCopy.filter((recordCopy, index) => {
        const valuesChanged = this.languages.some((language) => this.isValueDifferent(recordCopy, index, language));
        return this.isKeyDifferent(recordCopy, index) || valuesChanged;
      });
    },
  },
  watch: {
    records(records) {
      this.recordsCopy = copyRecords(records);
    },
  },
  created() {
    if (this.importUrl && this.isAllowed) {
      this.buttons.unshift({
        icon: 'icon-upload',
        tooltip: 'HELP_IMPORT',
        onClick: this.importTranslations,
      });
    }
  },
  methods: {
    isKeyDifferent(recordCopy, index) {
      return this.records[index].key !== recordCopy.key;
    },
    isKeyEmpty(record) {
      return record.key === '';
    },
    isValueDifferent(recordCopy, index, language) {
      return this.records[index].values[language] !== recordCopy.values[language];
    },
    createModel() {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: () => [
          [Fields.KEY, ...this.languages.map((language) => Fields.textInputField(language, language))],
        ],
        modalTitleFunction: () => 'COMMON_ADD',
      });

      const saveModelPromiseFunction = (model) => {
        const translations = this.languages.map((language) => ({
          key: model.key,
          value: model[language] || '',
          language,
        }));

        return http.post(this.recordsUrl, translations);
      };

      this.openSaveModelModal(modal, saveModelPromiseFunction);
    },
    importTranslations() {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: (model) => [
          [
            {
              field: 'file',
              isMandatory: true,
              component: UiFormFileInput,
              componentOptions: {
                label: 'COMMON_FILE',
                type: 'file',
                on: {
                  change: (e) => {
                    model.file = e.target.files[0];
                  },
                },
              },
            },
            {
              field: 'overrideKeys',
              component: UiFormCheckbox,
              componentOptions: {
                label: 'TRANSLATION_IMPORT_OVERRIDE_KEYS',
                tooltip: Fields.commonTooltip('HELP_TRANSLATION_IMPORT_OVERRIDE_KEYS'),
                on: {
                  input: () => {
                    if (model.overrideKeys) {
                      model.overrideAll = false;
                    }
                  },
                },
              },
            },
            {
              field: 'overrideAll',
              component: UiFormCheckbox,
              componentOptions: {
                label: 'TRANSLATION_IMPORT_OVERRIDE_ALL',
                tooltip: Fields.commonTooltip('HELP_TRANSLATION_IMPORT_OVERRIDE_ALL'),
                on: {
                  input: () => {
                    if (model.overrideAll) {
                      model.overrideKeys = false;
                    }
                  },
                },
              },
            },
          ],
        ],
        modalTitleFunction: () => 'COMMON_FILE_IMPORT',
        defaultValues: {
          file: '',
          overrideKeys: false,
          overrideAll: false,
        },
      });
      const saveModelPromiseFunction = async (model) => {
        if (model.file) {
          return http.post(this.importUrl, model.file, {
            headers: {
              'Content-Type': 'application/octet-stream',
            },
            params: {
              fileName: model.file.name,
              overrideAll: model.overrideAll,
              overrideKeys: model.overrideKeys,
            },
          });
        }

        throw { code: 'COMMON_IMPORT_FILE_NOT_SELECTED' };
      };

      this.openSaveModelModal(modal, saveModelPromiseFunction);
    },
    removeModel(index) {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_REMOVE'),
        confirmationText: this.$translate('COMMON_REMOVE_CONFIRMATION'),
      });
      modal.$on(AppEvent.SUCCESS, async () => {
        const { id } = this.records[index];
        try {
          await http.delete(`${this.recordsUrl}/${id}`);
          await this.load();
          modal.close();
        } catch (error) {
          modal.close();
          throw error;
        }
      });
    },
    async save() {
      if (this.changedRecords.some(this.isKeyEmpty)) {
        throw { code: 'TRANSLATION_KEY_BLANK' };
      } else {
        const mapRecord = (record) => this.languages.map((language) => ({
          id: record.id,
          key: record.key,
          value: record.values[language],
          language,
        }));

        try {
          this.$loader.show('SAVING_DATA');
          await http.put(this.recordsUrl, this.changedRecords.flatMap(mapRecord));
          this.$loader.hide();
          this.$modal.create(UiModalPopup, {
            text: this.$translate('COMMON_SAVE_SUCCESS'),
          });
          this.records = copyRecords(this.recordsCopy);
        } catch (error) {
          this.$loader.hide();
          throw error;
        }
      }
    },
  },
});
</script>
