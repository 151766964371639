import { ACTIONS, BUTTON_EDIT, BUTTON_REMOVE } from '@/components/ui-table/columns';

export const ACTIONS_AGREEMENT_VERSIONS = (isAllowed, editItemMethod, removeItemMethod) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: [
      ...(record.active ? [] : [BUTTON_EDIT(() => editItemMethod(record))]),
      BUTTON_REMOVE(() => removeItemMethod(record)),
    ],
  });

  return ACTIONS(isAllowed, dataComponentPropsMapper);
};
