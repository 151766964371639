import { UiFormTextInput } from 'redge-media-web-ui';
import { commonTooltip, textInputField } from '@/components/ui-form/fields';

export const EMAIL = (disabled = false) => ({
  field: 'email',
  isMandatory: true,
  component: UiFormTextInput,
  componentOptions: {
    label: 'COMMON_EMAIL',
    type: 'email',
    disabled,
  },
});

export const FIRST_NAME = textInputField('firstName', 'OPERATOR_FIRST_NAME');

export const LAST_NAME = textInputField('lastName', 'OPERATOR_LAST_NAME');

export const PASSWORD = (model, tooltip) => ({
  field: 'password',
  component: UiFormTextInput,
  componentOptions: {
    label: 'OPERATOR_PASSWORD',
    type: model._passwordInputType,
    autocomplete: 'new-password',
    tooltip: tooltip ? commonTooltip(tooltip) : '',
    iconClass: model._passwordInputType === 'password' ? 'icon-eye' : 'icon-eye-crossed',
    on: {
      'icon-click': () => {
        model._passwordInputType = model._passwordInputType === 'password' ? 'text' : 'password';
      },
    },
  },
});
