<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
          v-text="model.name"
        ></h1>
        <ui-switch
          v-if="model"
          v-tooltip="activateModelButtonText"
          :disabled="!isAllowed"
          :value="isModelActive"
          class="details-container__header-element"
          @input="activateModel('CATEGORY')"
        />
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import { Page, RoleName } from '@/enums';
import { ActivateModelMixin, RoleMixin } from '@/mixin';
import UiTabs from '@/components/ui-tabs/index.vue';
import Audits from './audits.vue';
import Detail from './detail.vue';
import Files from './files.vue';

export default {
  components: {
    UiSwitch,
    UiTabs,
    Audits,
    Detail,
    Files,
  },
  mixins: [ActivateModelMixin, RoleMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      tabs: [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'COMMON_FILES', page: Page.FILES },
        { label: 'COMMON_EVENTS', page: Page.AUDITS },
      ],
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
  },
};
</script>
