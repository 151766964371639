<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import operator from '@/application/operator';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import * as Filters from '@/components/filter/filters';
import ListView from '@/components/list-view/list-view.vue';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Columns from '@/components/ui-table/columns';
import * as AdministrationColumns from '@/modules/administration/view/columns';
import * as Fields from '@/components/ui-form/fields';
import * as AdministrationFields from '@/modules/administration/view/fields';
import { UiTableDetailLinkResourceOperator } from '@/components/ui-table';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.ADMIN,
      recordsUrl: Urls.OPERATORS,
      removeModelUrlFunction: ({ id }) => Urls.OPERATORS_ID(id),
      saveModelUrlFunction: () => Urls.OPERATORS,
      saveModelModalTitleFunction: () => 'ADD_OPERATOR',
      saveModelFormElementsFunction: (model) => [
        [
          AdministrationFields.EMAIL(),
          AdministrationFields.FIRST_NAME,
          AdministrationFields.LAST_NAME,
          AdministrationFields.PASSWORD(model, 'OPERATOR_NO_PASSWORD_INFO'),
          Fields.ROLES(Urls.OPERATORS_ROLES),
        ],
      ],
      saveModelDefaultValues: {
        _passwordInputType: 'password',
      },
      exportUrl: Urls.OPERATORS_EXPORT,
      filters: [Filters.ACTIVE, Filters.autocomplete('roles', { label: 'COMMON_ROLE', dictUrl: Urls.OPERATORS_ROLES })],
      paramsConfig: {
        sort: QueryParam.EMAIL,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'email',
            minWidth: 240,
            isSortable: true,
            headingLabel: 'COMMON_EMAIL',
            dataComponent: UiTableDetailLinkResourceOperator,
            dataComponentPropsMapper: (record) => ({ operator: record }),
          },
          {
            field: 'firstName',
            width: 150,
            isSortable: true,
            headingLabel: 'OPERATOR_FIRST_NAME',
          },
          {
            field: 'lastName',
            width: 150,
            isSortable: true,
            headingLabel: 'OPERATOR_LAST_NAME',
          },
          Columns.ROLES,
          Columns.CREATED_AT,
          Columns.LAST_LOGIN_DATE,
          AdministrationColumns.ACTIONS_OPERATOR(this.removeModel, operator.id),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
