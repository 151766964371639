<template>
  <div class="ui-loader" :class="{ 'ui-loader--overlay': overlay }">
    <p class="ui-loader__text" v-text="$translate(text)"></p>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: 'LOADING_INFO',
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
