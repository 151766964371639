<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import Urls from '@/application/urls';
import { ItemType, RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as ProductFields from '@/modules/product/view/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        isSaveButtonSticky: true,
        modelUrl: Urls.PRODUCTS_LIVES_PROGRAMMES_ID_PAGE(this.id, this.page),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.EXTERNAL_UID,
            Fields.textInputField('programRecordingId', 'PROGRAMME_PROGRAM_RECORDING_ID', false, true),
            Fields.textInputField('contentId', 'PROGRAMME_CONTENT_ID'),
            Fields.TITLE,
            ProductFields.ADVISORS,
            Fields.numberInputField('starRating', 'PROGRAMME_STAR_RATING'),
            Fields.CREATE_SINCE(),
            Fields.CREATE_TILL_MANDATORY(),
            ProductFields.RATING,
            ProductFields.RATING_EMBEDDED(model),
            ProductFields.UHD,
            Fields.checkboxField('recommended', 'PROGRAMME_RECOMMENDED'),
            ProductFields.SHOW_RECOMMENDATIONS,
            Fields.checkboxField('liveBroadcast', 'PROGRAMME_LIVE_BROADCAST'),
            ProductFields.CATEGORIES(ItemType.LIVE),
            ProductFields.GENRES,
            ProductFields.TAGS,
            Fields.CUSTOM_BADGE,
            Fields.PLATFORMS,
            ProductFields.CHROMECAST_PLATFORMS,
            Fields.CREATED_AT,
            Fields.MODIFIED_AT,
            Fields.textHeader('COMMON_ASSOCIATION'),
            ProductFields.SEASON,
            ProductFields.EPISODE_NUMBER,
            Fields.textHeader('COMMON_CATCHUP'),
            { ...Fields.PLATFORMS, field: 'catchupServicePlatforms' },
            Fields.textHeader('LIVE_TIMESHIFT'),
            { ...Fields.PLATFORMS, field: 'timeshiftServicePlatforms' },
            Fields.textHeader('LIVE_STARTOVER'),
            { ...Fields.PLATFORMS, field: 'startoverServicePlatforms' },
            Fields.textHeader('COMMON_NPVR'),
            { ...Fields.PLATFORMS, field: 'npvrServicePlatforms' },
          ],
          [
            Fields.CREATE_HEADER_CHAT(this.id, 'COMMON_CHATS'),
            ProductFields.CREATE_CHAT_ENABLED('COMMON_ENABLED_SINGULAR'),
            Fields.HEADER_LEAD,
            Fields.LEAD,
            Fields.HEADER_DESCRIPTION,
            Fields.DESCRIPTION,
          ],
        ],
      };
    },
  },
};
</script>
