<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
          v-text="model.email"
        ></h1>
        <div class="details-container__header-icons">
          <div class="details-container__header-elements details-container__header-elements--left">
            <ui-external-application-icons :model="model" :is-model-active="isModelActive" />
          </div>
          <div class="details-container__header-elements">
            <ui-switch
              v-if="model && !model.erasedAt"
              v-tooltip="activateModelButtonText"
              :disabled="!isAllowed"
              :value="isModelActive"
              class="details-container__header-element"
              @input="activateModel('SUBSCRIBER')"
            />
          </div>
        </div>
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import { isSessionDbAvailable } from '@/helper';
import { Page, RoleName } from '@/enums';
import { ActivateModelMixin, RoleMixin } from '@/mixin';
import UiTabs from '@/components/ui-tabs/index.vue';
import UiExternalApplicationIcons from '@/components/ui-external-application-icons/index.vue';
import Detail from './detail.vue';
import Profiles from './profiles.vue';
import Payments from './payments.vue';
import Subscriptions from './subscriptions.vue';
import Wallet from './subscriber-wallet.vue';
import Cards from './cards.vue';
import Devices from './devices.vue';
import Agreements from './agreements.vue';
import Downloads from './downloads.vue';
import Recordings from './recordings.vue';
import BookmarkFavourite from './bookmark-favourite.vue';
import BookmarkWatched from './bookmark-watched.vue';
import HttpSessions from './http-sessions.vue';
import VideoSessions from './subscriber-video-sessions.vue';
import Audits from './audit.vue';

export default {
  components: {
    UiSwitch,
    UiTabs,
    Detail,
    UiExternalApplicationIcons,
    Profiles,
    Payments,
    Subscriptions,
    Wallet,
    Cards,
    Devices,
    Agreements,
    Downloads,
    Recordings,
    BookmarkFavourite,
    BookmarkWatched,
    HttpSessions,
    VideoSessions,
    Audits,
  },
  mixins: [ActivateModelMixin, RoleMixin],
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
    tabs() {
      return [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'TAB_PROFILES', page: Page.PROFILES },
        { label: 'COMMON_PAYMENTS', page: Page.PAYMENTS },
        { label: 'COMMON_SUBSCRIPTIONS', page: Page.SUBSCRIPTIONS },
        { label: 'COMMON_WALLET', page: Page.WALLET },
        { label: 'COMMON_CARDS', page: Page.CARDS },
        { label: 'COMMON_DEVICES', page: Page.DEVICES },
        { label: 'COMMON_AGREEMENTS', page: Page.AGREEMENTS },
        { label: 'COMMON_DOWNLOADS', page: Page.DOWNLOADS },
        { label: 'COMMON_RECORDINGS', page: Page.RECORDINGS },
        { label: 'TAB_BOOKMARK-FAVOURITE', page: Page.BOOKMARK_FAVOURITE },
        { label: 'TAB_BOOKMARK-WATCHED', page: Page.BOOKMARK_WATCHED },
        { label: 'TAB_HTTP-SESSIONS', page: Page.HTTP_SESSIONS, isHidden: isSessionDbAvailable() },
        { label: 'TAB_VIDEO-SESSIONS', page: Page.VIDEO_SESSIONS, isHidden: isSessionDbAvailable() },
        { label: 'COMMON_EVENTS', page: Page.AUDITS },
      ];
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
  },
};
</script>
