<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
        >
          <ui-item-title :item="{ title: model.name }" no-title-label="COMMON_NO_NAME" />
        </h1>
        <ui-switch
          v-if="model"
          v-tooltip="activateModelButtonText"
          :value="isModelActive"
          class="details-container__header-element"
          @input="activateModel('AD_SERVER')"
        />
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import { ActivateModelMixin } from '@/mixin';
import UiItemTitle from '@/components/ui-item-title/index.vue';
import Detail from './detail.vue';

export default {
  components: {
    UiItemTitle,
    UiSwitch,
    Detail,
  },
  mixins: [ActivateModelMixin],
  data() {
    return {
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
  },
};
</script>
