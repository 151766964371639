<template>
  <div v-click-outside="hide" class="ui-filters" :class="{ 'ui-filters--is-visible': filterMenuActive }">
    <div class="ui-filters__header">
      <p v-text="$translate('FILTER_LABEL')"></p>
      <span class="ui-filters__header-clear" @click="clearActiveFilters" v-text="$translate('FILTER_CLEAR')"></span>
      <i class="ui-filters__header-close icon-close" @click="hide"></i>
    </div>

    <ui-filter-settings
      v-if="hasRole(RoleName.ADMIN)"
      :active-filters="activeFilters"
      :filter-id="filterId"
      :available-filters-settings="availableFiltersSettings"
      @processing="processing = $event"
      @filterSettingChanged="filterSettingChanged"
      @update-available-filters-settings="$emit('update-available-filters-settings', $event)"
    />

    <div class="ui-filters__list">
      <component
        :is="component"
        v-for="{ field, component, componentOptions } in filters"
        :key="field"
        :ref="field"
        :values="activeFilters[field]"
        :modified-not-saved="modifiedNotSavedFilters.includes(field)"
        v-bind="componentOptions"
        @change-modified-not-saved="(value) => changeFilterModifiedNotSaved(field, value)"
        @change="(values) => changeActiveFilter(field, values)"
      />
    </div>
  </div>
</template>
<script>
import { clickOutside } from 'redge-media-web-ui';
import EventBus from '@/application/event-bus';
import { RoleMixin } from '@/mixin';
import { Constants, RoleName } from '@/enums';
import { getEmptyNotRequiredActiveFilters } from '@/lib/settings-filters';
import UiFilterSettings from './settings/index.vue';
import { resetStore, storeGetters, storeMutations } from './store';

export default {
  components: {
    UiFilterSettings,
  },
  directives: {
    clickOutside,
  },
  mixins: [RoleMixin],
  props: {
    activeFilters: {
      type: Object,
      required: true,
    },
    filterId: {
      type: Number,
      required: true,
    },
    availableFiltersSettings: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
      RoleName,
    };
  },
  computed: {
    ...storeGetters,
  },
  created() {
    resetStore();
    EventBus.$on(Constants.OPEN_FILTER_MENU, this.openMenuFilter);
  },
  beforeDestroy() {
    EventBus.$off(Constants.OPEN_FILTER_MENU, this.openMenuFilter);
  },
  methods: {
    ...storeMutations,
    updateQueryParams(queryParams) {
      this.$emit('update-query-params', queryParams);
    },
    filterSettingChanged({ id, filters }) {
      this.updateQueryParams({
        filterId: id,
        ...getEmptyNotRequiredActiveFilters(this.activeFilters, this.filters),
        ...filters,
      });
    },
    hide() {
      if (!this.processing) {
        this.setFilterMenuActive(false);
      }
    },
    openMenuFilter(field) {
      this.setFilterMenuActive(true);

      Object.values(this.$refs).forEach((component) => component[0]?.hideDatePicker?.());

      const filterComponent = this.$refs[field]?.[0];
      filterComponent?.$el.scrollIntoView();
      filterComponent?.focus();
    },
    changeFilterModifiedNotSaved(field, value) {
      if (value && !this.modifiedNotSavedFilters.includes(field)) {
        this.setModifiedNotSavedFilters([...this.modifiedNotSavedFilters, field]);
      } else if (!value) {
        this.setModifiedNotSavedFilters(this.modifiedNotSavedFilters.filter((f) => f !== field));
      }
    },
    clearActiveFilters() {
      this.updateQueryParams(getEmptyNotRequiredActiveFilters(this.activeFilters, this.filters));
    },
    changeActiveFilter(field, values) {
      this.updateQueryParams({ [field]: values });
    },
  },
};
</script>
