<template>
  <v-list-wrapper :table-definition="tableDefinition">
    <template #title="{ record: { id, title } }">
      <ui-table-detail-link :id="id" :text="title" name="contact.thread.detail">
        <template v-if="title">
          {{ title }}
        </template>
        <template v-else>
          <i class="no-title-icon">!</i>
          <span class="font-style-italic opacity-5" v-text="$translate('COMMON_NO_TITLE')"></span>
        </template>
      </ui-table-detail-link>
    </template>
    <template #operator="{ record: { assignee } }">
      <template v-if="assignee">
        <ui-table-detail-link
          v-if="hasRole(RoleName.ADMIN)"
          :id="assignee.id"
          :text="assignee.email"
          name="operator.detail"
        />
        <span v-else v-text="assignee.email"></span>
      </template>
    </template>
  </v-list-wrapper>
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import { UiTableDetailLink } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';
import * as Filters from '@/components/filter/filters';

export default ListView.extend({
  components: {
    UiTableDetailLink,
  },
  mixins: [SaveModelMixin],
  data() {
    return {
      RoleName,
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.CONTACTS,
      saveModelUrlFunction: () => Urls.CONTACTS,
      saveModelModalTitleFunction: () => 'ADD_ASSIGNMENT',
      saveModelFormElementsFunction: (model) => [
        [
          {
            field: '_selectedSender',
            fieldForErrorCode: 'senderEmail',
            isMandatory: true,
            component: UiFormAutocompleteInline,
            componentOptions: {
              label: 'COMMON_EMAIL',
              dictUrl: Urls.SUBSCRIBERS_DICTIONARIES,
              maxResults: 50,
              allowNewValue: true,
              on: {
                input: () => {
                  model.senderEmail = model._selectedSender?.name;
                },
              },
            },
          },
          Fields.TITLE,
          Fields.textareaField('content', 'CONTACT_CONTENT', true),
        ],
      ],
      saveModelDefaultValues: {
        assignee: {},
        category: {},
        _selectedSender: {},
      },
      exportUrl: Urls.CONTACTS_EXPORT,
      filters: [
        Filters.CREATE_SINCE(),
        Filters.CREATE_TILL(),
        Filters.autocomplete('statuses', { label: 'COMMON_STATUS', dictUrl: Urls.CONTACTS_STATUSES }),
        Filters.autocomplete('assignees', { label: 'COMMON_OPERATOR', dictUrl: Urls.OPERATORS_DICTIONARIES }),
        Filters.autocomplete('categories', { label: 'COMMON_CATEGORY', dictUrl: Urls.CONTACTS_CATEGORIES }),
      ],
      paramsConfig: {
        sort: QueryParam.MODIFIED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'title',
            minWidth: 200,
            headingLabel: 'COMMON_TITLE',
          },
          {
            field: 'category.name',
            width: 160,
            headingLabel: 'COMMON_CATEGORY',
          },
          {
            field: 'senderEmail',
            minWidth: 180,
            isSortable: true,
            headingLabel: 'COMMON_EMAIL',
            dataClass: 'text-truncated',
          },
          {
            field: 'status',
            width: 150,
            isSortable: true,
            headingLabel: 'COMMON_STATUS',
          },
          {
            field: 'operator',
            minWidth: 180,
            headingLabel: 'COMMON_OPERATOR',
          },
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
        ],
        records: this.records,
      };
    },
  },
});
</script>
