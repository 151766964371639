function Sortable(dragElm, _options) {
  this.options = $.extend(
    {
      handle: null,
      axis: null,
      ghostClass: '',
      parentClass: null,
      onSwap() {},
      canSwap() {
        return true;
      },
    },
    _options,
  );

  this.canSwap = this.options.canSwap;
  this.onSwap = this.options.onSwap;
  this.axis = this.options.axis;
  this.parentClass = this.options.parentClass;

  this.dragElm = dragElm;
  this.ghost = null;
  this.cleanUp();

  this.dragElm.each((index, elm) => {
    const handle = this.options.handle ? $(elm).find(this.options.handle) : $(elm);
    handle.off('mousedown').on('mousedown', { that: this, dragElm: $(elm) }, this.onItemMouseDown);
    handle.off('click.sortable').on('click.sortable', { that: this, dragElm: $(elm) }, this.onItemClick);
  });

  this.currentItem = null;
}

Sortable.prototype = {
  onItemClick(event) {
    event.stopPropagation();
    event.preventDefault();
  },
  onItemMouseDown(event) {
    const { that } = event.data;
    const { dragElm } = event.data;

    if ($(event.target).hasClass('item-link')) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    that.cleanUp();

    const handle = $(this);

    const item = dragElm;
    that.currentItem = item;
    that.currentItem.addClass('section-item--is-sorted');

    that.ghost = item.clone().css({
      width: item.outerWidth(),
      position: 'absolute',
      'z-index': 100,
      'pointer-events': 'none',
      opacity: 1,
    });

    if (that.options.ghostClass) {
      that.ghost.addClass(that.options.ghostClass);
    }

    if (that.parentClass) {
      const parent = handle.closest(`.${that.parentClass}`);
      if (parent) {
        that.ghost.addClass(parent.attr('data-sortable'));
      }
    }

    $(document.body).off('mousemove.sortable').on('mousemove.sortable', { that }, that.onDocumentMouseMove);
    $(document.body).off('mouseup.sortable').on('mouseup.sortable', { that }, that.onDocumentMouseUp);
    that.dragElm.on('mouseenter.sortable', { that }, that.onItemMouseEnter);

    $(document.body).append(that.ghost);
  },

  onDocumentMouseUp(event) {
    const { that } = event.data;
    that.cleanUp();
  },

  onDocumentMouseMove(event) {
    const ctx = event.data.that;
    const drag = ctx.options.handle ? ctx.ghost.find(ctx.options.handle) : ctx.ghost;

    ctx.ghost.css({
      left: ctx.axis !== null && ctx.axis === 'y' ? ctx.currentItem.offset().left : event.pageX - drag.width() / 2,
      top: ctx.axis !== null && ctx.axis === 'x' ? ctx.currentItem.offset().top : event.pageY - drag.height() / 2,
    });
  },

  // eslint-disable-next-line consistent-return
  onItemMouseEnter(event) {
    const { that } = event.data;

    if (!that.canSwap($(this), that.currentItem)) {
      return false;
    }

    const isAfter = $(this).prevAll().filter(that.currentItem).length !== 0;

    if (isAfter) {
      $(this).after(that.currentItem);
    } else {
      $(this).before(that.currentItem);
    }
  },

  cleanUp() {
    $(document.body).off('mousemove.sortable');
    $(document.body).off('mouseout.sortable');

    if (this.dragElm) {
      this.dragElm.off('mouseenter.sortable');
    }

    if (this.currentItem && this.dragElm) {
      this.onSwap(this.currentItem);
    }

    if (this.ghost) {
      this.ghost.remove();
    }

    if (this.currentItem) {
      this.currentItem.removeClass('section-item--is-sorted');
    }

    this.currentItem = null;
  },
};

export default Sortable;
