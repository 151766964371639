import { ImageLabel, ImageType } from '@/enums';

export const coverImagesProps = (isAllowed, product, images) => ({
  title: 'PRODUCT_FILES_COVERS',
  isAllowed,
  product,
  type: ImageType.COVER,
  images,
  settings: [
    {
      label: ImageLabel.ASPECT_16X9,
      scaler: { main: { dstw: 315, dsth: 177 } },
    },
    {
      label: ImageLabel.ASPECT_1X1,
      scaler: { main: { dstw: 377, dsth: 377 } },
    },
    {
      label: ImageLabel.ASPECT_3X4,
      scaler: { main: { dstw: 560, dsth: 746 } },
    },
  ],
});

export const artworkImagesProps = (isAllowed, product, images) => ({
  title: 'PRODUCT_FILES_ARTWORKS',
  isAllowed,
  product,
  type: ImageType.ARTWORK,
  images,
  settings: [
    {
      label: ImageLabel.ASPECT_16X9,
      scaler: { main: { dstw: 1920, dsth: 1080 } },
    },
    {
      label: ImageLabel.ASPECT_1X1,
      scaler: { main: { dstw: 960, dsth: 960 } },
    },
    {
      label: ImageLabel.ASPECT_3X4,
      scaler: { main: { dstw: 560, dsth: 746 } },
    },
  ],
});

export const galleryImagesProps = (isAllowed, product, images) => ({
  title: 'PRODUCT_FILES_GALLERY',
  isAllowed,
  product,
  type: ImageType.GALLERY,
  images,
  settings: [
    {
      label: ImageLabel.ASPECT_16X9,
      scaler: { main: { dstw: 1920, dsth: 1080 } },
      multiple: true,
    },
  ],
});

export const titleTreatmentImagesProps = (isAllowed, product, images) => ({
  title: 'PRODUCT_FILES_TITLE_TREATMENT',
  isAllowed,
  product,
  type: ImageType.TITLE_TREATMENT,
  images,
  settings: [
    {
      label: ImageLabel.CUSTOM,
      scaler: { type: 0 },
    },
  ],
});
