import * as views from '@/modules';
import { Page } from '@/enums';

export default [
  {
    path: '/gui/administration/operators',
    name: 'operator',
    component: views.AdministrationOperators,
    meta: {
      titleLabels: ['MENU_ADMINISTRATION_OPERATOR'],
    },
  },
  {
    path: '/gui/administration/operators/:id/:page',
    name: 'operator.detail',
    component: views.AdministrationOperatorsDetail,
    meta: {
      titleLabels: ['MENU_ADMINISTRATION_OPERATOR'],
    },
  },
  {
    path: '/gui/administration/ads',
    name: 'ads',
    redirect: { name: 'ads.page', params: { page: Page.SERVERS } },
  },
  {
    path: '/gui/administration/ads/:page',
    name: 'ads.page',
    component: views.AdministrationAds,
    meta: {
      titleLabels: ['MENU_ADMINISTRATION_AD_SERVERS'],
    },
  },
  {
    path: '/gui/administration/ads/servers/:id/:page',
    name: 'servers.detail',
    component: views.AdministrationAdsServersDetail,
    meta: {
      titleLabels: ['MENU_ADMINISTRATION_AD_SERVERS'],
    },
  },
  {
    path: '/gui/administration/geoip',
    name: 'geoip',
    redirect: { name: 'geoip.page', params: { page: Page.WHITE } },
  },
  {
    path: '/gui/administration/geoip/:page',
    name: 'geoip.page',
    component: views.AdministrationGeoip,
    meta: {
      titleLabels: ['MENU_ADMINISTRATION_GEOIP'],
    },
  },
  {
    path: '/gui/administration/nicknames',
    name: 'nickname',
    component: views.AdministrationNicknames,
    meta: {
      titleLabels: ['MENU_ADMINISTRATION_NICKNAMES'],
    },
  },
  {
    path: '/gui/administration/timers',
    name: 'timer',
    redirect: { name: 'timer.page', params: { page: Page.TIMERS } },
  },
  {
    path: '/gui/administration/timers/:page',
    name: 'timer.page',
    component: views.AdministrationTimers,
    meta: {
      titleLabels: ['MENU_ADMINISTRATION_TIMER'],
    },
  },
  {
    path: '/gui/administration/timers/timers/:id/:page',
    name: 'timer.detail',
    component: views.AdministrationTimersDetail,
    meta: {
      titleLabels: ['MENU_ADMINISTRATION_TIMER'],
    },
  },
  {
    path: '/gui/administration/translations/bo/:page?',
    name: 'translation.bo',
    component: views.AdministrationTranslations,
    meta: {
      titleLabels: ['COMMON_TRANSLATIONS'],
    },
  },
  {
    path: '/gui/administration/settings/:page?',
    name: 'setting.page',
    component: views.AdministrationSettings,
    meta: {
      titleLabels: ['MENU_ADMINISTRATION_SETTING'],
    },
  },
  {
    path: '/gui/administration/softwaredecoding',
    name: 'softwaredecoding',
    component: views.AdministrationSoftwareDecoding,
    meta: {
      titleLabels: ['MENU_ADMINISTRATION_DECODING_CPU'],
    },
  },
  {
    path: '/gui/administration/audits',
    name: 'audit',
    component: views.AdministrationAudits,
    meta: {
      titleLabels: ['COMMON_EVENTS'],
    },
  },
];
