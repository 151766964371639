<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as SubscriberColumns from '@/modules/subscriber/view/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';

export default ListView.extend({
  mixins: [RemoveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_ID_HTTP_SESSIONS(this.id),
      removeModelUrlFunction: ({ id }) => Urls.SUBSCRIBERS_HTTP_SESSIONS_ID_BLOCK(id),
      removeConfirmationText: this.$translate('SESSION_BLOCK_CONFIRMATION'),
      exportUrl: Urls.SUBSCRIBERS_ID_HTTP_SESSIONS_EXPORT(this.id),
      paramsConfig: {
        sort: QueryParam.ID,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          { ...Columns.SINCE, isSortable: false },
          { ...Columns.TILL, isSortable: false },
          Columns.DEVICE_DETAIL(({ device }) => ({ device })),
          Columns.DEVICE_INFO(({ device }) => ({ device })),
          Columns.RECORD_DEVICE_MAKER,
          Columns.RECORD_DEVICE_OS,
          Columns.RECORD_DEVICE_APP_VERSION,
          {
            field: 'ip',
            width: 150,
            headingLabel: 'HTTP_SESSION_IP',
          },
          Columns.SESSION_DELETE_REASON,
          SubscriberColumns.ACTIONS_SESSIONS(this.isAllowed, this.removeModelAndDeactivate),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
  methods: {
    async removeModelAndDeactivate(model, method) {
      await this.removeModel(model, method);
      this.records = this.records.map((record) => (record.id === model.id ? { ...record, displayActive: false } : record));
    },
  },
});
</script>
