<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import { UiFormCheckbox } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as ContentFields from '@/modules/content/view/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        modelUrl: Urls.ITEMS_CATEGORIES_ID(this.id),
        formElementsFunction: () => [
          [
            Fields.HEADER_METADATA,
            Fields.KEY,
            Fields.NAME,
            Fields.textInputField('seoName', 'CONTENT_CATEGORY_SEO_NAME'),
            Fields.textInputField('seoNameSingular', 'CONTENT_CATEGORY_SEO_NAME_SINGULAR'),
            Fields.EXTERNAL_UID,
            Fields.RANK_NOT_MANDATORY,
            ContentFields.SLUG,
            Fields.TYPE_DISABLED,
            Fields.checkboxField('promoted', 'CONTENT_CATEGORY_PROMOTED'),
            {
              field: 'adult',
              component: UiFormCheckbox,
              componentOptions: {
                label: 'CONTENT_CATEGORY_ADULT',
                tooltip: Fields.commonTooltip('HELP_CONTENT_CATEGORY_ADULT'),
              },
            },
            Fields.PLATFORMS,
          ],
          [Fields.HEADER_DESCRIPTION, Fields.DESCRIPTION],
        ],
      };
    },
  },
};
</script>
