<template>
  <div class="ui-filter-item" :class="{ 'ui-filter-item--modified': modifiedNotSaved }">
    <label>{{ $translate(label) }}:</label>
    <div class="flex">
      <ui-price-input v-model="keyword" @keydown.enter="select(keyword)" />
      <button class="button ml-1" @click="select(keyword)" v-text="$translate('COMMON_ADD')"></button>
    </div>
    <ul class="ui-filter-item__selected-values">
      <ui-filter-item-selected-value
        v-for="value in values"
        :key="value"
        :value="priceFormat(value)"
        @remove="remove(value)"
      />
    </ul>
  </div>
</template>
<script>
import { AppEvent } from '@/enums';
import { priceFormat } from '@/helper';
import UiPriceInput from '@/components/ui-price-input/index.vue';
import UiFilterItemSelectedValue from './ui-filter-item-selected-value.vue';

export default {
  components: {
    UiFilterItemSelectedValue,
    UiPriceInput,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      default: () => [],
    },
    modifiedNotSaved: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      keyword: '',
    };
  },
  watch: {
    keyword() {
      this.$emit(AppEvent.CHANGE_MODIFIED_NOT_SAVED, !!this.keyword);
    },
  },
  methods: {
    priceFormat,
    select(value) {
      if (!value) {
        return;
      }

      this.$emit(AppEvent.CHANGE, [value]);
      this.keyword = '';
    },
    remove(value) {
      this.$emit(
        AppEvent.CHANGE,
        this.values.filter((v) => v !== value),
      );
    },
    focus() {
      this.$el.querySelector('input')?.focus();
    },
  },
};
</script>
