<template>
  <div class="sidebar-content">
    <div class="sidebar-content__filters">
      <ui-search class="sidebar-content__search" @change="request.params.keyword = $event" />
      <dictionary-fetcher v-slot="{ fetch, ...slotOptions }" :dict-url="Urls.PRODUCTS_LIVES" :max-results="50">
        <ui-multiselect
          :value="selectedLive"
          v-bind="slotOptions"
          field="title"
          searchable
          allow-empty
          :empty-value-label="$translate('COMMON_ALL')"
          :is-option-disabled="(option) => !option.title"
          :slots="{ option: UiMultiselectOptionProduct }"
          class="sidebar-content__select"
          @input="setLiveId"
          @fetch="fetch"
        />
      </dictionary-fetcher>
      <ui-date-picker-input v-model="request.params.since" :timepicker="false" :language="language" />
      <ui-checkbox v-model="request.params.recommendedOnly" v-tooltip="$translate('HELP_SECTION_RECOMMENDED_ONLY')" />
    </div>
    <div class="ui-filter-item ui-filter-item--switch">
      <label v-text="`${$translate('COMMON_ACTIVE_MALE')}:`"></label>
      <ui-multi-switch v-model="request.params.active" />
    </div>
    <ul v-if="records.length > 0" class="sidebar-content__list">
      <li
        v-for="record in records"
        :key="record.id"
        :class="{ 'opacity-4': !record.active }"
        class="sidebar-content__list-item"
      >
        <div
          :style="background(record.images)"
          class="sidebar-content__list-item-image"
          @mousedown="onMouseDown(record, $event)"
        ></div>
        <div class="sidebar-content__list-item-description">
          <span
            class="sidebar-content__list-item-datetime"
            v-text="`${$dateFormat(record.since, 'MM-DD HH:mm')} - ${$dateFormat(record.till, 'MM-DD HH:mm')}`"
          >
          </span>
          <router-link :to="productLink(record)" class="sidebar-content__list-item-name">
            <ui-product-title :product="record" />
          </router-link>
        </div>
      </li>
    </ul>
    <p
      v-else-if="isFetching"
      class="sidebar-content__no-data-info"
      v-text="`${$translate('WIDGET_SIDEBAR_PRODUCTS_LOADING')}...`"
    ></p>
    <p v-else class="sidebar-content__no-data-info" v-text="$translate('COMMON_NO_RESULTS')"></p>
  </div>
</template>
<script>
import {
  UiCheckbox, UiDatePickerInput, UiMultiSwitch, UiMultiselect,
} from 'redge-media-web-ui';
import Urls from '@/application/urls';
import i18n from '@/application/i18n';
import DictionaryFetcher from '@/components/dictionary-fetcher/index.vue';
import UiSearch from '@/components/search/search.vue';
import { UiMultiselectOptionProduct } from '@/components/ui-multiselect';
import UiProductTitle from '@/components/ui-product-title/index.vue';
import { QueryParam } from '@/enums';
import { background, productLink } from '@/helper';
import DroppableMixin from '@/mixin/droppable';

export default {
  components: {
    UiCheckbox,
    UiDatePickerInput,
    UiMultiselect,
    UiMultiSwitch,
    UiSearch,
    UiProductTitle,
    DictionaryFetcher,
  },
  mixins: [DroppableMixin],
  props: {
    records: {
      type: Array,
      required: true,
    },
    fetchRecords: {
      type: Function,
      required: true,
    },
    isFetching: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedLive: undefined,
      request: {
        url: Urls.PRODUCTS_LIVES_PROGRAMMES,
        params: {
          sort: QueryParam.SINCE,
          order: QueryParam.ASCENDING,
          recommendedOnly: false,
          keyword: undefined,
          'liveId[]': undefined,
          active: undefined,
        },
      },
      UiMultiselectOptionProduct,
      Urls,
    };
  },
  computed: {
    language() {
      return new Intl.Locale(i18n.language).language;
    },
  },
  watch: {
    request: {
      handler: 'fetchRecords',
      immediate: true,
      deep: true,
    },
  },
  methods: {
    background,
    productLink,
    setLiveId(live) {
      this.selectedLive = live;
      this.request.params['liveId[]'] = live?.id;
    },
  },
};
</script>
