<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition">
    <template slot="custom-buttons">
      <div v-if="amountFree" class="wallet-quantity">
        <label class="wallet-quantity__label" v-text="`${$translate('SUBSCRIBER_WALLET_QUANTITY')}:`"></label>
        <span class="wallet-quantity__amount" v-text="amountFree"></span>
      </div>
    </template>
  </v-list-wrapper>
</template>
<script>
import Urls from '@/application/urls';
import { priceFormat } from '@/helper';
import ListView from '@/components/list-view/list-view.vue';
import { UiTablePaymentStatus } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import * as PaymentColumns from '@/modules/payment/view/columns';
import * as Filters from '@/components/filter/filters';
import * as PaymentFilters from '@/modules/payment/view/filters';
import { Page, QueryParam } from '@/enums';

export default ListView.extend({
  data() {
    return {
      recordsUrl: Urls.WALLET_PAYMENTS,
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
      filters: [
        Filters.SUBSCRIBER,
        Filters.autocomplete('statuses', {
          label: 'COMMON_STATUS',
          dictUrl: Urls.WALLET_STATUSES,
        }),
        PaymentFilters.PAYMENT_SYSTEM,
      ],
      amountFree: undefined,
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            ...Columns.SUBSCRIBER_DETAIL(({ subscriber }) => ({ subscriber, page: Page.WALLET })),
            isHidden: this.isSubscriberView,
          },
          {
            field: 'amountCharged',
            width: 120,
            isSortable: true,
            headingLabel: 'PAYMENT_WALLET_AMOUNT_CHARGED',
            dataClass: 'text-right',
            dataMapper: ({ amountCharged }) => priceFormat(amountCharged),
          },
          {
            field: 'amountFunded',
            width: 120,
            isSortable: true,
            headingLabel: 'PAYMENT_WALLET_AMOUNT_FUNDED',
            dataClass: 'text-right',
            dataMapper: ({ amountFunded }) => priceFormat(amountFunded),
          },
          {
            field: 'status',
            width: 120,
            isSortable: true,
            headingLabel: 'COMMON_STATUS',
            dataComponent: UiTablePaymentStatus,
            dataComponentPropsMapper: ({ status }) => ({ status }),
          },
          {
            field: 'system',
            width: 90,
            isSortable: true,
            headingLabel: 'PAYMENT_SYSTEM',
          },
          Columns.EXTERNAL_UID,
          PaymentColumns.PAYMENT_IP_COUNTRY,
          Columns.CREATED_AT,
        ],
        records: this.records,
      };
    },
  },
});
</script>
