<template>
  <div v-if="model" class="details-container contact-container">
    <div class="details-container__header">
      <h1 v-text="`${model.id} [${model.senderEmail}] ${model.title}`"></h1>
    </div>
    <div class="ui-detail">
      <ui-form v-model="model" v-bind="formDefinition" class="ui-detail__form" />

      <ui-messages
        :messages="model.messages"
        :sender-email="model.sender && model.sender.email"
        :is-write-allowed="isAllowed"
        :is-replying="isReplying"
        :templates="templates"
        :footer="footer"
        @toggle-is-replying="isReplying = !isReplying"
        @reply="reply"
      />
    </div>
  </div>
</template>
<script>
import { UiForm, UiModalPopup } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import operator from '@/application/operator';
import http from '@/lib/http';
import { Page, RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { formatTime, productLink } from '@/helper';
import * as Fields from '@/components/ui-form/fields';
import UiMessages from '@/components/ui-messages/index.vue';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import UiFormDictionarySelect from '@/components/ui-form/ui-form-dictionary-select.vue';

export default {
  components: {
    UiForm,
    UiMessages,
  },
  mixins: [RoleMixin],
  data() {
    return {
      error: undefined,
      isReplying: false,
      writeRole: RoleName.CRM_WRITE,
      model: undefined,
      templates: [],
      footer: undefined,
    };
  },
  computed: {
    formDefinition() {
      return {
        value: this.model,
        elements: [
          [
            Fields.HEADER_METADATA,
            {
              field: 'category',
              isMandatory: true,
              component: UiFormDictionarySelect,
              componentOptions: {
                label: 'COMMON_CATEGORY',
                valueAsObject: true,
                dictUrl: Urls.CONTACTS_CATEGORIES,
              },
            },
            {
              field: 'status',
              isMandatory: true,
              component: UiFormDictionarySelect,
              componentOptions: {
                label: 'COMMON_STATUS',
                dictUrl: Urls.CONTACTS_STATUSES,
              },
            },
            {
              field: 'assignee',
              component: UiFormAutocompleteInline,
              componentOptions: {
                label: 'COMMON_OPERATOR',
                field: 'email',
                maxResults: 50,
                allowEmpty: true,
                dictUrl: Urls.OPERATORS,
              },
            },
            Fields.textareaField('note', 'CONTACT_NOTE'),
            Fields.CREATED_AT,
            Fields.MODIFIED_AT,
            {
              ...Fields.datePickerField('assignedAt', 'CONTACT_ASSIGNED', false, true),
              isHidden: !this.model.assignedAt,
            },
            {
              ...Fields.textInputField('processingTime', 'CONTACT_PROCESSING_TIME', false, true),
              isHidden: !this.model.processingTime,
            },
            { ...Fields.textInputField('overtime', 'CONTACT_OVERTIME', false, true), isHidden: !this.model.overtime },
          ],
          [
            Fields.textHeaderWithIcon('COMMON_SUBSCRIBER', this.model.sender?.email ? 'icon-link' : '', '', () => this.$router.push({
              name: 'subscriber.detail',
              params: { id: this.model.sender?.id, page: Page.DETAIL },
            })),
            {
              field: 'sender',
              component: UiFormAutocompleteInline,
              componentOptions: {
                label: 'COMMON_EMAIL',
                field: 'email',
                maxResults: 50,
                allowEmpty: true,
                dictUrl: Urls.SUBSCRIBERS_DICTIONARIES,
              },
            },
            { ...Fields.textInputField('ip', 'CONTACT_IP', false, true), isHidden: !this.model.ip },
            {
              ...Fields.textHeaderWithIcon('COMMON_DEVICE', 'icon-link', '', () => this.$router.push({
                name: 'device.detail',
                params: { id: this.model.device.id, page: Page.DETAIL },
              })),
              isHidden: !this.model.device?.id,
            },
            {
              ...Fields.textInputField('device.platform', 'PLATFORM_NAME', false, true),
              isHidden: !this.model.device?.id,
            },
            { ...Fields.textInputField('device.uid', 'DEVICE_UUID', false, true), isHidden: !this.model.device?.id },
            { ...Fields.textInputField('device.agent', 'DEVICE_AGENT', false, true), isHidden: !this.model.device?.id },
            { ...Fields.textInputField('device.os', 'DEVICE_OS', false, true), isHidden: !this.model.device?.id },
            {
              ...Fields.textHeaderWithIcon('COMMON_PRODUCT', 'icon-link', '', () => this.$router.push(productLink(this.model.item))),
              isHidden: !this.model.item?.id,
            },
            { ...Fields.textInputField('item.title', 'COMMON_NAME', false, true), isHidden: !this.model.item?.id },
            { ...Fields.textInputField('item.type', 'COMMON_TYPE', false, true), isHidden: !this.model.item?.id },
          ],
        ],
        buttons: [
          {
            onClick: this.takeover,
            label: 'CONTACT_TAKEOVER',
            type: 'button',
            class: 'button button-info contact__button-takeover',
            tabindex: '0',
            disabled: !this.isAllowed,
          },
          {
            onClick: this.save,
            label: 'COMMON_SAVE',
            type: 'submit',
            class: 'button button-info',
            tabindex: '0',
            disabled: !this.isAllowed,
          },
        ],
        isDisabled: !this.isAllowed,
        errors: this.error?.response?.data?.errors,
      };
    },
  },
  async created() {
    [this.model, this.templates, this.footer] = await Promise.all([
      this.fetchModel(),
      http.get(Urls.DOCUMENTS, { params: { types: ['CONTACT_TEMPLATE'] } }).then(({ data }) => data.items),
      http.get(`${Urls.DOCUMENTS}/contact-footer`).then(({ data }) => data),
    ]);
  },
  methods: {
    takeover() {
      this.model.assignee = { id: operator.id };
      this.save();
    },
    async fetchModel() {
      return http
        .get(Urls.CONTACTS_ID(this.$route.params.id))
        .then(({ data: { processingTime, overtime, ...rest } }) => ({
          processingTime: processingTime && formatTime(processingTime),
          overtime: overtime && formatTime(overtime),
          ...rest,
        }));
    },
    async save() {
      const data = {
        categoryId: this.model.category.id,
        assigneeId: this.model.assignee?.id || '',
        senderId: this.model.sender?.id || '',
        status: this.model.status,
        note: this.model.note,
      };

      try {
        await http.put(Urls.CONTACTS_ID(this.$route.params.id), data);
        this.error = undefined;
        this.model = { ...this.model, ...(await this.fetchModel()) };
        this.$modal.create(UiModalPopup, {
          text: this.$translate('COMMON_SAVE_SUCCESS'),
        });
      } catch (error) {
        this.error = error;
        throw error;
      }
    },
    async reply(content) {
      await http.put(Urls.CONTACTS_ID_REPLY(this.$route.params.id), { content });
      this.model = { ...this.model, ...(await this.fetchModel()) };
      this.isReplying = false;
    },
  },
};
</script>
