<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import { UiFormNumberInput, checkAndApplyLimits } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { DiscountType, QueryParam, RoleName } from '@/enums';
import { priceFormat } from '@/helper';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import UiFormDictionarySelect from '@/components/ui-form/ui-form-dictionary-select.vue';
import UiFormPriceInput from '@/components/ui-form/ui-form-price-input.vue';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

const MIN_PERCENT_VALUE = 1;
const MAX_PERCENT_VALUE = 100;

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    const url = Urls.PROMOTIONS_ID_DISCOUNTS(this.id);

    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_DISCOUNT' : 'ADD_DISCOUNT'),
      saveModelFormElementsFunction: (model) => [
        [
          {
            field: 'type',
            isMandatory: true,
            component: UiFormDictionarySelect,
            componentOptions: {
              label: 'COMMON_TYPE',
              dictUrl: Urls.PROMOTIONS_DISCOUNT_TYPES,
              on: {
                input: (newType) => {
                  if (model.oldType === undefined) {
                    model.oldType = newType;
                  }

                  const isValueMappingRequired = model.value !== undefined && [newType, model.oldType].includes(DiscountType.PERCENT_VALUE);

                  model.oldType = newType;

                  if (!isValueMappingRequired) {
                    return;
                  }

                  if (newType === DiscountType.PERCENT_VALUE) {
                    model.value = checkAndApplyLimits(
                      Math.floor(model.value / 100),
                      MIN_PERCENT_VALUE,
                      MAX_PERCENT_VALUE,
                    );
                  } else {
                    model.value *= 100;
                  }
                },
              },
            },
          },
          {
            field: 'value',
            isMandatory: true,
            component: model.type === DiscountType.PERCENT_VALUE ? UiFormNumberInput : UiFormPriceInput,
            componentOptions: {
              label: 'COMMON_VALUE',
              min: model.type === DiscountType.PERCENT_VALUE ? MIN_PERCENT_VALUE : undefined,
              max: model.type === DiscountType.PERCENT_VALUE ? MAX_PERCENT_VALUE : undefined,
              tooltip: Fields.commonTooltip('HELP_VALUE_OF_DISCOUNT_FOR_TYPE'),
            },
          },
          {
            field: 'quantity',
            isMandatory: true,
            component: UiFormNumberInput,
            componentOptions: {
              label: 'COMMON_QUANTITY',
              min: 1,
              tooltip: Fields.commonTooltip('HELP_AMOUNT_OF_PRODUCTS_WITH_DISCOUNT'),
            },
          },
          Fields.RANK,
        ],
      ],
      paramsConfig: {
        sort: QueryParam.RANK,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          { ...Columns.TYPE, width: 200 },
          {
            field: 'value',
            headingLabel: 'COMMON_VALUE',
            dataMapper: ({ type, value }) => {
              if (type === DiscountType.ABSOLUTE_VALUE) {
                return `${this.$translate('PAYMENT_PRICE')} = ${priceFormat(value)}`;
              }
              if (type === DiscountType.PERCENT_VALUE) {
                return `${this.$translate('PAYMENT_PRICE')} - ${value}%`;
              }
              if (type === DiscountType.RELATIVE_VALUE) {
                return `${this.$translate('PAYMENT_PRICE')} - ${priceFormat(value)}`;
              }

              return undefined;
            },
          },
          {
            field: 'quantity',
            headingLabel: 'COMMON_PRODUCTS_QUANTITY',
          },
          Columns.RANK,
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
