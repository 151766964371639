<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
        >
          <ui-item-title :item="model" />
        </h1>
        <span
          v-tooltip="$translate('HELP_LINK')"
          class="details-container__header-element icon-link"
          @click="openLinkModal"
        ></span>
        <ui-switch
          v-if="model"
          v-tooltip="activateModelButtonText"
          :disabled="!isAllowed"
          :value="isModelActive"
          class="details-container__header-element"
          @input="activateModel()"
        />
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import { ItemType, Page, RoleName } from '@/enums';
import Urls from '@/application/urls';
import http from '@/lib/http';
import { ActivateModelMixin, RoleMixin, SaveModelMixin } from '@/mixin';
import UiTabs from '@/components/ui-tabs/index.vue';
import UiItemTitle from '@/components/ui-item-title/index.vue';
import * as Fields from '@/components/ui-form/fields';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';
import Detail from './detail.vue';
import Files from './files.vue';

export default {
  components: {
    UiSwitch,
    UiTabs,
    UiItemTitle,
    Detail,
    Files,
  },
  mixins: [ActivateModelMixin, SaveModelMixin, RoleMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      tabs: [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'COMMON_FILES', page: Page.FILES },
      ],
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
    async openLinkModal() {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: (model) => [
          [
            Fields.ITEM_TYPE(model, [
              ItemType.VOD,
              ItemType.SERIAL,
              ItemType.EPISODE,
              ItemType.LIVE,
              ItemType.BUNDLE,
              ItemType.PPV,
            ]),
            Fields.SELECTED_SERIES_PARENT(model, ![ItemType.SEASON, ItemType.EPISODE].includes(model.itemType)),
            Fields.SELECTED_SEASON_PARENT(model, model.itemType !== ItemType.EPISODE),
            Fields.SELECTED_PRODUCT_WITH_PARENT(model),
          ],
        ],
        modalTitleFunction: () => 'BANNER_LINK_WITH_PRODUCT',
        defaultValues: {
          itemType: ItemType.VOD,
        },
      });

      const saveModelPromiseFunction = ({ productId }) => {
        const url = Urls.BANNERS_ID_LINK(this.id);
        return http.put(url, { productId });
      };

      await this.openSaveModelModal(modal, saveModelPromiseFunction);
      this.$refs.childComponent.$refs.uiDetail?.fetchModel();
    },
  },
};
</script>
