<template>
  <div class="ui-message">
    <div class="ui-message__header" @click.prevent="toggleIsExpanded">
      <span v-if="headerText" class="ui-message__header-email" v-text="headerText"></span>
      <div>
        <span class="ui-message__header-date-text" v-text="`${$translate('COMMON_DATE')}:`"></span>
        <span v-text="$dateFormat(message.createdAt)"></span>
      </div>
    </div>
    <div v-show="isExpanded" class="ui-message__content">
      <iframe ref="iframe" width="100%" height="100%"></iframe>
      <p v-if="hasAttachments" class="ui-message__attachments-text" v-text="`${$translate('COMMON_ATTACHMENTS')}:`"></p>
      <ul v-if="hasAttachments" class="ui-message__attachments-list">
        <li v-for="{ name, url, id } in message.attachments" :key="id" class="ui-message__attachments-list-item">
          <a :href="url" target="_blank" v-text="name"></a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
    senderEmail: String,
  },
  computed: {
    headerText() {
      return this.message.operator?.email || this.senderEmail;
    },
    hasAttachments() {
      return this.message.attachments?.length;
    },
  },
  mounted() {
    const style = document.createElement('style');
    style.textContent = `
    body {
      margin: 0;
      color: #888;
      font-size: 13px;
    }`;

    const { iframe } = this.$refs;

    iframe.contentDocument.body.innerHTML = this.message.content;
    iframe.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
    iframe.contentDocument.head.appendChild(style);
  },
  methods: {
    toggleIsExpanded() {
      this.$emit('toggle-is-expanded');
    },
  },
};
</script>
