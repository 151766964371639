<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { Page, QueryParam, RoleName } from '@/enums';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_ID_RECORDINGS(this.id),
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.RECORDING_STATUS,
          Columns.RECORDING_NAME,
          Columns.PRODUCT_DETAIL(({ live }) => ({ product: live, page: Page.RECORDINGS }), 'RECORDING_LIVE'),
          {
            field: 'directory.name',
            width: 160,
            headingLabel: 'RECORDING_DIRECTORY',
          },
          { ...Columns.SINCE, isSortable: false },
          { ...Columns.TILL, isSortable: false },
          Columns.CREATED_AT,
        ],
        records: this.records,
      };
    },
  },
});
</script>
