<template>
  <div class="details-container header-container">
    <ui-tabs :current-route="$route" :tabs="tabs" />
    <component :is="page" class="content" :page="page" />
  </div>
</template>

<script>
import UiTabs from '@/components/ui-tabs/index.vue';
import { Page } from '@/enums';
import Translations from './translation.vue';
import Audits from './audit.vue';

export default {
  components: {
    UiTabs,
    Translations,
    Audits,
  },
  data() {
    return {
      tabs: [{ label: 'COMMON_TRANSLATIONS' }, { label: 'COMMON_EVENTS', page: Page.AUDITS }],
    };
  },
  computed: {
    page() {
      return this.$route.params.page || 'translations';
    },
  },
};
</script>
