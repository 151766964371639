<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import http from '@/lib/http';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, RoleMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as ProductColumns from '@/modules/product/view/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as ProductFields from '@/modules/product/view/fields';
import * as Fields from '@/components/ui-form/fields';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';

export default ListView.extend({
  mixins: [RemoveModelMixin, RoleMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    const url = Urls.PRODUCTS_VODS_SERIALS_ID_SEASONS(this.id);

    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: () => url,
      saveModelModalTitleFunction: () => 'ADD_SEASON',
      saveModelFormElementsFunction: () => [[ProductFields.NUMBER, ProductFields.TITLE_NOT_MANDATORY]],
      paramsConfig: {
        sort: QueryParam.NUMBER,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          ProductColumns.TITLE,
          ProductColumns.NUMBER,
          { ...Columns.SINCE, isSortable: false },
          ProductColumns.SEASON_ACTIONS(this.isAllowed, this.moveSeason, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
  methods: {
    moveSeason(seasonId, newSeasonNumber) {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: (model) => [
          [ProductFields.SELECTED_SERIES(model), Fields.numberInputField('newSeasonNumber', 'SERIES_SEASON_SELECTED')],
        ],
        modalTitleFunction: () => 'SERIES_SEASON_MOVE_TITLE',
        defaultValues: {
          newSeasonNumber,
        },
      });
      const saveModelPromiseFunction = (model) => {
        const url = Urls.PRODUCTS_VODS_SERIALS_ID_SEASONS_ID_MOVE(this.id, seasonId);
        return http.put(url, model);
      };

      this.openSaveModelModal(modal, saveModelPromiseFunction);
    },
  },
});
</script>
