<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';
import * as Filters from '@/components/filter/filters';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.ADMIN,
      recordsUrl: Urls.DEVICES_SOFTWARE_DECODING,
      removeModelUrlFunction: ({ id }) => Urls.DEVICES_SOFTWARE_DECODING_DEVICES_ID(id),
      saveModelUrlFunction: ({ id }) => (id ? Urls.DEVICES_SOFTWARE_DECODING_DEVICES_ID(id) : Urls.DEVICES_SOFTWARE_DECODING_DEVICES),
      saveModelModalTitleFunction: ({ id }) => (id ? 'COMMON_EDIT' : 'COMMON_ADD'),
      saveModelFormElementsFunction: () => [
        [
          Fields.textInputField('agent', 'DEVICE_AGENT', true),
          Fields.textInputField('agentVersion', 'DEVICE_AGENT_VERSION', false),
          Fields.textInputField('maker', 'DEVICE_MAKER', true),
          Fields.textInputField('os', 'DEVICE_OS', true),
          Fields.textInputField('osVersion', 'DEVICE_OS_VERSION', false),
        ],
      ],
      filters: [
        Filters.input('agents', { label: 'DEVICE_AGENT' }),
        Filters.input('agentVersions', { label: 'DEVICE_AGENT_VERSION' }),
        Filters.input('makers', { label: 'DEVICE_MAKER' }),
        Filters.input('oses', { label: 'DEVICE_OS' }),
        Filters.input('osVersions', { label: 'DEVICE_OS_VERSION' }),
      ],
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'agent',
            width: 160,
            isSortable: true,
            headingLabel: 'DEVICE_AGENT',
          },
          Columns.DEVICE_AGENT_VERSION,
          Columns.DEVICE_MAKER,
          Columns.DEVICE_OS,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.ACTIONS_EDIT_REMOVE(true, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
