<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import * as Fields from '@/components/ui-form/fields';
import { UiDetail } from '@/components/ui-detail';
import * as ProductFields from '@/modules/product/view/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        modelUrl: Urls.PRODUCTS_PPVS_ID_PAGE(this.id, this.page),
        toApiModelMapper: ({ programme, ...model }) => ({ ...model }),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.textInputWitLink(
              'programme.id',
              'COMMON_PROGRAMME_ID',
              () => this.$router.push({
                name: 'lives.programme.detail',
                params: { id: model.programme.id, label: 'programme' },
              }),
              !model.programme?.id,
            ),
            Fields.TITLE,
            Fields.CREATE_SINCE(),
            Fields.CREATE_TILL(),
            ProductFields.RATING,
            ProductFields.RATING_EMBEDDED(model),
            ProductFields.SHOW_RECOMMENDATIONS,
            Fields.CUSTOM_BADGE,
            ProductFields.ADVISORS,
            Fields.PLATFORMS,
            ProductFields.CHROMECAST_PLATFORMS,
            Fields.CREATED_AT,
            Fields.MODIFIED_AT,
          ],
          [
            ...ProductFields.GEOLOCK_FIELDS(model),
            Fields.HEADER_LEAD,
            Fields.LEAD,
            Fields.HEADER_DESCRIPTION,
            Fields.DESCRIPTION,
          ],
        ],
      };
    },
  },
};
</script>
