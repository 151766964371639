<template>
  <section :class="{ 'ui-detail--sticky-save': isSaveButtonSticky }" class="ui-detail">
    <ui-form
      v-if="model"
      v-model="model"
      v-bind="formDefinition"
      class="ui-detail__form"
    />
  </section>
</template>
<script>
import { UiForm, UiModalPopup } from 'redge-media-web-ui';
import { AppEvent } from '@/enums';
import http from '@/lib/http';

const naiveSortArray = (array) => array.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));

const sortArraysInsideObject = (object) => Object.entries(object).reduce(
  (acc, [key, value]) => ({ ...acc, [key]: Array.isArray(value) ? naiveSortArray(value) : value }),
  {},
);

const removeUnderscorePrefixedFields = (object) => Object.entries(object).reduce((acc, [key, value]) => (key.startsWith('_') ? acc : { ...acc, [key]: value }), {});

const deepObjectCopy = (object) => JSON.parse(JSON.stringify(object));

export default {
  components: {
    UiForm,
  },
  props: {
    modelUrl: {
      type: String,
      required: true,
    },
    fromApiModelMapper: {
      type: Function,
      default: (model) => model,
    },
    toApiModelMapper: {
      type: Function,
      default: (model) => model,
    },
    onSubmitModelValidation: {
      type: Function,
      default: () => {},
    },
    formElementsFunction: {
      type: Function,
      required: true,
    },
    isAllowed: {
      type: Boolean,
      default: false,
    },
    isSaveButtonSticky: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: undefined,
      model: undefined,
      serializedModel: undefined,
    };
  },
  computed: {
    hasModelChanged() {
      const model = JSON.stringify(sortArraysInsideObject(removeUnderscorePrefixedFields(deepObjectCopy(this.model))));

      return model !== this.serializedModel;
    },
    formDefinition() {
      return {
        value: this.model,
        elements: this.formElementsFunction(this.model),
        buttons: [
          {
            onClick: this.saveModel,
            label: 'COMMON_SAVE',
            type: 'submit',
            class: 'button button-info',
            tabindex: '0',
            disabled: !this.hasModelChanged,
            'data-qa': 'vod-save-button',
          },
        ],
        isDisabled: !this.isAllowed,
        errors: this.error?.response?.data?.errors,
      };
    },
  },
  mounted() {
    this.fetchModel();
  },
  methods: {
    async fetchModel() {
      this.$loader.show('LOADING_INFO');

      try {
        this.model = await http
          .get(this.modelUrl)
          .then(({ data }) => data)
          .then(this.fromApiModelMapper);

        this.setSerializedModel(this.model);
        this.$emit(AppEvent.MODEL, this.model);
      } catch (error) {}

      this.$loader.hide();
    },
    async saveModel() {
      this.onSubmitModelValidation(this.model);

      try {
        this.$loader.show('SAVING_DATA');

        const updatedModel = this.toApiModelMapper({ ...this.model });
        await http.put(this.modelUrl, updatedModel).then(({ data }) => data);

        this.$modal.create(UiModalPopup, { text: this.$translate('COMMON_SAVE_SUCCESS') });
        this.setSerializedModel(this.model);
        this.$emit(AppEvent.MODEL, this.model);
        this.$loader.hide();
        this.error = undefined;
      } catch (error) {
        this.$loader.hide();
        this.error = error;
        throw error;
      }
    },
    updateModel(changedModelFields) {
      this.model = { ...this.model, ...changedModelFields };
      return this.model;
    },
    setSerializedModel(model) {
      this.serializedModel = JSON.stringify(
        sortArraysInsideObject(removeUnderscorePrefixedFields(deepObjectCopy(model))),
      );
    },
  },
};
</script>
