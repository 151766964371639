<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition">
    <template #title="{ record: { id, title } }">
      <ui-table-detail-link :id="id" :text="title" name="section.detail">
        <ui-item-title :item="{ title }" />
      </ui-table-detail-link>
    </template>
  </v-list-wrapper>
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam } from '@/enums';
import ListView from '@/components/list-view/list-view.vue';
import UiItemTitle from '@/components/ui-item-title/index.vue';
import { UiTableDetailLink } from '@/components/ui-table';

export default ListView.extend({
  components: {
    UiItemTitle,
    UiTableDetailLink,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      recordsUrl: Urls.VIEWS_ID_SECTIONS(this.id),
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'title',
            minWidth: 240,
            headingLabel: 'COMMON_TITLE',
          },
        ],
        records: this.records,
      };
    },
  },
});
</script>
