<template>
  <component :is="tag" class="ui-sortable">
    <slot></slot>
  </component>
</template>
<script>
const GHOST_ITEM_CLASS = 'ui-sortable__ghost-item';
const CLONE_ITEM_CLASS = 'ui-sortable__clone-item';

export default {
  props: {
    tag: {
      type: String,
      default: 'ul',
    },
    value: {
      type: Array,
      required: true,
    },
    handleClass: {
      type: String,
    },
  },
  data() {
    return {
      onMouseMoveHandler: (event) => this.onMouseMove(event),
      onMouseUpHandler: () => this.onMouseUp(),
      items: undefined,
      currentItem: undefined,
      initialIndex: -1,
      currentItemEl: undefined,
      clone: undefined,
      handlers: [],
    };
  },
  computed: {
    itemsElms: {
      cache: false,
      get() {
        return [...this.$el.children];
      },
    },
  },
  watch: {
    value: {
      handler() {
        this.items = [...this.value];
      },
      immediate: true,
    },
  },
  mounted() {
    this.$el.addEventListener('mousedown', (event) => this.onMouseDown(event));
  },
  methods: {
    onMouseDown(event) {
      event.stopPropagation();
      event.preventDefault();

      if (!this.items) {
        return;
      }

      const { target } = event;
      this.currentItemEl = target;

      if (this.handleClass && !target.classList.contains(this.handleClass)) {
        return;
      }

      while (this.currentItemEl.parentNode !== this.$el && this.$el.contains(this.currentItemEl)) {
        this.currentItemEl = this.currentItemEl.parentNode;
      }

      const index = this.itemsElms.indexOf(this.currentItemEl);
      this.initialIndex = index;
      this.currentItem = this.items[index];

      document.addEventListener('mousemove', this.onMouseMoveHandler);
      document.addEventListener('mouseup', this.onMouseUpHandler);

      this.clone = this.currentItemEl.cloneNode(true);
      this.clone.classList.add(CLONE_ITEM_CLASS, ...this.currentItemEl.classList);
      this.currentItemEl.classList.add(GHOST_ITEM_CLASS);

      const computedStyle = window.getComputedStyle(this.currentItemEl);
      this.clone.style.width = computedStyle.getPropertyValue('width');
      this.clone.style.height = computedStyle.getPropertyValue('height');
      document.body.appendChild(this.clone);

      this.handlers = this.itemsElms.map((child) => {
        const handler = (handlerEvent) => this.onMouseEnter(handlerEvent);
        child.addEventListener('mouseenter', handler);
        return {
          el: child,
          handler,
        };
      });

      this.updateClonePosition(event);
    },
    onMouseEnter(event) {
      const { target } = event;
      const targetIndex = this.itemsElms.indexOf(target);
      const elementIndex = this.itemsElms.indexOf(this.currentItemEl);

      if (targetIndex === elementIndex) {
        return;
      }

      if (targetIndex < elementIndex) {
        target.parentNode.insertBefore(this.currentItemEl, target);
      } else {
        target.parentNode.insertBefore(this.currentItemEl, target.nextSibling);
      }
    },
    onMouseMove(event) {
      this.updateClonePosition(event);
    },
    onMouseUp() {
      document.removeEventListener('mousemove', this.onMouseMoveHandler);
      document.removeEventListener('mouseup', this.onMouseUpHandler);

      this.handlers.forEach(({ el, handler }) => el.removeEventListener('mouseenter', handler));

      this.clone.parentNode.removeChild(this.clone);
      this.currentItemEl.classList.remove(GHOST_ITEM_CLASS);

      const index = this.itemsElms.indexOf(this.currentItemEl);
      this.items.splice(index, 0, this.items.splice(this.initialIndex, 1)[0]);

      this.clone = undefined;
      this.currentItemEl = undefined;
      this.initialIndex = -1;

      this.$emit('input', this.items, this.currentItem, index);
    },
    updateClonePosition(event) {
      const rect = this.currentItemEl.getBoundingClientRect();
      this.clone.style.left = `${rect.x}px`;
      this.clone.style.top = `${event.pageY - this.clone.offsetHeight / 2}px`;
    },
  },
};
</script>
