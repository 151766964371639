<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { ItemType, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as ProductColumns from '@/modules/product/view/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import { filters } from '@/modules/product/view/vods/vods.vue';
import * as Fields from '@/components/ui-form/fields';
import * as ProductFields from '@/modules/product/view/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_VODS_SERIALS,
      removeModelUrlFunction: ({ id }) => Urls.PRODUCTS_VODS_SERIALS_ID(id),
      saveModelUrlFunction: () => Urls.PRODUCTS_VODS_SERIALS,
      saveModelModalTitleFunction: () => 'ADD_SERIES',
      saveModelFormElementsFunction: () => [
        [
          Fields.TITLE,
          Fields.MAIN_CATEGORY(ItemType.VOD),
          ProductFields.YEAR,
          ProductFields.RATING,
          ProductFields.HD,
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL(),
        ],
      ],
      saveModelDefaultValues: {
        type: ItemType.SERIAL,
        type_: ItemType.SERIAL,
      },
      exportUrl: Urls.PRODUCTS_VODS_SERIALS_EXPORT,
      filters,
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.POSTER(),
          ProductColumns.TITLE,
          Columns.PLATFORMS,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
