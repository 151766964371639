<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import { UiFormCheckbox } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RemoveModelMixin, RoleMixin, SaveModelMixin } from '@/mixin';
import { commonTooltip } from '@/components/ui-form/fields';
import * as Fields from '@/components/ui-form/fields';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';

export default ListView.extend({
  mixins: [RemoveModelMixin, RoleMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    const recordsUrl = Urls.TENANTS_SECTIONS_TILE_ACTIVITIES(this.id);
    return {
      writeRole: RoleName.TENANT_MANAGEMENT_WRITE,
      recordsUrl,
      removeModelUrlFunction: ({ id }) => `${recordsUrl}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${recordsUrl}/${id}` : recordsUrl),
      saveModelModalTitleFunction: ({ id }) => (id ? 'COMMON_EDIT' : 'COMMON_ADD'),
      saveModelFormElementsFunction: () => [
        [
          Fields.NAME,
          {
            field: 'defaultTileActivity',
            component: UiFormCheckbox,
            componentOptions: {
              label: 'COMMON_DEFAULT_TILE_ACTIVITY',
              tooltip: commonTooltip('HELP_DEFAULT_TILE_ACTIVITY'),
            },
          },
        ],
      ],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'name',
            width: 150,
            isSortable: true,
            headingLabel: 'COMMON_NAME',
          },
          {
            field: 'defaultTileActivity',
            width: 150,
            headingLabel: 'COMMON_DEFAULT_TILE_ACTIVITY',
          },
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
