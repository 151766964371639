import Urls from '@/application/urls';
import i18n from '@/application/i18n';
import { VideoType } from '@/enums';
import Autocomplete from './widget/autocomplete.vue';
import DatePicker from './widget/date-picker.vue';
import Input from './widget/input.vue';
import MultiSwitch from './widget/multi-switch.vue';
import PriceInput from './widget/price-input.vue';
import Product from './widget/product.vue';
import SingleSwitch from './widget/single-switch.vue';

export const singleSwitch = (field, componentOptions) => ({ component: SingleSwitch, field, componentOptions });

export const autocomplete = (field, componentOptions) => ({ component: Autocomplete, field, componentOptions });

export const datePicker = (field, componentOptions) => ({ component: DatePicker, field, componentOptions });

export const input = (field, componentOptions) => ({ component: Input, field, componentOptions });

export const multiSwitch = (field, componentOptions) => ({ component: MultiSwitch, field, componentOptions });

export const priceInput = (field, componentOptions) => ({ component: PriceInput, field, componentOptions });

export const product = (field, componentOptions) => ({ component: Product, field, componentOptions });

export const ACTIVE = multiSwitch('active', { label: 'COMMON_ACTIVE_MALE' });

export const ADVISORS = autocomplete('advisors', { label: 'ADVISORS', dictType: 'ADVISOR' });

export const EXTERNAL_UID = input('externalUids', { label: 'COMMON_EXTERNAL_UID' });

export const MULTILINGUAL = multiSwitch('multilingual', { label: 'COMMON_MULTILINGUAL' });

export const RECORDING_STATUS = autocomplete('statuses', {
  label: 'COMMON_STATUS',
  dictUrl: Urls.PRODUCTS_LIVES_RECORDINGS_STATUSES,
});

export const RECORDING_LIVE = autocomplete('lives', {
  label: 'RECORDING_LIVE',
  dictUrl: Urls.ITEMS_TYPE_DICTIONARIES(VideoType.LIVE),
});

export const RECORDING_SUBSCRIBER = autocomplete('subscriberEmails', {
  label: 'COMMON_SUBSCRIBER',
  dictUrl: Urls.SUBSCRIBERS_DICTIONARIES,
});

export const CREATE_SINCE = () => datePicker('since', { label: 'COMMON_SINCE', timepicker: true, language: new Intl.Locale(i18n.language).language });

export const SINCE_MANDATORY = { ...CREATE_SINCE(), isMandatory: true };

export const SUBSCRIBER = autocomplete('subscribers', {
  label: 'COMMON_SUBSCRIBER',
  dictUrl: Urls.SUBSCRIBERS_DICTIONARIES,
});

export const PLATFORMS = autocomplete('platforms', { label: 'PLATFORM_NAME', dictType: 'PLATFORM' });

export const PRODUCT_ID = input('productIds', { label: 'COMMON_ID', type: 'number' });

export const CREATE_TILL = () => datePicker('till', { label: 'COMMON_TILL', timepicker: true, language: new Intl.Locale(i18n.language).language });

export const TILL_MANDATORY = { ...CREATE_TILL(), isMandatory: true };
