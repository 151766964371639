<template>
  <div v-if="product" class="details-form">
    <ui-product-file-medium
      video-type="CATCHUP"
      has-drm
      :product="product"
      present-audio-title
      :audios="product.audios"
      present-video-title
      :videos="product.videos"
      :title="$translate('ITEM_FILES_MEDIA')"
      :allowed="isAllowed"
      @change="load"
    />

    <ui-product-file-stripe
      :is-allowed="isAllowed"
      :product-id="product.id"
      :records="product.stripe ? [product.stripe] : []"
      @change="load"
    />

    <ui-product-file-images v-bind="coverImagesProps" @change="load" />

    <ui-product-file-images v-bind="bannerImagesProps" @change="load" />

    <ui-product-file-images v-bind="artworkImagesProps" @change="load" />
  </div>
</template>
<script>
import Urls from '@/application/urls';
import UiProductFileImages from '@/components/product-file-images/index.vue';
import UiProductFileMedium from '@/components/product-file-medium/index.vue';
import UiProductFileStripe from '@/components/product-file-stripe/index.vue';
import {
  AppEvent, ImageLabel, ImageType, RoleName,
} from '@/enums';
import http from '@/lib/http';
import { RoleMixin } from '@/mixin';

export default {
  components: {
    UiProductFileImages,
    UiProductFileMedium,
    UiProductFileStripe,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      product: undefined,
    };
  },
  computed: {
    coverImagesProps() {
      return {
        title: 'PROGRAMME_IMAGE_COVER',
        isAllowed: this.isAllowed,
        product: this.product,
        type: ImageType.COVER,
        images: this.product.images,
        settings: [
          {
            label: ImageLabel.ASPECT_16X9,
            scaler: { main: { dstw: 1920, dsth: 1080 } },
          },
        ],
      };
    },
    bannerImagesProps() {
      return {
        title: 'PROGRAMME_IMAGE_BANNER',
        isAllowed: this.isAllowed,
        product: this.product,
        type: ImageType.BANNER,
        images: this.product.banner,
        settings: [
          {
            label: ImageLabel.ASPECT_16X9,
            scaler: { main: { dstw: 1920, dsth: 1080 } },
          },
        ],
      };
    },
    artworkImagesProps() {
      return {
        title: 'PROGRAMME_IMAGE_ARTWORK',
        isAllowed: this.isAllowed,
        product: this.product,
        type: ImageType.ARTWORK,
        images: this.product.artworks,
        settings: [
          {
            label: ImageLabel.ASPECT_16X9,
            scaler: { main: { dstw: 1920, dsth: 1080 } },
          },
        ],
      };
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.product = await http
        .get(Urls.PRODUCTS_LIVES_PROGRAMMES_ID_PAGE(this.id, this.page))
        .then(({ data }) => data);
      this.$emit(AppEvent.MODEL, this.product);
    },
  },
};
</script>
