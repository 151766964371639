<template>
  <ui-number-input v-bind="$props" v-on="$listeners" />
</template>
<script>
import { UiNumberInput } from 'redge-media-web-ui';

export default {
  components: {
    UiNumberInput,
  },
  props: {
    ...UiNumberInput.props,
    precision: {
      type: UiNumberInput.props.precision.type,
      default: 2,
    },
  },
};
</script>
