import { UiModalPopup } from 'redge-media-web-ui';
import { AppEvent } from '@/enums';
import http from '@/lib/http';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';

export default {
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    isSaveModelButtonVisible() {
      return this.isAllowed;
    },
  },
  watch: {
    isSaveModelButtonVisible: {
      immediate: true,
      handler() {
        const addButton = {
          icon: 'icon-add',
          tooltip: 'HELP_ADD',
          onClick: this.createModel,
        };

        const isAddButtonAdded = this.buttons?.find(({ icon }) => icon === 'icon-add');

        if (isAddButtonAdded && !this.isSaveModelButtonVisible) {
          const index = this.buttons?.indexOf(addButton);
          this.buttons?.splice(index, 1);
        }

        if (!isAddButtonAdded && this.isSaveModelButtonVisible) {
          this.buttons?.push(addButton);
        }
      },
    },
  },
  methods: {
    openSaveModelModal(modal, promiseFunction) {
      return new Promise((resolve) => {
        modal.$on(AppEvent.SUCCESS, async (model) => {
          const finalModel = this.areSaveModelDefaultValuesMapped
            ? this.convertKeysToObj(model, this.saveModelDefaultValues, this.saveModelDefaultValuesObjectKey)
            : model;

          if (this.isSaving) {
            return;
          }

          try {
            this.isSaving = true;
            await promiseFunction(finalModel);
            this.isSaving = false;

            modal.close();
            await this.load?.();
            resolve();
            this.$modal.create(UiModalPopup, {
              text: this.$translate('COMMON_SAVE_SUCCESS'),
            });
          } catch (error) {
            this.isSaving = false;
            modal.error = error;
            throw error;
          }
        });
      });
    },
    convertKeysToObj(model, objectRequiredKeys, customObjectKey) {
      const payload = { ...model };
      const customObject = {};

      Object.keys(objectRequiredKeys).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(payload, key)) {
          customObject[key] = payload[key];
          delete payload[key];
        }
      });

      if (Object.keys(customObject).length > 0) {
        payload[customObjectKey] = customObject;
      }

      return payload;
    },
    saveModel(defaultValues, httpMethod) {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: this.saveModelFormElementsFunction,
        modalTitleFunction: this.saveModelModalTitleFunction,
        defaultValues,
      });

      const saveModelPromiseFunction = (model) => http[httpMethod](this.saveModelUrlFunction(model), model);

      return this.openSaveModelModal(modal, saveModelPromiseFunction);
    },
    createModel() {
      return this.saveModel(this.saveModelDefaultValues || {}, 'post');
    },
    editModel(model) {
      return this.saveModel({ ...this.saveModelDefaultValues, ...model }, 'put');
    },
  },
};
