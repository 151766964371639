import info from '@/application/info';
import http from '@/lib/http';
import Urls from '@/application/urls';

export default async (params) => {
  const appInfo = info.getApplicationInfoById('chat');

  if (!appInfo) {
    return null;
  }

  const { data } = await http.get(Urls.CHAT, { params });
  return `${appInfo.baseUrl}/chat/threads/${data.name}/metadata`;
};
