<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1 v-if="model" v-text="model.name"></h1>
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import UiTabs from '@/components/ui-tabs/index.vue';
import { Page } from '@/enums';
import Detail from './detail.vue';
import Files from './files.vue';

export default {
  components: {
    UiTabs,
    Detail,
    Files,
  },
  data() {
    return {
      tabs: [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'COMMON_FILES', page: Page.FILES },
      ],
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
  },
};
</script>
