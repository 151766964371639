<template>
  <app-menu-base
    :sections="availableSections"
    :is-folded="isFolded"
    :is-locked="isLocked"
    data-qa="app-menu"
    @toggle-section-expanded="toggleSectionExpanded"
    @set-is-folded="setIsFolded"
    @set-is-locked="setIsLocked"
  />
</template>
<script>
import { AppMenu } from 'redge-media-web-ui';
import menuSections from '@/application/menu-data';
import getExternalAppLink from '@/helper/get-external-app-link';
import operator from '@/application/operator';

const MENU_SETTING_FOLDED = 'redgalaxy-portal-bo-menu-folded';
const MENU_SETTING_LOCKED = 'redgalaxy-portal-bo-menu-locked';
const MENU_SETTING_EXPANDED_SECTIONS = 'redgalaxy-portal-bo-menu-expanded-sections';

const getFromLocalStorage = (name, defaultValue) => {
  const localStorageValue = localStorage.getItem(name);
  return localStorageValue !== null ? JSON.parse(localStorageValue) : defaultValue;
};

export default {
  components: {
    AppMenuBase: AppMenu,
  },
  data() {
    return {
      isLocked: getFromLocalStorage(MENU_SETTING_LOCKED, true),
      isFolded: getFromLocalStorage(MENU_SETTING_FOLDED, false),
      expandedSections: getFromLocalStorage(
        MENU_SETTING_EXPANDED_SECTIONS,
        menuSections.map(({ name }) => name),
      ),
    };
  },
  computed: {
    availableSections() {
      return operator.availableSections.map((section) => ({
        ...this.filterAndUpdateSectionItems(section),
        isExpanded: this.expandedSections.includes(section.name),
        isActive: this.$route.fullPath.startsWith(`/gui/${section.name}`),
      }));
    },
  },
  methods: {
    toggleSectionExpanded({ isExpanded, name }) {
      this.expandedSections = isExpanded
        ? this.expandedSections.filter((sectionName) => sectionName !== name)
        : [...this.expandedSections, name];
      localStorage.setItem(MENU_SETTING_EXPANDED_SECTIONS, JSON.stringify(this.expandedSections));
    },
    setIsFolded(value) {
      this.isFolded = value;
      localStorage.setItem(MENU_SETTING_FOLDED, value);
    },
    setIsLocked(value) {
      this.isLocked = value;
      localStorage.setItem(MENU_SETTING_LOCKED, value);
    },
    filterAndUpdateSectionItems(section) {
      const updateSectionItems = section.items.reduce((acc, item) => {
        if (item.appName) {
          const url = getExternalAppLink(item.appName);
          return url ? [...acc, { ...item, url }] : acc;
        }

        return [...acc, item];
      }, []);

      return {
        ...section,
        items: updateSectionItems,
      };
    },
  },
};
</script>
