<template>
  <div class="list-wrapper">
    <v-list-nav
      :collection-meta="collectionMeta"
      :filter-id="parseInt(routeQuery.filterId)"
      :available-filters-settings="availableFiltersSettings"
      :search="search"
      :keyword="routeQuery.keyword"
      :buttons="buttons"
      :filters="filters"
      :active-filters="activeFilters"
      @update-query-params="updateQueryParams"
    >
      <template slot="custom-buttons">
        <slot name="custom-buttons"></slot>
      </template>
    </v-list-nav>

    <slot name="body">
      <ui-table
        v-if="tableDefinition"
        v-bind="tableDefinition"
        :sort-field="routeQuery.sort"
        :sort-order="routeQuery.order"
        @sort="sortTable"
        @update-query-param="updateQueryParam"
      >
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
          <slot :name="slot" v-bind="scope"></slot>
        </template>
      </ui-table>
    </slot>

    <v-sidebar-filter
      v-if="filters"
      :filters="filters"
      :active-filters="activeFilters"
      :filter-id="parseInt(routeQuery.filterId)"
      :available-filters-settings="availableFiltersSettings"
      @update-query-params="updateQueryParams"
      @update-available-filters-settings="availableFiltersSettings = $event"
    />
  </div>
</template>
<script>
import { ListViewRouterMixin } from '@/mixin';
import { QueryParam } from '@/enums';
import { getActiveFilters } from '@/lib/settings-filters';
import { UiTable } from '@/components/ui-table';
import VSidebarFilter from '@/components/filter/index.vue';
import VListNav from './list-nav.vue';

export default {
  name: 'list-wrapper',
  components: {
    UiTable,
    VListNav,
    VSidebarFilter,
  },
  mixins: [ListViewRouterMixin],
  props: {
    search: {
      type: Boolean,
      default: true,
    },
    tableDefinition: Object,
  },
  data() {
    return {
      filters: [],
      buttons: [],
      collectionMeta: {},
      availableFiltersSettings: [],
    };
  },
  computed: {
    activeFilters() {
      return getActiveFilters(this.routeQuery, this.filters);
    },
  },
  methods: {
    sortTable(sort) {
      let order;

      if (this.routeQuery.sort === sort) {
        order = this.routeQuery.order === QueryParam.ASCENDING ? QueryParam.DESCENDING : QueryParam.ASCENDING;
      } else {
        order = this.tableDefinition.columns.find(({ field }) => field === sort).defaultSortOrder || QueryParam.ASCENDING;
      }

      this.updateRouteQuery({ sort, order });
    },
    updateQueryParam(name, value) {
      const valueAsString = value.toString();
      const valuesAsStrings = this.routeQuery[name] ? this.routeQuery[name].map((v) => v.toString()) : [];
      const modifiedValues = valuesAsStrings.includes(valueAsString)
        ? valuesAsStrings.filter((v) => v !== valueAsString)
        : [...valuesAsStrings, valueAsString];

      this.updateRouteQuery({ [name]: modifiedValues });
    },
    updateQueryParams(queryParams) {
      this.updateRouteQuery({ firstResult: 0, ...queryParams });
    },
  },
};
</script>
