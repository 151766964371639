<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.ITEMS_LOGOS,
      removeModelUrlFunction: ({ id }) => Urls.ITEMS_LOGOS_ID(id),
      saveModelUrlFunction: () => Urls.ITEMS_LOGOS,
      saveModelModalTitleFunction: () => 'ADD_LOGO',
      saveModelFormElementsFunction: () => [[Fields.NAME]],
      paramsConfig: {
        sort: QueryParam.NAME,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.POSTER((record) => ({ record, contain: true })),
          Columns.ITEM_NAME(({ id, name }) => ({ id, text: name, name: 'logos.detail' })),
          { ...Columns.EXTERNAL_UID, field: 'externalId' },
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
