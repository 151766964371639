<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
        >
          <ui-product-title :product="model" />
        </h1>
        <div class="details-container__header-icons">
          <div class="details-container__header-elements details-container__header-elements--left">
            <ui-external-application-icons :model="model" :is-model-active="isModelActive" />
          </div>
          <div class="details-container__header-elements">
            <ui-switch
              v-if="model"
              v-tooltip="activateModelButtonText"
              :disabled="!isAllowed"
              :value="isModelActive"
              class="details-container__header-element"
              @input="activateModel()"
            />
          </div>
        </div>
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import { Page, RoleName } from '@/enums';
import UiTabs from '@/components/ui-tabs/index.vue';
import UiProductTitle from '@/components/ui-product-title/index.vue';
import DisplaySchedules from '@/modules/product/view/vods/detail/display-schedules.vue';
import PaymentSchedules from '@/modules/product/view/vods/detail/payment-schedules.vue';
import Payments from '@/modules/product/view/vods/detail/payments.vue';
import Audits from '@/modules/product/view/vods/detail/audits.vue';
import Subscriptions from '@/modules/product/view/vods/detail/subscriptions.vue';
import { ActivateModelMixin, RoleMixin } from '@/mixin';
import UiExternalApplicationIcons from '@/components/ui-external-application-icons/index.vue';
import Detail from './detail.vue';
import Files from './files.vue';
import Products from './products.vue';

export default {
  components: {
    UiExternalApplicationIcons,
    UiSwitch,
    UiTabs,
    UiProductTitle,
    Detail,
    Products,
    Files,
    DisplaySchedules,
    PaymentSchedules,
    Payments,
    Subscriptions,
    Audits,
  },
  mixins: [ActivateModelMixin, RoleMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      tabs: [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'COMMON_PRODUCTS', page: Page.PRODUCTS },
        { label: 'COMMON_FILES', page: Page.FILES },
        { label: 'TAB_DISPLAY-SCHEDULES', page: Page.DISPLAY_SCHEDULES },
        { label: 'TAB_PAYMENT-SCHEDULES', page: Page.PAYMENT_SCHEDULES },
        { label: 'COMMON_PAYMENTS', page: Page.PAYMENTS },
        { label: 'COMMON_SUBSCRIPTIONS', page: Page.SUBSCRIPTIONS },
        { label: 'COMMON_EVENTS', page: Page.AUDITS },
      ],
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
  },
};
</script>
