<template>
  <div>
    <span
      v-if="record.video && record.status === RecordStatus.READY && hasRole(RoleName.CMS_READ)"
      v-tooltip="$translate('HELP_VIDEO')"
      class="icon-play cursor-pointer"
      @click="play(record.programRecordingId, MediumType.VIDEO, VideoType.RECORDING)"
    ></span>
    <span
      v-if="record.audio && record.status === RecordStatus.READY && hasRole(RoleName.CMS_READ)"
      v-tooltip="$translate('HELP_AUDIO_TITLE')"
      class="icon-audio cursor-pointer"
      @click="play(record.programRecordingId, MediumType.AUDIO, VideoType.RECORDING)"
    ></span>
    {{ record.name }}
  </div>
</template>
<script>
import { RoleMixin } from '@/mixin';
import PlayMixin from '@/mixin/play.vue';
import {
  MediumType, RecordStatus, RoleName, VideoType,
} from '@/enums';

export default {
  name: 'ui-table-recording-name',
  mixins: [PlayMixin, RoleMixin],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      MediumType,
      RecordStatus,
      RoleName,
      VideoType,
    };
  },
};
</script>
