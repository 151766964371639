<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import { UiFormNumberInput, UiFormTextInput } from 'redge-media-web-ui';
import { NumberDivider } from 'redge-media-web-utils';
import { ItemType, RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import Urls from '@/application/urls';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as ProductFields from '@/modules/product/view/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        isSaveButtonSticky: true,
        modelUrl: Urls.PRODUCTS_LIVES_ID_PAGE(this.id, this.page),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.EXTERNAL_UID,
            Fields.textInputField('externalEpgUid', 'LIVE_EPG_UID'),
            Fields.TITLE,
            {
              field: 'rank',
              isMandatory: true,
              component: UiFormNumberInput,
              componentOptions: {
                label: 'COMMON_RANK',
                tooltip: Fields.commonTooltip('HELP_RANK_DETERMINES_POSITION_IN_CHANNELS_LIST'),
              },
            },
            Fields.CREATE_SINCE(),
            Fields.CREATE_TILL(),
            ProductFields.RATING,
            ProductFields.RATING_EMBEDDED(model),
            ProductFields.LOGIN_REQUIRED,
            ProductFields.UHD,
            ProductFields.SHOW_RECOMMENDATIONS,
            Fields.checkboxField('coverAsSlides', 'LIVE_SLIDES'),
            Fields.checkboxField('visibleOnEpg', 'LIVE_VISIBLE_ON_EPG'),
            {
              field: 'color',
              component: UiFormTextInput,
              componentOptions: {
                label: 'COMMON_COLOR',
                tooltip: Fields.commonTooltip('HELP_LIVE_COLOR'),
              },
            },
            Fields.MAIN_CATEGORY(ItemType.LIVE),
            ProductFields.CATEGORIES(ItemType.LIVE),
            ProductFields.GENRES,
            Fields.CUSTOM_BADGE,
            Fields.PLATFORMS,
            ProductFields.CHROMECAST_PLATFORMS,
            Fields.CREATED_AT,
            Fields.MODIFIED_AT,
            Fields.textHeader('COMMON_CATCHUP'),
            {
              field: 'catchUpDuration',
              component: UiFormNumberInput,
              componentOptions: {
                label: 'LIVE_TIME_IN_DAYS',
                divider: NumberDivider.DAY_TO_SECONDS,
              },
            },
            { ...Fields.PLATFORMS, field: 'catchupServicePlatforms' },
            Fields.textHeader('LIVE_TIMESHIFT'),
            {
              field: 'timeshiftDuration',
              component: UiFormNumberInput,
              componentOptions: {
                label: 'LIVE_TIME_IN_MINUTES',
                divider: NumberDivider.MINUTE_TO_SECONDS,
              },
            },
            { ...Fields.PLATFORMS, field: 'timeshiftServicePlatforms' },
            Fields.textHeader('LIVE_STARTOVER'),
            { ...Fields.PLATFORMS, field: 'startoverServicePlatforms' },
            Fields.textHeader('COMMON_NPVR'),
            {
              field: 'npvrDuration',
              component: UiFormNumberInput,
              componentOptions: {
                label: 'LIVE_TIME_IN_DAYS',
                divider: NumberDivider.DAY_TO_SECONDS,
              },
            },
            { ...Fields.PLATFORMS, field: 'npvrServicePlatforms' },
          ],
          [
            ...ProductFields.GEOLOCK_FIELDS(model),
            Fields.HEADER_LEAD,
            Fields.LEAD,
            Fields.HEADER_DESCRIPTION,
            Fields.DESCRIPTION,
          ],
        ],
      };
    },
  },
};
</script>
