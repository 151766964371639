<script>
import Urls from '@/application/urls';
import * as Filters from '@/components/filter/filters';
import * as PaymentFilters from '@/modules/payment/view/filters';
import PaymentsList from '@/modules/payment/view/payment/index.vue';

export default PaymentsList.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      recordsUrl: Urls.SUBSCRIPTIONS_ID_PAYMENTS(this.id),
      exportUrl: undefined,
      filters: [Filters.EXTERNAL_UID, Filters.CREATE_SINCE(), Filters.CREATE_TILL(), PaymentFilters.PAYMENT_CURRENCY],
    };
  },
});
</script>
