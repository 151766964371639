<template>
  <div v-if="product" class="details-form">
    <ui-product-file-images v-bind="artworkImagesProps" @change="load" />
  </div>
</template>
<script>
import Urls from '@/application/urls';
import http from '@/lib/http';
import {
  AppEvent, ImageLabel, ImageType, RoleName,
} from '@/enums';
import { RoleMixin } from '@/mixin';
import UiProductFileImages from '@/components/product-file-images/index.vue';

export default {
  components: {
    UiProductFileImages,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      product: undefined,
    };
  },
  computed: {
    artworkImagesProps() {
      return {
        title: 'VOD_FILES_COVERS',
        isAllowed: this.isAllowed,
        product: this.product,
        type: ImageType.ARTWORK,
        images: this.product.images,
        settings: [
          {
            label: ImageLabel.ASPECT_16X9,
            scaler: { main: { dstw: 315, dsth: 177 } },
          },
          {
            label: ImageLabel.ASPECT_1X1,
            scaler: { main: { dstw: 377, dsth: 377 } },
          },
        ],
      };
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.product = await http.get(Urls.PRODUCTS_SPECIAL_ID(this.id)).then(({ data }) => data);
      this.$emit(AppEvent.MODEL, this.product);
    },
  },
};
</script>
