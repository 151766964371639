<template>
  <div class="stats">
    <div v-if="statistics" class="stats__wrapper">
      <div v-for="(statistic, statisticIndex) in statistics" :key="statisticIndex" class="stat-item">
        <h1 class="stat-item__header" v-text="$translate(statistic.name)"></h1>
        <ol
          class="stat-item__list"
          :class="{ 'pointer-events-none': !hasRole(RoleName.CMS_READ) && statistic.type === RecordType.ITEM }"
        >
          <li v-for="(data, dataIndex) in statistic.data" :key="dataIndex" class="stat-item__list-item">
            <label class="stat-item__label">
              <router-link :to="data.link" :class="{ 'stat-item__label--disabled': !isAllowed }">
                <ui-product-title :product="data" />
              </router-link>
            </label>
            <span class="stat-item__value" v-text="data.value"></span>
          </li>
        </ol>
      </div>
    </div>
    <ui-loader v-else />
  </div>
</template>
<script>
import http from '@/lib/http';
import Urls from '@/application/urls';
import { productLink } from '@/helper';
import { Page, RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import UiProductTitle from '@/components/ui-product-title/index.vue';

const RecordType = {
  ITEM: 'ITEM',
  SUBSCRIBER: 'SUBSCRIBER',
};

export default {
  components: {
    UiProductTitle,
  },
  mixins: [RoleMixin],
  data() {
    return {
      RecordType,
      RoleName,
      writeRole: RoleName.CRM_WRITE,
      statistics: undefined,
    };
  },
  async mounted() {
    const statistics = await http.get(Urls.STATISTICS).then((res) => res.data);

    const statisticsData = await Promise.all(
      statistics.map(({ id }) => http
        .get(Urls.STATISTICS_ID(id))
        .then((res) => res.data)
        .catch(() => {})),
    );

    this.statistics = statisticsData.filter(Boolean).map((statistic) => {
      const [type] = Object.values(statistic.fields[0]);

      const data = statistic.results.map((result) => {
        const [_item, value] = Object.values(result);
        const item = { value };

        // eslint-disable-next-line default-case
        switch (type) {
          case RecordType.ITEM:
            item.link = productLink(_item);
            item.title = _item.title;
            break;
          case RecordType.SUBSCRIBER:
            item.link = { name: 'subscriber.detail', params: { id: _item.id, page: Page.DETAIL } };
            item.title = _item.email;
            break;
        }

        return item;
      });

      return {
        name: statistic.name,
        data,
        type,
      };
    });
  },
};
</script>
