<template>
  <ad-break-list v-bind="adBreakListProps" v-on="$listeners" />
</template>
<script>
import AdBreakList from '@/components/ad-break-list/index.vue';
import { AdBreakPlacement } from '@/enums';

export default {
  components: {
    AdBreakList,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    adBreakListProps() {
      return {
        id: this.id,
        defaultPlacementValue: AdBreakPlacement.PREROLL,
        isOffsetColumnHidden: true,
      };
    },
  },
};
</script>
