import { Page } from '@/enums';
import { UiTableDetailLinkResource, UiTableDetailLinkResourceOperator } from '@/components/ui-table';
import { ACTIONS, BUTTON_MORE, BUTTON_REMOVE } from '@/components/ui-table/columns';

export const ACTIONS_DETAIL = (openDetailMethod) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: [BUTTON_MORE(() => openDetailMethod(record))],
  });

  return ACTIONS(true, dataComponentPropsMapper);
};

export const ACTIONS_OPERATOR = (removeRecordFunction, operatorId) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: record.id === operatorId ? [] : [BUTTON_REMOVE(() => removeRecordFunction(record))],
  });

  return ACTIONS(true, dataComponentPropsMapper);
};

export const AUDIT_EVENT_TYPE = {
  field: 'eventType',
  width: 240,
  isSortable: true,
  headingLabel: 'COMMON_ACTION',
};

export const AUDIT_RESOURCE_NAME = {
  field: 'resourceName',
  minWidth: 200,
  headingLabel: 'COMMON_NAME',
  dataComponent: UiTableDetailLinkResource,
};

export const AUDIT_RESOURCE_TYPE = {
  field: 'resourceType',
  width: 240,
  isSortable: true,
  headingLabel: 'COMMON_TYPE',
};

export const AUDIT_OPERATOR = {
  field: 'operator',
  minWidth: 240,
  headingLabel: 'COMMON_OPERATOR',
  dataComponent: UiTableDetailLinkResourceOperator,
  dataComponentPropsMapper: ({ operator }) => ({ operator, page: Page.AUDITS }),
};

export const BUTTON_SYNCHRONIZE_INCREMENTAL = (onClick) => ({
  tooltip: 'HELP_SYNCHRONIZATION_INCREMENTAL',
  icon: 'icon-efficiency',
  onClick,
});

export const TIMER_ACTIONS = (synchronizeItemMethod) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: record.manual
      ? [BUTTON_SYNCHRONIZE_INCREMENTAL(() => synchronizeItemMethod({ id: record.id, incremental: false }))]
      : [],
  });
  return ACTIONS(true, dataComponentPropsMapper);
};
