import { UiModalPopup } from 'redge-media-web-ui';

export default (error, vm) => {
  const errorResponseData = error?.response?.data;
  const isErrorDataArray = Array.isArray(errorResponseData);
  let code = vm.$translate(errorResponseData?.code || error?.code);

  if (isErrorDataArray) {
    code = errorResponseData.reduce((acc, { code: errorCode }) => `${acc}${vm.$translate(errorCode)}<br/>`, '');
    vm.$modal.close();
  }

  if (code) {
    vm.$modal.create(UiModalPopup, {
      text: vm.$translate(code),
      modalModifier: 'ui-modal-popup--error',
    });

    return;
  }

  throw error;
};
