<template>
  <ui-item-title :class="{ 'opacity-4': option.active === false }" :item="{ title: option[field] }" />
</template>
<script>
import UiItemTitle from '@/components/ui-item-title/index.vue';

export default {
  name: 'ui-multiselect-option-item',
  components: {
    UiItemTitle,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
  },
};
</script>
