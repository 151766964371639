<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { UiTableDetailLink } from '@/components/ui-table';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';
import * as ContentFields from '@/modules/content/view/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.ITEMS_CATEGORIES,
      removeModelUrlFunction: ({ id }) => Urls.ITEMS_CATEGORIES_ID(id),
      saveModelUrlFunction: () => Urls.ITEMS_CATEGORIES,
      saveModelModalTitleFunction: () => 'ADD_CATEGORY',
      saveModelFormElementsFunction: () => [
        [Fields.KEY, Fields.NAME, ContentFields.SLUG, Fields.TYPE(Urls.ITEMS_CATEGORIES_TYPE('types'))],
      ],
      paramsConfig: {
        sort: QueryParam.RANK,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'key',
            width: 180,
            isSortable: true,
            headingLabel: 'COMMON_KEY',
            dataComponent: UiTableDetailLink,
            dataComponentPropsMapper: ({ id, key }) => ({ id, text: key, name: 'categories.detail' }),
          },
          Columns.NAME,
          Columns.RANK,
          {
            field: 'seoName',
            minWidth: 180,
            isSortable: true,
            headingLabel: 'CONTENT_CATEGORY_SEO_NAME',
          },
          {
            field: 'seoNameSingular',
            minWidth: 180,
            isSortable: true,
            headingLabel: 'CONTENT_CATEGORY_SEO_NAME_SINGULAR',
          },
          {
            field: 'slug',
            width: 180,
            isSortable: true,
            headingLabel: 'COMMON_SLUG',
          },
          { ...Columns.TYPE, isSortable: true },
          Columns.PLATFORMS,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
