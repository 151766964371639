<template>
  <button
    v-tooltip="$translate(tooltip)"
    class="button"
    type="button"
    @click.prevent="$emit('click', $event)"
  >
    <span :class="icon"></span>
  </button>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>
