import {
  UiDatePickerInput,
  UiFormCheckbox,
  UiFormHeader,
  UiFormNumberInput,
  UiFormTextInput,
  UiFormTextarea,
} from 'redge-media-web-ui';
import { translate } from '@/helper';
import Urls from '@/application/urls';
import http from '@/lib/http';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import UiFormDictionarySelect from '@/components/ui-form/ui-form-dictionary-select.vue';
import UiFormMultipleChoice from '@/components/ui-form/ui-form-multiple-choice.vue';
import UiFormPriceInput from '@/components/ui-form/ui-form-price-input.vue';
import UiFormTextInputWithFileUpload from '@/components/ui-form/ui-form-text-input-with-file-upload.vue';
import {
  UiMultiselectOptionProduct,
  UiMultiselectOptionWithNameHover,
  UiMultiselectSingleLabelWithNameHover,
} from '@/components/ui-multiselect';
import { ItemType } from '@/enums';
import getChatExternalAppLink from '@/helper/get-chat-external-app-link';
import invalidateCache from '@/helper/invalidate-cache';
import i18n from '@/application/i18n';

export const textInputField = (field, label, isMandatory = false, disabled, language) => ({
  field,
  isMandatory,
  component: UiFormTextInput,
  componentOptions: {
    label,
    disabled,
    language,
  },
});

export const checkboxField = (...attrs) => ({
  ...textInputField(...attrs),
  component: UiFormCheckbox,
});

export const datePickerField = (...attrs) => ({
  ...textInputField(...attrs),
  component: UiDatePickerInput,
});

export const textareaField = (...attrs) => ({
  ...textInputField(...attrs),
  component: UiFormTextarea,
});

export const numberInputField = (...attrs) => ({
  ...textInputField(...attrs),
  component: UiFormNumberInput,
});

export const priceInputField = (...attrs) => ({
  ...textInputField(...attrs),
  component: UiFormPriceInput,
});

export const textHeader = (text) => ({
  component: UiFormHeader,
  componentOptions: {
    text,
  },
});

export const textHeaderWithIcon = (text, iconClass, iconTooltipText, onClickFunction) => ({
  component: UiFormHeader,
  componentOptions: {
    text,
    iconClass,
    iconTooltipText,
    on: {
      'icon-click': onClickFunction,
    },
  },
});

export const textInputWitLink = (field, label, onLinkClick, isHidden = false) => ({
  field,
  isHidden,
  component: UiFormTextInput,
  componentOptions: {
    label,
    disabled: true,
    iconClass: 'icon-link',
    on: {
      'icon-click': onLinkClick,
    },
  },
});

export const commonTooltip = (text) => ({
  value: text,
  modifiers: {
    icon: true,
    translate: true,
  },
});

export const CREATE_HEADER_CHAT = (id, label) => textHeaderWithIcon(label, 'icon-settings', 'HELP_COMMON_CHAT_MANAGEMENT', async () => {
  const externalAppLink = await getChatExternalAppLink({ itemId: id });
  window.open(externalAppLink, '_blank');
});

export const CUSTOM_BADGE = {
  field: 'customBadge',
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'ITEM_CUSTOM_BADGE',
    dictType: 'CUSTOM_BADGE',
    field: 'key',
    slots: {
      tag: UiMultiselectSingleLabelWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
    'data-qa': 'vod-custom-badge',
    allowEmpty: true,
  },
};

export const CREATED_AT = {
  field: 'createdAt',
  component: UiDatePickerInput,
  componentOptions: {
    label: 'COMMON_CREATED',
    disabled: true,
  },
};

export const DESCRIPTION = {
  field: 'description',
  component: () => import('@/components/ui-text-editor/ui-text-editor.vue'),
  componentOptions: {
    'data-qa': 'vod-description',
    config: {
      invalid_elements: 'pre',
    },
  },
};

export const EXTERNAL_UID = {
  field: 'externalUid',
  component: UiFormTextInput,
  componentOptions: {
    label: 'COMMON_EXTERNAL_UID',
    disabled: true,
    'data-qa': 'vod-external-uid',
  },
};

export const EXTERNAL_WEB_URL = {
  field: 'externalWebUrl',
  component: UiFormTextInput,
  componentOptions: {
    label: 'COMMON_EXTERNAL_WEB_URL',
  },
};

export const HEADER_DESCRIPTION = textHeader('COMMON_DESCRIPTION');

export const HEADER_LEAD = textHeader('ITEM_LEAD');

export const HEADER_METADATA = textHeader('COMMON_METADATA');

export const CREATE_HEADER_METADATA_WITH_INVALIDATION = (id, label, cacheType) => textHeaderWithIcon(label, 'icon-block', 'HELP_CACHE_INVALIDATION', async () => invalidateCache(cacheType, id));

export const ITEM_TYPE = (model, productTypes) => ({
  field: 'itemType',
  isMandatory: true,
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'COMMON_PRODUCT_TYPE',
    getLabel: ({ name }) => translate(`PRODUCT_TYPE_${name}`),
    dict: productTypes.map((name) => ({ name })),
    on: {
      input: () => {
        model._selectedSeasonParent = {};
        model._selectedSeriesParent = {};
        model._selectedLiveParent = {};
        model._selectedProduct = {};
        model.productId = '';
      },
    },
  },
});

export const KEY = textInputField('key', 'COMMON_KEY', true);

export const LANGUAGE = {
  field: 'language',
  isMandatory: true,
  component: UiFormDictionarySelect,
  componentOptions: {
    field: 'key',
    label: 'COMMON_LANGUAGE',
    dictType: 'MEDIA_LANGUAGE',
    getLabel: ({ key, name }) => `${key} (${name})`,
  },
};

export const LEAD = {
  field: 'lead',
  component: UiFormTextarea,
  componentOptions: {
    'data-qa': 'vod-short-description',
  },
};

export const LOGO = {
  field: 'logo',
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'COMMON_LOGO',
    dictUrl: Urls.ITEMS_LOGOS,
    valueAsObject: true,
    allowEmpty: true,
    'data-qa': 'vod-logo',
  },
};

export const MAIN_CATEGORY = (itemsCategoriesType, isHidden = false) => ({
  field: 'mainCategory',
  isMandatory: true,
  isHidden,
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'COMMON_CATEGORY',
    dictUrl: Urls.ITEMS_CATEGORIES_TYPE(itemsCategoriesType),
    field: 'key',
    slots: {
      singleLabel: UiMultiselectSingleLabelWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
    valueAsObject: true,
    'data-qa': 'vod-main-category',
  },
});

export const MODIFIED_AT = {
  field: 'modifiedAt',
  component: UiDatePickerInput,
  componentOptions: {
    label: 'COMMON_MODIFIED',
    disabled: true,
  },
};

export const NAME = textInputField('name', 'COMMON_NAME', true);

export const PLATFORMS = {
  field: 'platforms',
  component: UiFormMultipleChoice,
  componentOptions: {
    label: 'PLATFORM_NAME',
    dictType: 'PLATFORM',
    'data-qa': 'vod-platforms',
  },
};

export const PLATFORMS_MANDATORY = (model) => ({
  ...PLATFORMS,
  isMandatory: true,
  componentOptions: {
    ...PLATFORMS.componentOptions,
    on: {
      fetched: (records) => {
        if (!model.platforms?.length) {
          model.platforms = records;
        }
      },
    },
  },
});

export const PROVIDER = {
  field: 'provider',
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'PRODUCT_PROVIDER',
    dictType: 'PROVIDER',
    field: 'key',
    slots: {
      singleLabel: UiMultiselectSingleLabelWithNameHover,
      option: UiMultiselectOptionWithNameHover,
    },
    'data-qa': 'vod-provider',
    allowEmpty: true,
  },
};

export const ROLES = (dictUrl, isMandatory = true, disabled = false) => ({
  field: 'roles',
  isMandatory,
  component: UiFormMultipleChoice,
  componentOptions: {
    label: 'COMMON_ROLE',
    field: 'name',
    disabled,
    dictUrl,
  },
});

export const RANK = numberInputField('rank', 'COMMON_RANK', true);

export const RANK_NOT_MANDATORY = numberInputField('rank', 'COMMON_RANK');

export const SELECTED_BUNDLE = (model, field = 'bundleId') => ({
  field: '_selectedBundle',
  fieldForErrorCode: field,
  isMandatory: true,
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'COMMON_BUNDLE',
    dictUrl: Urls.ITEMS_TYPE_DICTIONARIES(ItemType.BUNDLE),
    slots: {
      option: UiMultiselectOptionProduct,
    },
    isOptionDisabled: (record) => !record.name,
    maxResults: 50,
    on: {
      input: () => {
        model[field] = model._selectedBundle?.id;
      },
    },
    'data-qa': 'vod-selected-bundle',
  },
});

export const SELECTED_SERIES_PARENT = (model, isHidden) => ({
  field: '_selectedSeriesParent',
  isHidden,
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'COMMON_SERIES',
    dictUrl: Urls.ITEMS_TYPE_DICTIONARIES(ItemType.SERIAL),
    slots: {
      option: UiMultiselectOptionProduct,
    },
    isOptionDisabled: (record) => !record.name,
    maxResults: 50,
    allowEmpty: true,
    on: {
      input: () => {
        model._selectedProduct = {};
        model._selectedSeasonParent = {};
      },
    },
  },
});

export const SELECTED_SEASON_PARENT = (model, isHidden) => ({
  field: '_selectedSeasonParent',
  isHidden,
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'COMMON_SEASON',
    dictUrl: model._selectedSeriesParent
      ? Urls.ITEMS_PARENT_ID_TYPE_DICTIONARIES(model._selectedSeriesParent.id, ItemType.SEASON)
      : Urls.ITEMS_TYPE_DICTIONARIES(ItemType.SEASON),
    slots: {
      option: UiMultiselectOptionProduct,
    },
    isOptionDisabled: (record) => !record.name,
    maxResults: 50,
    allowEmpty: true,
    on: {
      input: () => {
        model._selectedProduct = {};
      },
    },
  },
});

export const SELECTED_LIVE_PARENT = (model, isHidden) => ({
  field: '_selectedLiveParent',
  isHidden,
  component: UiFormAutocompleteInline,
  componentOptions: {
    label: 'COMMON_LIVE',
    dictUrl: Urls.ITEMS_TYPE_DICTIONARIES(ItemType.LIVE),
    slots: {
      option: UiMultiselectOptionProduct,
    },
    isOptionDisabled: (record) => !record.name,
    maxResults: 50,
    allowEmpty: true,
    on: {
      input: () => {
        model._selectedProduct = {};
      },
    },
  },
});

export const SELECTED_PRODUCT_WITH_PARENT = (model, singlePayable) => {
  const TypeToParentId = {
    [ItemType.EPISODE]: model._selectedSeasonParent?.id,
    [ItemType.SEASON]: model._selectedSeriesParent?.id,
    [ItemType.PROGRAMME]: model._selectedLiveParent?.id,
  };

  const parentId = TypeToParentId[model.itemType];

  const dictUrlFunction = (url) => (singlePayable ? `${url}?singlePayable=true` : url);
  const url = parentId
    ? Urls.ITEMS_PARENT_ID_TYPE_DICTIONARIES(parentId, model.itemType)
    : dictUrlFunction(Urls.ITEMS_TYPE_DICTIONARIES(model.itemType));

  return {
    field: '_selectedProduct',
    fieldForErrorCode: 'productId',
    isHidden: !!model.id || model._isSingle,
    isMandatory: true,
    component: UiFormAutocompleteInline,
    componentOptions: {
      label: 'COMMON_PRODUCT',
      dictUrl: url,
      slots: {
        option: UiMultiselectOptionProduct,
      },
      isOptionDisabled: (record) => !record.name,
      maxResults: 50,
      on: {
        input: () => {
          model.productId = model._selectedProduct?.id;
        },
      },
    },
  };
};

export const CREATE_SINCE = () => ({
  ...datePickerField('since', 'COMMON_SINCE', true, undefined, new Intl.Locale(i18n.language).language),
  'data-qa': 'vod-since-date',
});

export const SUBSCRIBER = (vm, model, page) => textInputWitLink('subscriber.email', 'COMMON_SUBSCRIBER', () => vm.$router.push({
  name: 'subscriber.detail',
  params: { id: model.subscriber.id, page },
}));

export const CREATE_TILL = () => ({
  ...datePickerField('till', 'COMMON_TILL', undefined, undefined, new Intl.Locale(i18n.language).language),
  'data-qa': 'vod-till-date',
});

export const CREATE_TILL_MANDATORY = () => ({
  ...CREATE_TILL(),
  isMandatory: true,
});

export const TITLE = {
  field: 'title',
  'data-qa': 'vod-title',
  component: UiFormTextInput,
  componentOptions: {
    label: 'COMMON_TITLE',
    'data-qa': 'vod-title-input',
  },
};

export const TYPE = (dictUrl) => ({
  field: 'type',
  isMandatory: true,
  component: UiFormDictionarySelect,
  componentOptions: {
    label: 'COMMON_TYPE',
    dictUrl,
    'data-qa': 'select-box-type',
  },
});

export const TYPE_DISABLED = textInputField('type', 'COMMON_TYPE', false, true);

export const URL = textInputField('url', 'COMMON_URL', true);

export const URL_WITH_FILE_UPLOAD = (model, field = 'url', label = 'COMMON_URL', isIconVisible = true, loader) => ({
  field,
  isMandatory: true,
  component: UiFormTextInputWithFileUpload,
  componentOptions: {
    label,
    on: {
      'file-upload': async (e) => {
        const file = e.target.files[0];

        loader?.show();

        try {
          model[field] = await http
            .put(Urls.FILES_UPLOAD, file, {
              params: { fileName: file.name },
              headers: {
                'Content-Type': 'application/octet-stream',
              },
            })
            .then(({ data }) => data);
        } catch {}

        loader?.hide();
      },
    },
    isIconVisible,
    tooltip: model?.[field]?.startsWith('http:') ? commonTooltip('HELP_HTTP_WARNING') : undefined,
    iconTooltip: isIconVisible ? translate('HELP_MAXIMUM_FILE_SIZE') : undefined,
  },
});
