<script>
import { UiFormNumberInput } from 'redge-media-web-ui';
import { NumberDivider } from 'redge-media-web-utils';
import Urls from '@/application/urls';
import { Constants, ItemType, RoleName } from '@/enums';
import { SaveModelMixin } from '@/mixin';
import * as Filters from '@/components/filter/filters';
import PaymentsList from '@/modules/payment/view/payment/index.vue';
import * as PaymentFilters from '@/modules/payment/view/filters';
import * as Fields from '@/components/ui-form/fields';

export default PaymentsList.extend({
  mixins: [SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_ID_PAYMENTS(this.id),
      saveModelUrlFunction: () => Urls.SUBSCRIBERS_ID_PAYMENTS_FREE(this.id),
      saveModelModalTitleFunction: () => 'ADD_PRODUCT',
      saveModelFormElementsFunction: (model) => [
        [
          {
            field: 'paymentDuration',
            isMandatory: true,
            component: UiFormNumberInput,
            componentOptions: {
              label: 'SCHEDULE_PAYMENT_DURATION_HOURS',
              divider: NumberDivider.HOUR_TO_SECONDS,
            },
          },
          Fields.ITEM_TYPE(model, Constants.ALL_PRODUCT_TYPES),
          Fields.SELECTED_SERIES_PARENT(model, ![ItemType.SEASON, ItemType.EPISODE].includes(model.itemType)),
          Fields.SELECTED_SEASON_PARENT(model, model.itemType !== ItemType.EPISODE),
          Fields.SELECTED_PRODUCT_WITH_PARENT(model),
        ],
      ],
      saveModelDefaultValues: {
        paymentDuration: NumberDivider.DAY_TO_SECONDS * 2,
        itemType: ItemType.VOD,
      },
      exportUrl: undefined,
      filters: [
        Filters.SINCE_MANDATORY,
        Filters.TILL_MANDATORY,
        PaymentFilters.CURRENT_ACTIVE,
        PaymentFilters.PRODUCTS,
        PaymentFilters.PAYMENT_STATUS,
        PaymentFilters.PAYMENT_CHANNEL,
        PaymentFilters.PAYMENT_SYSTEM,
        Filters.EXTERNAL_UID,
        PaymentFilters.PRICE_GREATER,
        PaymentFilters.PRICE_LESS,
        PaymentFilters.PROMOTION_ID,
        PaymentFilters.SUBSCRIPTION_ID,
      ],
      isSubscriberView: true,
    };
  },
});
</script>
