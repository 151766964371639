<template>
  <div class="ui-table-item-details">
    <div class="ui-table-item-details__top">
      <h2 class="ui-table-item-details__header">
        <router-link v-tooltip="record.title" :to="productLink(record)" class="ui-table-item-details__link">
          <ui-product-title :product="record" data-qa="ui-table-item-details-text" />
        </router-link>
      </h2>
      <button v-if="record.uhd" class="ui-table-item-details__pill" @click="updateQueryParam('uhd', true)">
        UHD
      </button>
    </div>
    <p v-if="description" class="ui-table-item-details__description" v-text="description"></p>
    <div class="ui-table-item-details__pills">
      <button
        v-if="record.mainCategory"
        v-tooltip="record.mainCategory.name"
        class="ui-table-item-details__pill ui-table-item-details__pill--main"
        @click="updateQueryParam('mainCategories', record.mainCategory.key)"
        v-text="record.mainCategory.key"
      ></button>
      <button
        v-for="category in record.categories"
        :key="category.key"
        v-tooltip="category.name"
        class="ui-table-item-details__pill ui-table-item-details__pill--category"
        @click="updateQueryParam('categories', category.key)"
        v-text="category.key"
      ></button>
      <button
        v-for="displayScheduleType in displayScheduleTypes"
        :key="displayScheduleType"
        class="ui-table-item-details__pill ui-table-item-details__pill--display-schedule-type"
        @click="updateQueryParam('displayScheduleTypes', displayScheduleType)"
        v-text="$translate(`DISPLAY_SCHEDULE_${displayScheduleType}`)"
      ></button>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { DisplayScheduleType, ItemType } from '@/enums';
import { formatHourAndMinutes, productLink, translate } from '@/helper';
import UiProductTitle from '@/components/ui-product-title/index.vue';

export default {
  name: 'ui-table-item-details',
  components: {
    UiProductTitle,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    description() {
      const {
        type, year, duration, since, till,
      } = this.record;
      const calculatedDuration = type === ItemType.PROGRAMME ? dayjs(till).diff(dayjs(since), 'second') : duration;
      const texts = [
        ...(year ? [`${translate('PRODUCT_YEAR')}: ${year}`] : []),
        ...(calculatedDuration
          ? [`${translate('PRODUCT_DURATION')}: ${formatHourAndMinutes(calculatedDuration)}`]
          : []),
      ];
      return texts.join(', ');
    },
    displayScheduleTypes() {
      const displaySchedules = this.record.displaySchedules || [];
      const allDisplayScheduleTypes = displaySchedules.map(({ type }) => type);
      return [...new Set(allDisplayScheduleTypes)].filter((type) => type !== DisplayScheduleType.NORMAL);
    },
  },
  methods: {
    productLink,
    updateQueryParam(name, value) {
      this.$emit('update-query-param', name, value);
    },
  },
};
</script>
