<template>
  <div
    v-if="section"
    ref="section"
    data-sortable="list"
    class="section"
  >
    <ui-section-header
      :section="section"
      :is-allowed="isAllowed"
      :is-draggable="isDraggable"
      :is-expanded="isExpanded"
      @edit="$router.push({ name: 'section.detail', params: { id: section.id, page: Page.DETAIL } })"
      @remove="removeSection"
      @toggle="toggleList"
    />

    <div
      v-if="isExpanded"
      class="section__list"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <div class="section-item-list droppable" :data-id="section.id">
        <div class="section-item-list-wrapper">
          <template v-if="elements">
            <v-section-element
              v-for="element in elements"
              :key="element.id"
              :element="element"
              :platforms="platforms"
              :is-allowed="isAllowed"
              @remove="removeElement(element)"
              @edit="editElement(element)"
            />
          </template>
        </div>

        <div class="drag-invitation responsive-item-wrapper">
          <div class="responsive-item">
            <div class="image-wrapper">
              <div class="image" style="background-image: url(/static/img/drag.png)"></div>
            </div>
            <div class="title" v-text="$translate('WIDGET_DRAG_AND_DROP_MESSAGE')"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { UiFormNumberInput, UiModalConfirm } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import {
  AppEvent, ContentType, Page, RoleName, SectionLayoutType, SectionType,
} from '@/enums';
import EventBus from '@/application/event-bus';
import http from '@/lib/http';
import Sortable from '@/helper/sortable';
import { RoleMixin, SaveModelMixin } from '@/mixin';
import * as Fields from '@/components/ui-form/fields';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';
import UiSectionHeader from '@/components/ui-section/ui-section-header.vue';
import VSectionElement from './section-element.vue';

const SUBSCRIBER_SECTION_TYPES = [
  SectionType.SUBSCRIBER_FAVOURITE,
  SectionType.SUBSCRIBER_WATCHED,
  SectionType.SUBSCRIBER_RECORDING,
];

const getAlgorithmFormElements = () => [
  [
    Fields.numberInputField('maxResults', 'SECTION_ALGORITHM_LIMIT'),
    Fields.textareaField('params', 'SECTION_ALGORITHM_PARAMETERS'),
    Fields.textareaField('defaultParams', 'SECTION_DEFAULT_PARAMETERS', false, true),
  ],
];

const getProductFormElements = ({ layoutDict }) => [
  [
    Fields.CREATE_SINCE(),
    Fields.CREATE_TILL(),
    {
      ...Fields.numberInputField('elementsLimit', 'COMMON_ELEMENTS_LIMIT'),
      isHidden: layoutDict?.name !== SectionLayoutType.COLLECTION,
    },
    {
      field: 'displayDuration',
      component: UiFormNumberInput,
      isHidden: layoutDict?.name !== SectionLayoutType.BANNER,
      componentOptions: {
        label: 'SECTION_ELEMENT_DISPLAY_TIME',
        tooltip: Fields.commonTooltip('HELP_SECTION_ELEMENT_DISPLAY_TIME'),
      },
    },
  ],
];

export default {
  components: {
    UiSectionHeader,
    VSectionElement,
  },
  mixins: [RoleMixin, SaveModelMixin],
  props: {
    section: {
      type: Object,
      required: true,
    },
    isDraggable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      isExpanded: false,
      elements: [],
      platforms: [],
      Page,
    };
  },
  watch: {
    elements() {
      this.$nextTick(() => this.initElmsSortable());
    },
  },
  beforeDestroy() {
    EventBus.$off('ADD_ELEMENT_TO_SECTION', this.addElement);
  },
  methods: {
    async load() {
      const {
        data: { platforms, algorithms = [], elements = [] },
      } = await http.get(Urls.SECTIONS_ID_ELEMENTS(this.section.id));

      this.platforms = platforms.map(({ name }) => name);

      this.elements = [
        ...elements.map((element) => ({ ...element, contentType: ContentType.ELEMENT })),
        ...algorithms.map(({ algorithm: item, ...element }) => ({
          ...element,
          item,
          contentType: ContentType.ALGORITHM,
          defaultParams: item.defaultParams,
        })),
      ];
    },
    toggleList() {
      this.isExpanded = !this.isExpanded;

      if (this.isExpanded) {
        this.initElmsSortable();
        this.load();
      }
    },
    removeSection() {
      this.$emit(AppEvent.REMOVE, this.section.id);
    },
    initElmsSortable() {
      this.$nextTick(() => {
        // eslint-disable-next-line no-new
        new Sortable($('.sortable-item'), {
          handle: '.section-item-drag',
          ghostClass: 'section-item-ghost',
          parentClass: 'section',
          onSwap: (currentElm) => {
            const id = currentElm.data('id');
            const rank = currentElm.index();
            this.shiftElement(id, rank);
          },
        });
      });
    },
    shiftElement(id, rank) {
      const model = this.elements.find((element) => element.id === id);
      const url = model.contentType === ContentType.ELEMENT
        ? Urls.SECTIONS_ID_SHIFT_ELEMENTS_ID
        : Urls.SECTIONS_ID_SHIFT_ALGORITHMS_ID;

      return http.put(url(this.section.id, id), null, { params: { rank } });
    },
    async removeElement({ contentType, id }) {
      const url = contentType === ContentType.ELEMENT ? Urls.SECTIONS_ID_ELEMENTS : Urls.SECTIONS_ID_ALGORITHMS;

      await http.delete(`${url(this.section.id)}/${id}`);

      this.elements = this.elements.filter((element) => element.id !== id);
    },
    editElement(element) {
      if (element.contentType === ContentType.ALGORITHM) {
        this.editAlgorithmElement(element);
      } else {
        this.editProductElement(element);
      }
    },
    editAlgorithmElement(element) {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: getAlgorithmFormElements,
        modalTitleFunction: () => 'EDIT_SECTION_ELEMENT',
        defaultValues: element,
      });

      const saveModelPromiseFunction = (model) => http.put(Urls.SECTIONS_ID_ALGORITHMS_ID(this.section.id, model.id), model);

      this.openSaveModelModal(modal, saveModelPromiseFunction);
    },
    editProductElement(element) {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: () => getProductFormElements(this.section),
        modalTitleFunction: () => 'EDIT_SECTION_ELEMENT',
        defaultValues: element,
      });

      const saveModelPromiseFunction = (model) => http.put(Urls.SECTIONS_ID_ELEMENTS_ID(this.section.id, model.id), model);

      this.openSaveModelModal(modal, saveModelPromiseFunction);
    },
    onMouseEnter() {
      EventBus.$off('ADD_ELEMENT_TO_SECTION');
      EventBus.$on('ADD_ELEMENT_TO_SECTION', this.addElement);
    },
    onMouseLeave() {
      EventBus.$off('ADD_ELEMENT_TO_SECTION', this.addElement);
    },
    addElement(element, rank) {
      if (element.type === ContentType.ALGORITHM) {
        this.addAlgorithmElement(element, rank);
      } else {
        this.addProductElement(element, rank);
      }
    },
    addAlgorithmElement(element, rank) {
      const { contentType } = this.section;

      const add = async () => {
        const modal = this.$modal.create(UiModalSaveModel, {
          formElementsFunction: getAlgorithmFormElements,
          modalTitleFunction: () => 'ADD_SECTION_ELEMENT',
          defaultValues: {
            maxResults: element.maxResults,
            params: element.defaultParams,
            algorithmId: element.id,
            defaultParams: element.defaultParams,
          },
        });

        const saveModelPromiseFunction = (model) => http.post(Urls.SECTIONS_ID_ALGORITHMS(this.section.id), model, { params: { rank } });

        await this.openSaveModelModal(modal, saveModelPromiseFunction);
        this.section.contentType = SectionType.ALGORITHMIC;
      };

      if ([SectionType.STATIC, ...SUBSCRIBER_SECTION_TYPES].includes(contentType)) {
        this.confirmSectionTypeChange(contentType, () => add());
      } else {
        add();
      }
    },
    addProductElement(element, rank) {
      const { contentType } = this.section;

      const add = async () => {
        const modal = this.$modal.create(UiModalSaveModel, {
          formElementsFunction: () => getProductFormElements(this.section),
          modalTitleFunction: () => 'ADD_SECTION_ELEMENT',
          defaultValues: {
            since: dayjs().startOf('day').valueOf(),
            till: null,
            itemId: element.id,
            contentType: ContentType.ELEMENT,
            type: 'product',
          },
        });

        const saveModelPromiseFunction = (model) => http.post(Urls.SECTIONS_ID_ELEMENTS(this.section.id), model, { params: { rank } });

        await this.openSaveModelModal(modal, saveModelPromiseFunction);
        this.section.contentType = SectionType.STATIC;
      };

      if ([SectionType.ALGORITHMIC, ...SUBSCRIBER_SECTION_TYPES].includes(contentType)) {
        this.confirmSectionTypeChange(contentType, () => add());
      } else {
        add();
      }
    },
    confirmSectionTypeChange(oldType, callback) {
      let text;

      switch (oldType) {
        case SectionType.STATIC:
          text = this.$translate('SECTION_ADD_ALGORITHM_CONFIRMATION');
          break;
        case SectionType.SUBSCRIBER_FAVOURITE:
        case SectionType.SUBSCRIBER_WATCHED:
        case SectionType.SUBSCRIBER_RECORDING:
          text = this.$translate('SECTION_ADD_SUBSCRIBER_CONFIRMATION');
          break;
        default:
          text = this.$translate('SECTION_ADD_PRODUCT_CONFIRMATION');
      }

      const modal = this.$modal
        .create(UiModalConfirm, {
          title: this.$translate('ADD_ITEM'),
          confirmationText: text,
        })
        .$on(AppEvent.SUCCESS, () => {
          modal.close();
          callback();
        });
    },
  },
};
</script>
