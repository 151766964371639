<template>
  <div v-if="product" class="details-form">
    <ui-product-file-medium
      has-drm
      :product="product"
      present-audio-title
      :audios="product.audios"
      present-video-title
      :videos="product.videos"
      :video-type="VideoType.LIVE"
      :title="$translate('ITEM_FILES_MEDIA')"
      :allowed="isAllowed"
      @change="load"
    />

    <ui-product-file-stripe
      :is-allowed="isAllowed"
      :product-id="product.id"
      :records="product.stripe ? [product.stripe] : []"
      @change="load"
    />

    <ui-product-file-images v-bind="logoImagesProps" @change="load" />

    <ui-product-file-images v-bind="artworkImagesProps" @change="load" />

    <ui-product-file-images v-bind="slideImagesProps" @change="load" />

    <ui-product-file-images v-bind="titleTreatmentImagesProps" @change="load" />
  </div>
</template>
<script>
import Urls from '@/application/urls';
import UiProductFileImages from '@/components/product-file-images/index.vue';
import UiProductFileMedium from '@/components/product-file-medium/index.vue';
import UiProductFileStripe from '@/components/product-file-stripe/index.vue';
import { artworkImagesProps, titleTreatmentImagesProps } from '@/components/vod-files/images';
import {
  AppEvent, ImageLabel, ImageType, RoleName, VideoType,
} from '@/enums';
import http from '@/lib/http';
import { RoleMixin } from '@/mixin';

export default {
  components: {
    UiProductFileMedium,
    UiProductFileImages,
    UiProductFileStripe,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      product: undefined,
      VideoType,
    };
  },
  computed: {
    logoImagesProps() {
      return {
        title: 'COMMON_LOGO',
        isAllowed: this.isAllowed,
        product: this.product,
        type: ImageType.LOGO,
        images: this.product.images,
        settings: [
          {
            label: ImageLabel.ASPECT_16X9,
            scaler: { main: { dstw: 315, dsth: 177 }, type: 0 },
          },
          {
            label: ImageLabel.ASPECT_1X1,
            scaler: { main: { dstw: 377, dsth: 377 }, type: 0 },
          },
          {
            label: ImageLabel.ASPECT_3X4,
            scaler: { main: { dstw: 560, dsth: 746 }, type: 0 },
          },
        ],
      };
    },
    artworkImagesProps() {
      return artworkImagesProps(this.isAllowed, this.product, this.product.artworks);
    },
    slideImagesProps() {
      return {
        title: 'LIVE_SLIDES',
        isAllowed: this.isAllowed,
        product: this.product,
        type: ImageType.SLIDES,
        images: this.product.slides,
        settings: [
          {
            label: ImageLabel.ASPECT_16X9,
            scaler: { main: { dstw: 480, dsth: 270 } },
          },
        ],
      };
    },
    titleTreatmentImagesProps() {
      return titleTreatmentImagesProps(this.isAllowed, this.product, this.product.titleTreatmentImages);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.product = await http.get(Urls.PRODUCTS_LIVES_ID_PAGE(this.id, this.page)).then(({ data }) => data);
      this.$emit(AppEvent.MODEL, this.product);
    },
  },
};
</script>
