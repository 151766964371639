<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import {
  Constants, ItemType, Page, QueryParam, RoleName,
} from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import * as Filters from '@/components/filter/filters';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';
import operator from '@/application/operator';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_BUNDLES_ID_ELEMENTS(this.id),
      removeModelUrlFunction: ({ id }) => Urls.PRODUCTS_BUNDLES_ID_ELEMENTS_ID(this.id, id),
      saveModelUrlFunction: ({ id, productId }) => (id
        ? Urls.PRODUCTS_BUNDLES_ID_ELEMENTS_ID(this.id, id || productId)
        : Urls.PRODUCTS_BUNDLES_ID_ELEMENTS(this.id)),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_BUNDLE_ELEMENT' : 'ADD_ELEMENT_TO_BUNDLE'),
      saveModelFormElementsFunction: (model) => [
        [
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL(),
          Fields.RANK,
          {
            ...Fields.ITEM_TYPE(
              model,
              operator.selectedTenant.allProductsPaid
                ? [ItemType.SPECIAL]
                : Constants.ALL_PRODUCT_TYPES.filter((type) => type !== ItemType.BUNDLE),
            ),
            isHidden: !!model.id,
          },
          Fields.SELECTED_SERIES_PARENT(model, ![ItemType.SEASON, ItemType.EPISODE].includes(model.itemType)),
          Fields.SELECTED_SEASON_PARENT(model, model.itemType !== ItemType.EPISODE),
          Fields.SELECTED_PRODUCT_WITH_PARENT(model),
        ],
      ],
      saveModelDefaultValues: {
        itemType: ItemType.VOD,
      },
      filters: [
        Filters.datePicker('since', { label: 'COMMON_SINCE' }),
        Filters.datePicker('till', { label: 'COMMON_TILL' }),
      ],
      paramsConfig: {
        sort: QueryParam.RANK,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.PRODUCT_DETAIL(({ product }) => ({ product, page: Page.BUNDLES })),
          Columns.SINCE,
          Columns.TILL,
          Columns.CREATED_AT,
          Columns.RANK,
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
