import Urls from '@/application/urls';
import http from '@/lib/http';
import { UiModalPopup } from 'redge-media-web-ui';
import router from '@/router';
import { translate } from '@/helper/index';

export default async (cacheType, id) => {
  const params = id ? `?id=${id}` : '';

  http
    .delete(`${Urls.CACHE_INVALIDATION.replace(':cacheType', cacheType)}${params}`)
    .then(() => {
      router.app.$modal.create(UiModalPopup, {
        title: 'COMMON_INFO',
        text: translate('CACHE_INVALIDATION_SUCCESS_REFRESH'),
      });
    })
    .catch((err) => {
      router.app.$modal.create(UiModalPopup, {
        hideAfter: 5000,
        modalModifier: 'ui-modal-popup--error',
        overlay: true,
        text: err.message,
      });
    });
};
