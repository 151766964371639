<template>
  <div class="ui-filter-item">
    <label v-text="$translate(label)"></label>
    <ui-multiselect
      v-model="selectedProductType"
      :allow-empty="true"
      :placeholder="$translate('COMMON_SELECT_TYPE')"
      :records="productTypeOptions"
      class="ui-filter-item__product-type"
    />
    <ui-form-autocomplete-inline
      :close-on-select="false"
      :dict-url="productDictUrl"
      :disabled="!selectedProductType"
      :is-option-disabled="(option) => values.includes(option[field]) || !option.name"
      :placeholder="$translate('COMMON_SELECT_PRODUCT')"
      :slots="{ option: UiMultiselectOptionProduct }"
      @input="select"
    />
    <ul class="ui-filter-item__selected-values">
      <ui-filter-item-selected-value
        v-for="value in values"
        :key="value"
        :value="value"
        @remove="remove"
      />
    </ul>
  </div>
</template>
<script>
import { UiMultiselect } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { AppEvent, Constants } from '@/enums';
import { UiMultiselectOptionProduct } from '@/components/ui-multiselect';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import UiFilterItemSelectedValue from './ui-filter-item-selected-value.vue';

export default {
  components: {
    UiFormAutocompleteInline,
    UiFilterItemSelectedValue,
    UiMultiselect,
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    ...UiFormAutocompleteInline.props,
  },
  data() {
    return {
      UiMultiselectOptionProduct,
      productTypeOptions: Constants.ALL_PRODUCT_TYPES.map((name) => ({ name })),
      selectedProductType: undefined,
    };
  },
  computed: {
    productDictUrl() {
      return this.selectedProductType && Urls.ITEMS_TYPE_DICTIONARIES(this.selectedProductType.name);
    },
  },
  methods: {
    select(value) {
      const stringValue = value[this.field];
      if (!this.values.includes(stringValue)) {
        this.$emit(AppEvent.CHANGE, [...this.values, stringValue]);
      }
    },
    remove(value) {
      this.$emit(
        AppEvent.CHANGE,
        this.values.filter((v) => v !== value),
      );
    },
  },
};
</script>
