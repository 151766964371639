<template>
  <div class="details-container header-container">
    <ui-tabs :current-route="$route" :tabs="tabs" />
    <component :is="page" :page="page" />
  </div>
</template>

<script>
import UiTabs from '@/components/ui-tabs/index.vue';
import { Page } from '@/enums';
import Categories from './categories.vue';
import Threads from './threads.vue';

export default {
  components: {
    UiTabs,
    Categories,
    Threads,
  },
  data() {
    return {
      tabs: [
        { label: 'TAB_CONTACT', page: Page.THREADS },
        { label: 'COMMON_CATEGORIES', page: Page.CATEGORIES },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page;
    },
  },
};
</script>
