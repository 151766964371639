<template>
  <ui-product-title
    v-if="record.resourceDeleted"
    :product="{ title: record.resourceName }"
    class="d-block text-truncated line-through"
  />
  <ui-table-detail-link v-else-if="link.id" v-bind="link">
    <ui-product-title v-if="resourceIsProduct" :product="{ title: record.resourceName }" />
  </ui-table-detail-link>
</template>
<script>
import { auditLink } from '@/helper';
import { AuditResourceType } from '@/enums';
import { UiTableDetailLink } from '@/components/ui-table/index';
import UiProductTitle from '@/components/ui-product-title/index.vue';

export default {
  name: 'ui-table-detail-link-resource',
  components: {
    UiTableDetailLink,
    UiProductTitle,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    link() {
      const { name, params: { id, page } = {} } = auditLink(this.record);
      return {
        name,
        id,
        page,
        text: this.record.resourceName,
      };
    },
    resourceIsProduct() {
      return [
        AuditResourceType.BANNER,
        AuditResourceType.BUNDLE,
        AuditResourceType.ITEM,
        AuditResourceType.PRODUCT,
        AuditResourceType.SECTION,
        AuditResourceType.VOD,
      ].includes(this.record.resourceItemType);
    },
  },
};
</script>
