<template>
  <div class="ui-available-filters-settings">
    <div class="flex">
      <ui-available-filters
        ref="uiAvailableFilters"
        :value="selectedFilterSetting"
        :active-filters="activeFilters"
        :available-filters-settings="availableFiltersSettings"
        @change="filterSettingChanged"
      />

      <button
        :class="{ 'ui-available-filters-settings__save--inactive': isFilterSettingMatchesFilters }"
        type="button"
        class="button ui-available-filters-settings__save"
        @click="selectedFilterSetting ? saveSetting(selectedFilterSetting) : addSetting()"
        v-text="saveButtonLabel"
      ></button>

      <span
        class="icon-down ui-available-filters-settings__toggle"
        :class="{ 'ui-available-filters-settings__toggle--open': active }"
        @click="active = !active"
      ></span>
    </div>

    <ui-sortable
      v-if="active"
      :value="availableFiltersSettings"
      :handle-class="'ui-sortable__handle-item'"
      class="ui-available-filters-settings__list"
      @input="filtersSettingsOrderChanged"
    >
      <li
        v-for="setting in availableFiltersSettings"
        :key="setting.id"
        :class="{ 'ui-available-filters-settings__default': setting.default }"
        class="ui-available-filters-settings__list-item"
      >
        <i class="ui-sortable__handle-item icon-sidebar-medium"></i>
        <p v-text="setting.name"></p>
        <i class="icon-settings ui-available-filters-settings__edit" @click="editSetting(setting)"></i>
        <i class="icon-trash ui-available-filters-settings__delete" @click="removeSetting(setting)"></i>
      </li>
    </ui-sortable>
  </div>
</template>
<script>
import { UiModalConfirm } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import http from '@/lib/http';
import { AppEvent } from '@/enums';
import {
  checkIfFilterSettingMatchesFilters,
  getAvailableFilterName,
  getCurrentFilterSetting,
} from '@/lib/settings-filters';
import UiAvailableFilters from '@/components/list-view/ui-available-filters.vue';
import UiSortable from '@/components/ui-sortable/index.vue';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';
import * as Fields from '@/components/ui-form/fields';

const updateSettingRequest = (model) => http.put(Urls.APPLICATION_GUI_FILTER_NAME(model.id), model);

export default {
  components: {
    UiAvailableFilters,
    UiSortable,
  },
  props: {
    activeFilters: {
      type: Object,
      required: true,
    },
    filterId: {
      type: Number,
      required: true,
    },
    availableFiltersSettings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      active: false,
      processing: false,
    };
  },
  computed: {
    selectedFilterSetting() {
      return getCurrentFilterSetting(this.filterId, this.availableFiltersSettings);
    },
    isFilterSettingMatchesFilters() {
      return (
        Object.keys(this.activeFilters).length === 0
        || (this.selectedFilterSetting
          && checkIfFilterSettingMatchesFilters(this.selectedFilterSetting, this.activeFilters))
      );
    },
    saveButtonLabel() {
      const text = this.selectedFilterSetting ? 'COMMON_SAVE' : 'COMMON_SAVE_AS';
      return this.$translate(text);
    },
  },
  watch: {
    processing() {
      this.$emit('processing', this.processing);
    },
  },
  methods: {
    filterSettingChanged(filterSetting) {
      this.$emit('filterSettingChanged', filterSetting);
    },
    updateAvailableFiltersSettings(availableFiltersSettings) {
      this.$emit('update-available-filters-settings', availableFiltersSettings);
    },
    filtersSettingsOrderChanged(settings, model, rank) {
      if (model.rank !== rank) {
        const updatedModel = { ...model, rank };
        const updatedAvailableFiltersSettings = settings.map((setting) => (setting.id === updatedModel.id ? updatedModel : setting));

        this.updateAvailableFiltersSettings(updatedAvailableFiltersSettings);
        updateSettingRequest(updatedModel);
      }
    },
    async addSetting() {
      const model = {
        type: getAvailableFilterName(this.$route),
        filters: this.activeFilters,
        default: false,
        name: '',
        rank: 0,
      };

      const confirm = await this.showModal(model);

      if (confirm) {
        const { data } = await http.post(Urls.APPLICATION_GUI_FILTER_NAME(''), model);
        const updatedAvailableFiltersSettings = [
          ...this.availableFiltersSettings.map((setting) => (data.default ? { ...setting, default: false } : setting)),
          data,
        ];

        this.updateAvailableFiltersSettings(updatedAvailableFiltersSettings);
        this.filterSettingChanged(data);
      }
    },
    async editSetting(model) {
      const confirm = await this.showModal(model);
      if (confirm) {
        const updatedAvailableFiltersSettings = this.availableFiltersSettings.map((setting) => {
          if (setting.id === model.id) {
            return model;
          }

          if (model.default) {
            return { ...setting, default: false };
          }

          return setting;
        });

        this.updateAvailableFiltersSettings(updatedAvailableFiltersSettings);
        updateSettingRequest(model);
      }
    },
    saveSetting(model) {
      const updatedModel = { ...model, filters: this.activeFilters };
      const updatedAvailableFiltersSettings = this.availableFiltersSettings.map((setting) => (setting.id === updatedModel.id ? updatedModel : setting));

      this.updateAvailableFiltersSettings(updatedAvailableFiltersSettings);
      updateSettingRequest(updatedModel);
    },
    removeSetting(model) {
      this.processing = true;
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_REMOVE'),
        confirmationText: this.$translate('COMMON_REMOVE_CONFIRMATION'),
      });

      modal.$on(AppEvent.SUCCESS, () => {
        modal.close();
        this.onModalClose();

        if (model === this.selectedFilterSetting) {
          this.$refs.uiAvailableFilters.setEmpty();
        }

        this.updateAvailableFiltersSettings(this.availableFiltersSettings.filter((setting) => setting.id !== model.id));
        http.delete(Urls.APPLICATION_GUI_FILTER_NAME(model.id), model);
      });
      modal.$on(AppEvent.CLOSE, () => this.onModalClose());
    },
    onModalClose() {
      setTimeout(() => {
        this.processing = false;
      }, 10);
    },
    showModal(model) {
      this.processing = true;

      return new Promise((resolve) => {
        const modal = this.$modal.create(UiModalSaveModel, {
          formElementsFunction: () => [[Fields.NAME, Fields.checkboxField('default', 'COMMON_DEFAULT')]],
          modalTitleFunction: ({ id }) => (id ? 'EDIT_FILTER_SETTING' : 'ADD_FILTER_SETTING'),
          defaultValues: { ...model },
        });

        const close = (confirm) => {
          resolve(confirm);
          modal.close();
          this.onModalClose();
        };

        modal.$on(AppEvent.SUCCESS, (changedModel) => {
          Object.entries(changedModel).forEach(([key, value]) => {
            model[key] = value;
          });
          close(true);
        });
        modal.$on(AppEvent.CLOSE, () => close(false));
      });
    },
  },
};
</script>
