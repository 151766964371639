<template>
  <div>
    <span
      v-for="name in platformNames"
      :key="name"
      v-tooltip="name"
      class="icon-table-platform"
      :class="`icon-${name.toLowerCase()}`"
    ></span>
  </div>
</template>
<script>
export default {
  name: 'ui-table-platforms',
  props: {
    platforms: {
      type: Array,
      required: true,
    },
  },
  computed: {
    platformNames() {
      return this.platforms && this.platforms.map((platform) => platform.name);
    },
  },
};
</script>
