export default {
  ADMIN: 'ADMIN',
  CMS_READ: 'CMS_READ',
  CMS_WRITE: 'CMS_WRITE',
  CRM_READ: 'CRM_READ',
  CRM_WRITE: 'CRM_WRITE',
  TENANT_MANAGEMENT_READ: 'TENANT_MANAGEMENT_READ',
  TENANT_MANAGEMENT_WRITE: 'TENANT_MANAGEMENT_WRITE',
  TENANT_MANAGEMENT_ADMIN: 'TENANT_MANAGEMENT_ADMIN',
};
