<template>
  <ui-modal class="ui-modal--scrollable" :header-label="title" @close="close">
    <ui-table v-bind="tableDefinition" class="ui-table--without-panel" />
  </ui-modal>
</template>
<script>
import { AppEvent } from '@/enums';
import { UiTable } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import * as SubscriberColumns from '@/modules/subscriber/view/columns';

export default {
  components: {
    UiTable,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          { ...SubscriberColumns.DEVICE_OS_VERSION, width: 140 },
          { ...Columns.DEVICE_AGENT_VERSION, width: 130 },
          { ...Columns.CREATED_AT, width: 145, isSortable: false },
          { ...SubscriberColumns.DEVICE_VERSION_LAST_ATTACHED, width: 145 },
        ],
        records: this.item.items,
      };
    },
  },
  methods: {
    close() {
      this.$emit(AppEvent.CLOSE);
    },
  },
};
</script>
