<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { ModelDetailMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as AdministrationColumns from '@/modules/administration/view/columns';

export default ListView.extend({
  mixins: [ModelDetailMixin],
  data() {
    return {
      writeRole: RoleName.ADMIN,
      recordsUrl: Urls.APPLICATION_SETTINGS_AUDITS,
      exportUrl: Urls.APPLICATION_SETTINGS_AUDITS_EXPORT,
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          AdministrationColumns.AUDIT_OPERATOR,
          AdministrationColumns.AUDIT_EVENT_TYPE,
          {
            field: 'resourceName',
            minWidth: 200,
            headingLabel: 'COMMON_NAME',
          },
          Columns.CREATED_AT,
          AdministrationColumns.ACTIONS_DETAIL(this.showModelDetail),
        ],
        records: this.records,
      };
    },
  },
});
</script>
