<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Filters from '@/components/filter/filters';
import * as Fields from '@/components/ui-form/fields';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_GROUPS,
      removeModelUrlFunction: ({ id }) => Urls.SUBSCRIBERS_GROUPS_ID(id),
      saveModelUrlFunction: ({ id }) => (id ? Urls.SUBSCRIBERS_GROUPS_ID(id) : Urls.SUBSCRIBERS_GROUPS),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_SUBSCRIBER_GROUP' : 'ADD_SUBSCRIBER_GROUP'),
      saveModelFormElementsFunction: () => [
        [
          Fields.KEY,
          Fields.NAME,
          Fields.checkboxField('automaticAssignment', 'SUBSCRIBER_GROUP_AUTOMATIC_ASSIGNMENT'),
          {
            field: 'algorithms',
            component: UiFormAutocompleteInline,
            componentOptions: {
              dictUrl: Urls.SUBSCRIBERS_GROUPS_ALGORITHMS,
              label: 'COMMON_ALGORITHMS',
              multiple: true,
              allowEmpty: false,
            },
          },
        ],
      ],
      filters: [Filters.multiSwitch('automaticAssignment', { label: 'SUBSCRIBER_GROUP_AUTOMATIC_ASSIGNMENT' })],
      paramsConfig: {
        sort: QueryParam.KEY,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'key',
            minWidth: 200,
            isSortable: true,
            headingLabel: 'COMMON_KEY',
          },
          Columns.NAME,
          {
            field: 'algorithms',
            minWidth: 100,
            headingLabel: 'COMMON_ALGORITHMS',
            dataMapper: ({ algorithms }) => algorithms?.map(({ name }) => name).join(', '),
          },
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
