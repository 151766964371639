import * as views from '@/modules';

export default [
  {
    path: '/gui/tenants',
    name: 'tenants',
    component: views.Tenants,
    meta: {
      titleLabels: ['MENU_TENANTS'],
    },
  },
  {
    path: '/gui/tenants/:id/:page',
    name: 'tenants.detail',
    component: views.TenantsDetail,
    meta: {
      titleLabels: ['COMMON_TENANTS'],
    },
  },
];
