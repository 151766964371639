import RoleName from './role-name';

export default {
  'operator.detail': RoleName.ADMIN,
  'lives.programme.detail': RoleName.CMS_WRITE,
  'banners.detail': RoleName.CMS_WRITE,
  'serial.detail': RoleName.CMS_WRITE,
  'serial.season.detail': RoleName.CMS_WRITE,
  'serial.episode.detail': RoleName.CMS_WRITE,
  'lives.detail': RoleName.CMS_WRITE,
  'ppvs.detail': RoleName.CMS_WRITE,
  'vods.detail': RoleName.CMS_WRITE,
  'specials.detail': RoleName.CMS_WRITE,
  'bundles.detail': RoleName.CMS_WRITE,
  'section.detail': RoleName.CMS_WRITE,
};
