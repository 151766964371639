<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_ID_BUNDLES(this.id),
      removeModelUrlFunction: ({ id }) => Urls.PRODUCTS_BUNDLES_ID_ELEMENTS_ID(this.id, id),
      saveModelUrlFunction: () => Urls.PRODUCTS_BUNDLES_ELEMENTS_ID(this.id),
      saveModelModalTitleFunction: () => 'ADD_ELEMENT_TO_BUNDLE',
      saveModelFormElementsFunction: (model) => [
        [
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL(),
          { ...Fields.RANK, 'data-qa': 'vod-rank' },
          Fields.SELECTED_BUNDLE(model, 'productId'),
        ],
      ],
      paramsConfig: {
        sort: QueryParam.SINCE,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.PRODUCT_DETAIL((record) => ({ product: record.bundle }), 'COMMON_TITLE'),
          Columns.SINCE,
          Columns.TILL,
          Columns.CREATED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.bundle.displayActive),
      };
    },
  },
});
</script>
