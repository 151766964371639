<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition">
    <template #prices="{ record }">
      <div>
        <div
          v-for="channel in Object.keys(record.prices)"
          :key="channel"
          v-text="`${channel}: ${priceFormat(record.prices[channel])}`"
        ></div>
      </div>
    </template>
  </v-list-wrapper>
</template>
<script>
import { UiFormNumberInput, UiModalConfirm } from 'redge-media-web-ui';
import { NumberDivider } from 'redge-media-web-utils';
import Urls from '@/application/urls';
import http from '@/lib/http';
import {
  AppEvent, ItemType, PaymentScheduleType, RoleName,
} from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import { priceFormat } from '@/helper';
import ListView from '@/components/list-view/list-view.vue';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Columns from '@/components/ui-table/columns';
import * as ProductColumns from '@/modules/product/view/columns';
import * as Fields from '@/components/ui-form/fields';
import {
  UiMultiselectOptionProduct,
  UiMultiselectOptionWithNameHover,
  UiMultiselectTagProduct,
  UiMultiselectTagWithNameHover,
} from '@/components/ui-multiselect';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import UiFormDictionarySelect from '@/components/ui-form/ui-form-dictionary-select.vue';

const isPriceInputMandatory = (prices, priceName) => Object.values(prices).every((value) => !value) || !!prices[priceName];

const createFieldsType = (id, itemType) => {
  if (itemType === ItemType.PPV) {
    return Fields.TYPE_DISABLED;
  }

  return id ? Fields.TYPE_DISABLED : Fields.TYPE(Urls.PAYMENTS_SCHEDULES_TYPES);
};

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    itemType: String,
  },
  data() {
    const url = Urls.PRODUCTS_ID_PAYMENTS_SCHEDULES(this.id);

    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_SCHEDULE' : 'ADD_SCHEDULE'),
      saveModelFormElementsFunction: ({ id, type, prices }) => [
        [
          createFieldsType(id, this.itemType),
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL(),
          {
            ...Fields.priceInputField(
              'prices.PREPAID_ONLINE',
              'SCHEDULE_PRICES_PREPAID_ONLINE',
              isPriceInputMandatory(prices, 'PREPAID_ONLINE'),
            ),
            isHidden: type === PaymentScheduleType.SUBSCRIPTION,
            'data-qa': 'vod-prepaid-online-price',
          },
          {
            ...Fields.priceInputField(
              'prices.WALLET',
              'SCHEDULE_PRICES_WALLET',
              isPriceInputMandatory(prices, 'WALLET'),
            ),
            'data-qa': 'vod-wallet-price',
          },
          {
            ...Fields.priceInputField('prices.CARD', 'SCHEDULE_PRICES_CARD', isPriceInputMandatory(prices, 'CARD')),
            'data-qa': 'vod-card-price',
          },
          {
            field: 'duration',
            isMandatory: type === PaymentScheduleType.SUBSCRIPTION,
            isHidden: this.itemType === ItemType.PPV,
            component: UiFormNumberInput,
            componentOptions: {
              label: 'SCHEDULE_PAYMENT_DURATION_HOURS',
              tooltip:
                type === PaymentScheduleType.SUBSCRIPTION
                  ? Fields.commonTooltip('HELP_SCHEDULE_PAYMENT_SUBSCRIPTION_DURATION')
                  : '',
              min: NumberDivider.HOUR_TO_SECONDS,
              divider: NumberDivider.HOUR_TO_SECONDS,
            },
            'data-qa': 'vod-duration',
          },
          {
            field: 'subscriberGroups',
            component: UiFormAutocompleteInline,
            componentOptions: {
              label: 'COMMON_SUBSCRIBER_GROUPS',
              dictType: 'SUBSCRIBER_GROUP',
              field: 'key',
              multiple: true,
              slots: {
                tag: UiMultiselectTagWithNameHover,
                option: UiMultiselectOptionWithNameHover,
              },
            },
          },
          {
            field: 'trialDuration',
            isHidden: type !== PaymentScheduleType.SUBSCRIPTION,
            component: UiFormNumberInput,
            componentOptions: {
              label: 'SCHEDULE_PAYMENT_TRIAL_DURATION',
              tooltip: Fields.commonTooltip('HELP_SCHEDULE_PAYMENT_TRIAL_DURATION'),
              divider: NumberDivider.HOUR_TO_SECONDS,
            },
          },
          {
            field: 'agreements',
            component: UiFormAutocompleteInline,
            componentOptions: {
              label: 'COMMON_AGREEMENT',
              dictUrl: Urls.AGREEMENTS_PAYMENT_DICTIONARIES,
              multiple: true,
              'data-qa': 'vod-agreements',
              slots: {
                tag: UiMultiselectTagProduct,
                option: UiMultiselectOptionProduct,
              },
              isOptionDisabled: ({ name }) => !name,
            },
          },
          {
            field: 'currency',
            component: UiFormDictionarySelect,
            isMandatory: true,
            componentOptions: {
              label: 'COMMON_CURRENCY',
              dictUrl: Urls.CURRENCIES_DICTIONARIES,
            },
          },
        ],
      ],
      saveModelDefaultValues: {
        prices: {},
        type: this.itemType === ItemType.PPV ? 'SINGLE' : '',
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.SINCE,
          Columns.TILL,
          Columns.TYPE,
          Columns.CURRENCY,
          {
            field: 'prices',
            width: 160,
            headingLabel: 'SCHEDULE_PRICE',
          },
          {
            field: 'duration',
            width: 120,
            isSortable: true,
            headingLabel: 'SCHEDULE_PAYMENT_DURATION_HOURS',
            dataMapper: ({ duration }) => (duration ? duration / NumberDivider.HOUR_TO_SECONDS : ''),
            isHidden: this.itemType === ItemType.PPV,
          },
          {
            field: 'trialDuration',
            width: 100,
            headingLabel: 'SCHEDULE_PAYMENT_TRIAL_DURATION',
            dataMapper: ({ trialDuration }) => (trialDuration ? trialDuration / NumberDivider.HOUR_TO_SECONDS : null),
            isHidden: this.itemType === ItemType.PPV,
          },
          {
            field: 'subscriberGroups',
            width: 120,
            isSortable: true,
            headingLabel: 'SCHEDULE_PAYMENT_SUBSCRIBER_GROUPS',
            dataMapper: ({ subscriberGroups }) => subscriberGroups?.map(({ name }) => name).join(', '),
          },
          ProductColumns.PAYMENT_SCHEDULE_ACTIONS(this.isAllowed, this.editModel, this.deactivate, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
  methods: {
    priceFormat,
    deactivate(id) {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_DEACTIVATE'),
        confirmationText: this.$translate('COMMON_DEACTIVATE_CONFIRMATION'),
      });

      modal.$on(AppEvent.SUCCESS, () => http
        .put(Urls.PRODUCTS_ID_PAYMENTS_SCHEDULES_ID_DEACTIVATE(this.id, id))
        .then(() => {
          modal.close();
          this.load();
        })
        .catch((error) => {
          this.error = error;
          throw error;
        }));
    },
  },
});
</script>
