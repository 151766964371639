<template>
  <div class="ui-panel">
    <div class="ui-panel__header">
      <h2 class="ui-panel__header-text" v-text="$translate('VOD_SUBTITLES')"></h2>
      <div v-if="isAllowed">
        <v-button-icon
          icon="icon-add"
          tooltip="HELP_ADD"
          class="ui-panel__header-button"
          @click="createModelAndEmitEvent"
        />
      </div>
    </div>
    <ui-table v-bind="tableDefinition" class="ui-table--without-panel" />
  </div>
</template>
<script>
import { UiFormTextInput } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { AppEvent, ItemType } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import VButtonIcon from '@/components/button/button-icon.vue';
import { UiTable } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default {
  components: {
    VButtonIcon,
    UiTable,
  },
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    isAllowed: {
      type: Boolean,
      required: true,
    },
    productId: {
      type: Number,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    },
    hideSubtitlePath: Boolean,
    hideKey: Boolean,
    itemType: String,
  },
  data() {
    const url = this.itemType === ItemType.SERIAL
      ? Urls.PRODUCTS_VODS_SERIALS_ID_SUBTITLES(this.productId)
      : Urls.PRODUCTS_VODS_ID_SUBTITLES(this.productId);

    return {
      removeModelUrlFunction: ({ id }) => `${Urls.PRODUCTS_VODS_ID_SUBTITLES(this.productId)}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_SUBTITLES' : 'ADD_SUBTITLES'),
      saveModelFormElementsFunction: (model) => [
        [
          Fields.LANGUAGE,
          {
            ...Fields.URL_WITH_FILE_UPLOAD(model, 'path', 'SUBTITLE_PATH'),
            isHidden: this.hideSubtitlePath,
            isMandatory: this.isSubtitlePathMandatory,
          },
          {
            field: 'key',
            component: UiFormTextInput,
            componentOptions: {
              label: 'COMMON_KEY',
              tooltip: Fields.commonTooltip('HELP_COMMON_KEY'),
            },
          },
        ],
      ],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.LANGUAGE,
          {
            field: 'path',
            minWidth: 300,
            headingLabel: 'SUBTITLE_PATH',
            dataClass: 'break-all',
            isHidden: this.hideSubtitlePath,
          },
          {
            field: 'key',
            width: 160,
            headingLabel: 'COMMON_KEY',
            dataClass: 'break-all',
            isHidden: this.hideKey,
          },
          { ...Columns.CREATED_AT, isSortable: false },
          { ...Columns.MODIFIED_AT, isSortable: false },
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModelAndEmitEvent, this.removeModelAndEmitEvent),
        ],
        records: this.records,
      };
    },
  },
  methods: {
    async createModelAndEmitEvent() {
      await this.createModel();
      this.$emit(AppEvent.CHANGE);
    },
    async editModelAndEmitEvent(model) {
      await this.editModel(model);
      this.$emit(AppEvent.CHANGE);
    },
    async removeModelAndEmitEvent(model) {
      await this.removeModel(model);
      this.$emit(AppEvent.CHANGE);
    },
  },
};
</script>
