<script>
import operator from '@/application/operator';

export default {
  computed: {
    isAllowed() {
      return this.writeRole && this.hasRole(this.writeRole);
    },
  },
  methods: {
    hasRole(role) {
      return operator.roles.map(({ name }) => name).includes(role);
    },
  },
};
</script>
