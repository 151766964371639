<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { Page, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as SubscriberColumns from '@/modules/subscriber/view/columns';

export default ListView.extend({
  mixins: [RemoveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_ID_VIDEO_SESSIONS(this.id),
      removeModelUrlFunction: ({ id }) => Urls.SUBSCRIBERS_VIDEOS_SESSIONS_ID_BLOCK(id),
      removeConfirmationText: this.$translate('SESSION_BLOCK_CONFIRMATION'),
      exportUrl: Urls.SUBSCRIBERS_ID_VIDEOS_SESSIONS_EXPORT(this.id),
      paramsConfig: {
        sort: QueryParam.ID,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.PRODUCT_DETAIL(({ item }) => ({ product: item, page: Page.VIDEO_SESSIONS })),
          { ...Columns.SINCE, isSortable: false },
          { ...Columns.TILL, isSortable: false },
          Columns.VIDEO_SESSION_ID,
          Columns.DEVICE_DETAIL(({ device }) => ({ device })),
          Columns.DEVICE_INFO(({ device }) => ({ device })),
          Columns.RECORD_DEVICE_MAKER,
          Columns.RECORD_DEVICE_OS,
          Columns.RECORD_DEVICE_APP_VERSION,
          Columns.SESSION_DELETE_REASON,
          SubscriberColumns.ACTIONS_SESSIONS(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
