<template>
  <div class="section-container">
    <div class="section-container__sections">
      <v-list-wrapper>
        <div slot="custom-buttons" class="ui-form-item section-labels">
          <label for="select-label" v-text="`${$translate('COMMON_VIEW')}:`"></label>
          <select
            id="select-label"
            class="ui-select"
            :disabled="allSections"
            :value="sectionLabel"
            @input="sectionLabelChanged"
          >
            <option
              v-for="view in views"
              :key="view.key"
              :value="view.key"
              :class="{ 'font-style-italic opacity-5': !view.name }"
            >
              <template v-if="view.name">
                {{ view.name }}
              </template>
              <template v-else>
                <i class="no-title-icon">!</i>
                {{ $translate('COMMON_NO_TITLE') }}
              </template>
            </option>
          </select>

          <ui-checkbox class="section-all" :value="allSections" @input="allSectionsChanged">
            <label v-text="$translate('COMMON_ALL')"></label>
          </ui-checkbox>
        </div>

        <template slot="body">
          <div class="section-container__sections-wrapper">
            <v-section
              v-for="section in records"
              :key="section.id"
              :section="section"
              :is-draggable="!allSections"
              :data-id="section.id"
              @remove="removeSection"
            />
          </div>

          <ui-loader />
        </template>
      </v-list-wrapper>
    </div>

    <sections-sidebar />
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { UiCheckbox, UiFormCheckbox, UiModalConfirm } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import {
  AppEvent, ItemType, RoleName, SectionType,
} from '@/enums';
import http from '@/lib/http';
import { RoleMixin, SaveModelMixin } from '@/mixin';
import Sortable from '@/helper/sortable';
import ListView from '@/components/list-view/list-view.vue';
import * as Filters from '@/components/filter/filters';
import * as Fields from '@/components/ui-form/fields';
import * as ContentFields from '@/modules/content/view/fields';
import VSection from './section.vue';
import SectionsSidebar from './sidebar/index.vue';

export default ListView.extend({
  components: {
    UiCheckbox,
    VSection,
    SectionsSidebar,
  },
  mixins: [RoleMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.SECTIONS,
      saveModelUrlFunction: () => Urls.SECTIONS,
      saveModelModalTitleFunction: () => 'ADD_SECTION',
      saveModelFormElementsFunction: (model) => [
        [
          Fields.TITLE,
          ContentFields.VIEWS,
          ContentFields.CONTENT_TYPE(model),
          ContentFields.LAYOUT_DICT(model),
          ContentFields.PRESENTATION_TIME(model),
          ContentFields.TILES_ASPECT,
          ContentFields.TILES_ACTIVITY((records) => {
            const defaultRecord = records.find(({ defaultTileActivity }) => defaultTileActivity);
            if (defaultRecord) {
              model.tilesActivity = defaultRecord.name;
            }
          }),
          ContentFields.TILES_SIZE,
          ContentFields.CATEGORY_TYPE(model),
          Fields.MAIN_CATEGORY(model.categoryType, model.contentType !== SectionType.CATALOG || !model.categoryType),
          {
            field: 'generateWebUrl',
            component: UiFormCheckbox,
            componentOptions: {
              label: 'GENERATE_WEB_URL',
              on: {
                input: () => {
                  model.webUrl = model.generateWebUrl ? null : '';
                },
              },
            },
          },
          { ...ContentFields.WEB_URL, isHidden: model.webUrl === null },
          ContentFields.APP_URL,
          ContentFields.REFRESH_INTERVAL,
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL(),
          Fields.PLATFORMS,
        ],
      ],
      views: [],
      filters: [
        Filters.PLATFORMS,
        { ...Filters.ACTIVE, componentOptions: { ...Filters.ACTIVE.componentOptions, label: 'COMMON_ACTIVE_FEMALE' } },
        Filters.autocomplete('layouts', { label: 'SECTION_LAYOUT', dictType: 'SECTION_LAYOUT' }),
        Filters.autocomplete('contentTypes', { label: 'COMMON_TYPE', dictUrl: Urls.SECTIONS_CONTENT_TYPES }),
      ],
    };
  },
  computed: {
    allSections() {
      return ['true', true].includes(this.routeQuery.all_sections);
    },
    sectionLabel() {
      return this.$route.params.key;
    },
    extraParams() {
      return {
        elements: false,
        key: this.allSections ? undefined : this.sectionLabel,
      };
    },
    saveModelDefaultValues() {
      return {
        type: ItemType.SECTION,
        type_: ItemType.SECTION,
        webUrl: '',
        since: dayjs().startOf('day').valueOf(),
        tilesSize: 'M',
        refreshInterval: 10,
        active: false,
        platforms: [],
        presentationTime: 6,
        views: this.views.filter(({ key }) => key === this.sectionLabel),
        contentType: undefined,
        layoutDict: undefined,
      };
    },
  },
  watch: {
    async records() {
      await this.$nextTick();
      this.initSortable();
    },
    '$route.params.key': 'load',
  },
  async mounted() {
    this.views = await http.get(Urls.DICTIONARIES_TYPE('VIEW')).then(({ data }) => data.items);
  },
  methods: {
    sectionLabelChanged({ target: { value } }) {
      this.$router.push({ name: 'section.label', params: { key: value }, query: this.$route.query });
    },
    allSectionsChanged(value) {
      this.updateRouteQuery({ all_sections: value });
    },
    initSortable() {
      const ctx = this;
      // eslint-disable-next-line no-new
      new Sortable($('.section'), {
        handle: '.ui-section-header__move',
        axis: 'y',
        ghostClass: 'section-ghost',
        onSwap(currentElm) {
          const id = currentElm.data('id');
          const rank = currentElm.index();
          ctx.shiftElement(id, rank);
        },
      });
    },
    shiftElement(id, rank) {
      http.put(`${Urls.SECTIONS_ID_SHIFT(id)}?rank=${rank}&key=${this.sectionLabel}`);
    },
    removeSection(id) {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_REMOVE'),
        confirmationText: this.$translate('SECTION_DELETE_CONFIRMATION'),
      });

      modal.$on(AppEvent.SUCCESS, async () => {
        await http.delete(Urls.SECTIONS_ID(id));
        modal.close();
        this.load();
      });
    },
  },
});
</script>
