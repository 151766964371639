<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.CONTACTS_CATEGORIES,
      removeModelUrlFunction: ({ id }) => Urls.CONTACTS_CATEGORIES_ID(id),
      saveModelUrlFunction: ({ id }) => (id ? Urls.CONTACTS_CATEGORIES_ID(id) : Urls.CONTACTS_CATEGORIES),
      saveModelModalTitleFunction: () => 'ADD_CATEGORY',
      saveModelFormElementsFunction: () => [[Fields.NAME, Fields.KEY]],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.NAME,
          Columns.KEY,
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
