<script>
import Urls from '@/application/urls';
import { AuditResourceType, RoleName } from '@/enums';
import AuditDetailList from '@/modules/administration/view/audit/detail/index.vue';

export default AuditDetailList.extend({
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_ID_AUDITS(this.id),
      exportUrl: Urls.AUDITS_TYPE_TYPE_ID_EXPORT(AuditResourceType.SUBSCRIBER, this.id),
    };
  },
});
</script>
