<template>
  <div v-if="product" class="details-form">
    <ui-product-file-medium
      :product="product"
      :videos="product.trailers"
      :video-type="VideoType.TRAILER"
      :title="$translate('VOD_FILES_TRAILERS')"
      :allowed="isAllowed"
      @change="load"
    />

    <ui-product-file-audio-tracks
      :is-allowed="isAllowed"
      :product-id="product.id"
      :records="product.audioTracks"
      @change="load"
    />

    <ui-product-file-subtitle
      :is-allowed="isAllowed"
      :product-id="product.id"
      :records="product.subtitles"
      :item-type="ItemType.SERIAL"
      hide-subtitle-path
      hide-key
      @change="load"
    />

    <ui-product-file-images v-bind="coverImagesProps" @change="load" />

    <ui-product-file-images v-bind="artworkImagesProps" @change="load" />

    <ui-product-file-images v-bind="galleryImagesProps" @change="load" />

    <ui-product-file-images v-bind="titleTreatmentImagesProps" @change="load" />
  </div>
</template>
<script>
import Urls from '@/application/urls';
import http from '@/lib/http';
import {
  AppEvent, ItemType, RoleName, VideoType,
} from '@/enums';
import { RoleMixin } from '@/mixin';
import UiProductFileAudioTracks from '@/components/product-file-audio-track/index.vue';
import UiProductFileImages from '@/components/product-file-images/index.vue';
import UiProductFileMedium from '@/components/product-file-medium/index.vue';
import UiProductFileSubtitle from '@/components/product-file-subtitle/index.vue';
import {
  artworkImagesProps,
  coverImagesProps,
  galleryImagesProps,
  titleTreatmentImagesProps,
} from '@/components/vod-files/images';

export default {
  components: {
    UiProductFileAudioTracks,
    UiProductFileImages,
    UiProductFileMedium,
    UiProductFileSubtitle,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      product: undefined,
      ItemType,
      VideoType,
    };
  },
  computed: {
    coverImagesProps() {
      return coverImagesProps(this.isAllowed, this.product, this.product.images);
    },
    artworkImagesProps() {
      return artworkImagesProps(this.isAllowed, this.product, this.product.artworks);
    },
    galleryImagesProps() {
      return galleryImagesProps(this.isAllowed, this.product, this.product.gallery);
    },
    titleTreatmentImagesProps() {
      return titleTreatmentImagesProps(this.isAllowed, this.product, this.product.titleTreatmentImages);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.product = await http.get(Urls.PRODUCTS_VODS_ID_PAGE(this.id, this.page)).then(({ data }) => data);
      this.$emit(AppEvent.MODEL, this.product);
    },
  },
};
</script>
