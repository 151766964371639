import { priceFormat } from '@/helper';
import { Page } from '@/enums';
import { UiTableDetailLinkProduct, UiTablePaymentType } from '@/components/ui-table';

export const PAYMENT_CURRENCY = {
  field: 'currency',
  width: 90,
  isSortable: false,
  headingLabel: 'PAYMENT_CURRENCY',
};

export const PAYMENT_IP_COUNTRY = {
  field: 'payment-ip-country',
  width: 150,
  headingLabel: 'PAYMENT_IP_COUNTRY',
  dataMapper: ({ ip, country }) => (ip ? `${ip}${country ? ` (${country})` : ''}` : ''),
};

export const PAYMENT_PRODUCT = {
  field: 'product.title',
  minWidth: 120,
  headingLabel: 'COMMON_PRODUCT',
  dataComponent: UiTableDetailLinkProduct,
  dataComponentPropsMapper: ({ product }) => ({ product, page: Page.PAYMENTS }),
};

export const PAYMENT_PRICE = {
  field: 'price',
  width: 75,
  isSortable: true,
  headingLabel: 'PAYMENT_PRICE',
  dataClass: 'text-right',
  dataMapper: ({ price }) => priceFormat(price),
};

export const PAYMENT_TYPE = {
  field: 'channel',
  width: 160,
  isSortable: true,
  headingLabel: 'COMMON_TYPE',
  dataComponent: UiTablePaymentType,
};
