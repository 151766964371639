<script>
import Urls from '@/application/urls';
import AuditDetailList from '@/modules/administration/view/audit/detail/index.vue';

export default AuditDetailList.extend({
  data() {
    return {
      recordsUrl: Urls.APPLICATION_TIMERS_ID_AUDITS(this.id),
      exportUrl: Urls.APPLICATION_TIMERS_ID_AUDITS_EXPORT(this.id),
    };
  },
});
</script>
