<template>
  <div class="sidebar-content">
    <ul v-if="mappedRecords.length > 0" class="sidebar-content__list">
      <li v-for="record in mappedRecords" :key="record.id" class="sidebar-content__list-item">
        <div
          :style="{ 'background-image': 'url(/static/img/algorithms.png)' }"
          class="sidebar-content__list-item-image"
          @mousedown="onMouseDown(record, $event)"
        ></div>
        <div class="sidebar-content__list-item-description">
          <span class="sidebar-content__list-item-name" v-text="record.name"></span>
        </div>
      </li>
    </ul>
    <p
      v-else-if="isFetching"
      class="sidebar-content__no-data-info"
      v-text="`${$translate('WIDGET_SIDEBAR_PRODUCTS_LOADING')}...`"
    ></p>
    <p v-else class="sidebar-content__no-data-info" v-text="$translate('COMMON_NO_RESULTS')"></p>
  </div>
</template>
<script>
import Urls from '@/application/urls';
import DroppableMixin from '@/mixin/droppable';
import { ContentType, QueryParam } from '@/enums';

export default {
  mixins: [DroppableMixin],
  props: {
    records: {
      type: Array,
      required: true,
    },
    fetchRecords: {
      type: Function,
      required: true,
    },
    isFetching: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      request: {
        url: Urls.SECTIONS_ALGORITHMS,
        params: {
          sort: QueryParam.TITLE,
          order: QueryParam.ASCENDING,
        },
      },
    };
  },
  computed: {
    mappedRecords() {
      return this.records.map((record) => ({ ...record, type: ContentType.ALGORITHM, maxResults: 12 }));
    },
  },
  watch: {
    request: {
      handler: 'fetchRecords',
      immediate: true,
      deep: true,
    },
  },
};
</script>
