<template>
  <ui-detail v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import Urls from '@/application/urls';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import { RoleName } from '@/enums';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.ADMIN,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        modelUrl: Urls.ADS_SERVERS_ID(this.id),
        formElementsFunction: () => [
          [
            Fields.HEADER_METADATA,
            Fields.TYPE_DISABLED,
            Fields.textInputField('name', 'AD_SERVER_NAME', true),
            Fields.textInputField('url', 'COMMON_URL', true),
            Fields.CREATED_AT,
            Fields.MODIFIED_AT,
          ],
        ],
      };
    },
  },
};
</script>
