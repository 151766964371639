<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition">
    <template slot="custom-buttons">
      <div class="ad-break-list__vmap">
        <label class="ad-break-list__vmap-label" v-text="`${$translate('EXTERNAL_AD_VMAP_URL')}:`"></label>
        <input
          v-model="externalAdVmapUrl"
          :disabled="!isAllowed"
          class="ui-input"
          type="text"
        >
        <button
          type="submit"
          class="button button-info"
          @click.prevent="saveExternalAdVmapUrl"
          v-text="$translate('COMMON_SAVE')"
        ></button>
      </div>
    </template>
  </v-list-wrapper>
</template>
<script>
import { UiModalPopup } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import http from '@/lib/http';
import { AdBreakPlacement, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import UiFormDictionarySelect from '@/components/ui-form/ui-form-dictionary-select.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    defaultPlacementValue: String,
    isOffsetColumnHidden: Boolean,
  },
  data() {
    const url = Urls.ITEMS_ID_AD_BREAKS(this.id);
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : `${url}`),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_AD_BREAK' : 'ADD_AD_BREAK'),
      saveModelFormElementsFunction: (model) => [
        [
          {
            field: 'placement',
            component: UiFormDictionarySelect,
            isMandatory: true,
            componentOptions: {
              label: 'AD_BREAK_PLACEMENT',
              dictUrl: Urls.ITEMS_BREAKS_PLACEMENTS,
              disabled: !!this.defaultPlacementValue,
            },
          },
          {
            ...Fields.numberInputField(
              'percentOffsetStart',
              'AD_BREAK_PERCENT_OFFSET_START',
              model.percentOffsetStart !== '' || model.timeOffsetStart === '',
            ),
            isHidden: model.placement !== AdBreakPlacement.MIDROLL,
          },
          {
            ...Fields.numberInputField(
              'timeOffsetStart',
              'AD_BREAK_TIME_OFFSET_START',
              model.timeOffsetStart !== '' || model.percentOffsetStart === '',
            ),
            isHidden: model.placement !== AdBreakPlacement.MIDROLL,
          },
          {
            field: 'adServer',
            component: UiFormDictionarySelect,
            isMandatory: true,
            componentOptions: {
              dictUrl: Urls.ADS_SERVERS_ACTIVE,
              label: 'AD_SERVER_NAME',
              valueAsObject: true,
            },
          },
          {
            field: 'tags',
            component: UiFormAutocompleteInline,
            componentOptions: {
              label: 'AD_BREAK_TAGS',
              dictType: 'AD_BREAK_TAG',
              field: 'name',
              multiple: true,
            },
          },
        ],
      ],
      saveModelDefaultValues: {
        placement: this.defaultPlacementValue,
        timeOffsetStart: '',
        percentOffsetStart: '',
      },
      externalAdVmapUrl: undefined,
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'placement',
            width: 200,
            headingLabel: 'AD_BREAK_PLACEMENT',
          },
          {
            field: 'percentOffsetStart',
            width: 100,
            isHidden: this.isOffsetColumnHidden,
            headingLabel: 'AD_BREAK_PERCENT_OFFSET_START',
          },
          {
            field: 'timeOffsetStart',
            width: 100,
            isHidden: this.isOffsetColumnHidden,
            headingLabel: 'AD_BREAK_TIME_OFFSET_START',
          },
          {
            field: 'adServer.name',
            width: 200,
            headingLabel: 'AD_SERVER_NAME',
          },
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
  methods: {
    async saveExternalAdVmapUrl() {
      await http.post(Urls.ITEMS_ID_AD_BREAKS_EXTERNAL(this.id), { name: this.externalAdVmapUrl });
      this.$modal.create(UiModalPopup, {
        text: this.$translate('COMMON_SAVE_SUCCESS'),
      });
    },
  },
  watch: {
    async records() {
      const { data } = await http.get(Urls.ITEMS_ID_AD_BREAKS_EXTERNAL(this.id));
      this.externalAdVmapUrl = data;
    },
  },
});
</script>
