<template>
  <div class="ui-messages">
    <div class="ui-messages__header">
      <p class="ui-messages__header-text" v-text="`${$translate('CONTACT_MESSAGES')}:`"></p>

      <div class="ui-messages__toggle">
        <button
          class="button"
          @click="areAllMessagesCollapsed ? expandAll() : collapseAll()"
          v-text="$translate(areAllMessagesCollapsed ? 'CONTACT_EXPAND_ALL' : 'CONTACT_COLLAPSE_ALL')"
        ></button>
      </div>

      <template v-if="isWriteAllowed && isReplying">
        <button class="button" @click="toggleIsReplying" v-text="$translate('COMMON_CANCEL')"></button>
        <button class="button ui-messages__button-reply" @click="reply" v-text="$translate('COMMON_SEND')"></button>
      </template>
      <template v-else-if="isWriteAllowed">
        <select v-model="currentTemplate" class="ui-select ui-messages__templates">
          <option :value="undefined" v-text="'-'"></option>
          <option
            v-for="{ id, title } in templates"
            :key="id"
            :value="id"
            v-text="title"
          ></option>
        </select>
        <button
          class="button ui-messages__button-reply"
          @click="startReplying"
          v-text="$translate('CONTACT_REPLY')"
        ></button>
      </template>
    </div>

    <form v-if="isWriteAllowed && isReplying" id="reply-form">
      <ui-text-editor v-model="replyMessage" />
    </form>

    <div class="ui-panel">
      <ui-message
        v-for="message in messages"
        :key="message.id"
        :message="message"
        :is-expanded="!collapsedMessages.includes(message.id)"
        :sender-email="senderEmail"
        @toggle-is-expanded="toggleIsMessageExpanded(message)"
      />
    </div>
  </div>
</template>
<script>
import UiMessage from './ui-message.vue';

export default {
  components: {
    UiMessage,
    UiTextEditor: () => import('@/components/ui-text-editor/ui-text-editor.vue'),
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    templates: {
      type: Array,
      required: true,
    },
    footer: {
      type: Object,
      required: true,
    },
    isWriteAllowed: {
      type: Boolean,
      required: true,
    },
    isReplying: {
      type: Boolean,
      required: true,
    },
    senderEmail: String,
  },
  data() {
    return {
      collapsedMessages: [],
      currentTemplate: undefined,
      replyMessage: undefined,
    };
  },
  computed: {
    areAllMessagesCollapsed() {
      return this.collapsedMessages.length === this.messages.length;
    },
  },
  methods: {
    expandAll() {
      this.collapsedMessages = [];
    },
    collapseAll() {
      this.collapsedMessages = this.messages.map(({ id }) => id);
    },
    startReplying() {
      this.toggleIsReplying();
      this.replyMessage = '';
      const template = this.templates.find(({ id }) => id === this.currentTemplate);
      this.replyMessage = template ? template.content : this.footer.content;
    },
    toggleIsReplying() {
      this.$emit('toggle-is-replying');
    },
    toggleIsMessageExpanded({ id }) {
      this.collapsedMessages = this.collapsedMessages.includes(id)
        ? this.collapsedMessages.filter((messageId) => messageId !== id)
        : [...this.collapsedMessages, id];
    },
    async reply() {
      this.$emit('reply', this.replyMessage);
    },
  },
};
</script>
