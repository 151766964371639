import Urls from '@/application/urls';
import http from '@/lib/http';
import { Constants } from '@/enums';

const DEFAULT_LANGUAGE = localStorage.getItem(Constants.LANGUAGE_KEY) || 'ENG';
const DEFAULT_CONTENT_LANGUAGE = localStorage.getItem(Constants.CONTENT_LANGUAGE_KEY) || 'ENG';

const getBrowserLanguage = () => {
  const language = navigator.language || navigator.languages?.[0];

  return language?.split('-')[0];
};

const getLanguage = (localStorageKey, supportedLanguages) => {
  const userLanguage = localStorage.getItem(localStorageKey) || getBrowserLanguage();

  return supportedLanguages.includes(userLanguage) ? userLanguage : DEFAULT_LANGUAGE;
};

const setHttpLanguageParam = (language) => {
  http.defaults.params.lang = language;
};

const fetchTranslations = (language) => http.get(Urls.APPLICATIONS_TRANSLATIONS, { params: { language } }).then(({ data }) => data);

class I18n {
  constructor() {
    this.language = DEFAULT_LANGUAGE;
    this.contentLanguage = DEFAULT_CONTENT_LANGUAGE;
    this.languages = [];
    this.i18n = {};
  }

  get(key) {
    return this.i18n[this.language]?.[key];
  }

  async setLanguage(language) {
    if (!this.i18n[language]) {
      this.i18n[language] = await fetchTranslations(language);
    }
    this.language = language;
    localStorage.setItem(Constants.LANGUAGE_KEY, language);
  }

  async setContentLanguage(language) {
    setHttpLanguageParam(language);
    this.contentLanguage = language;
    localStorage.setItem(Constants.CONTENT_LANGUAGE_KEY, language);
  }

  async fetch() {
    this.languages = await http
      .get(Urls.APPLICATIONS_TRANSLATIONS_LANGUAGES)
      .then(({ data }) => data.map(({ name }) => name.toUpperCase()));
    this.language = getLanguage(Constants.LANGUAGE_KEY, this.languages);

    if (localStorage.getItem(Constants.SELECTED_TENANT_UID)) {
      this.contentLanguages = await http
        .get(Urls.APPLICATIONS_CONTENTS_LANGUAGES)
        .then(({ data }) => data.map(({ name }) => name.toUpperCase()));

      this.contentLanguage = getLanguage(Constants.CONTENT_LANGUAGE_KEY, this.contentLanguages);
      setHttpLanguageParam(this.contentLanguage);
    }

    this.i18n[this.language] = await fetchTranslations(this.language);
  }
}

const i18n = new I18n();

export default i18n;
