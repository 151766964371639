import Urls from '@/application/urls';
import { autocomplete, multiSwitch } from '@/components/filter/filters';
import { UiMultiselectOptionWithNameHover } from '@/components/ui-multiselect';

export const AD_BREAKS = multiSwitch('adBreaks', { label: 'CONTAINS_AD_BREAKS' });

export const AUDIO = multiSwitch('audios', { label: 'VOD_AUDIO' });

export const CATCHUP = multiSwitch('catchup', { label: 'CATCHUP_AVAILABLE' });

export const CATEGORIES = (itemsCategoriesType) => autocomplete('categories', {
  label: 'ITEM_CATEGORIES',
  dictUrl: Urls.ITEMS_CATEGORIES_TYPE(itemsCategoriesType),
  field: 'key',
  slots: {
    option: UiMultiselectOptionWithNameHover,
  },
});

export const CURRENCIES = autocomplete('currencies', {
  label: 'PRODUCT_PAYMENTS_CURRENCY',
  dictUrl: Urls.CURRENCIES_DICTIONARIES,
  field: 'name',
  slots: {
    option: UiMultiselectOptionWithNameHover,
  },
});

export const ENCRYPTED = multiSwitch('encrypted', { label: 'PRODUCT_ENCRYPTED' });

export const FREE = multiSwitch('free', { label: 'PRODUCT_FREE' });

export const GENRE = autocomplete('genres', {
  label: 'VOD_GENRE',
  dictType: 'GENRE',
  field: 'key',
  slots: {
    option: UiMultiselectOptionWithNameHover,
  },
});

export const HAS_DESCRIPTION = multiSwitch('hasDescription', { label: 'PRODUCT_HAS_DESCRIPTION' });

export const HAS_SUBTITLES = multiSwitch('hasSubtitles', { label: 'PRODUCT_HAS_SUBTITLES' });

export const LOGIN_REQUIRED = multiSwitch('loginRequired', { label: 'PRODUCT_LOGIN_REQUIRED' });

export const MAIN_CATEGORIES = (itemsCategoriesType) => autocomplete('mainCategories', {
  label: 'COMMON_CATEGORY',
  dictUrl: Urls.ITEMS_CATEGORIES_TYPE(itemsCategoriesType),
  field: 'key',
  slots: {
    option: UiMultiselectOptionWithNameHover,
  },
});

export const MOVIE = multiSwitch('videos', { label: 'VOD_MOVIE' });

export const NPRV = multiSwitch('npvr', { label: 'NPRV_AVAILABLE' });

export const PRODUCT_SPECIAL_GLOBAL = multiSwitch('globals', { label: 'PRODUCT_SPECIAL_GLOBAL' });

export const PRODUCT_SPECIAL_TYPES = autocomplete('types', {
  label: 'PRODUCT_SPECIAL_TYPES',
  dictUrl: Urls.PRODUCTS_SPECIAL_TYPES,
  field: 'name',
  slots: {
    option: UiMultiselectOptionWithNameHover,
  },
});

export const SERVICE_TYPES = autocomplete('serviceTypes', {
  label: 'SERVICE_TYPE',
  dictUrl: Urls.ITEMS_DICTIONARIES_SERVICE_TYPES,
});

export const CHROMECAST = multiSwitch('chromecast', { label: 'CHROMECAST' });

export const STARTOVER = multiSwitch('startover', { label: 'STARTOVER_AVAILABLE' });

export const TIMESHIFT = multiSwitch('timeshift', { label: 'TIMESHIFT_AVAILABLE' });

export const UHD = multiSwitch('uhds', { label: 'ITEM_UHD' });

export const STRIPE = multiSwitch('stripes', { label: 'COMMON_STRIPE' });
