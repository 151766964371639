<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PROFILES_AVATARS,
      removeModelUrlFunction: ({ id }) => Urls.PROFILES_AVATARS_ID(id),
      saveModelUrlFunction: () => Urls.PROFILES_AVATARS,
      saveModelModalTitleFunction: () => 'ADD_AVATAR',
      saveModelFormElementsFunction: (model) => [[Fields.NAME, Fields.RANK, Fields.URL_WITH_FILE_UPLOAD(model)]],
      paramsConfig: {
        sort: QueryParam.RANK,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.POSTER(),
          Columns.ITEM_NAME(({ id, name }) => ({ id, text: name, name: 'avatars.detail' })),
          Columns.RANK,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
