<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { AuditResourceType, QueryParam, RoleName } from '@/enums';
import { ModelDetailMixin } from '@/mixin';
import * as Filters from '@/components/filter/filters';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as AdministrationColumns from '@/modules/administration/view/columns';

export default ListView.extend({
  mixins: [ModelDetailMixin],
  data() {
    return {
      writeRole: RoleName.ADMIN,
      recordsUrl: Urls.AUDITS,
      filters: [
        Filters.CREATE_SINCE(),
        Filters.CREATE_TILL(),
        Filters.autocomplete('operatorEmails', { label: 'COMMON_OPERATOR', dictUrl: Urls.OPERATORS_DICTIONARIES }),
        Filters.autocomplete('resourceTypes', {
          label: 'COMMON_TYPE',
          dict: Object.keys(AuditResourceType).map((name) => ({ name })),
        }),
        Filters.autocomplete('eventTypes', { label: 'COMMON_ACTION', dictUrl: Urls.AUDITS_EVENT_TYPES }),
        Filters.product('resourceItemTitles', { label: 'AUDIT_RESOURCE_ITEM_TITLE' }),
        Filters.input('resourceNames', { label: 'AUDIT_RESOURCE_ELEMENT_NAME' }),
        Filters.autocomplete('resourceSubscriberEmails', {
          label: 'AUDIT_RESOURCE_SUBSCRIBER',
          dictUrl: Urls.SUBSCRIBERS_DICTIONARIES,
        }),
        Filters.autocomplete('resourceOperatorEmails', {
          label: 'AUDIT_RESOURCE_OPERATOR',
          dictUrl: Urls.OPERATORS_DICTIONARIES,
        }),
      ],
      exportUrl: Urls.AUDITS_EXPORT,
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          AdministrationColumns.AUDIT_OPERATOR,
          AdministrationColumns.AUDIT_RESOURCE_TYPE,
          AdministrationColumns.AUDIT_EVENT_TYPE,
          AdministrationColumns.AUDIT_RESOURCE_NAME,
          Columns.CREATED_AT,
          AdministrationColumns.ACTIONS_DETAIL(this.showModelDetail),
        ],
        records: this.records,
      };
    },
  },
});
</script>
