<template>
  <span>{{ record.channel }} (<ui-table-payment-status :status="record.status" />)</span>
</template>
<script>
import { UiTablePaymentStatus } from '@/components/ui-table';

export default {
  name: 'ui-table-payment-type',
  components: {
    UiTablePaymentStatus,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
};
</script>
