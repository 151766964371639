<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import { UiFormCheckbox, UiFormTextInput, UiModalConfirm } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import http from '@/lib/http';
import {
  AppEvent, ItemType, RoleName, SectionType,
} from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as ContentFields from '@/modules/content/view/fields';
import CacheType from '@/helper/cache-type';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      cacheType: CacheType.SECTION,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        isSaveButtonSticky: true,
        modelUrl: Urls.SECTIONS_ID(this.id),
        fromApiModelMapper: (model) => ({
          ...model,
          _contentType: model.contentType,
          categoryType: model.mainCategory?.type,
        }),
        formElementsFunction: (model) => {
          const contentTypeColumn = ContentFields.CONTENT_TYPE(model);

          return [
            [
              Fields.CREATE_HEADER_METADATA_WITH_INVALIDATION(this.id, 'COMMON_INFO', this.cacheType),
              Fields.EXTERNAL_UID,
              Fields.TITLE,
              Fields.checkboxField('showTitle', 'SECTION_SHOW_TITLE'),
              {
                ...Fields.checkboxField('showTileTitle', 'SECTION_SHOW_TILE_TITLE'),
                isHidden: model.layoutDict?.name === ItemType.BANNER,
              },
              {
                field: 'catalog',
                component: UiFormCheckbox,
                componentOptions: {
                  label: 'SECTION_CATALOG',
                  tooltip: Fields.commonTooltip('HELP_SECTION_CATALOG'),
                },
              },
              Fields.LOGO,
              ContentFields.VIEWS,
              {
                ...contentTypeColumn,
                componentOptions: {
                  ...contentTypeColumn.componentOptions,
                  on: {
                    input: (value, oldValue) => {
                      const modal = this.$modal.create(UiModalConfirm, {
                        title: this.$translate('COMMON_REMOVE'),
                        confirmationText: this.$translate('SECTION_CHANGE_TYPE_CONFIRMATION'),
                      });

                      let success = false;

                      modal.$on(AppEvent.SUCCESS, () => {
                        success = true;
                        modal.close();
                      });

                      modal.$on(AppEvent.CLOSE, () => {
                        if (!success) {
                          model._contentType = oldValue;
                        } else {
                          contentTypeColumn.componentOptions.on.input();
                        }
                      });
                    },
                  },
                },
              },
              ContentFields.LAYOUT_DICT(model),
              ContentFields.PRESENTATION_TIME(model),
              ContentFields.TILES_ASPECT,
              ContentFields.TILES_ACTIVITY(() => {}),
              ContentFields.TILES_SIZE,
              {
                field: 'color',
                component: UiFormTextInput,
                componentOptions: {
                  label: 'COMMON_COLOR',
                  tooltip: Fields.commonTooltip(`${this.$translate('HELP_EXAMPLE_SHORTCUT')} #FFFFFF, #0033CC`),
                },
              },
              ContentFields.CATEGORY_TYPE(model),
              Fields.MAIN_CATEGORY(
                model.categoryType,
                model.contentType !== SectionType.CATALOG || !model.categoryType,
              ),
              {
                field: 'webUrl',
                component: UiFormTextInput,
                componentOptions: {
                  label: 'COMMON_WEB_URL',
                  tooltip: Fields.commonTooltip('HELP_WEB_URL'),
                  iconClass: 'icon-link',
                  on: {
                    'icon-click': async () => {
                      model.webUrl = await http
                        .get(Urls.SECTIONS_ID_WEB_URL_DEFAULT(model.id))
                        .then(({ data }) => data)
                        .catch(() => {});
                    },
                  },
                },
              },
              ContentFields.APP_URL,
              ContentFields.REFRESH_INTERVAL,
              Fields.CREATE_SINCE(),
              Fields.CREATE_TILL(),
              Fields.CUSTOM_BADGE,
              Fields.PLATFORMS,
              Fields.CREATED_AT,
              Fields.MODIFIED_AT,
            ],
            [Fields.HEADER_LEAD, Fields.LEAD, Fields.HEADER_DESCRIPTION, Fields.DESCRIPTION],
          ];
        },
      };
    },
  },
};
</script>
