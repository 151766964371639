<template>
  <div v-if="product" class="details-form">
    <ui-product-file-medium
      has-drm
      :product="product"
      present-video-title
      :videos="product.videos"
      :video-type="VideoType.PPV"
      :title="$translate('COMMON_VIDEO')"
      :allowed="isAllowed"
      @change="load"
    />

    <ui-product-file-stripe
      :is-allowed="isAllowed"
      :product-id="product.id"
      :records="product.stripe ? [product.stripe] : []"
      @change="load"
    />

    <ui-product-file-images v-bind="coverImagesProps" @change="load" />

    <ui-product-file-images v-bind="artworkImagesProps" @change="load" />

    <ui-product-file-images v-bind="galleryImagesProps" @change="load" />

    <ui-product-file-images v-bind="titleTreatmentImagesProps" @change="load" />
  </div>
</template>
<script>
import Urls from '@/application/urls';
import UiProductFileImages from '@/components/product-file-images/index.vue';
import UiProductFileMedium from '@/components/product-file-medium/index.vue';
import UiProductFileStripe from '@/components/product-file-stripe/index.vue';
import {
  artworkImagesProps,
  coverImagesProps,
  galleryImagesProps,
  titleTreatmentImagesProps,
} from '@/components/vod-files/images';
import { AppEvent, RoleName, VideoType } from '@/enums';
import http from '@/lib/http';
import { RoleMixin } from '@/mixin';

export default {
  components: {
    UiProductFileMedium,
    UiProductFileImages,
    UiProductFileStripe,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      product: undefined,
      VideoType,
    };
  },
  computed: {
    coverImagesProps() {
      return coverImagesProps(this.isAllowed, this.product, this.product.images);
    },
    artworkImagesProps() {
      return artworkImagesProps(this.isAllowed, this.product, this.product.artworks);
    },
    galleryImagesProps() {
      return galleryImagesProps(this.isAllowed, this.product, this.product.gallery);
    },
    titleTreatmentImagesProps() {
      return titleTreatmentImagesProps(this.isAllowed, this.product, this.product.titleTreatmentImages);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.product = await http.get(Urls.PRODUCTS_PPVS_ID_PAGE(this.id, this.page)).then(({ data }) => data);
      this.$emit(AppEvent.MODEL, this.product);
    },
  },
};
</script>
