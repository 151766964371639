import {
  ACTIONS, BUTTON_EDIT, BUTTON_MOVE, BUTTON_REMOVE,
} from '@/components/ui-table/columns';
import { UiTableDetailLinkProduct, UiTableItemDetails } from '@/components/ui-table/index';

export const BUTTON_DEACTIVATE = (onClick) => ({
  tooltip: 'HELP_DEACTIVATE',
  icon: 'icon-block',
  onClick,
});

export const NUMBER = {
  field: 'number',
  width: 90,
  headingLabel: 'COMMON_NUMBER',
  isSortable: true,
};

export const PRODUCT_TITLE = (headingLabel, dataComponentPropsMapper) => ({
  field: 'product.title',
  minWidth: 150,
  headingLabel,
  dataComponent: UiTableDetailLinkProduct,
  dataComponentPropsMapper,
});

export const PROGRAMME_ACTIONS = (isAllowed, createVodMethod, removeProgramme, transformToVod) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: [
      BUTTON_MOVE(() => createVodMethod(record), 'CREATE_VOD'),
      BUTTON_REMOVE(() => removeProgramme(record)),
      BUTTON_MOVE(() => transformToVod(record), 'TRANSFORM_TO_VOD'),
    ],
  });
  return ACTIONS(isAllowed, dataComponentPropsMapper);
};

export const SEASON_ACTIONS = (isAllowed, moveMethod, removeItemMethod) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: [BUTTON_MOVE(() => moveMethod(record.id, record.number)), BUTTON_REMOVE(() => removeItemMethod(record))],
  });
  return ACTIONS(isAllowed, dataComponentPropsMapper);
};

export const PAYMENT_SCHEDULE_ACTIONS = (isAllowed, editMethod, deactivateMethod, removeItemMethod) => {
  const dataComponentPropsMapper = (model) => ({
    buttons: [
      BUTTON_EDIT(() => editMethod(model)),
      ...(model.displayActive ? [BUTTON_DEACTIVATE(() => deactivateMethod(model.id))] : []),
      BUTTON_REMOVE(() => removeItemMethod(model)),
    ],
  });
  return ACTIONS(isAllowed, dataComponentPropsMapper);
};

export const TITLE = {
  field: 'title',
  minWidth: 200,
  headingLabel: 'COMMON_TITLE',
  dataComponent: UiTableItemDetails,
};
