import info from '@/application/info';

export default (appId, params) => {
  const appInfo = info.getApplicationInfoById(appId);

  if (!appInfo) {
    return null;
  }

  const link = `${appInfo.baseUrl}?tenant=${appInfo.tenant}`;

  if (params) {
    return `${link}&${new URLSearchParams(params).toString()}`;
  }

  return link;
};
