<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import { dateFormat } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { UiMultiselectOptionItem } from '@/components/ui-multiselect';
import { UiTableDetailLink } from '@/components/ui-table';
import { ItemType, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.ITEMS_ID_SECTIONS(this.id),
      availableSectionsUrl: Urls.ITEMS_ID_SECTIONS_AVAILABLE(this.id),
      removeModelUrlFunction: ({ section }) => `${this.recordsUrl}/${section.id}`,
      // section?.type is used to determine whether we are editing or creating. This field is being returned only for already existing sections
      // For section editing, section field must be disabled.
      saveModelUrlFunction: ({ section }) => (section?.type ? `${this.recordsUrl}/${section.id}` : this.recordsUrl),
      saveModelModalTitleFunction: ({ section }) => (section?.type ? 'EDIT_SECTION' : 'ADD_SECTION'),
      saveModelFormElementsFunction: (model) => {
        if (model.section) {
          if (model.section.title) {
            model.section.name = model.section.title;
          }

          model.section.type_ = ItemType.SECTION;
        }

        return [
          [
            {
              field: 'section',
              isMandatory: true,
              component: UiFormAutocompleteInline,
              componentOptions: {
                disabled: !!model.section?.type,
                label: 'ITEM_SECTION',
                dictUrl: this.availableSectionsUrl,
                slots: {
                  option: UiMultiselectOptionItem,
                },
                isOptionDisabled: (record) => !record.active,
                maxResults: 50,
              },
            },
            Fields.RANK_NOT_MANDATORY,
          ],
        ];
      },
      paramsConfig: {
        sort: QueryParam.RANK,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'section.title',
            minWidth: 120,
            headingLabel: 'COMMON_TITLE',
            dataComponent: UiTableDetailLink,
            dataComponentPropsMapper: ({ section }) => ({
              name: 'section.detail',
              id: section.id,
              text: section.title,
            }),
          },
          {
            field: 'section.layoutDict.name',
            width: 180,
            isSortable: true,
            headingLabel: 'SECTION_LAYOUT',
          },
          {
            field: 'section.tilesAspect',
            width: 120,
            headingLabel: 'SECTION_TILES_ASPECT',
          },
          {
            field: 'section.contentType',
            width: 150,
            isSortable: true,
            headingLabel: 'COMMON_TYPE',
          },
          Columns.RANK,
          {
            field: 'section.since',
            width: 160,
            headingLabel: 'COMMON_SINCE',
            dataMapper: ({ section }) => dateFormat(section.since),
          },
          {
            field: 'section.till',
            width: 160,
            headingLabel: 'COMMON_TILL',
            dataMapper: ({ section }) => dateFormat(section.till),
          },
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
  },
});
</script>
