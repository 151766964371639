<template>
  <settings-list type="states" />
</template>
<script>
import SettingsList from '@/components/settings-list/index.vue';

export default {
  components: {
    SettingsList,
  },
};
</script>
