<template>
  <span v-if="item.title" v-text="item.title"></span>
  <span v-else>
    <i class="no-title-icon">!</i>
    <span class="font-style-italic opacity-5" v-text="$translate(noTitleLabel)"></span>
  </span>
</template>
<script>
export default {
  name: 'ui-item-title',
  props: {
    item: {
      type: Object,
      required: true,
    },
    noTitleLabel: {
      type: String,
      default: 'COMMON_NO_TITLE',
    },
  },
};
</script>
