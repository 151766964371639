<template>
  <ui-detail
    ref="uiDetail"
    v-bind="detailDefinition"
    class="ui-detail--readonly-detail"
    v-on="$listeners"
  />
</template>
<script>
import Urls from '@/application/urls';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as PaymentFields from '@/modules/payment/view/fields';

export default {
  components: {
    UiDetail,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    detailDefinition() {
      return {
        modelUrl: Urls.SUBSCRIPTIONS_ID(this.id),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            PaymentFields.PRICE,
            PaymentFields.CURRENCY,
            PaymentFields.DURATION,
            Fields.SUBSCRIBER(this, model, 'subscriptions'),
            PaymentFields.PRODUCT(this, model),
            PaymentFields.STATUS,
            { ...Fields.textInputField('cancelReason', 'SUBSCRIPTION_CANCEL_REASON'), isHidden: !model.cancelReason },
            PaymentFields.CHANNEL,
            { ...Fields.datePickerField('till', 'SUBSCRIPTION_TILL'), isHidden: !model.till },
            {
              ...Fields.datePickerField('lastFailAttemptAt', 'SUBSCRIPTION_LAST_FAIL_ATTEMPT_AT'),
              isHidden: !model.lastFailAttemptAt,
            },
            Fields.datePickerField('nextChargeAt', 'SUBSCRIPTION_NEXT_CHARGE_AT'),
            Fields.CREATED_AT,
            { ...Fields.datePickerField('cancelledAt', 'SUBSCRIPTION_CANCELLED_AT'), isHidden: !model.cancelledAt },
          ],
        ],
      };
    },
  },
};
</script>
