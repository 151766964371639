<template>
  <ui-table-detail-link
    v-if="device"
    :id="device.id"
    :text="device.uid"
    name="device.detail"
  />
</template>
<script>
import { UiTableDetailLink } from '@/components/ui-table';

export default {
  name: 'ui-table-detail-link-device',
  components: {
    UiTableDetailLink,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
};
</script>
