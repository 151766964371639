<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { Page, QueryParam, RoleName } from '@/enums';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Filters from '@/components/filter/filters';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_LIVES_ID_RECORDINGS(this.id),
      exportUrl: Urls.PRODUCTS_LIVES_RECORDINGS_EXPORT,
      filters: [Filters.RECORDING_STATUS, Filters.RECORDING_LIVE, Filters.RECORDING_SUBSCRIBER],
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.RECORDING_STATUS,
          Columns.RECORDING_NAME,
          Columns.SUBSCRIBER_DETAIL(({ directory: { subscriber } }) => ({ subscriber, page: Page.RECORDINGS }), false),
          { ...Columns.SINCE, isSortable: false },
          { ...Columns.TILL, isSortable: false },
          Columns.CREATED_AT,
        ],
        records: this.records,
      };
    },
  },
});
</script>
