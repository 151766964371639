export default {
  BANNER: 'BANNER',
  BUNDLE: 'BUNDLE',
  CATCH_UP: 'CATCH_UP',
  DOWNLOAD: 'DOWNLOAD',
  EPISODE: 'EPISODE',
  LIVE: 'LIVE',
  MAX_BITRATE: 'MAX_BITRATE',
  N_PVR: 'N_PVR',
  NO_ADVERTISING: 'NO_ADVERTISING',
  PPV: 'PPV',
  PROGRAMME: 'PROGRAMME',
  SEASON: 'SEASON',
  SECTION: 'SECTION',
  SERIAL: 'SERIAL',
  SPECIAL: 'SPECIAL',
  TIMESHIFT: 'TIMESHIFT',
  VIDEO_SESSION_LIMIT: 'VIDEO_SESSION_LIMIT',
  VOD: 'VOD',
};
