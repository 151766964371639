<template>
  <h1 v-if="payment">
    <router-link
      :to="{ name: 'subscriber.detail', params: { id: payment.subscriber.id, page: Page.DETAIL } }"
      v-text="payment.subscriber.email"
    />
    {{ isAllowed ? '|' : '' }}
    <router-link v-if="isAllowed" :to="productLink(payment.product)" class="item-link">
      <ui-product-title :product="payment.product" />
    </router-link>
  </h1>
</template>
<script>
import { productLink } from '@/helper';
import { Page, RoleName } from '@/enums';
import UiProductTitle from '@/components/ui-product-title/index.vue';
import { RoleMixin } from '@/mixin';

export default {
  components: {
    UiProductTitle,
  },
  mixins: [RoleMixin],
  props: {
    payment: Object,
  },
  data() {
    return {
      Page,
      writeRole: RoleName.CMS_WRITE,
    };
  },
  methods: {
    productLink,
  },
};
</script>
