<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import * as Filters from '@/components/filter/filters';
import ListView from '@/components/list-view/list-view.vue';
import * as Fields from '@/components/ui-form/fields';
import { UiMultiselectOptionWithNameHover } from '@/components/ui-multiselect';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import {
  ItemType, QueryParam, RoleName, VideoType,
} from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import * as ProductColumns from '@/modules/product/view/columns';
import * as ProductFields from '@/modules/product/view/fields';
import * as ProductFilters from '@/modules/product/view/filters';

export const filters = [
  Filters.PRODUCT_ID,
  Filters.PLATFORMS,
  Filters.ACTIVE,
  ProductFilters.HAS_DESCRIPTION,
  ProductFilters.AD_BREAKS,
  ProductFilters.HAS_SUBTITLES,
  ProductFilters.GENRE,
  ProductFilters.MAIN_CATEGORIES(ItemType.VOD),
  ProductFilters.CATEGORIES(ItemType.VOD),
  Filters.ADVISORS,
  Filters.autocomplete('tags', {
    label: 'ITEM_TAGS',
    dictType: 'TAG',
    field: 'key',
    slots: {
      option: UiMultiselectOptionWithNameHover,
    },
  }),
  Filters.autocomplete('countries', {
    label: 'COMMON_COUNTRY',
    dictType: 'COUNTRY',
    field: 'key',
    slots: {
      option: UiMultiselectOptionWithNameHover,
    },
  }),
  Filters.autocomplete('providers', {
    label: 'PRODUCT_PROVIDER',
    dictType: 'PROVIDER',
    field: 'key',
    slots: {
      option: UiMultiselectOptionWithNameHover,
    },
  }),
  Filters.autocomplete('displayScheduleTypes', {
    label: 'DISPLAY_SCHEDULE_FILTER_TYPE',
    dictUrl: Urls.ITEMS_DICTIONARIES_DISPLAY_SCHEDULES_TYPES,
  }),
  Filters.autocomplete('subtitleLanguages', {
    field: 'key',
    label: 'VOD_SUBTITLES',
    dictType: 'MEDIA_LANGUAGE',
    getLabel: ({ key, name }) => `${key} (${name})`,
  }),
  Filters.autocomplete('videoFormats', { label: 'VIDEO_FORMAT', dictUrl: Urls.ITEMS_DICTIONARIES_MEDIA_FORMATS }),
  ProductFilters.CURRENCIES,
  ProductFilters.FREE,
  ProductFilters.UHD,
  ProductFilters.AUDIO,
  ProductFilters.MOVIE,
  Filters.multiSwitch('trailers', { label: 'ITEM_TRAILER' }),
  Filters.multiSwitch('galleries', { label: 'VOD_GALLERY' }),
  ProductFilters.LOGIN_REQUIRED,
  Filters.EXTERNAL_UID,
  Filters.input('years', { label: 'PRODUCT_YEAR', type: 'number' }),
  Filters.autocomplete('ratings', {
    label: 'COMMON_RATING',
    dictType: 'RATING',
    dictUrl: Urls.RATINGS,
    field: 'value',
  }),
  Filters.multiSwitch('downloadAvailable', { label: 'DOWNLOAD_AVAILABLE' }),
];

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_VODS,
      removeModelUrlFunction: ({ id }) => Urls.PRODUCTS_VODS_ID(id),
      saveModelUrlFunction: () => Urls.PRODUCTS_VODS,
      saveModelModalTitleFunction: () => 'ADD_MOVIE',
      saveModelFormElementsFunction: () => [
        [
          Fields.TITLE,
          ProductFields.DURATION,
          Fields.MAIN_CATEGORY(ItemType.VOD),
          ProductFields.YEAR,
          ProductFields.RATING,
          ProductFields.HD,
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL(),
        ],
      ],
      saveModelDefaultValues: {
        type: ItemType.VOD,
        type_: ItemType.VOD,
      },
      exportUrl: Urls.PRODUCTS_VODS_EXPORT,
      filters: [...filters, ProductFilters.ENCRYPTED, ProductFilters.STRIPE],
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.POSTER((record) => ({ record, videoType: VideoType.MOVIE })),
          ProductColumns.TITLE,
          Columns.PLATFORMS,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
