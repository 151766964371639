<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { Page, QueryParam, RoleName } from '@/enums';
import * as ProductColumns from '@/modules/product/view/columns';
import ListView from '@/components/list-view/list-view.vue';
import * as Filters from '@/components/filter/filters';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';

export default ListView.extend({
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_SEASONS,
      exportUrl: Urls.PRODUCTS_SEASONS_EXPORT,
      filters: [
        Filters.ACTIVE,
        Filters.PLATFORMS,
        Filters.input('numbers', {
          label: 'COMMON_SEASON_NUMBER',
          type: 'number',
        }),
        Filters.CREATE_SINCE(),
      ],
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          ProductColumns.TITLE,
          {
            ...ProductColumns.NUMBER,
            headingLabel: 'COMMON_SEASON_NUMBER',
          },
          ProductColumns.PRODUCT_TITLE('COMMON_SERIES', ({ serial }) => ({
            product: serial,
            page: Page.DETAIL,
          })),
          Columns.PLATFORMS,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
