<template>
  <div>
    <div class="details-container">
      <div class="details-container__header">
        <h1
          v-if="model"
          class="details-container__header-text"
          :class="{ 'details-container__header-text--inactive': !isModelActive }"
        >
          <ui-product-title :product="model" />
        </h1>
        <div class="details-container__header-icons">
          <div class="details-container__header-elements details-container__header-elements--left">
            <ui-external-application-icons :model="model" :is-model-active="isModelActive" />
          </div>
          <div class="details-container__header-elements">
            <span
              v-if="isAllowed && model"
              v-tooltip="$translate('HELP_MOVE')"
              class="details-container__header-element icon-fullscreen"
              @click="moveToSeason"
            ></span>
            <span
              v-if="hasRole(RoleName.ADMIN) && model && model.externalUid"
              v-tooltip="$translate('HELP_SYNCHRONIZATION')"
              class="details-container__header-element icon-timeshift"
              @click="synchronizeModel({ incremental: false })"
            ></span>
            <span
              v-if="isAllowed && model && model.externalUid"
              v-tooltip="$translate('HELP_SYNCHRONIZATION_INCREMENTAL')"
              class="details-container__header-element icon-efficiency"
              @click="synchronizeModel({ incremental: true })"
            ></span>
            <ui-switch
              v-if="model"
              v-tooltip="activateModelButtonText"
              :disabled="!isAllowed"
              :value="isModelActive"
              class="details-container__header-element"
              @input="activateModel()"
            />
          </div>
        </div>
      </div>
      <ui-tabs :current-route="$route" :tabs="tabs" />
      <component
        :is="page"
        :id="id"
        ref="childComponent"
        :page="page"
        @model="onModel"
      />
    </div>
  </div>
</template>
<script>
import { UiSwitch } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import http from '@/lib/http';
import { isSessionDbAvailable, productLink } from '@/helper';
import { ItemType, Page, RoleName } from '@/enums';
import {
  ActivateModelMixin, RoleMixin, SaveModelMixin, SynchronizeModelMixin,
} from '@/mixin';
import UiTabs from '@/components/ui-tabs/index.vue';
import UiProductTitle from '@/components/ui-product-title/index.vue';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';
import * as ProductFields from '@/modules/product/view/fields';
import UiExternalApplicationIcons from '@/components/ui-external-application-icons/index.vue';
import Detail from './detail.vue';
import Persons from './persons.vue';
import Audits from './audits.vue';
import Bundles from './bundles.vue';
import Files from './files.vue';
import Sections from './sections.vue';
import Downloads from './downloads.vue';
import DisplaySchedules from './display-schedules.vue';
import PaymentSchedules from './payment-schedules.vue';
import Payments from './payments.vue';
import Subscriptions from './subscriptions.vue';
import VideoSessions from './video-sessions.vue';
import AdBreaks from './ad-breaks.vue';

export default {
  components: {
    UiSwitch,
    UiTabs,
    UiProductTitle,
    AdBreaks,
    Audits,
    Detail,
    UiExternalApplicationIcons,
    Persons,
    Bundles,
    Files,
    Sections,
    DisplaySchedules,
    Downloads,
    PaymentSchedules,
    Payments,
    Subscriptions,
    VideoSessions,
  },
  mixins: [ActivateModelMixin, RoleMixin, SaveModelMixin, SynchronizeModelMixin],
  data() {
    return {
      RoleName,
      writeRole: RoleName.CMS_WRITE,
      synchronizeUrl: Urls.PRODUCTS_VODS_ID_SYNCHRONIZE(this.$route.params.id),
      model: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    page() {
      return this.$route.params.page;
    },
    tabs() {
      return [
        { label: 'TAB_DETAIL', page: Page.DETAIL },
        { label: 'COMMON_PERSONS', page: Page.PERSONS },
        { label: 'COMMON_BUNDLES', page: Page.BUNDLES },
        { label: 'COMMON_FILES', page: Page.FILES },
        { label: 'COMMON_SECTIONS', page: Page.SECTIONS },
        { label: 'COMMON_DOWNLOADS', page: Page.DOWNLOADS },
        { label: 'TAB_DISPLAY-SCHEDULES', page: Page.DISPLAY_SCHEDULES },
        { label: 'TAB_PAYMENT-SCHEDULES', page: Page.PAYMENT_SCHEDULES },
        { label: 'COMMON_PAYMENTS', page: Page.PAYMENTS },
        { label: 'COMMON_SUBSCRIPTIONS', page: Page.SUBSCRIPTIONS },
        {
          label: 'TAB_VIDEO-SESSIONS',
          page: Page.VIDEO_SESSIONS,
          isHidden: isSessionDbAvailable() || !this.isAllowed,
        },
        { label: 'COMMON_AD_BREAKS', page: Page.AD_BREAKS },
        { label: 'COMMON_EVENTS', page: Page.AUDITS },
      ];
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
    init() {
      this.$refs.childComponent.$refs.uiDetail?.fetchModel();
    },
    async moveToSeason() {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: (model) => [[ProductFields.SELECTED_SERIES(model), ProductFields.SELECTED_SEASON(model)]],
        modalTitleFunction: () => 'MOVE_VOD_TO_SEASON',
      });
      const saveModelPromiseFunction = ({ destinationSeasonId }) => {
        const url = Urls.PRODUCTS_VODS_ID_MOVE_ID(this.id, destinationSeasonId);
        return http.put(url);
      };

      await this.openSaveModelModal(modal, saveModelPromiseFunction);

      this.$router.replace(productLink({ type: ItemType.EPISODE, id: this.id }));
    },
  },
};
</script>
