<template>
  <ui-table-detail-link
    :id="product.id"
    :name="name"
    :page="page"
    :tooltip="product.title"
  >
    <ui-product-title :product="product" />
  </ui-table-detail-link>
</template>
<script>
import { productLink } from '@/helper';
import { UiTableDetailLink } from '@/components/ui-table/index';
import UiProductTitle from '@/components/ui-product-title/index.vue';

export default {
  name: 'ui-table-detail-link-product',
  components: {
    UiTableDetailLink,
    UiProductTitle,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    page: {
      type: String,
      default: 'detail',
    },
  },
  computed: {
    name() {
      return productLink(this.product).name;
    },
  },
};
</script>
