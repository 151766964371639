<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { ItemType, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Filters from '@/components/filter/filters';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.BANNERS,
      removeModelUrlFunction: ({ id }) => Urls.BANNERS_ID(id),
      saveModelUrlFunction: () => Urls.BANNERS,
      saveModelModalTitleFunction: () => 'ADD_BANNER',
      saveModelFormElementsFunction: () => [
        [Fields.TITLE, Fields.CREATE_SINCE(), Fields.CREATE_TILL(), Fields.PLATFORMS],
      ],
      saveModelDefaultValues: {
        type: ItemType.BANNER,
        type_: ItemType.BANNER,
        platforms: [],
      },
      filters: [
        Filters.PLATFORMS,
        Filters.multiSwitch('videoAvailable', { label: 'VIDEO_FILE_AVAILABLE' }),
        Filters.ACTIVE,
      ],
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.POSTER(),
          Columns.PRODUCT_DETAIL((record) => ({ product: record }), 'COMMON_TITLE'),
          Columns.SINCE,
          Columns.TILL,
          Columns.PLATFORMS,
          Columns.CREATED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
