<template>
  <form class="settings-list">
    <ui-table v-bind="tableDefinition">
      <template #key="{ record: { key } }">
        <span v-tooltip.icon="getSettingTooltip(key)" class="settings-list__key" v-text="key"></span>
      </template>
      <template #value="{ record: { key } }">
        <input
          v-model="settingsCopy[key]"
          v-tooltip="type === 'states' ? $dateFormat(parseInt(settingsCopy[key])) : ''"
          :class="{ 'input-modified-color': settings[key] !== settingsCopy[key] }"
          class="ui-input"
          type="text"
        >
      </template>
    </ui-table>
    <div class="settings-list__button-wrapper">
      <button
        :disabled="!isAnyOfSettingsModified"
        tabindex="0"
        type="submit"
        class="button button-info"
        @click.prevent="save"
        v-text="$translate('COMMON_SAVE')"
      ></button>
    </div>
  </form>
</template>
<script>
import { UiModalPopup } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import http from '@/lib/http';
import { UiTable } from '@/components/ui-table';

export default {
  components: {
    UiTable,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      settings: {},
      settingsCopy: {},
    };
  },
  computed: {
    settingsUrl() {
      return Urls.APPLICATION_TYPE(this.type);
    },
    settingsAsList() {
      return Object.entries(this.settings)
        .map(([key, value]) => ({ key, value }))
        .sort((a, b) => a.key.localeCompare(b.key));
    },
    isAnyOfSettingsModified() {
      return this.settingsAsList.some(({ key, value }) => value !== this.settingsCopy[key]);
    },
    tableDefinition() {
      return {
        columns: [
          {
            field: 'key',
            minWidth: 350,
            headingLabel: 'COMMON_KEY',
          },
          {
            field: 'value',
            minWidth: 250,
            headingLabel: 'COMMON_VALUE',
          },
        ],
        records: this.settingsAsList,
      };
    },
  },
  async mounted() {
    try {
      this.$loader.show('LOADING_INFO');
      this.settings = await http.get(this.settingsUrl).then(({ data }) => data);
      this.$loader.hide();
    } catch (e) {
      this.$loader.hide();
    }
    this.settingsCopy = { ...this.settings };
  },
  methods: {
    getSettingTooltip(key) {
      const tooltipKey = `HELP_${key.replace(/\./g, '_')}`;
      const tooltip = this.$translate(tooltipKey);

      return tooltip !== tooltipKey ? tooltip : undefined;
    },
    async save() {
      try {
        this.$loader.show('SAVING_DATA');
        await http.put(this.settingsUrl, this.settingsCopy);
        this.$loader.hide();
        this.$modal.create(UiModalPopup, {
          text: this.$translate('COMMON_SAVE_SUCCESS'),
        });
        this.settings = { ...this.settingsCopy };
      } catch (error) {
        this.$loader.hide();
        throw error;
      }
    },
  },
};
</script>
