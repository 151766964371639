import { RoleName } from '@/enums';

export default [
  {
    name: 'product',
    label: 'COMMON_PRODUCTS',
    readRole: RoleName.CMS_READ,
    items: [
      {
        routeName: 'vods',
        icon: 'icon-movie',
        label: 'MENU_PRODUCT_VODS',
      },
      {
        routeName: 'series',
        icon: 'icon-serial',
        label: 'MENU_PRODUCT_SERIES',
      },
      {
        routeName: 'seasons',
        icon: 'icon-season',
        label: 'MENU_PRODUCT_SEASONS',
      },
      {
        routeName: 'episodes',
        icon: 'icon-episodes',
        label: 'MENU_PRODUCT_EPISODES',
      },
      {
        routeName: 'lives',
        icon: 'icon-smart_tv',
        label: 'MENU_PRODUCT_LIVES',
      },
      {
        routeName: 'ppvs',
        icon: 'icon-ppv',
        label: 'MENU_PRODUCT_PPVS',
      },
      {
        routeName: 'bundles',
        icon: 'icon-packages',
        label: 'COMMON_BUNDLES',
      },
      {
        routeName: 'specials',
        icon: 'icon-program',
        label: 'MENU_PRODUCT_SPECIALS',
      },
      {
        routeName: 'productaudit',
        icon: 'icon-calendar-detailed',
        label: 'COMMON_EVENTS',
      },
    ],
  },
  {
    name: 'subscriber',
    label: 'MENU_USER',
    readRole: RoleName.CRM_READ,
    items: [
      {
        routeName: 'subscriber',
        icon: 'icon-user',
        label: 'MENU_USER_SUBSCRIBER',
      },
      {
        routeName: 'groups',
        icon: 'icon-user-group',
        label: 'MENU_USER_GROUPS',
      },
      {
        routeName: 'device',
        icon: 'icon-pc',
        label: 'COMMON_DEVICES',
      },
      {
        routeName: 'contact',
        icon: 'icon-help',
        label: 'MENU_USER_CONTACT',
      },
      {
        routeName: 'download',
        icon: 'icon-download',
        label: 'COMMON_DOWNLOADS',
      },
      {
        routeName: 'recording',
        icon: 'icon-record',
        label: 'COMMON_RECORDINGS',
      },
      {
        routeName: 'subscriberaudit',
        icon: 'icon-calendar-detailed',
        label: 'COMMON_EVENTS',
      },
    ],
  },
  {
    name: 'content',
    label: 'COMMON_CONTENT_PLURAL',
    readRole: RoleName.CMS_READ,
    items: [
      {
        routeName: 'section',
        icon: 'icon-sections',
        label: 'MENU_CONTENT_SECTION',
      },
      {
        routeName: 'view',
        icon: 'icon-eye',
        label: 'MENU_CONTENT_VIEWS',
      },
      {
        routeName: 'category',
        icon: 'icon-categories',
        label: 'COMMON_CATEGORIES',
      },
      {
        routeName: 'dictionaries',
        icon: 'icon-dictionaries',
        label: 'COMMON_DICTIONARIES',
      },
      {
        routeName: 'logo',
        icon: 'icon-logo',
        label: 'MENU_CONTENT_LOGO',
      },
      {
        routeName: 'document',
        icon: 'icon-new',
        label: 'MENU_CONTENT_DOCUMENT',
      },
      {
        routeName: 'translation.fo',
        icon: 'icon-translations',
        label: 'COMMON_TRANSLATIONS',
      },
      {
        routeName: 'agreement',
        icon: 'icon-register',
        label: 'COMMON_AGREEMENTS',
      },
      {
        routeName: 'banner',
        icon: 'icon-banners',
        label: 'MENU_CONTENT_BANNER',
      },
      {
        routeName: 'avatar',
        icon: 'icon-avatars',
        label: 'MENU_CONTENT_AVATAR',
      },
      {
        routeName: 'contentaudit',
        icon: 'icon-calendar-detailed',
        label: 'COMMON_EVENTS',
      },
    ],
  },
  {
    name: 'payment',
    label: 'MENU_SALE',
    readRole: RoleName.CRM_READ,
    items: [
      {
        routeName: 'payments',
        icon: 'icon-payments',
        label: 'COMMON_PAYMENTS',
      },
      {
        routeName: 'subscriptions',
        icon: 'icon-pay',
        label: 'COMMON_SUBSCRIPTIONS',
      },
      {
        routeName: 'wallet',
        icon: 'icon-wallet',
        label: 'COMMON_WALLET',
      },
      {
        routeName: 'promotions',
        icon: 'icon-premiere',
        label: 'MENU_SALE_PROMOTIONS',
      },
      {
        routeName: 'salesaudit',
        icon: 'icon-calendar-detailed',
        label: 'COMMON_EVENTS',
      },
    ],
  },
  {
    name: 'report',
    label: 'MENU_REPORT',
    readRole: RoleName.CRM_READ,
    items: [
      {
        routeName: 'statistics',
        icon: 'icon-statistics',
        label: 'MENU_REPORT_STATISTICS',
      },
    ],
  },
  {
    name: 'administration',
    label: 'MENU_ADMINISTRATION',
    readRole: RoleName.ADMIN,
    items: [
      {
        routeName: 'operator',
        icon: 'icon-operator',
        label: 'MENU_ADMINISTRATION_OPERATOR',
      },
      {
        routeName: 'ads',
        icon: 'icon-list',
        label: 'MENU_ADMINISTRATION_ADS',
      },
      {
        routeName: 'nickname',
        icon: 'icon-block',
        label: 'MENU_ADMINISTRATION_NICKNAMES',
      },
      {
        routeName: 'geoip',
        icon: 'icon-locked',
        label: 'MENU_ADMINISTRATION_GEOIP',
      },
      {
        routeName: 'timer',
        icon: 'icon-watch',
        label: 'MENU_ADMINISTRATION_TIMER',
      },
      {
        routeName: 'translation.bo',
        icon: 'icon-translations',
        label: 'COMMON_TRANSLATIONS',
      },
      {
        routeName: 'setting.page',
        icon: 'icon-settings',
        label: 'MENU_ADMINISTRATION_SETTING',
      },
      {
        routeName: 'softwaredecoding',
        icon: 'icon-list',
        label: 'MENU_ADMINISTRATION_DECODING_CPU',
      },
      {
        routeName: 'audit',
        icon: 'icon-calendar-detailed',
        label: 'COMMON_EVENTS',
      },
    ],
  },
  {
    name: 'tenant',
    label: 'MENU_MANAGEMENT',
    readRole: RoleName.TENANT_MANAGEMENT_READ,
    items: [
      {
        routeName: 'tenants',
        icon: 'icon-tenants',
        label: 'COMMON_TENANTS',
      },
    ],
  },
];
