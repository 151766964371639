const UrlUtil = {
  extractQuery(path) {
    const query = {};

    if (path.indexOf('?') === -1) {
      return query;
    }

    const qs = path.split('?')[1];
    if (!qs.length) {
      return query;
    }

    qs.split('&').forEach((param) => {
      const [key, value] = param.split('=');
      if (key.includes('[]')) {
        const formattedKey = key.replace('[]', '');
        if (!query[formattedKey]) {
          query[formattedKey] = [];
        }
        query[formattedKey].push(value || '');
      } else {
        query[key] = value || '';
      }
    });

    return query;
  },
};

export default UrlUtil;
