import http from '@/lib/http';
import ModelDetailTemplate from '@/components/model-detail/index.vue';

export default {
  data() {
    return {
      isLoadingDetail: false,
    };
  },
  methods: {
    async showModelDetail(model) {
      this.isLoadingDetail = true;

      const item = this.modelDetailUrlFunction
        ? await http.get(this.modelDetailUrlFunction(model)).then(({ data }) => data)
        : model;

      this.isLoadingDetail = false;

      this.$modal.create(this.modelDetailTemplate || ModelDetailTemplate, {
        title: 'COMMON_DETAIL',
        item,
      });
    },
  },
};
