<template>
  <div class="subscriber-detail">
    <ui-detail
      ref="uiDetail"
      v-bind="detailDefinition"
      v-on="$listeners"
      @model="onModel"
    />
    <div class="ui-panel">
      <div class="ui-panel__header">
        <h2 class="ui-panel__header-text" v-text="$translate('SUBSCRIBER_AUTHORIZATION_METHODS')"></h2>
      </div>
      <ui-table v-bind="tableDefinition" class="ui-table--without-panel" />
    </div>
  </div>
</template>
<script>
import { UiFormTextInput, UiModalConfirm, UiTable } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import http from '@/lib/http';
import { AppEvent, RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as SubscriberFields from '@/modules/subscriber/view/subscriber/fields';
import * as Columns from '@/components/ui-table/columns';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import { UiMultiselectOptionWithNameHover, UiMultiselectTagWithNameHover } from '@/components/ui-multiselect';

export default {
  components: {
    UiDetail,
    UiTable,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      authorizationMethods: [],
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        modelUrl: Urls.SUBSCRIBERS_ID(this.id),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.textInputField('email', 'COMMON_EMAIL', false, true),
            Fields.checkboxField('tester', 'COMMON_TESTER'),
            Fields.checkboxField('crossBorderVerified', 'SUBSCRIBER_CROSS_BORDER_VERIFIED'),
            {
              field: 'pin',
              component: UiFormTextInput,
              componentOptions: {
                label: 'SUBSCRIBER_PIN',
                disabled: true,
                tooltip: Fields.commonTooltip('HELP_SUBSCRIBER_PIN_DESCRIPTION'),
              },
            },
            {
              field: 'groups',
              component: UiFormAutocompleteInline,
              componentOptions: {
                label: 'COMMON_GROUPS',
                dictType: 'SUBSCRIBER_GROUP',
                field: 'key',
                multiple: true,
                slots: {
                  tag: UiMultiselectTagWithNameHover,
                  option: UiMultiselectOptionWithNameHover,
                },
              },
            },
            Fields.textInputField('language', 'COMMON_LANGUAGE', false, true),
            { ...Fields.CREATED_AT, label: 'SUBSCRIBER_REGISTRATION_DATE' },
            { ...Fields.datePickerField('erasedAt', 'SUBSCRIBER_ERASED_DATE', false, true), isHidden: !model.erasedAt },
            SubscriberFields.LAST_LOGIN_DATE,
          ],
        ],
      };
    },
    tableDefinition() {
      return {
        columns: [
          {
            field: 'name',
            width: 160,
            headingLabel: 'COMMON_TYPE',
          },
          {
            field: 'description',
            width: 160,
            headingLabel: 'COMMON_NAME',
            dataMapper: ({ name, description }) => (name !== 'PASSWORD' ? description : ''),
          },
          {
            field: 'externalUid',
            minWidth: 200,
            headingLabel: 'COMMON_EXTERNAL_UID',
          },
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeApp),
        ],
        records: this.authorizationMethods,
      };
    },
  },
  methods: {
    onModel(model) {
      this.authorizationMethods = [...model.dictionaryAuths].sort((a, b) => a.name.localeCompare(b.name));
      this.$emit(AppEvent.MODEL, model);
    },
    removeApp({ description }) {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_REMOVE'),
        confirmationText: this.$translate('COMMON_REMOVE_CONFIRMATION'),
      });

      modal.$on(AppEvent.SUCCESS, async () => {
        await http.delete(Urls.SUBSCRIBERS_ID_AUTHS_APP(this.$route.params.id, description));
        this.authorizationMethods = this.authorizationMethods.filter((record) => record.description !== description);
        modal.close();
      });
    },
  },
};
</script>
