<template>
  <ui-detail
    ref="uiDetail"
    v-bind="detailDefinition"
    class="ui-detail--readonly-detail"
    v-on="$listeners"
  />
</template>
<script>
import Urls from '@/application/urls';
import { Page, RoleName } from '@/enums';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as PaymentFields from '@/modules/payment/view/fields';
import { RoleMixin } from '@/mixin';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        modelUrl: Urls.PAYMENTS_ID(this.id),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.EXTERNAL_UID,
            Fields.SUBSCRIBER(this, model, 'payments'),
            PaymentFields.PRODUCT(this, model, this.isAllowed),
            Fields.textInputWitLink(
              'subscription.id',
              'PAYMENT_SUBSCRIPTION',
              () => this.$router.push({
                name: 'subscriptions.detail',
                params: { id: model.subscription.id, page: Page.DETAIL },
              }),
              !model.subscription?.id,
            ),
            Fields.textInputWitLink(
              'promotion.name',
              'COMMON_PROMOTION',
              () => this.$router.push({
                name: 'promotions.detail',
                params: { id: model.promotion.id, page: Page.DETAIL },
              }),
              !model.promotion?.id,
            ),
            Fields.CREATED_AT,
            PaymentFields.PRICE,
            PaymentFields.CURRENCY,
            Fields.datePickerField('since', 'COMMON_SINCE'),
            Fields.datePickerField('till', 'COMMON_TILL'),
            PaymentFields.DURATION,
            Fields.datePickerField('cancelledAt', 'PAYMENT_CANCELLED_AT'),
            PaymentFields.CHANNEL,
            PaymentFields.STATUS,
            Fields.textInputField('errorCode', 'COMMON_ERROR'),
            Fields.textInputField('errorMessage', 'COMMON_ERROR_MESSAGE'),
            Fields.textInputField('system', 'PAYMENT_SYSTEM'),
            Fields.textInputField('platform.name', 'PLATFORM_NAME'),
            Fields.textInputField('ip', 'PAYMENT_IP'),
            Fields.textInputField('country', 'COMMON_COUNTRY'),
          ],
        ],
      };
    },
  },
};
</script>
