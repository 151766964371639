import http from '@/lib/http';
import errorHandler from '@/helper/error-handler';

export const downloadFile = ({ data, headers }) => {
  const fileNameWithQuotes = headers['content-disposition'].split(';')[1].split('=')[1];
  const fileName = fileNameWithQuotes.substring(1, fileNameWithQuotes.length - 1);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(data);

  link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
};

export default {
  created() {
    if (this.exportUrl && this.isAllowed) {
      this.buttons.push({
        icon: 'icon-download',
        tooltip: 'HELP_EXPORT',
        onClick: this.exportCSV,
      });
    }
  },
  methods: {
    exportCSV() {
      http
        .get(this.exportUrl, {
          params: { ...this.routeQueryWithoutFilterId, subscriberId: this.$route.params.id },
          responseType: 'blob',
        })
        .then(downloadFile)
        .catch(async (error) => {
          // Blob responseType causes error response data also to be returned as blob, we need to parse it to JSON for errorHandler
          const errorResponseData = JSON.parse((await error?.response?.data?.text()) || '{}');
          errorHandler(errorResponseData.errors?.[0] || {}, this);
        });
    },
  },
};
