<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { ModelDetailMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as AdministrationColumns from '@/modules/administration/view/columns';
import { QueryParam } from '@/enums';

export default ListView.extend({
  mixins: [ModelDetailMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exportUrl: Urls.OPERATORS_ID_AUDITS_EXPORT(this.id),
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          AdministrationColumns.AUDIT_OPERATOR,
          AdministrationColumns.AUDIT_RESOURCE_TYPE,
          AdministrationColumns.AUDIT_EVENT_TYPE,
          AdministrationColumns.AUDIT_RESOURCE_NAME,
          Columns.CREATED_AT,
          AdministrationColumns.ACTIONS_DETAIL(this.showModelDetail),
        ],
        records: this.records,
      };
    },
    recordsUrl() {
      return Urls.OPERATORS_ID_AUDITS(this.id);
    },
  },
});
</script>
