<template>
  <div class="ui-panel">
    <div class="ui-panel__header">
      <h2 class="ui-panel__header-text" v-text="title"></h2>
      <div v-if="allowed">
        <v-button-icon
          v-if="product.type !== ItemType.LIVE"
          icon="icon-smallscreen"
          tooltip="HELP_COPY_MEDIA"
          class="ui-panel__header-button"
          @click="copyMedium"
        />
        <v-button-icon
          v-if="findFirstCatchup(audios, videos)"
          icon="icon-cut"
          tooltip="HELP_EDIT_START_STOP"
          class="ui-panel__header-button"
          @click="editStartStop(audios, videos)"
        />
        <v-button-icon
          v-if="hasDrm"
          icon="icon-locked"
          tooltip="HELP_EDIT_DRM_KEYS"
          class="ui-panel__header-button"
          @click="editDrm"
        />
        <v-button-icon
          icon="icon-add"
          tooltip="HELP_ADD"
          class="ui-panel__header-button"
          @click="createModelAndEmitEvent"
        />
      </div>
    </div>

    <h3 v-if="presentVideoTitle" class="ui-panel__sub-header" v-text="$translate('COMMON_VIDEO')"></h3>
    <ui-table v-if="videos" v-bind="videosTableDefinition" class="ui-table--without-panel">
      <template #format="{ record }">
        <div class="ui-table-media-format">
          <span class="icon-play icon-play--source" @click="playTest(record)"></span>
          <span v-text="record.format"></span>
        </div>
      </template>
    </ui-table>

    <h3 v-if="presentAudioTitle" class="ui-panel__sub-header" v-text="$translate('AUDIO_TITLE')"></h3>
    <ui-table v-if="audios" v-bind="audiosTableDefinition" class="ui-table--without-panel">
      <template #format="{ record }">
        <div class="ui-table-media-format">
          <span class="icon-play icon-play--source" @click="playTest(record)"></span>
          <span v-text="record.format"></span>
        </div>
      </template>
    </ui-table>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import Urls from '@/application/urls';
import {
  AppEvent, Constants, FileFormat, ItemType, MediumType, VideoType,
} from '@/enums';
import http from '@/lib/http';
import catchupRange from '@/helper/catchup-range';
import { PlayMixin, RemoveModelMixin, SaveModelMixin } from '@/mixin';
import { UiTable, UiTableStartStop } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import VButtonIcon from '@/components/button/button-icon.vue';
import * as Fields from '@/components/ui-form/fields';
import * as ProductFields from '@/modules/product/view/fields';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';

const mapListPerFormatObjectToList = (object) => Object.entries(object).reduce(
  (acc, [format, values]) => [
    ...acc,
    ...values.map((value) => ({
      ...value,
      format,
    })),
  ],
  [],
);

export default {
  components: {
    VButtonIcon,
    UiTable,
  },
  mixins: [PlayMixin, RemoveModelMixin, SaveModelMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    presentAudioTitle: Boolean,
    audios: Object,
    presentVideoTitle: Boolean,
    videos: Object,
    hasDrm: Boolean,
    allowed: Boolean,
    product: Object,
    videoType: String,
  },
  data() {
    const url = this.product.type === ItemType.PROGRAMME
      ? Urls.PRODUCTS_LIVES_PROGRAMMES_ID_RECORDINGS_ID_MEDIA(this.product.id, this.product.programRecordingId)
      : Urls.ITEMS_ID_MEDIA(this.product.id);

    return {
      ItemType,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_MEDIUM' : 'ADD_MEDIUM'),
      saveModelFormElementsFunction: (model) => [
        [
          ProductFields.MEDIUM_TYPE(model, this.videoType),
          ProductFields.FILE_FORMAT([FileFormat.HLS, FileFormat.DASH, FileFormat.SS, FileFormat.MP4]),
          {
            ...Fields.URL_WITH_FILE_UPLOAD(model, 'url', 'COMMON_URL', model.format === FileFormat.MP4, this.$loader),
            'data-qa': 'vod-url',
          },
          { ...Fields.PLATFORMS_MANDATORY(model), isHidden: this.product.type === ItemType.PROGRAMME },
        ],
      ],
      saveModelDefaultValues: {
        platforms: [],
        type: MediumType.VIDEO,
        videoType: this.videoType,
        format: FileFormat.MP4,
      },
    };
  },
  computed: {
    videosAsList() {
      return mapListPerFormatObjectToList(this.videos);
    },
    audiosAsList() {
      return mapListPerFormatObjectToList(this.audios);
    },
    videosTableDefinition() {
      return {
        columns: [
          Columns.FILE_FORMAT,
          Columns.FILE_URL,
          {
            field: 'start-stop',
            width: 160,
            isHidden: !this.findFirstCatchup(this.videos),
            headingLabel: 'START/STOP',
            dataComponent: UiTableStartStop,
          },
          { ...Columns.PLATFORMS, isHidden: this.product.type === ItemType.PROGRAMME },
          Columns.ACTIONS_EDIT_REMOVE(this.allowed, this.editModelAndEmitEvent, this.removeModelAndEmitEvent),
        ],
        records: this.videosAsList,
      };
    },
    audiosTableDefinition() {
      return {
        columns: [
          Columns.FILE_FORMAT,
          Columns.FILE_URL,
          { ...Columns.PLATFORMS, isHidden: this.product.type === ItemType.PROGRAMME },
          Columns.ACTIONS_EDIT_REMOVE(this.allowed, this.editModelAndEmitEvent, this.removeModelAndEmitEvent),
        ],
        records: this.audiosAsList,
      };
    },
  },
  methods: {
    playTest(medium) {
      const id = this.videoType === VideoType.CATCHUP ? this.product.programRecordingId : this.product.id;
      this.playSource(id, medium.url, medium.format, this.videoType);
    },
    async createModelAndEmitEvent() {
      await this.createModel();
      this.$emit(AppEvent.CHANGE);
    },
    async editModelAndEmitEvent(model) {
      await this.editModel(model);
      this.$emit(AppEvent.CHANGE);
    },
    async removeModelAndEmitEvent(model) {
      await this.removeModel(model);
      this.$emit(AppEvent.CHANGE);
    },
    async copyMedium() {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: (model) => [
          [
            ProductFields.MEDIUM_TYPE(model, this.videoType),
            Fields.ITEM_TYPE(model, [ItemType.VOD, ItemType.EPISODE]),
            Fields.SELECTED_SERIES_PARENT(model, ![ItemType.SEASON, ItemType.EPISODE].includes(model.itemType)),
            Fields.SELECTED_SEASON_PARENT(model, model.itemType !== ItemType.EPISODE),
            Fields.SELECTED_PRODUCT_WITH_PARENT(model),
          ],
        ],
        modalTitleFunction: () => 'COPY_MEDIA',
        defaultValues: {
          itemType: ItemType.VOD,
        },
      });

      const getCopyMediumUrl = (model) => {
        if (this.videoType === VideoType.TRAILER) {
          return Urls.PRODUCTS_VODS_ID_MEDIA_VODS_ID_TRAILERS(this.product.id, model.productId);
        }

        if (this.product.type === ItemType.PROGRAMME) {
          return Urls.PRODUCTS_LIVES_PROGRAMMES_ID_MEDIA_VODS_ID(this.product.id, model.productId);
        }

        if (this.product.type === ItemType.PPV) {
          return Urls.PRODUCTS_PPVS_ID_MEDIA_VODS_ID(this.product.id, model.productId);
        }

        return Urls.PRODUCTS_VODS_ID_MEDIA_TYPE_VODS_ID(this.product.id, model.type, model.productId);
      };

      await this.openSaveModelModal(modal, (model) => http.put(getCopyMediumUrl(model)));
      this.$emit(AppEvent.CHANGE);
    },
    async editDrm() {
      const { drmKey, fairplayKey, fairplayIv } = this.product;
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: (model) => [
          [
            Fields.textHeaderWithIcon('DRM_TITLE', drmKey ? 'icon-trash' : '', 'HELP_DRM_DELETE_KEY', () => {
              model.drmKey = '';
              model.drmDelete = true;
            }),
            Fields.textInputField('drmKey', 'COMMON_KEY'),
            Fields.textHeaderWithIcon(
              'FAIRPLAY_TITLE',
              fairplayKey && fairplayIv ? 'icon-trash' : '',
              'HELP_DRM_DELETE_PAIR_OF_KEYS',
              () => {
                model.fairplayKey = '';
                model.fairplayIv = '';
                model.fairplayDelete = true;
              },
            ),
            Fields.textInputField('fairplayKey', 'COMMON_KEY'),
            Fields.textInputField('fairplayIv', 'FAIRPLAY_IV'),
          ],
        ],
        modalTitleFunction: () => 'EDIT_DRM_KEYS',
        defaultValues: { drmKey, fairplayKey, fairplayIv },
      });
      const saveModelPromiseFunction = (model) => {
        if (model.drmKey) {
          model.drmDelete = false;
        }

        if (model.fairplayKey || model.fairplayIv) {
          model.fairplayDelete = false;
        }

        const changedFields = Object.entries(model)
          .filter(([key, value]) => value !== this.product[key])
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        return http.put(Urls.ITEMS_ID_DRM_KEY(this.product.id), changedFields);
      };

      await this.openSaveModelModal(modal, saveModelPromiseFunction);
      this.$emit(AppEvent.CHANGE);
    },
    async editStartStop(audios, videos) {
      const catchupRangeValues = catchupRange(this.findFirstCatchup(audios, videos));

      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: () => [
          [
            { ...Fields.datePickerField('start', 'START', true), fieldForErrorCode: 'startTime' },
            { ...Fields.datePickerField('stop', 'STOP', true), fieldForErrorCode: 'stopTime' },
          ],
        ],
        modalTitleFunction: () => 'EDIT_START_STOP',
        defaultValues: {
          start: (catchupRangeValues?.start && this.$dateFormat(catchupRangeValues.start + Constants.CDN_EPOCH)) || '',
          stop: (catchupRangeValues?.stop && this.$dateFormat(catchupRangeValues.stop + Constants.CDN_EPOCH)) || '',
        },
      });

      const saveModelPromiseFunction = (model) => {
        const range = {
          startTime: model.start && dayjs(dayjs(model.start).valueOf() - Constants.CDN_EPOCH).valueOf(),
          stopTime: model.stop && dayjs(dayjs(model.stop).valueOf() - Constants.CDN_EPOCH).valueOf(),
        };

        const urls = {
          [ItemType.VOD]: Urls.PRODUCTS_VODS_ID_MEDIA_PERIOD(this.product.id),
          [ItemType.PROGRAMME]: Urls.PRODUCTS_LIVES_PROGRAMMES_ID_RECORDINGS_ID_MEDIA_PERIOD(
            this.product.id,
            this.product.programRecordingId,
          ),
          [ItemType.PPV]: Urls.PRODUCTS_PPVS_ID_MEDIA_PERIOD(this.product.id),
        };
        const url = urls[this.product.type];

        return http.put(url, range);
      };

      await this.openSaveModelModal(modal, saveModelPromiseFunction);
      this.$emit(AppEvent.CHANGE);
    },
    findFirstCatchup(audios, videos) {
      const videoArray = videos ? Object.values(videos) : [];
      const audioArray = audios ? Object.values(audios) : [];

      if (videoArray.length === 0 && audioArray.length === 0) {
        return null;
      }

      const flattenMedia = [...videoArray, ...audioArray].reduce((prev, curr) => [...prev, ...curr], []);
      return flattenMedia.find((medium) => catchupRange(medium));
    },
  },
};
</script>
