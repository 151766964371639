import * as views from '@/modules';

export default [
  {
    path: '/gui/product/vods',
    name: 'vods',
    component: views.ProductVods,
    meta: {
      titleLabels: ['MENU_PRODUCT_VODS'],
    },
  },
  {
    path: '/gui/product/vods/:id/:page',
    name: 'vods.detail',
    component: views.ProductVodsDetail,
    meta: {
      titleLabels: ['MENU_PRODUCT_VODS'],
    },
  },
  {
    path: '/gui/product/series',
    name: 'series',
    component: views.ProductSeries,
    meta: {
      titleLabels: ['MENU_PRODUCT_SERIES'],
    },
  },
  {
    path: '/gui/product/series/:id/:page',
    name: 'serial.detail',
    component: views.ProductSeriesDetail,
    meta: {
      titleLabels: ['MENU_PRODUCT_SERIES'],
    },
  },
  {
    path: '/gui/product/seasons',
    name: 'seasons',
    component: views.ProductSeasons,
    meta: {
      titleLabels: ['MENU_PRODUCT_SEASONS'],
    },
  },
  {
    path: '/gui/product/seasons/:id/:page',
    name: 'season.detail',
    component: views.ProductSeriesSeasonsDetail,
    meta: {
      titleLabels: ['MENU_PRODUCT_SEASONS'],
    },
  },
  {
    path: '/gui/product/episodes',
    name: 'episodes',
    component: views.ProductEpisodes,
    meta: {
      titleLabels: ['MENU_PRODUCT_EPISODES'],
    },
  },
  {
    path: '/gui/product/seasons/:id/:page',
    name: 'episode.detail',
    component: views.ProductSeriesEpisodesDetail,
    meta: {
      titleLabels: ['MENU_PRODUCT_EPISODES'],
    },
  },
  {
    path: '/gui/product/series/seasons/:id/:page',
    name: 'serial.season.detail',
    component: views.ProductSeriesSeasonsDetail,
    meta: {
      titleLabels: ['MENU_PRODUCT_SERIES', 'COMMON_SEASONS'],
    },
  },
  {
    path: '/gui/product/series/episodes/:id/:page',
    name: 'serial.episode.detail',
    component: views.ProductSeriesEpisodesDetail,
    meta: {
      titleLabels: ['MENU_PRODUCT_SERIES', 'COMMON_EPISODES'],
    },
  },
  {
    path: '/gui/product/lives',
    name: 'lives',
    component: views.ProductLives,
    meta: {
      titleLabels: ['MENU_PRODUCT_LIVES'],
    },
  },
  {
    path: '/gui/product/lives/:id/:page',
    name: 'lives.detail',
    component: views.ProductLivesDetail,
    meta: {
      titleLabels: ['MENU_PRODUCT_LIVES'],
    },
  },
  {
    path: '/gui/product/lives/programmes/:id/:page',
    name: 'lives.programme.detail',
    component: views.ProductLivesProgrammesDetail,
    meta: {
      titleLabels: ['MENU_PRODUCT_LIVES', 'TAB_PROGRAMMES'],
    },
  },
  {
    path: '/gui/product/ppvs',
    name: 'ppvs',
    component: views.Ppvs,
    meta: {
      titleLabels: ['MENU_PRODUCT_PPVS'],
    },
  },
  {
    path: '/gui/product/ppvs/:id/:page',
    name: 'ppvs.detail',
    component: views.PpvsDetail,
    meta: {
      titleLabels: ['MENU_PRODUCT_PPVS'],
    },
  },
  {
    path: '/gui/product/bundles',
    name: 'bundles',
    component: views.ProductBundles,
    meta: {
      titleLabels: ['COMMON_BUNDLES'],
    },
  },
  {
    path: '/gui/product/bundles/:id/:page',
    name: 'bundles.detail',
    component: views.ProductBundlesDetail,
    meta: {
      titleLabels: ['COMMON_BUNDLES'],
    },
  },
  {
    path: '/gui/product/specials',
    name: 'specials',
    component: views.ProductSpecials,
    meta: {
      titleLabels: ['MENU_PRODUCT_SPECIALS'],
    },
  },
  {
    path: '/gui/product/specials/:id/:page',
    name: 'specials.detail',
    component: views.ProductSpecialsDetail,
    meta: {
      titleLabels: ['MENU_PRODUCT_SPECIALS'],
    },
  },
  {
    path: '/gui/product/audits',
    name: 'productaudit',
    component: views.ProductAudits,
    meta: {
      titleLabels: ['COMMON_EVENTS'],
    },
  },
];
