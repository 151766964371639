<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition">
    <template #renewal="{ record: { nextChargeAt, lastFailAttemptAt } }">
      <div v-if="nextChargeAt" v-text="`${$translate('COMMON_NEXT_NEUTER')}: ${$dateFormat(nextChargeAt)}`"></div>
      <div v-if="lastFailAttemptAt" v-text="`${$translate('COMMON_FAILED')}: ${$dateFormat(lastFailAttemptAt)}`"></div>
    </template>
  </v-list-wrapper>
</template>
<script>
import { NumberDivider } from 'redge-media-web-utils';
import Urls from '@/application/urls';
import { Page, RoleName } from '@/enums';
import ListView from '@/components/list-view/list-view.vue';
import * as PaymentFilters from '@/modules/payment/view/filters';
import * as Filters from '@/components/filter/filters';
import { UiTableDetailLink } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import * as PaymentColumns from '@/modules/payment/view/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';

export default ListView.extend({
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIPTIONS,
      exportUrl: Urls.SUBSCRIPTIONS_EXPORT,
      paramsConfig: {},
      filters: [
        Filters.SINCE_MANDATORY,
        Filters.TILL_MANDATORY,
        PaymentFilters.CURRENT_ACTIVE,
        Filters.SUBSCRIBER,
        PaymentFilters.PRODUCTS,
        PaymentFilters.SUBSCRIPTION_STATUS,
        PaymentFilters.PAYMENT_CHANNEL,
        PaymentFilters.PRICE_GREATER,
        PaymentFilters.PRICE_LESS,
        PaymentFilters.PAYMENT_CURRENCY,
      ],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'id',
            width: 75,
            headingLabel: 'PAYMENT_ID',
            dataComponent: UiTableDetailLink,
            dataComponentPropsMapper: ({ id }) => ({ id, text: `${id}`, name: 'subscriptions.detail' }),
          },
          {
            ...Columns.SUBSCRIBER_DETAIL(({ subscriber }) => ({ subscriber, page: Page.SUBSCRIPTIONS })),
            isHidden: this.isSubscriberView,
          },
          PaymentColumns.PAYMENT_PRODUCT,
          PaymentColumns.PAYMENT_PRICE,
          PaymentColumns.PAYMENT_CURRENCY,
          {
            field: 'duration',
            width: 70,
            headingLabel: 'COMMON_TIME',
            dataMapper: ({ duration }) => `${duration / NumberDivider.HOUR_TO_SECONDS}h`,
          },
          PaymentColumns.PAYMENT_TYPE,
          {
            field: 'renewal',
            width: 220,
            headingLabel: 'COMMON_RENEWAL',
          },
          {
            field: 'cancelReason',
            width: 180,
            headingLabel: 'SUBSCRIPTION_CANCEL_REASON',
          },
          Columns.CREATED_AT,
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
