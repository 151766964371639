<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import AuditList from '@/modules/administration/view/audit/index.vue';

export default AuditList.extend({
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.AUDITS_GROUP_NAME('SUBSCRIBER_GROUP'),
      exportUrl: Urls.AUDITS_GROUP_NAME_EXPORT('SUBSCRIBER_GROUP'),
    };
  },
});
</script>
