<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import { UiFormTextInput } from 'redge-media-web-ui';
import { AppEvent, RoleName } from '@/enums';
import http from '@/lib/http';
import { RemoveModelMixin, RoleMixin, SaveModelMixin } from '@/mixin';
import operator from '@/application/operator';
import Urls from '@/application/urls';
import ListView from '@/components/list-view/list-view.vue';
import * as Fields from '@/components/ui-form/fields';
import { commonTooltip } from '@/components/ui-form/fields';
import UiFormDictionarySelect from '@/components/ui-form/ui-form-dictionary-select.vue';
import * as Columns from '@/components/ui-table/columns';
import { UiTableDetailLink } from '@/components/ui-table';
import * as ContentFields from '@/modules/content/view/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, RoleMixin, SaveModelMixin],
  data() {
    const recordsUrl = Urls.TENANTS;
    return {
      tenantConfig: undefined,
      writeRole: RoleName.TENANT_MANAGEMENT_ADMIN,
      recordsUrl,
      recordIdentifier: 'name',
      removeModelUrlFunction: ({ uid }) => Urls.TENANTS_ID(uid),
      rowClassModifierFunction: () => '',
      saveModelUrlFunction: () => recordsUrl,
      saveModelModalTitleFunction: () => 'COMMON_ADD_TENANT',
      saveModelFormElementsFunction: () => [this.getTenantCopyCustomForm()],
      areSaveModelDefaultValuesMapped: true,
      saveModelDefaultValuesObjectKey: 'configTypeToValue',
    };
  },
  computed: {
    saveModelDefaultValues() {
      return Object.fromEntries(new Map(this.tenantConfig.map((obj) => [obj, true])));
    },
    tableDefinition() {
      return {
        columns: [
          {
            field: 'slug',
            width: 180,
            isSortable: true,
            headingLabel: 'COMMON_SLUG',
            dataComponent: UiTableDetailLink,
            dataComponentPropsMapper: ({ uid, slug }) => ({ id: uid, text: `${slug}`, name: 'tenants.detail' }),
          },
          Columns.NAME,
          {
            field: 'uid',
            width: 100,
            headingLabel: 'COMMON_UID',
          },
          Columns.ACTIONS_REMOVE(this.hasRole(RoleName.TENANT_MANAGEMENT_ADMIN), this.removeModel),
        ],
        records: this.records,
      };
    },
  },
  watch: {
    'listData.elements': {
      deep: true,
      handler(elements) {
        const tenantsNames = operator.tenants.map(({ name }) => name);
        elements.forEach((element) => {
          if (!tenantsNames.includes(element.name)) {
            operator.tenants.push(element);
          }
        });
      },
    },
  },
  async created() {
    await this.loadTenantConfigToCopy();
    this.$emit(AppEvent.MODEL, { tenant: this.id });
  },
  methods: {
    getTenantCopyCustomForm() {
      const config = this.tenantConfig.map((tenantConfigType) => Fields.checkboxField(tenantConfigType, `COMMON_${tenantConfigType}`));
      return [
        ContentFields.SLUG,
        Fields.NAME,
        {
          field: 'uid',
          component: UiFormTextInput,
          componentOptions: {
            label: 'COMMON_UID',
            tooltip: commonTooltip('HELP_TENANT_UID'),
          },
        },
        {
          field: 'cloneUid',
          component: UiFormDictionarySelect,
          isMandatory: true,
          componentOptions: {
            label: 'COMMON_TENANT_CLONE',
            field: 'uid',
            getLabel: ({ name }) => name,
            dictUrl: Urls.TENANTS_CLONES,
          },
        },
        ...config,
      ];
    },
    async loadTenantConfigToCopy() {
      this.tenantConfig = await http.get(Urls.TENANTS_CONFIG).then(({ data }) => data);
    },
  },
});
</script>
