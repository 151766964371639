<template>
  <div class="flex-column">
    <ui-tabs :current-route="$route" :tabs="tabs" />
    <v-list-wrapper :table-definition="tableDefinition" />
  </div>
</template>
<script>
import Urls from '@/application/urls';
import { Page, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView, { DEFAULT_PAGINATION_QUERY } from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import UiTabs from '@/components/ui-tabs/index.vue';
import * as Fields from '@/components/ui-form/fields';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  components: {
    UiTabs,
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_PHRASE' : 'ADD_PHRASE'),
      paramsConfig: {
        sort: QueryParam.KEY,
        order: QueryParam.ASCENDING,
      },
    };
  },
  computed: {
    recordsUrl() {
      return Urls.DICTIONARIES_TYPE(this.$route.params.page.toUpperCase());
    },
    removeModelUrlFunction() {
      return ({ id }) => `${this.recordsUrl}/${id}`;
    },
    saveModelUrlFunction() {
      return ({ id }) => (id ? `${this.recordsUrl}/${id}` : this.recordsUrl);
    },
    tableDefinition() {
      return {
        columns: [
          {
            field: 'key',
            minWidth: 200,
            isSortable: true,
            headingLabel: 'COMMON_KEY',
            isHidden: [Page.PERSON, Page.SECTION_LAYOUT].includes(this.$route.params.page),
          },
          Columns.NAME,
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModel, this.removeModel),
        ],
        records: this.records,
      };
    },
    tabs() {
      const tabs = [
        { label: 'COMMON_COUNTRIES', page: Page.COUNTRY },
        { label: 'COMMON_GENRES', page: Page.GENRE },
        { label: 'ITEM_TAGS', page: Page.TAG },
        { label: 'COMMON_PROVIDERS', page: Page.PROVIDER },
        { label: 'COMMON_PRODUCTIONS', page: Page.PRODUCTION },
        { label: 'COMMON_PERSONS', page: Page.PERSON },
        { label: 'COMMON_BADGES', page: Page.CUSTOM_BADGE },
        { label: 'COMMON_SECTION_LAYOUT', page: Page.SECTION_LAYOUT },
      ];

      return tabs.map((tab) => ({ ...tab, query: { ...this.$route.query, ...DEFAULT_PAGINATION_QUERY } }));
    },
    saveModelFormElementsFunction() {
      const { page } = this.$route.params;

      const isGenreTab = page === Page.GENRE;
      const isPersonTab = page === Page.PERSON;
      const isSectionLayoutTab = page === Page.SECTION_LAYOUT;

      return () => [
        [
          { ...Fields.textInputField('key', 'COMMON_KEY', true), isHidden: isPersonTab || isSectionLayoutTab },
          { ...Fields.EXTERNAL_UID, isHidden: !isGenreTab },
          Fields.NAME,
          { ...Fields.HEADER_DESCRIPTION, isHidden: !isGenreTab },
          { ...Fields.DESCRIPTION, isHidden: !isGenreTab },
        ],
      ];
    },
  },
});
</script>
