<template>
  <header class="app-header" :class="`app-header--env-${environment}`" @keydown.esc="close">
    <h1 class="app-header__logo">
      <router-link class="app-header__logo-link" :to="{ name: 'home' }">
        <img class="app-header__logo-image" src="/static/img/logo.svg" alt="Redge Media Portal">
      </router-link>
      <span v-if="environment !== 'prod'" :data-env="environment" class="app-header__logo-badge"></span>
    </h1>
    <div class="app-header__element">
      <span class="app-header__element-label" v-text="$translate('COMMON_TENANT').toUpperCase()"></span>
      <select
        v-if="operator.tenants.length && operator.selectedTenant"
        :value="operator.selectedTenant.uid"
        class="app-header__tenants ui-select"
        @change="changeTenant"
      >
        <option
          v-for="{ name, uid } in operator.tenants"
          :key="uid"
          :value="uid"
          v-text="name"
        ></option>
      </select>
    </div>
    <div class="app-header__element">
      <span class="app-header__element-label" v-text="$translate('HELP_CONTENT_LANGUAGE').toUpperCase()"></span>
      <button
        v-for="language in i18n.contentLanguages"
        :key="language"
        :class="{ 'app-header__language--active': i18n.contentLanguage === language }"
        class="app-header__language"
        @click="i18n.setContentLanguage(language)"
        v-text="language.toUpperCase()"
      ></button>
    </div>
    <div
      v-if="applications && applications.length"
      v-click-outside="closeApplicationsModal"
      class="app-header__applications"
    >
      <button class="app-header__applications-button" @click="toggleApplicationsModal">
        <i class="app-header__applications-icon icon-apps"></i>
      </button>
      <div v-if="isApplicationsModalVisible" class="app-header__modal">
        <div class="app-header__modal-content app-header__modal-content--applications">
          <a
            v-for="{ appId, baseUrl, label, tenant } in applications"
            :key="appId"
            :href="`${baseUrl}?tenant=${tenant}`"
            class="app-header__application"
            target="_blank"
          >
            <img :src="`/static/img/icon/application/${appId}.png`" :alt="appId" class="app-header__application-logo">
            {{ label }}
          </a>
        </div>
      </div>
    </div>
    <div v-click-outside="close" class="app-header__operator">
      <button class="app-header__operator-button" data-qa="operator-button" @click="toggleOperatorModal">
        <span v-if="operator.isLogged" class="app-header__operator-info" v-text="operatorFullName"></span>
        <span class="app-header__operator-icon icon-operator"></span>
      </button>
      <div v-if="operatorModal" class="app-header__modal">
        <div class="app-header__modal-content app-header__modal-content--operator">
          <div class="app-header__operator-modal-row">
            <span v-tooltip="$translate('HELP_PAGE_LANGUAGE')" class="icon-translations app-header__modal-icon"></span>
            <button
              v-for="language in i18n.languages"
              :key="language"
              :class="{ 'app-header__language--active': i18n.language === language }"
              class="app-header__language"
              @click="i18n.setLanguage(language)"
              v-text="language.toUpperCase()"
            ></button>
          </div>
          <div class="app-header__modal-row">
            <span v-tooltip="$translate('HELP_EMAIL')" class="icon-mail app-header__modal-icon"></span>
            <span v-text="operator.email"></span>
          </div>
          <div class="app-header__modal-row">
            <span v-tooltip="$translate('HELP_LAST_LOGIN_DATE')" class="icon-login app-header__modal-icon"></span>
            <span v-text="$dateFormat(operator.lastLoggedAt)"></span>
          </div>
          <div class="app-header__modal-row">
            <span v-tooltip="$translate('HELP_ROLES')" class="icon-user app-header__modal-icon"></span>
            <span v-text="operatorRoles"></span>
          </div>
          <div class="app-header__modal-row">
            <span v-tooltip="$translate('HELP_OPERATOR_PASSWORD')" class="icon-unlocked app-header__modal-icon"></span>
            <button
              class="button"
              @click="openPasswordChangeModal"
              v-text="$translate('OPERATOR_CHANGE_PASSWORD')"
            ></button>
            <button
              v-if="!operator.emptyPassword"
              class="button"
              @click="removePassword"
              v-text="$translate('OPERATOR_DELETE_PASSWORD')"
            ></button>
          </div>
        </div>
        <div v-if="operator.isLogged" class="app-header__modal-actions">
          <button
            class="button"
            data-qa="logout-button"
            @click="logout"
            v-text="$translate('OPERATOR_LOGOUT')"
          ></button>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import {
  UiFormTextInput, UiModalConfirm, UiModalPopup, clickOutside,
} from 'redge-media-web-ui';
import i18n from '@/application/i18n';
import info from '@/application/info';
import Urls from '@/application/urls';
import operator from '@/application/operator';
import http from '@/lib/http';
import { AppEvent, Constants } from '@/enums';
import { SaveModelMixin } from '@/mixin';
import UiModalSaveModel from '@/components/modal/ui-modal-save-model.vue';

export default {
  directives: {
    clickOutside,
  },
  mixins: [SaveModelMixin],
  data() {
    return {
      i18n,
      operatorModal: false,
      isApplicationsModalVisible: false,
      environment: process.env.VUE_APP_ENV,
      operator,
    };
  },
  computed: {
    operatorRoles() {
      return this.operator.roles.map((role) => role.name).join(', ');
    },
    operatorFullName() {
      return `${this.operator.firstName || ''} ${this.operator.lastName || ''}`.trim() || this.operator.email;
    },
    applications() {
      return info.applications;
    },
  },
  methods: {
    async changeTenant({ target: { value } }) {
      localStorage.setItem(Constants.SELECTED_TENANT_UID, value);
      await operator.fetch().catch(() => {});
      await i18n.fetch().catch(() => {});
      await info[operator.isLogged ? 'fetchInfo' : 'fetchAuthInfo']();

      // This redirect is required for cases when user is in asset detail.
      // In those cases staying on the same page and reloading content could cause 404 error if asset with the same id wouldn't exist in newly selected tenant.
      this.$router.push({ name: 'home' }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
    },
    close() {
      this.operatorModal = false;
    },
    closeApplicationsModal() {
      this.isApplicationsModalVisible = false;
    },
    toggleApplicationsModal() {
      this.isApplicationsModalVisible = !this.isApplicationsModalVisible;
    },
    toggleOperatorModal() {
      this.operatorModal = !this.operatorModal;
    },
    async logout() {
      await operator.logout();
      await info.fetchAuthInfo();

      this.operatorModal = false;
      this.$router.push({ name: 'login' });
    },
    async removePassword() {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_REMOVE'),
        confirmationText: this.$translate('OPERATOR_REMOVE_PASSWORD'),
      });

      modal.$on(AppEvent.SUCCESS, async () => {
        await http.delete(Urls.OPERATORS_ID_PASSWORD(this.operator.id));
        this.operator.password = '';
        this.operator.emptyPassword = true;
        modal.close();
        this.$modal.create(UiModalPopup, {
          text: this.$translate('OPERATOR_PASSWORD_REMOVE_CONFIRMATION'),
        });
      });
    },
    openPasswordChangeModal() {
      const modal = this.$modal.create(UiModalSaveModel, {
        formElementsFunction: () => [
          [
            {
              field: 'oldPassword',
              component: UiFormTextInput,
              componentOptions: {
                label: 'OPERATOR_OLD_PASSWORD',
                type: 'password',
                autocomplete: 'old-password',
              },
            },
            {
              field: 'newPassword',
              component: UiFormTextInput,
              componentOptions: {
                label: 'OPERATOR_NEW_PASSWORD',
                type: 'password',
                autocomplete: 'new-password',
              },
            },
          ],
        ],
        modalTitleFunction: () => 'OPERATOR_CHANGE_PASSWORD_HEADER',
      });
      const saveModelPromiseFunction = (model) => http.put(Urls.OPERATORS_PASSWORD, model);

      this.openSaveModelModal(modal, saveModelPromiseFunction);
    },
  },
};
</script>
