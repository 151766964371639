<template>
  <div class="ui-list-nav">
    <v-search v-if="search" :keyword="keyword" @change="keywordChanged" />

    <slot name="custom-buttons"></slot>

    <ui-active-filters
      :active-filters="activeFilters"
      :filters="filters"
      class="ui-list-nav__active-filters"
      @change="changeActiveFilter"
    />

    <div class="ui-list-nav__wrapper-right">
      <ui-paginator
        v-if="Object.entries(collectionMeta).length > 0"
        v-bind="collectionMeta"
        @change="firstResultChanged"
      />

      <div class="ui-list-nav__buttons-wrapper">
        <v-button-icon
          v-for="(button, index) in buttons"
          :key="index"
          :icon="button.icon"
          :tooltip="button.tooltip"
          class="ui-list-nav__button"
          @click="button.onClick"
        />

        <ui-available-filters
          v-if="availableFiltersSettings.length"
          :value="selectedFilterSetting"
          :active-filters="activeFilters"
          :available-filters-settings="availableFiltersSettings"
          @change="filterSettingChanged"
        />

        <v-button-icon
          v-if="filters.length > 0"
          icon="icon-sidebar-open"
          :tooltip="modifiedNotSavedFilters.length > 0 ? 'HELP_NOT_ALL_FILTERS_WERE_APPLIED' : 'HELP_FILTER_LABEL'"
          :class="{ 'ui-list-nav__button--modified': modifiedNotSavedFilters.length > 0 }"
          class="ui-list-nav__button"
          @click.stop="setFilterMenuActive(!filterMenuActive)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { UiPaginator } from 'redge-media-web-ui';
import { getCurrentFilterSetting, getEmptyNotRequiredActiveFilters } from '@/lib/settings-filters';
import VSearch from '@/components/search/search.vue';
import VButtonIcon from '@/components/button/button-icon.vue';
import UiActiveFilters from '@/components/list-view/ui-active-filters.vue';
import UiAvailableFilters from '@/components/list-view/ui-available-filters.vue';
import { storeGetters, storeMutations } from '@/components/filter/store';

export default {
  name: 'list-nav',
  components: {
    VSearch,
    UiActiveFilters,
    UiAvailableFilters,
    UiPaginator,
    VButtonIcon,
  },
  props: {
    collectionMeta: {
      type: Object,
      required: true,
    },
    filterId: {
      type: Number,
      required: true,
    },
    availableFiltersSettings: {
      type: Array,
      required: true,
    },
    activeFilters: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Array,
      default: () => [],
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    search: {
      type: Boolean,
      default: true,
    },
    keyword: {
      type: String,
    },
  },
  computed: {
    ...storeGetters,
    selectedFilterSetting() {
      return getCurrentFilterSetting(this.filterId, this.availableFiltersSettings);
    },
  },
  methods: {
    ...storeMutations,
    updateQueryParams(queryParams) {
      this.$emit('update-query-params', queryParams);
    },
    filterSettingChanged({ id, filters }) {
      this.updateQueryParams({
        filterId: id,
        ...getEmptyNotRequiredActiveFilters(this.activeFilters, this.filters),
        ...filters,
      });
    },
    keywordChanged(keyword) {
      this.updateQueryParams({ keyword });
    },
    firstResultChanged(firstResult) {
      this.updateQueryParams({ firstResult });
    },
    changeActiveFilter(field, values) {
      this.updateQueryParams({ [field]: values });
    },
  },
};
</script>
