<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { ItemType, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Filters from '@/components/filter/filters';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';
import * as ProductFilters from '@/modules/product/view/filters';

export default ListView.extend({
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_SPECIAL,
      removeModelUrlFunction: ({ id }) => Urls.PRODUCTS_SPECIAL_ID(id),
      saveModelUrlFunction: () => Urls.PRODUCTS_SPECIAL,
      saveModelModalTitleFunction: () => 'ADD_SPECIAL',
      saveModelFormElementsFunction: () => [
        [Fields.TITLE, Fields.TYPE(Urls.PRODUCTS_SPECIAL_TYPES), Fields.CREATE_SINCE(), Fields.CREATE_TILL()],
      ],
      saveModelDefaultValues: {
        type: ItemType.CATCH_UP,
        type_: 'PRODUCT_SPECIAL',
      },
      exportUrl: Urls.PRODUCTS_SPECIAL_EXPORT,
      filters: [
        Filters.PLATFORMS,
        Filters.ACTIVE,
        ProductFilters.PRODUCT_SPECIAL_TYPES,
        ProductFilters.PRODUCT_SPECIAL_GLOBAL,
        ProductFilters.HAS_DESCRIPTION,
        ProductFilters.CURRENCIES,
      ],
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.PRODUCT_DETAIL((record) => ({ product: record }), 'COMMON_TITLE'),
          Columns.PLATFORMS,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.TYPE,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
