<template>
  <div class="ui-filter-item">
    <ui-form-autocomplete-inline
      v-bind="uiAutocompleteInlineProps"
      :close-on-select="false"
      :is-option-disabled="(option) => values.includes(option[field])"
      @input="select"
    />
    <ul class="ui-filter-item__selected-values">
      <ui-filter-item-selected-value
        v-for="value in values"
        :key="value"
        :value="value"
        @remove="remove"
      />
    </ul>
  </div>
</template>
<script>
import { AppEvent } from '@/enums';
import { filterObjectEntries } from '@/helper';
import { DictionaryFetchMixin } from '@/mixin';
import UiFormAutocompleteInline from '@/components/ui-form/ui-form-autocomplete-inline.vue';
import UiFilterItemSelectedValue from './ui-filter-item-selected-value.vue';

export default {
  components: {
    UiFormAutocompleteInline,
    UiFilterItemSelectedValue,
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    ...UiFormAutocompleteInline.props,
    ...DictionaryFetchMixin.props,
  },
  computed: {
    uiAutocompleteInlineProps() {
      return filterObjectEntries(this.$props, [
        ...Object.keys(UiFormAutocompleteInline.props),
        ...Object.keys(DictionaryFetchMixin.props),
      ]);
    },
  },
  methods: {
    select(value) {
      const stringValue = value[this.field];
      if (!this.values.includes(stringValue)) {
        this.$emit(AppEvent.CHANGE, [...this.values, stringValue]);
      }
    },
    remove(value) {
      this.$emit(
        AppEvent.CHANGE,
        this.values.filter((v) => v !== value),
      );
    },
  },
};
</script>
