<template>
  <payment-schedule-list v-bind="paymentScheduleListProps" v-on="$listeners" />
</template>
<script>
import PaymentScheduleList from '@/components/payment-schedule-list/index.vue';

export default {
  components: {
    PaymentScheduleList,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    paymentScheduleListProps() {
      return {
        id: this.id,
      };
    },
  },
};
</script>
