<template>
  <div v-if="operator.deleted" class="text-truncated line-through" v-text="operator.email"></div>
  <ui-table-detail-link
    v-else
    :id="operator.id"
    :text="operator.email"
    :page="page"
    name="operator.detail"
  />
</template>
<script>
import { UiTableDetailLink } from '@/components/ui-table/index';

export default {
  name: 'ui-table-detail-link-resource-operator',
  components: {
    UiTableDetailLink,
  },
  props: {
    operator: {
      type: Object,
      required: true,
    },
    page: String,
  },
};
</script>
