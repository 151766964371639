<template>
  <span :style="colorStyle" class="text-truncated" v-text="status"></span>
</template>
<script>
import { PaymentStatus } from '@/enums';

export default {
  name: 'ui-table-payment-status',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    colorStyle() {
      switch (this.status) {
        case PaymentStatus.CONFIRMED:
        case PaymentStatus.ACTIVE:
          return { color: '#268528' };
        case PaymentStatus.REJECTED:
        case PaymentStatus.BLOCKED:
          return { color: '#ea2a00' };
        case PaymentStatus.WAITING:
          return { color: '#eaa100' };
        default:
          return {};
      }
    },
  },
};
</script>
