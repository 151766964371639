<template>
  <div class="details-container header-container">
    <ui-tabs :current-route="$route" :tabs="tabs" />
    <component :is="page" class="content" :page="page" />
  </div>
</template>
<script>
import UiTabs from '@/components/ui-tabs/index.vue';
import { Page } from '@/enums';
import Servers from './servers.vue';
import Tags from './tags.vue';
import Audits from './audits.vue';

export default {
  components: {
    UiTabs,
    Audits,
    Servers,
    Tags,
  },
  data() {
    return {
      tabs: [
        { label: 'MENU_ADMINISTRATION_AD_SERVERS', page: Page.SERVERS },
        { label: 'AD_TAGS', page: Page.TAGS },
        { label: 'COMMON_EVENTS', page: Page.AUDITS },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page;
    },
  },
};
</script>
