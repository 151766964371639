<template>
  <ui-detail v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import { UiFormTextInput } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.TENANT_MANAGEMENT_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        modelUrl: Urls.TENANTS_ID(this.id),
        formElementsFunction: () => [
          [
            {
              field: 'slug',
              isMandatory: true,
              component: UiFormTextInput,
              componentOptions: {
                label: 'COMMON_SLUG',
              },
            },
            Fields.NAME,
            {
              field: 'uid',
              component: UiFormTextInput,
              componentOptions: {
                label: 'COMMON_UID',
                disabled: true,
              },
            },
          ],
        ],
      };
    },
  },
};
</script>
