<template>
  <div class="ui-form-input">
    <label
      v-tooltip="tooltip"
      :for="attrs.id"
      class="ui-form-input__label"
      v-text="`${$translate(label)}:`"
    ></label>
    <div class="ui-form-input__input-wrapper">
      <input
        v-bind="attrs"
        type="file"
        class="ui-input ui-form-input__input"
        v-on="listeners"
      >
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    tooltip: [String, Object],
    label: String,
  },
  computed: {
    attrs() {
      const {
        id, type, value, ...attrs
      } = this.$attrs;

      return {
        ...attrs,
        id: id || `input-${this._uid}`,
      };
    },
    listeners() {
      const { input, ...listeners } = this.$listeners;

      return {
        ...listeners,
      };
    },
  },
};
</script>
