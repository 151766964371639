<template>
  <ui-detail ref="uiDetail" v-bind="detailDefinition" v-on="$listeners" />
</template>
<script>
import { UiFormCheckbox } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { ItemType, RoleName } from '@/enums';
import { RoleMixin } from '@/mixin';
import { UiDetail } from '@/components/ui-detail';
import * as Fields from '@/components/ui-form/fields';
import * as ProductFields from '@/modules/product/view/fields';

export default {
  components: {
    UiDetail,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
    };
  },
  computed: {
    detailDefinition() {
      return {
        isAllowed: this.isAllowed,
        isSaveButtonSticky: true,
        modelUrl: Urls.PRODUCTS_VODS_SERIALS_ID_PAGE(this.id, this.page),
        formElementsFunction: (model) => [
          [
            Fields.HEADER_METADATA,
            Fields.TITLE,
            ProductFields.ORIGINAL_TITLE,
            Fields.EXTERNAL_UID,
            Fields.LOGO,
            ProductFields.YEAR,
            ProductFields.HD,
            ProductFields.UHD,
            ProductFields.SHOW_RECOMMENDATIONS,
            Fields.CREATE_SINCE(),
            Fields.CREATE_TILL(),
            ProductFields.ADVISORS,
            ProductFields.RATING,
            ProductFields.RATING_EMBEDDED(model),
            ProductFields.AUTOPLAY,
            {
              field: 'showSeasonNumber',
              component: UiFormCheckbox,
              componentOptions: {
                label: 'SERIES_SHOW_SEASON_NUMBER',
                tooltip: Fields.commonTooltip(
                  `${this.$translate('HELP_SERIES_SHOW_SEASON_NUMBER_PART_ONE')} ${this.$translate(
                    'HELP_SERIES_SHOW_SEASON_NUMBER_PART_TWO',
                  )}`,
                ),
                'data-qa': 'vod-show-season-number',
              },
            },
            ProductFields.SERIES_SHOW_EPISODE_NUMBER,
            ProductFields.SORT_TYPE,
            Fields.PROVIDER,
            ProductFields.PRODUCTION,
            Fields.MAIN_CATEGORY(ItemType.VOD),
            ProductFields.CATEGORIES(ItemType.VOD),
            ProductFields.GENRES,
            ProductFields.TAGS,
            ProductFields.COUNTRIES,
            Fields.CUSTOM_BADGE,
            Fields.PLATFORMS,
            ProductFields.CHROMECAST_PLATFORMS,
            Fields.CREATED_AT,
            Fields.MODIFIED_AT,
          ],
          [
            ...ProductFields.GEOLOCK_FIELDS(model),
            Fields.HEADER_LEAD,
            Fields.LEAD,
            Fields.HEADER_DESCRIPTION,
            Fields.DESCRIPTION,
          ],
        ],
      };
    },
  },
};
</script>
