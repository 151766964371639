export default {
  AD_BREAKS: 'ad-breaks',
  AGREEMENTS: 'agreements',
  AUDITS: 'audits',
  BLACK: 'black',
  BOOKMARK_FAVOURITE: 'bookmark-favourite',
  BOOKMARK_WATCHED: 'bookmark-watched',
  BUNDLES: 'bundles',
  CARDS: 'cards',
  CATEGORIES: 'categories',
  CODES_MULTIPLE_USE: 'codes-multiple-use',
  CODES_SINGLE_USE: 'codes-single-use',
  COUNTRY: 'country',
  CRITERIA: 'criteria',
  CUSTOM_BADGE: 'custom_badge',
  DETAIL: 'detail',
  DEVICES: 'devices',
  DISCOUNTS: 'discounts',
  DISPLAY_SCHEDULES: 'display-schedules',
  DOWNLOADS: 'downloads',
  EPISODES: 'episodes',
  FILES: 'files',
  GENRE: 'genre',
  HTTP_SESSIONS: 'http-sessions',
  PAYMENT_SCHEDULES: 'payment-schedules',
  PAYMENTS: 'payments',
  PERSON: 'person',
  PERSONS: 'persons',
  PRODUCTION: 'production',
  PRODUCTS: 'products',
  PROFILES: 'profiles',
  PROGRAMMES: 'programmes',
  PROVIDER: 'provider',
  RATING: 'rating',
  RECORDINGS: 'recordings',
  SEASONS: 'seasons',
  SECTION_LAYOUT: 'section_layout',
  SECTIONS: 'sections',
  SERVERS: 'servers',
  STATES: 'states',
  SUBSCRIPTIONS: 'subscriptions',
  SUSPENSION_WINDOWS: 'suspension-windows',
  TAG: 'tag',
  TAGS: 'tags',
  THREADS: 'threads',
  TILE_ACTIVITIES: 'tile-activities',
  TIMERS: 'timers',
  VERSIONS: 'versions',
  VIDEO_SESSIONS: 'video-sessions',
  WALLET: 'wallet',
  WHITE: 'white',
};
