<template>
  <div v-if="subscriber.erasedAt" class="text-truncated line-through" v-text="subscriber.email"></div>
  <ui-table-detail-link
    v-else-if="subscriber.id"
    :id="subscriber.id"
    :page="page"
    :text="subscriber.email"
    name="subscriber.detail"
  >
    <span v-if="subscriber.tester">
      <img src="/static/img/icon/subscriber/tester.png">
    </span>
    {{ subscriber.email }}
  </ui-table-detail-link>
</template>
<script>
import { UiTableDetailLink } from '@/components/ui-table';

export default {
  name: 'ui-table-detail-link-subscriber',
  components: {
    UiTableDetailLink,
  },
  props: {
    subscriber: {
      type: Object,
      default: () => ({}),
    },
    page: {
      type: String,
      default: 'detail',
    },
  },
};
</script>
