import { UiModalConfirm, UiModalPopup } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { AppEvent, ItemType } from '@/enums';
import http from '@/lib/http';
import UiModalActivationErrors from '@/components/modal/ui-modal-activation-errors.vue';
import errorHandler from '@/helper/error-handler';

export default {
  data() {
    return {
      isActivating: false,
    };
  },
  computed: {
    isModelActive() {
      return !!this.model?.active;
    },
    activateModelButtonText() {
      return this.$translate(this.isModelActive ? 'COMMON_DEACTIVATE' : 'COMMON_ACTIVATE');
    },
  },
  methods: {
    activateModel(type = this.model.type) {
      if (this.isActivating) {
        return;
      }
      try {
        this.$loader.show('SAVING_DATA');
        if ([ItemType.SERIAL, ItemType.SEASON].includes(type) && this.isModelActive) {
          const modal = this.$modal.create(UiModalConfirm, {
            title: this.$translate('DEACTIVATE_SERIAL_SEASON'),
            confirmationText: this.$translate('DEACTIVATE_SERIAL_SEASON_CONFIRMATION'),
          });

          modal.$on(AppEvent.SUCCESS, async () => {
            this.activate(type);
            modal.close();
          });
        } else {
          this.activate(type);
        }
        this.$loader.hide();
      } catch (error) {
        this.isActivating = false;
        this.$loader.hide();

        const errorResponseData = error?.response?.data;

        if (Array.isArray(errorResponseData) && !this.isModelActive) {
          this.$modal.create(UiModalActivationErrors, { errors: errorResponseData });
        } else {
          throw error;
        }
      }
    },
    async activate(type) {
      this.isActivating = true;
      await http[this.isModelActive ? 'delete' : 'post'](Urls.TYPE_ID_ACTIVATION(type, this.model.id))
        .then(() => {
          const changedModelFields = { active: !this.isModelActive };
          this.model = { ...this.model, ...changedModelFields };
          this.$refs.childComponent.$refs.uiDetail?.updateModel(changedModelFields);
          this.$refs.childComponent.$refs.uiDetail?.setSerializedModel(this.model);
          this.$modal.create(UiModalPopup, { text: this.$translate('COMMON_SAVE_SUCCESS') });
        })
        .catch((error) => {
          errorHandler(error, this);
        });
      this.isActivating = false;
    },
  },
};
