<template>
  <div class="ui-table-detail-link">
    <router-link
      v-if="id"
      v-tooltip="tooltip || text"
      :to="{ name, params: { id, page }, query }"
      class="ui-table-detail-link__link"
      :class="{ 'pointer-events-none': !isClickAllowed }"
    >
      <slot>{{ text }}</slot>
    </router-link>
  </div>
</template>
<script>
import { RoleMixin } from '@/mixin';
import { UiTableDetailLinkRoleFilters } from '@/enums';

export default {
  name: 'ui-table-detail-link',
  mixins: [RoleMixin],
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      default: 'detail',
    },
    text: String,
    query: Object,
    tooltip: String,
  },
  computed: {
    isClickAllowed() {
      const role = UiTableDetailLinkRoleFilters[this.name];
      return !role || this.hasRole(role);
    },
  },
};
</script>
