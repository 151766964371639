<template>
  <div class="ui-panel">
    <div class="ui-panel__header" data-qa="vod-attachments">
      <h2 class="ui-panel__header-text" v-text="$translate('COMMON_ATTACHMENTS')"></h2>
      <div v-if="isAllowed">
        <v-button-icon
          icon="icon-add"
          tooltip="HELP_ADD"
          class="ui-panel__header-button"
          @click="createModelAndEmitEvent"
        />
      </div>
    </div>
    <ui-table v-bind="tableDefinition" class="ui-table--without-panel" data-qa="vod-attachments-table" />
  </div>
</template>
<script>
import Urls from '@/application/urls';
import { AppEvent, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import VButtonIcon from '@/components/button/button-icon.vue';
import { UiTable } from '@/components/ui-table';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';

export default {
  components: {
    VButtonIcon,
    UiTable,
  },
  mixins: [RemoveModelMixin, SaveModelMixin],
  props: {
    isAllowed: {
      type: Boolean,
      required: true,
    },
    productId: {
      type: Number,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    },
  },
  data() {
    const url = Urls.PRODUCTS_VODS_ID_ATTACHMENTS(this.productId);

    return {
      writeRole: RoleName.CMS_WRITE,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'EDIT_ATTACHMENT' : 'ADD_ATTACHMENT'),
      saveModelFormElementsFunction: (model) => [
        [
          { ...Fields.NAME, 'data-qa': 'vod-attachment-name' },
          { ...Fields.URL_WITH_FILE_UPLOAD(model), 'data-qa': 'vod-attachment-url' },
        ],
      ],
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.NAME,
          Columns.FILE_URL,
          Columns.ACTIONS_EDIT_REMOVE(this.isAllowed, this.editModelAndEmitEvent, this.removeModelAndEmitEvent),
        ],
        records: this.records,
      };
    },
  },
  methods: {
    async createModelAndEmitEvent() {
      await this.createModel();
      this.$emit(AppEvent.CHANGE);
    },
    async editModelAndEmitEvent(model) {
      await this.editModel(model);
      this.$emit(AppEvent.CHANGE);
    },
    async removeModelAndEmitEvent(model) {
      await this.removeModel(model);
      this.$emit(AppEvent.CHANGE);
    },
  },
};
</script>
