<script>
import Urls from '@/application/urls';
import { RoleName } from '@/enums';
import PaymentsList from '@/modules/payment/view/payment/index.vue';
import * as Filters from '@/components/filter/filters';
import * as PaymentFilters from '@/modules/payment/view/filters';

export default PaymentsList.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CMS_WRITE,
      recordsUrl: Urls.PRODUCTS_ID_PAYMENTS(this.id),
      exportUrl: undefined,
      filters: [
        Filters.SUBSCRIBER,
        PaymentFilters.PAYMENT_CHANNEL,
        PaymentFilters.PAYMENT_STATUS,
        PaymentFilters.PAYMENT_SYSTEM,
        Filters.EXTERNAL_UID,
        PaymentFilters.PRICE_GREATER,
        PaymentFilters.PRICE_LESS,
        Filters.SINCE_MANDATORY,
        Filters.TILL_MANDATORY,
      ],
    };
  },
});
</script>
