<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import { UiModalConfirm, UiModalPopup } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { AppEvent, QueryParam, RoleName } from '@/enums';
import http from '@/lib/http';
import ListView from '@/components/list-view/list-view.vue';
import * as Filters from '@/components/filter/filters';
import * as Columns from '@/components/ui-table/columns';
import * as SubscriberColumns from '@/modules/subscriber/view/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import { UiMultiselectOptionWithNameHover } from '@/components/ui-multiselect';

export default ListView.extend({
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS,
      exportUrl: Urls.SUBSCRIBERS_EXPORT,
      filters: [
        Filters.ACTIVE,
        Filters.multiSwitch('tester', { label: 'COMMON_TESTER' }),
        Filters.datePicker('createdAtSince', { label: 'SUBSCRIBER_REGISTRATION_DATE_SINCE', timepicker: true }),
        Filters.datePicker('createdAtTill', { label: 'SUBSCRIBER_REGISTRATION_DATE_TILL', timepicker: true }),
        Filters.datePicker('lastLoggedAtSince', { label: 'SUBSCRIBER_LAST_LOGIN_DATE_SINCE', timepicker: true }),
        Filters.datePicker('lastLoggedAtTill', { label: 'SUBSCRIBER_LAST_LOGIN_DATE_TILL', timepicker: true }),
        Filters.EXTERNAL_UID,
        Filters.autocomplete('agreements', {
          label: 'COMMON_AGREEMENT',
          dictType: 'AGREEMENT',
          field: 'key',
          slots: {
            option: UiMultiselectOptionWithNameHover,
          },
        }),
        Filters.autocomplete('groups', {
          label: 'COMMON_GROUPS',
          dictUrl: Urls.SUBSCRIBERS_GROUPS,
          field: 'key',
          slots: {
            option: UiMultiselectOptionWithNameHover,
          },
        }),
      ],
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            ...Columns.SUBSCRIBER_DETAIL((record) => ({ subscriber: record })),
            field: 'email',
            headingLabel: 'COMMON_EMAIL',
          },
          { ...Columns.CREATED_AT, headingLabel: 'SUBSCRIBER_REGISTRATION_DATE' },
          Columns.LAST_LOGIN_DATE,
          SubscriberColumns.ACTIONS_SUBSCRIBER(this.isAllowed, this.erase, this.resendVerificationEmail),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
  methods: {
    resendVerificationEmail(subscriberId) {
      return http.post(Urls.SUBSCRIBERS_ID_VERIFICATION_EMAIL_RESEND(subscriberId)).then(() => this.$modal.create(UiModalPopup, {
        text: this.$translate('SUBSCRIBER_ACTIVATION_EMAIL_SENT'),
      }));
    },
    erase(id) {
      const modal = this.$modal
        .create(UiModalConfirm, {
          title: this.$translate('SUBSCRIBER_ERASE_TITLE'),
          confirmationText: this.$translate('SUBSCRIBER_ERASE_CONFIRMATION'),
        })
        .$on(AppEvent.SUCCESS, () => http
          .post(Urls.SUBSCRIBERS_ID_ERASE(id))
          .then(() => {
            modal.close();
            this.load();
          })
          .catch((error) => {
            modal.close();
            throw error;
          }));
    },
  },
});
</script>
