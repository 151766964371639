<script>
import Urls from '@/application/urls';
import AuditList from '@/modules/administration/view/audit/index.vue';

export default AuditList.extend({
  data() {
    return {
      recordsUrl: Urls.AUDITS_GROUP_NAME('PRODUCT_GROUP'),
      exportUrl: Urls.AUDITS_GROUP_NAME_EXPORT('PRODUCT_GROUP'),
    };
  },
});
</script>
