<template>
  <v-list-wrapper :search="false" :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { formatHourAndMinutes } from '@/helper';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as SubscriberColumns from '@/modules/subscriber/view/columns';
import { QueryParam } from '@/enums';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      recordsUrl: Urls.SUBSCRIBERS_ID_BOOKMARKS_TYPE(this.id, 'WATCHED'),
      paramsConfig: {
        sort: QueryParam.PROFILE_NAME,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          SubscriberColumns.BOOKMARKS_PROFILE_NAME,
          Columns.PRODUCT_DETAIL(({ item }) => ({ product: item })),
          {
            field: 'playTime',
            width: 200,
            headingLabel: 'SUBSCRIBER_BOOKMARK_WATCHED_PLAYTIME',
          },
          {
            field: 'duration',
            width: 160,
            headingLabel: 'PRODUCT_DURATION',
            dataMapper: ({ item }) => formatHourAndMinutes(item.duration),
          },
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
        ],
        records: this.records,
      };
    },
  },
});
</script>
