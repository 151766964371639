<template>
  <div v-if="ready" style="height: 100%">
    <app-header v-if="isOperatorLogged" />
    <div id="main-container" :class="{ 'main-container--with-header': isOperatorLogged }">
      <p v-if="isOperatorLogged && errorInfo" class="app__info" v-text="errorInfo"></p>
      <template v-else>
        <app-menu v-if="isOperatorLogged" />
        <router-view :key="i18n.contentLanguage" class="content" />
      </template>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {
  UiModalPlugin, UiModalPopup, dateFormat, getIsAdblockDetected, tooltip,
} from 'redge-media-web-ui';
import operator from '@/application/operator';
import info from '@/application/info';
import i18n from '@/application/i18n';
import { AppEvent, Constants } from '@/enums';
import UiLoader from '@/plugins/loader';
import AppMenu from '@/layout/app-menu.vue';
import AppHeader from '@/layout/app-header.vue';
import { translate } from '@/helper';
import errorHandler from '@/helper/error-handler';
import router from '@/router';

Vue.config.productionTip = false;
Vue.config.errorHandler = errorHandler;
Vue.config.devtools = process.env.VUE_APP_DEVTOOLS === 'true';
Vue.use(UiLoader);
Vue.use(UiModalPlugin);

Vue.directive('tooltip', tooltip());
Vue.prototype.$translate = translate;
Vue.prototype.$dateFormat = dateFormat;

export default Vue.extend({
  name: 'App',
  router,
  components: {
    AppHeader,
    AppMenu,
  },
  data() {
    return {
      ready: false,
      operator,
      i18n,
      rtlLanguages: process.env.VUE_APP_RTL_LANGUAGES,
    };
  },
  computed: {
    errorInfo() {
      if (!operator.tenants.length) {
        return this.$translate('COMMON_NO_TENANTS_INFO');
      }

      if (!operator.availableSections.length) {
        return this.$translate('COMMON_NO_ROLES_INFO');
      }

      return false;
    },
    isRtl() {
      return this.rtlLanguages.includes(this.i18n.contentLanguage);
    },
    isOperatorLogged() {
      return operator.isLogged;
    },
  },
  watch: {
    isRtl: {
      handler(value) {
        value ? document.documentElement.setAttribute('dir', 'rtl') : document.documentElement.removeAttribute('dir');
      },
      immediate: true,
    },
    isOperatorLogged() {
      if (this.isOperatorLogged && (!i18n.languages.length || !i18n.contentLanguages)) {
        i18n.fetch();
      }
    },
  },
  async created() {
    await operator.fetch().catch((error) => console.error(error));
    await info[this.isOperatorLogged ? 'fetchInfo' : 'fetchAuthInfo']();
    await this.showAdblockModal();

    this.ready = true;
  },
  methods: {
    async showAdblockModal() {
      const adBlockKey = Constants.AD_BLOCK_KEY;

      if (!localStorage.getItem(adBlockKey) && (await getIsAdblockDetected())) {
        const modal = this.$modal.create(UiModalPopup, {
          hideAfter: 600000, // 10 min
          text: this.$translate('AD_BLOCK_MODAL_CONTENT'),
        });

        modal.$on(AppEvent.CLOSE, () => {
          modal.close();
          localStorage.setItem(adBlockKey, 'true');
        });
      }
    },
  },
});
</script>
