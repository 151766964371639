<template>
  <v-list-wrapper :table-definition="tableDefinition">
    <template #avatar="{ record }">
      <span v-if="record.avatar" :style="getAvatarStyle(record.avatar)" class="ui-table-poster"></span>
    </template>
    <template #name="{ record }">
      <div v-if="record.name" class="break-word" v-text="record.name"></div>
    </template>
  </v-list-wrapper>
</template>
<script>
import Urls from '@/application/urls';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import { QueryParam } from '@/enums';

export default ListView.extend({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      recordsUrl: Urls.SUBSCRIBERS_ID_PROFILES(this.id),
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  methods: {
    getAvatarStyle({ url = '' }) {
      return {
        'background-image': `url('${url}')`,
      };
    },
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          {
            field: 'avatar',
            width: 100,
            headingLabel: 'COMMON_AVATAR',
          },
          {
            field: 'name',
            width: 150,
            isSortable: true,
            headingLabel: 'COMMON_NAME',
          },
          {
            field: 'nickname',
            width: 150,
            isSortable: true,
            headingLabel: 'COMMON_NICKNAME',
          },
          {
            field: 'uid',
            minWidth: 150,
            isSortable: true,
            headingLabel: 'COMMON_UID',
          },
          {
            field: 'defaultSubtitle',
            width: 160,
            headingLabel: 'SUBSCRIBER_PROFILE_DEFAULT_SUBTITLE',
          },
          {
            field: 'defaultSoundtrack',
            width: 160,
            headingLabel: 'SUBSCRIBER_PROFILE_DEFAULT_SOUNDTRACK',
          },
          {
            field: 'maxRating',
            width: 120,
            headingLabel: 'COMMON_RATING',
          },
          Columns.CREATED_AT,
        ],
        records: this.records,
      };
    },
  },
});
</script>
