<script>
import Urls from '@/application/urls';
import http from '@/lib/http';
import { RoleName } from '@/enums';
import { SaveModelMixin } from '@/mixin';
import { priceFormat } from '@/helper';
import WalletPaymentsList from '@/modules/payment/view/wallet/index.vue';
import * as Fields from '@/components/ui-form/fields';

export default WalletPaymentsList.extend({
  mixins: [SaveModelMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.SUBSCRIBERS_ID_WALLET(this.id),
      saveModelUrlFunction: () => Urls.SUBSCRIBERS_ID_WALLET(this.id),
      saveModelModalTitleFunction: () => 'SUBSCRIBER_WALLET_FUND_TITLE',
      saveModelFormElementsFunction: () => [[Fields.priceInputField('amount', 'SUBSCRIBER_WALLET_QUANTITY', true)]],
      isSubscriberView: true,
    };
  },
  watch: {
    async records() {
      const { data } = await http.get(Urls.SUBSCRIBERS_ID_WALLET_FREE_AMOUNT(this.id));
      this.amountFree = priceFormat(data);
    },
  },
});
</script>
