<template>
  <v-list-wrapper :table-definition="tableDefinition" />
</template>
<script>
import Urls from '@/application/urls';
import { QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Columns from '@/components/ui-table/columns';
import * as Fields from '@/components/ui-form/fields';
import * as Filters from '@/components/filter/filters';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';

export default ListView.extend({
  mixins: [SaveModelMixin, RemoveModelMixin],
  data() {
    const url = Urls.ADS_SERVERS;
    return {
      writeRole: RoleName.ADMIN,
      recordsUrl: url,
      removeModelUrlFunction: ({ id }) => `${url}/${id}`,
      saveModelUrlFunction: ({ id }) => (id ? `${url}/${id}` : url),
      saveModelModalTitleFunction: ({ id }) => (id ? 'COMMON_EDIT_ADS_SERVER' : 'COMMON_EDIT_ADS_SERVER'),
      saveModelFormElementsFunction: () => [
        [
          Fields.textInputField('name', 'AD_SERVER_NAME', true),
          Fields.textInputField('url', 'COMMON_URL', true),
          Fields.TYPE(Urls.ADS_SERVERS_TYPES),
        ],
      ],
      filters: [Filters.input('names', { label: 'AD_SERVER_NAME' }), Filters.input('urls', { label: 'COMMON_URL' })],
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.ITEM_NAME(({ id, name }) => ({ id, text: name, name: 'servers.detail' })),
          Columns.TYPE,
          Columns.FILE_URL,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
