<template>
  <v-list-wrapper :table-definition="tableDefinition">
    <template #name="{ record: { id, name } }">
      <ui-table-detail-link :id="id" name="promotions.detail" :tooltip="name">
        <ui-item-title :item="{ title: name }" no-title-label="COMMON_NO_NAME" />
      </ui-table-detail-link>
    </template>
  </v-list-wrapper>
</template>
<script>
import dayjs from 'dayjs';
import { UiFormCheckbox } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import { DiscountType, QueryParam, RoleName } from '@/enums';
import { RemoveModelMixin, SaveModelMixin } from '@/mixin';
import ListView from '@/components/list-view/list-view.vue';
import * as Filters from '@/components/filter/filters';
import * as Columns from '@/components/ui-table/columns';
import { inactiveTableRowModifier } from '@/components/ui-table/helpers';
import * as Fields from '@/components/ui-form/fields';
import * as PaymentFields from '@/modules/payment/view/fields';
import { UiTableDetailLink } from '@/components/ui-table';
import UiItemTitle from '@/components/ui-item-title/index.vue';
import { commonTooltip } from '@/components/ui-form/fields';

export default ListView.extend({
  components: {
    UiTableDetailLink,
    UiItemTitle,
  },
  mixins: [RemoveModelMixin, SaveModelMixin],
  data() {
    return {
      writeRole: RoleName.CRM_WRITE,
      recordsUrl: Urls.PROMOTIONS,
      removeModelUrlFunction: ({ id }) => Urls.PROMOTIONS_ID(id),
      saveModelUrlFunction: () => Urls.PROMOTIONS,
      saveModelModalTitleFunction: () => 'ADD_PROMOTION',
      saveModelFormElementsFunction: (model) => [
        [
          Fields.TYPE(Urls.PROMOTIONS_TYPES),
          {
            field: 'staticType',
            component: UiFormCheckbox,
            isMandatory: true,
            componentOptions: {
              label: 'STATIC_TYPE',
              tooltip: commonTooltip('HELP_STATIC_TYPE'),
            },
          },
          Fields.NAME,
          PaymentFields.PROMOTION_FREE_ACCESS_DURATION(model),
          Fields.CREATE_SINCE(),
          Fields.CREATE_TILL(),
        ],
      ],
      saveModelDefaultValues: {
        type: DiscountType.FREE_ACCESS,
        staticType: true,
        since: dayjs().startOf('day').valueOf(),
      },
      filters: [
        Filters.autocomplete('types', { label: 'COMMON_TYPE', dictUrl: Urls.PROMOTIONS_TYPES }),
        Filters.ACTIVE,
      ],
      paramsConfig: {
        sort: QueryParam.CREATED_AT,
        order: QueryParam.DESCENDING,
      },
    };
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          Columns.NAME,
          Columns.TYPE,
          Columns.SINCE,
          Columns.TILL,
          Columns.CREATED_AT,
          Columns.MODIFIED_AT,
          Columns.ACTIONS_REMOVE(this.isAllowed, this.removeModel),
        ],
        records: this.records,
        rowClassModifierFunction: (record) => inactiveTableRowModifier(!record.displayActive),
      };
    },
  },
});
</script>
